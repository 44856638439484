import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const personHTTP = () => {
	const { get } = HttpService();

	const getPersonPrimaries = (personId) => {
		const url = `${BASE_URL}/api/person-management/person/${personId}/primary/data`;
		return get(url, 'GetPrimaries', []);
	};

	const getPersonClassifications = () => {
		const url = `${BASE_URL}/api/person-management/classifications`;
		return get(url, 'GetPersonClassifications', []);
	};

	return {
		getPersonPrimaries,
		getPersonClassifications
	};
};
