import { useRef, useState } from 'react';
import { isValid } from 'date-fns';
import { formatDateByYYYYMMDD } from '../utils';

export const usePartyLookup = () => {
	const timeoutRef = useRef(null);
	const [search, setSearch] = useState({});
	const searchRef = useRef({});
	const [clearTrigger, setClearTrigger] = useState(0);
	const onChange = (field, value) => {
		setClearTrigger(Date.now());
		const newState = { ...searchRef.current, [field]: value };
		for (const field in newState) {
			if (newState[field] === '' || newState[field] === undefined || newState[field] === null) {
				delete newState[field];
			}

			if (isValid(new Date(newState.birth))) {
				newState.birth = formatDateByYYYYMMDD(newState.birth);
			} else {
				delete newState.birth;
			}

			if (field === 'dateOfBirth') {
				if (newState[field] && isValid(new Date(newState[field]))) {
					newState.dateOfBirth = formatDateByYYYYMMDD(newState.dateOfBirth);
				} else {
					delete newState.dateOfBirth;
				}
			}
		}
		searchRef.current = newState;
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => setSearch(searchRef.current), 1000);
	};

	const resetSearchParams = () => {
		searchRef.current = null;
		setSearch('');
	};
	return { search, onChange, clearTrigger, resetSearchParams };
};
