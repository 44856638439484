import Form from 'react-bootstrap/Form';

import styles from './styles.module.scss';
import { RequiredMark } from '../../../components/common';
import AffectsReportAlertTooltip from '../../AffectsReportAlertTooltip/AffectsReportAlertTooltip';

const CheckboxInput = ({
	id = '',
	label = '',
	className = '',
	name = '',
	testId,
	onChecked = () => {},
	checked = false,
	disabled = false,
	required = false,
	ariaLabel = '',
	isReportAffected
}) => {
	const handleCheck = (e) => {
		const {
			target: { checked: check }
		} = e;
		onChecked(check);
	};
	const labelToShow = (
		<AffectsReportAlertTooltip isReportAffected={isReportAffected}>
			{required ? (
				<>
					{label}
					<RequiredMark />
				</>
			) : (
				label
			)}
		</AffectsReportAlertTooltip>
	);
	return (
		<Form.Group className={`checkbox-container ${className}${disabled ? ' is-disabled' : ''}`}>
			<Form.Check
				className={`${styles.formCheckInput} ${disabled ? 'notAllowed' : ''}`}
				id={id}
				data-testid={testId}
				name={name}
				onChange={handleCheck}
				checked={checked}
				label={labelToShow}
				disabled={disabled}
				aria-label={isReportAffected ? `${label}, ${isReportAffected?.tooltip}.` : label}
			/>
		</Form.Group>
	);
};

export default CheckboxInput;
