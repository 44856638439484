import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	inputFields: []
};

const isReportAffectedSlice = createSlice({
	name: 'isReportAffected',
	initialState,
	reducers: {
		setPages(state, action) {
			state.inputFields = action.payload;
		}
	}
});

export const { setPages } = isReportAffectedSlice.actions;
export default isReportAffectedSlice.reducer;
