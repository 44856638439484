import { Button } from 'react-bootstrap';
import Dialog from '../../components/common/Dialog';

export const logInDialog = {
	children: (
		<div className="d-flex flex-md-row flex-column justify-content-around gap-4 align-items-start">
			<span>
				You are accessing a restricted information system. System usage may be monitored, recorded,
				and subject to audit. Unauthorized use of the system is prohibited and may be subject to
				criminal and/or civil penalties. Use of the system indicates consent to monitoring and
				recording.
			</span>
		</div>
	),
	title: 'System Use Notification',
	submitText: 'Proceed',
	cancelText: 'Logout',
	type: 'success'
};

const ModalDialogLogin = ({ isOpenLoginModal, closeLoginModal, setConfirm }) => {
	const children = (
		<div className="d-flex flex-md-row flex-column justify-content-around gap-4 align-items-start">
			<i className="fas fa-exclamation-circle fa-3x text-success"></i>
			<span>
				You are accessing a restricted information system. System usage may be monitored, recorded,
				and subject to audit. Unauthorized use of the system is prohibited and may be subject to
				criminal and/or civil penalties. Use of the system indicates consent to monitoring and
				recording.
			</span>
		</div>
	);
	const title = 'System Use Notification';

	const footer = (
		<>
			<Button
				variant="primary"
				onClick={() => {
					setConfirm(true);
					closeLoginModal();
				}}
			>
				Proceed
			</Button>
			<Button
				variant="secondary"
				onClick={() => {
					setConfirm(false);
					closeLoginModal();
				}}
			>
				Sign Out
			</Button>
		</>
	);

	return (
		<Dialog
			isShow={isOpenLoginModal}
			children={children}
			title={title}
			footer={footer}
			centered={true}
			closeButton={false}
			size="lg"
		></Dialog>
	);
};

export default ModalDialogLogin;
