import React, { useRef } from 'react';
import { Button, Container, Form, Row } from 'react-bootstrap';
import CanvasDraw from 'react-canvas-draw';
import { Formik } from 'formik';
import { RequiredMark, CheckboxInput } from '../../common';
import Error from '../../../pages/Case/Error';
import styles from './styles.module.scss';

const CreateSignatureDrawForm = ({
	setSignatureImage,
	setAcceptTerms,
	acceptTerms,
	validate,
	onSubmit,
	termsAlert
}) => {
	const saveableCanvas = useRef(null);
	const initialValues = { files: '', signatureAuthorization: false };

	const signatureOnCheck = () => {
		setAcceptTerms(true);
		const dataURL = saveableCanvas.current.getDataURL();
		fetch(dataURL).then((res) =>
			res.blob().then((blob) => {
				setSignatureImage(blob);
			})
		);
	};

	return (
		<>
			<Formik initialValues={initialValues} validationSchema={validate} onSubmit={onSubmit}>
				{(formik) => (
					<Form onSubmit={formik.handleSubmit} id="signatureDrawForm">
						<Row>
							<Form.Label htmlFor="drawSignature">
								Sign Below
								<RequiredMark />
							</Form.Label>

							<Container className={styles.drawSignature}>
								<CanvasDraw
									ref={saveableCanvas}
									brushColor={'#000000'}
									brushRadius={1}
									lazyRadius={10}
								/>
								<div className="d-flex flex-row gap-2">
									<Button variant="secondary" onClick={() => saveableCanvas.current.eraseAll()}>
										Erase
									</Button>
									<Button variant="secondary" onClick={() => saveableCanvas.current.undo()}>
										Undo
									</Button>
								</div>
							</Container>
						</Row>
						<Row>
							<CheckboxInput
								className="mt-3"
								id="signatureAuthorization"
								label="By checking this box, I understand that this is a legal representation of my signature."
								name="signatureAuthorization"
								required={true}
								val={acceptTerms}
								checked={acceptTerms}
								onChecked={signatureOnCheck}
							/>
							<Error
								condition={termsAlert}
								msg={'Please provide signature then accept terms and conditions.'}
							/>
						</Row>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CreateSignatureDrawForm;
