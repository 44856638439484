import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import { useDispatch } from 'react-redux';
import { initUserInfo } from '../redux/userDucks';
import { useCookies } from 'react-cookie';
import promiseAlert from '../components/PromiseAlert';
import { logInDialog } from '../pages/LoginPage/ModalDialogLogin';
import { format } from 'date-fns';
import resetModal from '../components/ResetLoginModal';
import { loginActivate } from '../pages/LoginPage/loginActive';
import { removeAutoSaveData } from '../utils/autoSave';
import { BASE_URL, SUCCESFUL_REQUEST_CODE } from '../utils';
import { checkIfExpired } from '../pages/LoginPage/PasswordExpirationModal';
import { NOOP } from '../constants';

const initialStore = {
	token: ''
};

export const AuthContext = createContext(initialStore);

export const AuthProvider = ({ children, initialIdToken }) => {
	const navigate = useNavigate();
	const { setValueInStorage, getValueFromStorage, removeValueFromStorage } = useLocalStorage();
	const [token, setAccessToken] = useState(initialIdToken ? initialIdToken : '');
	const [dualSession, setDualSession] = useState(true);
	const dispatch = useDispatch();
	const [cookies, setCookie, removeCookie] = useCookies(['session_token']);
	const username = JSON.parse(getValueFromStorage('username'));

	const getIfMFA = async () => {
		const mfa = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/public/password-policy/mfa-active`
		);
		return mfa.data.mfaAllowed;
	};

	const repeatedSession = async () => {
		try {
			let res = await promiseAlert({
				title: 'Concurrent Session Notification',
				children:
					'You currently have an active session of the UCMS system. To establish another session, select Continue, otherwise select Cancel.',
				submitText: 'Continue',
				cancelText: 'Cancel'
			});

			if (res) {
				return true;
			}
		} catch (error) {
			console.warn(`User '${username}' cancelled concurrent session login.`);
			return false;
		}
	};

	const login = async ({
		credentials = {},
		setErrorMessage = NOOP,
		onSuccess = NOOP,
		onFailure = NOOP
	}) => {
		try {
			const body = {
				client_id: process.env.REACT_APP_CLIENT_ID_AUTH0,
				username: credentials.username,
				password: credentials.password,
				realm: 'Username-Password-Authentication',
				credential_type: 'http://auth0.com/oauth/grant-type/password-realm'
			};

			const response = await axios.post(`${BASE_URL}/api/authenticate`, body);

			if (SUCCESFUL_REQUEST_CODE.includes(response.status)) {
				try {
					// Await user confirmation
					let res = await promiseAlert(logInDialog);
					if (res) {
						const {
							passwordExpirationWarningFlag,
							passwordExpirationDate,
							mandatoryPasswordResetFlag
						} = await checkIfExpired(credentials.username);

						if (passwordExpirationWarningFlag || mandatoryPasswordResetFlag) {
							await resetModal({
								expireDate: format(new Date(passwordExpirationDate), 'MM/dd/yyyy'),
								mandatory: mandatoryPasswordResetFlag
							});
						}
						setAccessToken(response?.data?.access_token);

						setCookie('session_token', response?.data?.access_token, {
							path: '/',
							maxAge: response?.data?.expires_in,
							secure: true
						});

						onSuccess(response?.data?.access_token);
					}
				} catch (error) {
					console.error('Unable to proceed with login.', error);
					onFailure();
				}
			} else {
				setErrorMessage({
					message: 'Unable to authenticate user.',
					show: true
				});
				onFailure();
			}
		} catch (error) {
			switch (error.response.status) {
				case 302:
				case 409:
					await resetModal({
						expireDate: format(new Date(), 'MM/dd/yyyy'),
						mandatory: true
					});
					break;
				case 400:
				case 401:
				case 403:
					setErrorMessage({
						message: 'Invalid username and/or password.',
						show: true
					});
					onFailure();
					break;
				default:
					setErrorMessage({
						message: 'Unable to authenticate user.',
						show: true
					});
					onFailure();
					break;
			}
		}
	};

	const storeAccessToken = (idToken) => {
		setAccessToken(idToken);
		setCookie('session_token', idToken, { path: '/' });
	};

	let logout = async () => {
		// Track logout
		await loginActivate(username, token, 'logout');

		// Clearing all session cookies
		const sessionCookies = document.cookie.split(';');

		for (let i = 0; i < sessionCookies.length; i++) {
			const cookie = sessionCookies[i];

			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;

			removeCookie(name, { path: '/' });
		}

		removeCookie('session_token');

		// Remove auto-save from local storage
		removeAutoSaveData();

		setAccessToken('');

		dispatch((dispatch) => {
			dispatch({
				type: 'LOG_OUT'
			});
		});

		navigate('/login');
	};

	const exchangeAuthCode = async (user, authCode) => {
		const authResult = await axios.post(
			`${process.env.REACT_APP_API_URL}/public/access/token`,
			{
				url: `${process.env.REACT_APP_BASE_URL}/login`,
				code: authCode
			},
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);

		const { access_token: accessToken } = authResult.data;
		storeAccessToken(accessToken);
	};

	const checkLoginToken = async () => {
		try {
			if (token && username) {
				const res = await loginActivate(username, token, 'login');

				if (res?.message === `${username} cannot login, last session is active`) {
					console.warn(`User '${username}' has initiated session in another device`);
					removeCookie('session_token', { path: '/' });
					setAccessToken('');
					navigate('/login');
				}
			}
		} catch (error) {
			console.error('Check for active login failed', error);
		}
	};

	useEffect(() => {
		const loginCheckInterval = setInterval(checkLoginToken, 60000);
		const userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;

		if (userInfo) {
			dispatch(initUserInfo(userInfo));
		}

		return () => clearInterval(loginCheckInterval);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	return (
		<AuthContext.Provider
			value={{
				token,
				login,
				exchangeAuthCode,
				logout,
				repeatedSession,
				dualSession
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
