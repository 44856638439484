import { useRef, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import { HomeAdminService } from '../../services/HomeAdminService';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FieldFormTextInput } from '../../components/forms';

const MainSectionDialog = (props) => {
	const { insertBookmark, updateBookmark } = HomeAdminService();

	const formRef = useRef();

	const initialValues = {
		id: props.dialogData.id,
		name: props.dialogData.name,
		description: props.dialogData.description,
		categoryId: props.dialogData.categoryId,
		url: props.dialogData.url
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Bookmark name is required'),
		description: Yup.string().required('Bookmark description is required')
	});

	const handleInviteUser = async (data) => {
		const { name, description } = data;

		const body = {
			id: props.dialogData.id,
			name: name,
			description: description,
			categoryId: props.dialogData.categoryId,
			url: props.dialogData.url,
			userId: null
		};

		if (props.dialogData.id === 0 || props.dialogData.id === '0') {
			insertBookmark(body, props.dialogData.userId.username).then((res) => {
				props.checkBookmark();
				props.saveClose();
			});
		} else {
			updateBookmark(body, props.dialogData.id, props.dialogData.userId.username).then((res) => {
				props.saveClose();
				props.checkBookmark();
				if (props.alldataIni !== undefined) {
					props.alldataIni();
				}
			});
		}
	};

	useEffect(() => {
		if (props.setSubmition !== false) {
			if (formRef.current) {
				formRef.current.handleSubmit();
			}
		}
	}, [props.setSubmition, props.dialogData]);

	return (
		<Formik
			innerRef={formRef}
			initialValues={initialValues}
			onSubmit={handleInviteUser}
			validationSchema={validationSchema}
		>
			{(formik) => {
				return (
					<Form>
						<Row className="mb-4">
							<Col xs="12" md="12" className="mb-3">
								<FieldFormTextInput
									id="name"
									label="Name of Bookmark"
									required
									value={formik.values.name}
									onChange={formik.handleChange}
								/>
							</Col>

							<Col xs="12" md="12" className="mb-3">
								<FieldFormTextInput
									id="description"
									label="Description of Bookmark"
									required
									value={formik.values.description}
									onChange={formik.handleChange}
								/>
							</Col>
						</Row>
					</Form>
				);
			}}
		</Formik>
	);
};
export default MainSectionDialog;
