import styles from './styles.module.scss';

/**
 * Component to display required mark with its style
 *
 */

const RequiredMark = () => (
	<>
		<strong className={styles.isRequired} aria-hidden="true">
			*
		</strong>
		<span className="sr-only">required</span>
	</>
);

export default RequiredMark;
