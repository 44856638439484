import { Modal } from 'react-bootstrap';

import styles from './styles.module.scss';

const AlertDialog = ({
	open,
	onClose,
	title,
	children,
	footer,
	type = 'error',
	closeable = true,
	hasBackdrop = false,
	size = 'lg'
}) => {
	return (
		<Modal
			show={open}
			onHide={onClose}
			backdrop="static"
			animation={false}
			keyboard={false}
			size={size}
			aria-label={title}
			centered
			backdropClassName={hasBackdrop ? styles.backdrop : ''}
			role="alert"
		>
			<Modal.Header closeLabel={`${title} Close Dialog`} closeButton={closeable}>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex flex-md-row flex-column align-items-start justify-content-start gap-3 p-4">
				<i
					className={`fas fa-exclamation-circle fa-3x ${
						type === 'success' ? 'text-success' : 'text-danger'
					}`}
				></i>
				<span>{children}</span>
			</Modal.Body>
			<Modal.Footer>{footer}</Modal.Footer>
		</Modal>
	);
};

export default AlertDialog;
