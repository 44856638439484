import axios from 'axios';
import { BASE_URL } from '../../utils/apiUtils';

export const checkIfExpired = async (username) => {
	try {
		const res = await axios.get(
			`${BASE_URL}/api/public/password-policy/check-password-expiration?username=${username}`
		);
		return {
			...res.data
		};
	} catch (error) {
		console.log(error);
	}
};
