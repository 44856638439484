import React from 'react';

import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import { SelectInput } from '../common';
import { DEFAULT_SELECT_OPTION } from '../../constants';

export const FieldSelectInput = ({
	id,
	label = '',
	options,
	required,
	onChange,
	getError,
	disabled,
	className = '',
	isSearchable,
	placeholderText = '',
	isReportAffected,
	positionAbsolute,
	inputRef = null,
	disableColorRenderThroughLabelValue = false,
	isClearable = false
}) => (
	<Field name={id}>
		{({ field, form }) => (
			<>
				<SelectInput
					id={field.name}
					name={field.name}
					inputRef={inputRef}
					label={label}
					required={required}
					options={options}
					value={
						options?.find(
							(option) => JSON.stringify(option.value) === JSON.stringify(field.value)
						) || DEFAULT_SELECT_OPTION
					}
					onChange={(option) => {
						form.setFieldValue(field.name, option?.value ?? null);
						onChange?.(option);
					}}
					onBlur={field.onBlur}
					isInvalid={!!form.touched[field.name] && !!form.errors[field.name]}
					className={`field-select-input ${className}`}
					isDisabled={disabled}
					isSearchable={isSearchable}
					placeHolder={placeholderText}
					isReportAffected={isReportAffected}
					disableColorRenderThroughLabelValue={disableColorRenderThroughLabelValue}
					isClearable={isClearable}
				/>
				<ErrorMessage
					render={(error) => {
						const errorMessage = getError?.(error, field, form) || error;
						return (
							<span
								role="alert"
								className={`small text-danger ${positionAbsolute ? 'position-absolute' : ''}`}
							>
								{errorMessage}
							</span>
						);
					}}
					name={field.name}
				/>
			</>
		)}
	</Field>
);

FieldSelectInput.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	options: PropTypes.array.isRequired,
	required: PropTypes.bool,
	onChange: PropTypes.func
};
