const Error = ({ msg, condition, requiredField }) => (
	<>
		{condition ? (
			<span className="text-danger form-text" role="alert">
				{requiredField ? `${requiredField} is required.` : msg}
			</span>
		) : (
			''
		)}
	</>
);
export default Error;
