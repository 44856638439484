import { useBackendPaginationFetcher } from './useBackendPaginationFetcher';

export const usePaymentMethodData = (type) => {
	const { data } = useBackendPaginationFetcher({
		url: `/api/payment/codes/${type}`,
		key: 'paymentCodes',
		mapper: ({ paymentCode, description }) => ({ value: paymentCode, label: description }),
		constantSize: 1000
	});

	return { methodsOption: data };
};
