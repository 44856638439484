import { Modal, Container, Row } from 'react-bootstrap';

const ModalDialog = ({
	isOpenModal,
	closeModal,
	title,
	children,
	footer,
	centered = true,
	animation,
	size
}) => {
	return (
		<Modal
			size={size ? size : 'lg'}
			aria-label={title}
			centered={centered}
			animation={animation}
			show={isOpenModal}
			onHide={closeModal}
			backdrop="static"
		>
			<Modal.Header closeLabel={`${title} Close Dialog`} closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row className="my-3 mx-2">{children}</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>{footer}</Modal.Footer>
		</Modal>
	);
};

export default ModalDialog;
