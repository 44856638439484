import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setHistoryPaths } from '../redux/pathsHistory';

export const useSaveHistory = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setHistoryPaths(location.pathname));
	}, [location, dispatch]);
};
