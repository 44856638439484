import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { isValid, addYears } from 'date-fns';
import { Form } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { useConfigs } from '../../hooks/useConfigs';
import { timezoneFormat } from '../../context/ConfigsContext/utils';
import { RequiredMark } from '../common';
import DatePickerMask from '../DatePickerMask';
import { convertISODateFormatToDateExcludingTime } from '../../utils';

const Icon = ({ fRef }) => (
	<i
		className={`far fa-calendar`}
		onClick={() => fRef?.current?.onInputClick()}
		style={{
			float: 'right',
			position: 'relative',
			bottom: '50%',
			right: '10%',
			transform: 'translate(4px,-28px)',
			cursor: 'pointer'
		}}
	></i>
);

export const FormDatePicker = ({
	id,
	label,
	placeholder = 'mm/dd/yyyy',
	dateFormat = 'MM/dd/yyyy',
	onChange,
	onBlur,
	maxDate = addYears(new Date(), 100),
	minDate = new Date(1900, 0, 1),
	isInvalid,
	disabled,
	required,
	value,
	onKeyDown,
	arialabel
}) => {
	const ref = useRef(null);
	const { timeZone } = useConfigs();

	const handleDataChange = (value) => {
		const formattedDate = DateTime.fromJSDate(value, { setZone: true })
			.setZone(timezoneFormat(timeZone), { keepLocalTime: true })
			.toISO();
		onChange?.(formattedDate || '');
	};
	const selectedDate = value
		? value instanceof Date
			? new Date(value.getFullYear(), value.getMonth(), value.getDay())
			: convertISODateFormatToDateExcludingTime(value)
		: null;
	return (
		<Form.Group controlId={id}>
			{label && (
				<Form.Label>
					{label}
					{required && <RequiredMark />}
				</Form.Label>
			)}
			<DatePicker
				id={id}
				name={id}
				placeholderText={placeholder}
				dateFormat={dateFormat}
				peekNextMonth
				selected={selectedDate}
				showMonthDropdown
				showYearDropdown
				onBlur={
					onBlur
						? onBlur
						: (event) => {
								const date = new Date(event?.target.value);
								if (isValid(date)) {
									handleDataChange(date);
								}
						  }
				}
				dropdownMode="select"
				ref={ref}
				onChange={handleDataChange}
				className={`form-control ${isInvalid ? 'is-invalid' : ''} ${disabled ? 'notAllowed' : ''}`}
				maxDate={maxDate}
				minDate={minDate}
				disabled={disabled}
				autoComplete="off"
				required={required}
				onKeyDown={onKeyDown}
				customInput={
					<DatePickerMask
						ref={ref}
						arialabel={arialabel}
						mindate={minDate}
						maxdate={maxDate}
						rendericon={'false'}
						dateformat={dateFormat}
					/>
				}
			/>
			{!isInvalid && <Icon fRef={ref} />}
		</Form.Group>
	);
};
