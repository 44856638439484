// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_toastContainer__rmY\\+a {\n  width: -moz-fit-content;\n  width: fit-content;\n  z-index: 1056;\n}\n\n.styles_toast__qiD25 {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/ToastAlert/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;EAAA,kBAAA;EACA,aAAA;AACD;;AAEA;EACC,WAAA;AACD","sourcesContent":[".toastContainer {\n\twidth: fit-content;\n\tz-index: 1056; // modal has 1055\n}\n\n.toast {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastContainer": "styles_toastContainer__rmY+a",
	"toast": "styles_toast__qiD25"
};
export default ___CSS_LOADER_EXPORT___;
