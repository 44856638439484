import { DateTime } from 'luxon';
import React, { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { timezoneFormat } from '../../context/ConfigsContext/utils';
import { useConfigs } from '../../hooks/useConfigs';
import { useSystemDate } from '../../hooks/useSystemDate';
import ErrorMessage from '../FormikFactory/utils/ErrorMessage';
import { addYears } from 'date-fns';
import DatePickerMask from '../DatePickerMask';

export const toLocalDate = (date) => {
	const localTimezone = DateTime.local().zone.name;
	const localDate = DateTime.fromISO(date, { setZone: true })
		.setZone(localTimezone, { keepLocalTime: true })
		.toJSDate();
	return localDate;
};

const DatePicker = ({
	label,
	required,
	error,
	defaultValue,
	showTimeOnly,
	zoned,
	onChange,
	arialabel,
	isAutoDate,
	maxDate = addYears(new Date(), 100),
	minDate = new Date(1900, 0, 1),
	...props
}) => {
	const ref = useRef(null);
	const [date, setDate] = useState(null);
	const { toSystemDate } = useSystemDate();

	const { timeZone } = useConfigs();

	const handleDataChange = (value) => {
		try {
			const formattedDate = DateTime.fromJSDate(value, { setZone: true })
				.setZone(timezoneFormat(timeZone), { keepLocalTime: true })
				.toISO();
			setDate(value);
			onChange(zoned ? formattedDate : value ? value.toISOString() : '');
		} catch (error) {
			console.error('Error in components/_Datepicker converting datetime, Error: ' + error);
		}
	};

	useEffect(() => {
		if (isAutoDate && required) {
			setDate(new Date());
			onChange(new Date().toISOString());
		}
	}, [isAutoDate]);

	useEffect(() => {
		try {
			if (defaultValue && !Boolean(date)) {
				const defaultDate = zoned
					? toLocalDate(toSystemDate(new Date(defaultValue)))
					: toLocalDate(new Date(defaultValue).toISOString());
				setDate(defaultDate);
			}
		} catch (error) {
			console.error('Error in components/_Datepicker converting datetime, Error: ' + error);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	return (
		<Form.Group>
			{label && (
				<Form.Label htmlFor={props.id}>
					{label}
					{required && (
						<>
							<span className="text-danger fw-bold mx-1" aria-hidden="true">
								*
							</span>
						</>
					)}
				</Form.Label>
			)}

			<ReactDatePicker
				placeholderText="mm/dd/yyyy"
				selected={date}
				onChange={handleDataChange}
				maxDate={maxDate}
				minDate={minDate}
				peekNextMonth={!showTimeOnly}
				showMonthDropdown={!showTimeOnly}
				showYearDropdown={!showTimeOnly}
				dropdownMode="select"
				dateFormat={showTimeOnly ? 'h:mm aa' : 'MM/dd/yyyy'}
				timeFormat="h:mm aa"
				autoComplete="off"
				className={`form-control ${Boolean(error) ? 'is-invalid' : ''} ${
					props.disabled ? ' notAllowed ' : ''
				}`}
				showTimeSelectOnly={showTimeOnly}
				required={required}
				{...props}
				ref={ref}
				customInput={
					<DatePickerMask
						ref={ref}
						arialabel={arialabel}
						date={date}
						mindate={minDate}
						maxdate={maxDate}
						rendericon={'false'}
						dateformat={showTimeOnly ? 'h:mm aa' : 'MM/dd/yyyy'}
					/>
				}
			/>
			{!Boolean(error) && (
				<i
					className={`far fa-calendar float-end`}
					style={{
						transform: 'translateY(-165%) translateX(-100%)',

						pointerEvents: 'none'
					}}
				/>
			)}
			<ErrorMessage error={error} />
		</Form.Group>
	);
};

export default DatePicker;
