// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_backdrop__-LTme {\n  z-index: 1055;\n}", "",{"version":3,"sources":["webpack://./src/components/AlertDialog/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD","sourcesContent":[".backdrop {\n\tz-index: 1055;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "styles_backdrop__-LTme"
};
export default ___CSS_LOADER_EXPORT___;
