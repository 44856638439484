// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_isRequired__s4zUS {\n  margin-left: 0.25rem;\n  color: #dc3545;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/components/common/RequiredMark/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,oBAAA;EACA,cAAA;EACA,iBAAA;AACD","sourcesContent":[".isRequired {\n\tmargin-left: 0.25rem;\n\tcolor: #dc3545;\n\tfont-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isRequired": "styles_isRequired__s4zUS"
};
export default ___CSS_LOADER_EXPORT___;
