import PropTypes from 'prop-types';
import React from 'react';

export const ErrorLabel = ({ errors, touched, value, small, customMessage }) =>
	(errors[value] && touched[value]) || customMessage ? (
		<div role="alert" className={`text-danger${small ? ' small' : ''}`}>
			{errors[value] || customMessage}
		</div>
	) : null;

ErrorLabel.propTypes = {
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	value: PropTypes.string.isRequired,
	small: PropTypes.bool
};
