export const ATTORNEY_VIEW_ALL_COLUMNS = [
	{ heading: 'Attorney Name', value: 'attorneyName' },
	{ heading: 'Bar Number', value: 'barNo' },
	{ heading: 'Email Address', value: 'email' },
	{ heading: 'Phone Number', value: 'phoneNumber' },
	{ heading: 'Status', value: 'status' }
];

export const ATTORNEY_REPORT_ALL_ATTORNEYS = 'AttorneyReportAllAttorneys';
export const ATTORNEY_REPORT_CASE_BY_ATTORNEYS = 'AttorneyReportCaseByAttorneys';
export const ATTORNEY_REPORT_COURT_APPOINTED_ATTORNEYS = 'AttorneyReportCourtAppointedAttorneys';
export const ATTORNEY_REPORT_OPEN_CASES_FOR_ATTORNEYS = 'AttorneyReportOpenCasesForAttorneys';
export const ATTORNEY_HEARING_REPORT = 'AttorneyHearingReport';
export const ATTORNEY_VACATION_REPORT = 'AttorneyVacationReport';
export const OPEN_CASES_FOR_ATTORNEY_REPORT = 'OpenCasesForAttorneyReport';

export const ATTORNEY_REPORTS_OPTIONS = [
	{ label: 'All Attorneys', value: ATTORNEY_REPORT_ALL_ATTORNEYS },
	{ label: 'Case By Attorneys', value: ATTORNEY_REPORT_CASE_BY_ATTORNEYS },
	{ label: 'Court Appointed Attorneys', value: ATTORNEY_REPORT_COURT_APPOINTED_ATTORNEYS },
	{ label: 'Open Cases For Attorneys', value: ATTORNEY_REPORT_OPEN_CASES_FOR_ATTORNEYS },
	{ label: 'Attorney Vacation Report', value: ATTORNEY_VACATION_REPORT },
	{ label: 'Attorney Hearing Report', value: ATTORNEY_HEARING_REPORT }
];
