import * as Yup from 'yup';
import useControls from './useControls';
import { isValid } from 'date-fns';

function useIsRequired(field, fieldName, url) {
	const { controls } = useControls(url);
	return this.test('isRequired', `${fieldName} is required.`, (value) => {
		if (controls[field]) {
			if (value && typeof value === 'object' && !isValid(value)) {
				return value.length > 0;
			}

			return controls[field] ? controls[field] && value : true;
		}

		return true;
	});
}

Yup.addMethod(Yup.mixed, 'isRequired', useIsRequired);

export default useIsRequired;
