import useSWR from 'swr';
import { BASE_URL, fetcher } from '../utils';

export const useAPI = (url, config) => {
	const { dynamic, swrConfig } = config || {};
	const swrUrl = dynamic ? () => `${BASE_URL}/api${url}` : `${BASE_URL}/api${url}`;
	const { data, error, mutate: refetch } = useSWR(url && swrUrl, fetcher, swrConfig);
	const isLoading = !data && !error;

	return {
		data,
		error,
		isLoading,
		refetch
	};
};
