import { useEffect } from 'react';
import { useField, ErrorMessage } from 'formik';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import Select, { components } from 'react-select';
import AffectsReportAlertTooltip from '../AffectsReportAlertTooltip/AffectsReportAlertTooltip';

import styles from './styles.module.scss';
import { fixFilterOptions } from '../FormikFactory/utils/Select';
import { RequiredMark } from '../common';

const EMPTY_LABEL = '- Select -';

export const SelectFormSearchable = (props) => {
	const [field, meta, helpers] = useField(props);
	const { isLandscape } = useMobileOrientation();

	const ValidationStyles = {
		menu: (provided) => ({
			...provided,
			backgroundColor: '#ebebeb',
			boxShadow: '2px 2px 6px 0 #777',
			border: '1px solid #777'
		}),
		control: (provided, state) => ({
			...provided,
			borderColor: props.meta?.touched && props.meta?.error ? '#dc3545' : '#6c757d',
			cursor: props.isDisabled ? 'not-allowed' : 'auto',
			pointerEvents: 'auto',
			'&:hover': state.isDisabled
				? {
						boxShadow: 'none !important'
				  }
				: { boxShadow: '0 0 0 0.15rem rgb(13 110 253 / 75%) !important' }
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected
				? '#0d6efd'
				: state.isFocused
				? '#6EA8FE'
				: provided?.backgroundColor,
			color: state.isSelected ? 'white' : state.isFocused ? 'black' : provided?.color,
			'&:hover': {
				backgroundColor: state.isSelected ? '#0d6efd' : '#6EA8FE',
				color: state.isSelected ? 'white' : 'black'
			}
		}),
		singleValue: (provided) => ({ ...provided, color: 'black' }),
		dropdownIndicator: (provided) => ({
			...provided,
			color: '#212529'
		}),
		placeholder: (provided) => ({
			...provided,
			color: 'black'
		}),
		multiValueRemove: (provided, state) => ({
			...provided,
			visibility: state.isDisabled && 'hidden',
			width: state.isDisabled && '4px'
		})
	};

	const ValueContainer = ({ children, ...props }) => {
		const onClick = () => {
			if (isMobile && isLandscape) {
				document.activeElement.focus();
			}
		};
		return (
			components.ValueContainer && (
				<components.ValueContainer
					{...props}
					innerProps={{
						onClick
					}}
				>
					{!!children && (
						<div
							className={`${props.meta?.touched && props.meta?.error && styles.searchSelectIcon}`}
							aria-hidden="true"
							style={{ position: 'absolute', right: 6, color: 'red' }}
						>
							&nbsp;
						</div>
					)}
					{children}
				</components.ValueContainer>
			)
		);
	};
	const onMenuOpen = () => {
		if (isMobile && isLandscape && props.isSearchable) {
			document.activeElement.blur();
		}
	};

	useEffect(() => {
		const { defaultValue, options } = props;
		if (defaultValue) {
			helpers.setValue(
				options?.find((o) => {
					return o.label === defaultValue;
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getOptions = () => {
		const { withEmptyLabel, options } = props;
		if (options) {
			if (withEmptyLabel) {
				const emptyOption = {
					label: EMPTY_LABEL,
					value: null
				};
				return [emptyOption, ...options];
			} else {
				return options;
			}
		}
	};

	return (
		<>
			<AffectsReportAlertTooltip isReportAffected={props.isReportAffected}>
				<label className="form-label">
					{props.label}
					{props.required && <RequiredMark />}
					{props.isReportAffected && (
						// The period is required to pause the screenreader to read the tooltip
						<span className="visually-hidden">. {props.isReportAffected.tooltip}</span>
					)}
				</label>
			</AffectsReportAlertTooltip>

			<Select
				id={props.id}
				inputId={`input-${props.id}`}
				name={props.name}
				aria-label={
					props.isReportAffected
						? `${props.label}, ${props.isReportAffected?.tooltip}.`
						: props.label
				}
				isMulti={props.isMulti}
				value={field.value || null}
				onChange={(value) => {
					helpers.setValue(value);
					props.onChange && props.onChange(value);
				}}
				isClearable={props.isClearable}
				options={getOptions()}
				validateOnBlur={false}
				isSearchable={props.isSearchable}
				filterOption={fixFilterOptions()}
				components={{ ValueContainer }}
				styles={ValidationStyles}
				isDisabled={props.isDisabled}
				onMenuOpen={onMenuOpen}
				testId={props.testId}
				placeholder={EMPTY_LABEL}
				defaultValue={props?.defaultValue}
			/>

			<ErrorMessage
				component="div"
				name={field.name}
				className={'small text-danger'}
				role="alert"
			/>
		</>
	);
};
