import { useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';

/**
 * Displays Wizard component with steps and previous, next, cancel, submit buttons.
 * @param {function} onClose - Function called when Cancel button is pressed
 * @returns {ReactElement} returns JSX with the Wizard component.
 */
const Wizard = ({ onClose, children }) => {
	const { wizardStep, setWizardStep, setNumberOfPages, submitFormRef } = global.wizard;
	const numberOfPages = children.length;
	const lastPage = numberOfPages - 1;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setNumberOfPages(numberOfPages), []);

	const handleSubmitRef = () => {
		if (submitFormRef.current) {
			submitFormRef.current.handleSubmit();
		}
	};

	// go back to previous wizardStep
	const prevStep = () => {
		if (wizardStep !== 0) {
			const newStep = wizardStep - 1;
			setWizardStep(newStep);
		}
	};

	// proceed to the next wizardStep
	const nextStep = () => {
		if (wizardStep !== lastPage) {
			const newStep = wizardStep + 1;
			setWizardStep(newStep);
		}
	};

	return (
		<>
			{children[wizardStep]}
			{wizardStep === 0 && (
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" className="d-flex justify-content-end gap-2">
						<Button onClick={() => nextStep()}>Next</Button>
						<Button variant="secondary" onClick={() => onClose()}>
							Cancel
						</Button>
					</Col>
				</Row>
			)}
			{wizardStep > 0 && (
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" className="d-flex">
						<Col>
							<Button variant="secondary" onClick={() => prevStep()}>
								Previous
							</Button>
						</Col>
						<Col className="d-flex justify-content-end gap-2">
							{wizardStep < lastPage && <Button onClick={nextStep}>Next</Button>}
							{wizardStep === lastPage && (
								<Button type="submit" onClick={handleSubmitRef}>
									Submit
								</Button>
							)}
							<Button variant="secondary" onClick={() => onClose()}>
								Cancel
							</Button>
						</Col>
					</Col>
				</Row>
			)}
		</>
	);
};

export default Wizard;
