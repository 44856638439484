import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { Alert } from 'react-bootstrap';

export const TOAST_TYPES = {
	SUCCESS: 'SUCCESS',
	DANGER: 'DANGER',
	WARNING: 'WARNING'
};

export const scrollToTopCenterPanel = () => {
	const currWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

	if (currWidth <= 768) {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}, 100);
	} else {
		const centerPanel = document.querySelector('[class^="styles_centerPanel"]');

		if (centerPanel) {
			const responseAlertElem = centerPanel.querySelector('[role=alert][class*=show]');

			if (responseAlertElem) {
				responseAlertElem.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'start'
				});
			}
		}
	}
};

/**
 * ResponseAlert.
 * @param {string} text - Text in the body of the Alert
 * @param {boolean} show - Flag to show alert.
 * @param {string} type - Defines colors of the alert, use TOAST_TYPES for better practice.
 * @param {number} delay - Defines time to hide component in miliseconds.
 * @param {boolean} autoClose - Defines if the component will close automatically.
 * @returns {ReactElement} returns JSX with the component ResponseAlert.
 */

export const ResponseAlert = ({
	show,
	text = '',
	type = '',
	close,
	delay,
	autoClose,
	isEnabledScrollTop = true
}) => {
	const _delay = delay || 6000;
	const _autoClose = autoClose || false;

	useEffect(() => {
		if (_autoClose && show) {
			let timer = setTimeout(() => close(), _delay);
			return () => {
				clearTimeout(timer);
			};
		}
		// eslint-disable-next-line
	}, [show]);

	useEffect(() => {
		if (show && isEnabledScrollTop) {
			scrollToTopCenterPanel();
		}
		// eslint-disable-next-line
	}, [show]);

	if (show) {
		return (
			<div className="container-fluid">
				<div className="row justify-content-center">
					<div className="col-md-6 col-11">
						<Alert
							variant={`${type.toLowerCase()}-light border-${type.toLowerCase()}`}
							onClose={() => close()}
							dismissible
							className="text-center"
						>
							{type === 'SUCCESS' ? (
								<i className={`fas fa-check-circle text-${type.toLowerCase()}`}></i>
							) : (
								<i className={`fas fa-exclamation-circle text-${type.toLowerCase()}`}></i>
							)}
							<span role="alert" className={`text-dark ${styles.responseAlertText}`}>
								{text}
							</span>
						</Alert>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

ResponseAlert.propTypes = {
	show: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	autoClose: PropTypes.bool,
	delay: PropTypes.number
};

export default ResponseAlert;
