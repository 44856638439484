const ScanButtonServices = () => {
	const selectFile = async (url, webViewer) => {
		const { Core } = webViewer;
		const officeToPDF = await Core.officeToPDFBuffer(url);
		return new Blob([officeToPDF], { type: 'application/pdf' });
	};

	return {
		selectFile
	};
};

export default ScanButtonServices;
