import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const EfileService = () => {
	const { get, put } = HttpService();

	const getReviewer = (clerkId) => {
		const url = `${BASE_URL}/api/efile/office/reviewers?clerkId=${clerkId}`;
		return get(url, 'listReviewers', {});
	};

	const putUpdateEfileReviewed = (envelopId) => {
		const url = `${BASE_URL}/api/efile/envelopes/review/${envelopId}`;
		return put(url, {}, 'putUpdateEfileReviewed', {});
	};

	const getUnRevievedEfile = () => {
		const url = `${BASE_URL}/api/efile/envelopes/clerk`;
		return get(url, 'getUnRevievedEfile', {});
	};

	return { getReviewer, putUpdateEfileReviewed, getUnRevievedEfile };
};
