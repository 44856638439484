import { useEffect, useRef } from 'react';
import styles from './styles.module.scss';

const SkipToContentLink = () => {
	const skipLinkRef = useRef(null);

	useEffect(() => {
		function focusSkipLink(event) {
			// Use ALT+K to focus on Skip To Main Content link
			if (event.keyCode === 75 && event.altKey) {
				if (skipLinkRef.current) {
					skipLinkRef.current.focus();
				}
			}
		}

		document.addEventListener('keydown', focusSkipLink);

		return () => {
			document.removeEventListener('keydown', focusSkipLink);
		};
	});

	const skipToContent = (e) => {
		e.preventDefault();

		const mainContent = document.getElementById('main-content');

		if (mainContent) {
			const pageHeading = [...mainContent.querySelectorAll('h1, h2')].filter(
				(el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
			);

			if (pageHeading.length > 0) {
				const firstElem = pageHeading[0];

				if (firstElem.getAttribute('tabindex')) {
					firstElem.focus();
				} else {
					firstElem.setAttribute('tabindex', '-1');
					firstElem.focus();

					setTimeout(() => {
						firstElem.blur();
						firstElem.removeAttribute('tabindex');
					}, 1000);
				}
			} else {
				// Applies to other focusable items
				const altFocusElements = [
					...mainContent.querySelectorAll(
						'button, input, select, textarea, a[href]:not([class~=d-none]), [tabindex]:not([tabindex=-1])'
					)
				].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));

				if (altFocusElements) {
					altFocusElements[0].focus();
				} else {
					e.currentTarget.blur();
				}
			}
		} else {
			e.currentTarget.blur();
		}
	};

	return (
		<a href="/#" onClick={skipToContent} className={styles.skipLink} ref={skipLinkRef}>
			Skip to main content
		</a>
	);
};

export default SkipToContentLink;
