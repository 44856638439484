import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const SystemNotificationsService = () => {
	const { get, put } = HttpService();

	const getAllNotifications = async (userID, clerkID) => {
		const response = await get(
			`${BASE_URL}/api/system-notification/all-notifications/user/${userID}?clerk-id=${clerkID}`
		);
		return response;
	};

	const updateMsgAsRead = async (userID, notificationID) => {
		const response = await put(
			`${BASE_URL}/api/system-notification/message-read/notification-id/${notificationID}/user/${userID}`
		);
		return response;
	};

	const updateAllMsgAsDismissed = async (userID, clerkID) => {
		const response = await put(
			`${BASE_URL}/api/system-notification/deactivate-notifications/user/${userID}/clerk/${clerkID}`
		);

		return response;
	};

	const getRecentNotifications = async (userID, page = 0, size = 5) => {
		const response = await get(
			`${BASE_URL}/api/system-notification/recent-notifications/user/${userID}?page=${page}&size=${size}`
		);
		return response;
	};

	return {
		getAllNotifications,
		updateMsgAsRead,
		updateAllMsgAsDismissed,
		getRecentNotifications
	};
};
