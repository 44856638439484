import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	totalRecords: 0
};

const advancedSearchSlice = createSlice({
	initialState,
	name: 'advancedSearchSlice',
	reducers: {
		updateTotalRecords: (state, action) => {
			state.totalRecords = action.payload;
		}
	}
});

export const { updateTotalRecords } = advancedSearchSlice.actions;

export default advancedSearchSlice.reducer;
