import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AffectsReportAlertTooltip from '../AffectsReportAlertTooltip/AffectsReportAlertTooltip';
import { RequiredMark } from '../common';

export const InputLabel = ({ name, required, htmlFor, isReportAffected }) => (
	<AffectsReportAlertTooltip isReportAffected={isReportAffected}>
		<Form.Label htmlFor={htmlFor}>
			{name} {required && <RequiredMark />}
		</Form.Label>
	</AffectsReportAlertTooltip>
);

InputLabel.propTypes = {
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	htmlFor: PropTypes.string.isRequired,
	isReportAffected: PropTypes.object
};
