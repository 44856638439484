import { BASE_URL } from '../../utils';
import { HttpService } from '../HttpService';

export const HateCrimesServices = () => {
	const { get, put } = HttpService();

	const GET_HATE_CRIME_ALL = (caseId) => {
		return `${BASE_URL}/api/hate-crime-management/case/${caseId}/hate-crimes`;
	};

	const getHateCrimeAll = (caseId) => {
		return get(
			`${BASE_URL}/api/hate-crime-management/case/${caseId}/hate-crimes?page=0&size=10`,
			'getHateCrimeAll',
			[]
		);
	};

	const getHateCrimeById = (hateCrimeId) => {
		return get(`${BASE_URL}/api/hate-crime-management/${hateCrimeId}`, 'getHateCrimeById', []);
	};
	const putHateCrimeById = (hateCrimeId, body) => {
		const url = `${BASE_URL}/api/hate-crime-management/${hateCrimeId}`;
		return put(url, body, 'updateHateCrimes', {});
	};

	const getCodeTable = () => {
		const url = `${BASE_URL}/api/code-table-management/code-tables/categories/motion_to_affirm_result`;
		return get(url, 'codeTable', []);
	};

	return {
		getHateCrimeById,
		putHateCrimeById,
		getCodeTable,
		GET_HATE_CRIME_ALL,
		getHateCrimeAll
	};
};
