import { usePagePermissions } from '../../hooks';
import { LeftNavBar } from '../layout';

const MaintenanceLeftSideNavigation = () => {
	const { permissions } = usePagePermissions([
		'codes',
		'feecode',
		'offensemaint',
		'eventcode',
		'attorneys',
		'judgeview',
		'casetypejudge',
		'casenumber',
		'casestyle',
		'certifiedpay',
		'batchcase',
		'batchparties',
		'batchbonds',
		'batchpayments',
		'batchsingle',
		'batchevents',
		'wftaskservice',
		'wftask',
		'formmaint'
	]);

	const links = [];
	if (
		permissions.codes.read ||
		permissions.feecode.read ||
		permissions.offensemaint.read ||
		permissions.eventcode.read
	) {
		links.push({
			text: 'System Codes',
			icon: 'fas fa-edit',
			children: (() => {
				const children = [];
				permissions.codes.read &&
					children.push({
						text: 'All Codes',
						href: '/maintenance/code-maintenance'
					});
				permissions.feecode.read &&
					children.push({
						text: 'Fee Maintenance',
						href: '/fee-maintenance'
					});
				permissions.offensemaint.read &&
					children.push({
						text: 'Offense Maintenance',
						href: '/offense-maintenance'
					});
				permissions.eventcode.read &&
					children.push({
						text: 'Event Maintenance',
						href: '/event-maintenance/event-all-codes'
					});
				children.push({
					text: 'Money Distribution Maintenance',
					href: '/money-distribution-maintenance'
				});
				return children;
			})()
		});
	}

	permissions.attorneys.read &&
		links.push({
			text: 'Attorneys',
			icon: 'fas fa-balance-scale',
			href: '/attorneys'
		});

	if (permissions.judgeview.read || permissions.casetypejudge.read) {
		links.push({
			text: 'Judges',
			icon: 'fas fa-gavel',
			children: (() => {
				const children = [];
				permissions.judgeview.read &&
					children.push({
						text: 'Judge Maintenance',
						href: '/judges'
					});
				permissions.casetypejudge.read &&
					children.push({
						text: 'Judge Assignments',
						href: '/judge-case-type'
					});
				return children;
			})()
		});
	}

	if (permissions.casenumber.read || permissions.casestyle.read) {
		links.push({
			text: 'Case Maintenance',
			icon: 'fas fa-landmark',
			children: (() => {
				const children = [];
				permissions.casenumber.read &&
					children.push({
						text: 'Case Number',
						href: '/case-maintenance/case-number'
					});

				permissions.casestyle.read &&
					children.push({
						text: 'Case Style',
						href: '/case-maintenance/case-style-templates'
					});
				return children;
			})()
		});
	}

	permissions.certifiedpay.read &&
		links.push({
			text: 'Certified Payments',
			icon: 'fas fa-money-bill-alt',
			href: '/certified-payments'
		});

	if (
		permissions.batchcase.update ||
		permissions.batchparties.update ||
		permissions.batchbonds.update ||
		permissions.batchpayments.update ||
		permissions.batchsingle.update ||
		permissions.batchevents.update
	) {
		links.push({
			text: 'Batch Updates ',
			icon: 'fas fa-tasks',
			href: '/case-batch'
		});
	}

	if (permissions.wftaskservice.read || permissions.wftask.read) {
		links.push({
			text: 'Workflows',
			icon: 'fas fa-folder-open',
			children: (() => {
				const children = [];
				permissions.wftaskservice.read &&
					children.push({
						text: 'Task Service Maintenance',
						href: '/workflows/maintenance/all-task-services'
					});

				permissions.wftask.read &&
					children.push({
						text: 'Task Maintenance',
						href: '/workflows/maintenance/all-tasks'
					});
				return children;
			})()
		});
	}

	permissions.formmaint.read &&
		links.push({
			text: 'Form Maintenance ',
			icon: 'fas fa-file-alt',
			href: '/form-maintenance'
		});
	return <LeftNavBar links={links} />;
};

export default MaintenanceLeftSideNavigation;
