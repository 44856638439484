import React from 'react';

import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import { FormSelect } from './FormSelect';

export const FieldFormSelect = ({ id, label = '', options, required, onChange }) => (
	<Field name={id}>
		{({ field, form, ...props }) => (
			<>
				<FormSelect
					id={field.name}
					value={field.value}
					label={label}
					options={options}
					onChange={(value) => {
						form.setFieldValue(field.name, value);
						onChange?.(value);
					}}
					required={required}
					className={form.errors?.[field.name] && form.touched?.[field.name] && 'is-invalid'}
				/>
				<ErrorMessage component="div" name={field.name} className="text-danger" role="alert" />
			</>
		)}
	</Field>
);

FieldFormSelect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	options: PropTypes.array.isRequired,
	required: PropTypes.bool,
	onChange: PropTypes.func
};
