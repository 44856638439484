import { memo } from 'react';

import NewDatePicker from '../../NewDatePicker';

const SearchInputField = ({
	input,
	item,
	index,
	formTitle,
	inputReference,
	handleSearchInput,
	searchInputValues,
	setSearchInputValues,
	setIsSearching,
	isClearable
}) => {
	const inputId = `${inputReference}-${index}`;

	if (item?.type === 'date') {
		const currentInputValue = new Date(searchInputValues[inputId]);
		return (
			<NewDatePicker
				name={inputId}
				onChange={(input) => {
					input?.target?.value ? setIsSearching(true) : setIsSearching(false);
					setSearchInputValues({
						...searchInputValues,
						[inputId]: input?.target?.value
					});
					handleSearchInput(input, item, index);
				}}
				className="form-control form-control-sm border border-secondary"
				iconStyle={{
					position: 'absolute',
					bottom: '25%',
					right: '10%',
					cursor: 'pointer'
				}}
				initialValue={currentInputValue.toString() !== 'Invalid Date' ? currentInputValue : ''}
				aria-label={`${formTitle} On Input Select ${item.heading}`}
				withPortalId={!item?.inModal}
				disabled={item?.disableSearch}
				isClearable={isClearable}
			/>
		);
	}

	return (
		<input
			className="form-control form-control-sm border border-secondary"
			ref={input}
			type={item.type ? item.type : 'text'}
			onChange={(input) => {
				setSearchInputValues({ ...searchInputValues, [inputId]: input?.target?.value });
				handleSearchInput(input, item, index);
			}}
			value={searchInputValues[inputId] ?? ''}
			autoComplete="off"
			data-testid={`searchBox-${inputId}`}
			id={inputId}
			tabIndex={item.tabIndex}
			aria-label={`${formTitle} On Input Search ${item.heading}`}
			disabled={item?.disableSearch}
		/>
	);
};

export default memo(SearchInputField);
