import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchRecordsCount = createAsyncThunk('get/RecordsCount', async (apiCallback) => {
	try {
		const response = await apiCallback();
		return response.data;
	} catch (error) {
		console.error('error: Unable to get records Count');
	}
});

const initialState = {
	recordsCount: null
};

const recordCountSlice = createSlice({
	initialState,
	name: 'recordCountSlice',
	reducers: {
		updateRecordsCount: (state, action) => {
			state.recordsCount = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchRecordsCount.fulfilled, (state, action) => {
			state.recordsCount = action.payload;
		});
	}
});
export const { updateRecordsCount } = recordCountSlice.actions;
export default recordCountSlice.reducer;
