import { useCallback, useState, useEffect } from 'react';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import usePrevious from './usePrevious';
import { fetcher, formatUrl, formatUrlAllEntries } from '../utils';
import { PAGINATION_SIZE_ARRAY } from '../constants/tableWithIntegration';

/**
 * useBackendPaginationFetcher.
 * @param {string} url Url without initial part and params table part e.g. /fee/codes
 * @param {key} key Inside the data the object with the data come with your own key .e.g fees
 * @param {Function} mapper Format the data object
 * @param {number} constantSize String to get the request without pagination stuff
 * @param {boolean} disableMapper the mapper is disabled.
 * @param {boolean} preventFetch prevents fetch if a condition is met.
 * @param {boolean} hasTotalRow a boolean to hide/show total row
 * @param {object} queryParams query params to be added to the url as key, value pair.
 * @param {any} customFetcher custom fetcher to set your own http configurations
 */

export const useBackendPaginationFetcher = ({
	url,
	key,
	mapper,
	constantSize,
	disableMapper,
	preventFetch = false,
	hasTotalRow,
	sortBy,
	customFetcher,
	queryParams,
	disableRevalidate,
	revalidateOnFocus = true,
	enableGetExtraValue = false,
	keyExtraValue = '',
	disableGetAllData = false,
	paginationSizeArray = PAGINATION_SIZE_ARRAY
}) => {
	const [formattedData, setFormattedData] = useState([]);
	const prevFormattedData = usePrevious(formattedData);

	const dataFormatter = async (row) => {
		let result = [];
		try {
			if (Array.isArray(row)) {
				result = await Promise.all(row.map(mapper));
			}
		} catch (err) {
			console.error(err);
		}
		return result;
	};

	const [page, setPage] = useState(0);
	const [size, setSize] = useState(constantSize ?? 100);
	const [filter, setFilter] = useState();
	const [sort, setSort] = useState(sortBy);
	const [showAll, setShowAll] = useState(false);
	const [allData, setAllData] = useState([]);
	const [isGettingAllData, setIsGettingAllData] = useState(false);
	const [getAllDataTrigger, setGetAllDataTrigger] = useState(false);
	const options = {
		refreshInterval: 0,
		shouldRetryOnError: false,
		revalidateOnFocus: revalidateOnFocus
	};

	if (disableRevalidate) {
		Object.assign(options, {
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnMount: false
		});
	}

	const { formattedUrl, urlWithoutPagination } = formatUrl(
		url,
		sort,
		queryParams,
		filter,
		page,
		size
	);

	const {
		data = [],
		mutate,
		isValidating,
		error
	} = useSWR(preventFetch ? null : formattedUrl, customFetcher ? customFetcher : fetcher, options);

	const isLoading = (!data && !error) || isValidating || isGettingAllData;

	const { totalElements = 0, totalPages = 0 } = data;
	const objectData = !error && key ? data[key] : data;
	const dataExtraValue = enableGetExtraValue && data[keyExtraValue] ? data[keyExtraValue] : null;

	const prepareData = async (objectData) => {
		if (disableMapper) {
			setFormattedData(objectData);
		} else {
			const result = await dataFormatter(objectData);
			setFormattedData(result);
		}
	};

	useEffect(() => {
		if (!isEqual(prevFormattedData, objectData)) {
			prepareData(objectData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectData]);

	const getAllData = async () => {
		try {
			setIsGettingAllData(true);
			const allDataReq = customFetcher
				? await customFetcher(formattedUrlAllEntries)
				: await fetcher(formattedUrlAllEntries);
			const objectAllData = key ? allDataReq[key] : allDataReq;

			if (disableMapper) {
				setAllData(objectAllData);
			} else {
				const result = await dataFormatter(objectAllData);
				setAllData(result);
			}
			setIsGettingAllData(false);
		} catch (error) {
			console.error(error);
			setIsGettingAllData(false);
		}
	};

	const refresh = useCallback(
		(event) => {
			if (event === 'delete' && formattedData?.length === 1 && page > 0) {
				setPage(page - 1);
				return Promise.resolve();
			}
			if (event === 'getAllData') {
				!disableGetAllData && getAllData();
			}
			if (event === 'create') {
				if (showAll) {
					setSize((prev) => prev + 1);
				} else {
					return mutate();
				}
			} else {
				return mutate();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formattedData, mutate, page, showAll]
	);

	const formattedUrlAllEntries = formatUrlAllEntries(urlWithoutPagination, totalElements);

	useEffect(() => {
		if (size >= totalElements && !isEqual(allData, formattedData) && formattedData?.length !== 0) {
			setAllData(formattedData);
		} else if (
			url &&
			totalElements > 0 &&
			allData?.length !== totalElements &&
			formattedUrlAllEntries &&
			!isGettingAllData
		) {
			!disableGetAllData && getAllData();
		}
		// eslint-disable-next-line
	}, [url, refresh, formattedData, totalElements]);

	useBackendPaginationFetcher.propTypes = {
		url: PropTypes.string.isRequired,
		key: PropTypes.string.isRequired,
		mapper: PropTypes.func.isRequired,
		constantSize: PropTypes.number,
		disableMapper: PropTypes.bool,
		hasTotalRow: PropTypes.bool
	};

	const paginationItems = {
		parentPage: page,
		setParentPage: setPage,
		size,
		setSize,
		totalElements,
		totalPages,
		setFilter,
		filter,
		sortServerSide: setSort,
		showAll,
		setShowAll,
		allData,
		paginationSizeArray
	};

	if (hasTotalRow) {
		Object.assign(paginationItems, { financialItems: data });
	}

	return {
		paginationItems,
		data: formattedData,
		refreshTable: refresh,
		isLoading,
		setAllData,
		dataExtraValue
	};
};
