import { UNITED_STATES_OF_AMERICA_ACRONYM } from '../constants';
import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const CaseService = () => {
	const { get, post, put, deleteFn } = HttpService();

	// Get from API

	const getAllCases = () => {
		return get(
			`${BASE_URL}/api/case/cases?page=${0}&size=${1000000}&sortBy=caseId&sortDirection=desc`
		);
	};

	const getAllCasesSelect = (userID) => {
		return get(`${BASE_URL}/api/case/cases/${userID}`);
	};

	const getCases = (person_id, setData) => {
		get(`${BASE_URL}/api/person-management/${person_id}/cases`).then(function (res) {
			setData(res.data);
		});
	};

	const getCasesByCaseNumber = (search, clerkId, page) => {
		return get(
			`${BASE_URL}/api/case/search?caseNumber=${search}&category=case&clerkDataId=${clerkId}&page=${page}&size=25`,
			'getCasesByCaseNumber',
			{}
		);
	};

	const getCaseByNo = (caseNo) => {
		return get(`${BASE_URL}/api/case/case-number/${caseNo}`, 'getCaseByNo', {});
	};

	const getCaseData = (caseId) => {
		return get(`${BASE_URL}/api/case/${caseId}`, 'caseData', {});
	};

	const getCaseCardData = (caseId) => {
		return get(`${BASE_URL}/api/party-management/caseView/${caseId}`, 'caseCardData', {});
	};

	//Change to correct API Route
	const getCategories = () => {
		const url = `${BASE_URL}/api/code-table-management/code-type/66/code-tables`;
		return get(url, 'listCategories', {});
	};

	//TODO add the correct path to sub categories
	const getSubCategories = (categories_id) => {
		const url = `${BASE_URL}/api/case-type-category-code-management/${categories_id}/case-type-category-codes`;
		return get(url, 'getSubCategories', {});
	};

	const getCaseTypes = (subCategoryCode) => {
		const url = `${BASE_URL}/api/case-type-code-management/case-type-category-code/${subCategoryCode}/case-type-codes?page=0&size=10000`;
		return get(url, 'getCaseTypes', {});
	};

	const getCourt = () => {
		const url = `${BASE_URL}/api/courts?page=0&size=1000`;
		return get(url, 'listCourt', {});
	};

	const getCourtsByClerkId = (clerkId) => {
		const url = `${BASE_URL}/api/courts/clerk/${clerkId}`;
		return get(url, 'listCourtsByClerkId', {});
	};

	const getFeeGroups = () => {
		const url = `${BASE_URL}/api/fee-group-code-management/group-codes?page=0&size=100`;
		return get(url, 'getFeeGroups', {});
	};

	const getOcaColumn = () => {
		const url = `${BASE_URL}/api/oca-management/code-columns?page=0&size=100`;
		return get(url, 'getOcaColumn', {});
	};

	const getFiledType = () => {
		const url = `${BASE_URL}/api/filed-code-management/filed-codes`;
		return get(url, 'listFiledType', {});
	};

	const getStates = (countryCode = UNITED_STATES_OF_AMERICA_ACRONYM) => {
		const url = `${BASE_URL}/api/state-codes-management/state-codes/${countryCode}`;
		return get(url, 'getStates', {});
	};

	const getCaseStatuses = () => {
		const url = `${BASE_URL}/api/code-table-management/code-tables/categories/case_status`;
		return get(url, 'getCaseStatuses', {});
	};

	const getAllCaseNotesView = (paramsGet) => {
		const url = `${BASE_URL}/api/judge-notes-management/judge-note`;
		return get(url, 'All case notes view', {});
	};

	const getOcaCaseType = (caseId) => {
		const url = `${BASE_URL}/api/offense/${caseId}/offenses/`;
		return get(url, 'listOcaCaseType', {});
	};

	const postCaseNote = (body) => {
		const url = `${BASE_URL}/api/judge-notes-management/judge-note`;
		return post(url, body, 'createNote', {});
	};

	const postCaseNewNote = (body) => {
		const url = `${BASE_URL}/api/note`;
		return post(url, body, 'createNote', {});
	};

	const validateUpdateCase = (body, id) => {
		const url = `${BASE_URL}/api/case/case-validation/${id}`;
		return post(url, body, 'validateUpdateCase', {});
	};

	const newSubstituteJudgeCase = (body) => {
		const url = `${BASE_URL}/api/case/substitute-judge`;
		return post(url, body, 'substituteJudgeForCase', {});
	};
	const replaceSubstituteJudgeCase = (body) => {
		const url = `${BASE_URL}/api/case/substitute-judge`;
		return put(url, body, 'replaceSubstituteJudgeForCase', {});
	};

	const getJudgeCase = (id) => {
		const url = `${BASE_URL}/api/case/case-substitute-judge/${id}`;
		return get(url, {}, 'getSubstituteJudgeForCase', {});
	};

	const putCaseNote = (body) => {
		const url = `${BASE_URL}/api/note`;
		return put(url, body, 'createNote', {});
	};
	const updateJudgeNote = (body) => {
		const url = `${BASE_URL}/api/judge-notes-management/judge-note`;
		return put(url, body, 'updateJudgeNote', {});
	};
	const postJudgeNote = (body) => {
		const url = `${BASE_URL}/api/judge-notes-management/judge-note`;
		return post(url, body, 'postNote', {});
	};

	const deleteCaseNote = (noteId) => {
		const url = `${BASE_URL}/api/note/${noteId}`;
		return deleteFn(url, 'deleteNote', {});
	};

	const deleteJudgeNote = (noteId) => {
		const url = `${BASE_URL}/api/judge-notes-management/judge-note/${noteId}`;
		return deleteFn(url, 'deleteJudgeNote', {});
	};

	// Post to API
	const createCase = (body) => {
		const url = `${BASE_URL}/api/case`;
		return post(url, body, 'createCase', {});
	};

	const postTransferCase = (bodyTransfer) => {
		const url = `${BASE_URL}/api/case/transfer`;
		return post(url, bodyTransfer, 'transferBody', {});
	};

	const postDueDates = (dueDates) => {
		// TODO - send to real endpoint
		// console.log('postTransfer ', transfer);
		return new Promise((resolve, reject) => resolve({ status: 201 }));
	};

	const postSeparateCase = (bodySeparate) => {
		const url = `${BASE_URL}/api/case/separate`;
		return post(url, bodySeparate, 'separateCase', {});
	};

	const postRemoveJudicialOfficer = (judicialOfficer) => {
		// TODO - send to real endpoint
		// console.log('postJudicialOfficer ', judicialOfficer);
		return new Promise((resolve, reject) => resolve({ status: 201 }));
	};

	const expungeCase = (body) => {
		const url = `${BASE_URL}/api/case/expunge`;
		return post(url, body, 'expungeCase', {});
	};

	const getJudges = (courtId) => {
		const url = `${BASE_URL}/api/judge/court/${courtId}`;
		return get(url, 'listJudge', {});
	};

	const getCaseNumberExistance = (caseNumber) => {
		return get(
			`${BASE_URL}/api/case/exist/${caseNumber}`,
			'caseNumberExistance',
			'Error with caseNumberExistance endpoint'
		);
	};

	const putCaseData = (caseData) => {
		return put(`${BASE_URL}/api/case`, caseData, 'putCaseData', {});
	};

	const purgeCase = (body) => {
		const url = `${BASE_URL}/api/case/purge`;
		return post(url, body, 'purgeCase', {});
	};

	const getAllCasesToExpungeOrPurgeNew = (search) => {
		return get(`${BASE_URL}/api/case/cases/search-by/case-number/${search}`);
	};

	// Case Transfer
	const getTransferCaseTableItems = (category) => {
		if (category.toLowerCase().includes('civil')) {
			//Return url of Civil cases
			return get(
				`${BASE_URL}/api/code-table-management/code-tables/categories/transfer_case_civil`
			);
		}
		if (category.toLowerCase().includes('criminal')) {
			//Return url of Civil cases
			return get(
				`${BASE_URL}/api/code-table-management/code-tables/categories/transfer_case_criminal`
			);
		}
		//If nothing return civil.
		return get(`${BASE_URL}/api/code-table-management/code-tables/categories/transfer_case_civil`);
	};
	const getAllJurisdiction = () => {
		return get(`${BASE_URL}/api/jurisdiction`);
	};
	const getCourtsByJurisdiction = (clerkId) => {
		return get(`${BASE_URL}/api/courts`, 'getCourtsByJurisdiction', {}, '', clerkId);
	};

	const getClerkTypeByUserId = (userId) => {
		return get(`${BASE_URL}/api/clerk/user/${userId}`, 'ClerkType by userId', {});
	};

	const getCategoriesByClerkId = (clerkId) => {
		return get(
			`${BASE_URL}/api/case-category-office/categories/${clerkId}`,
			'getCategoriesByClerkId',
			{}
		);
	};

	const getOcaCodes = (code) => {
		const url = `${BASE_URL}/api/case/status/code/${code}`;
		return get(url, 'getOcaCodes', {});
	};

	const getAttorneysByCaseId = (caseId) => {
		const url = `${BASE_URL}/api/party-management/party/case-representing/${caseId}`;
		return get(url, 'getAttorneysByCaseId', {});
	};

	const getPartiesByCaseId = (caseId) => {
		const url = `${BASE_URL}/api/party-management/cases/${caseId}?page=0&size=20`;
		return get(url, 'getPartiesByCaseId', {});
	};

	const postAssignCase = (body) => {
		const url = `${BASE_URL}/api/case/assign`;
		return post(url, body, 'postAssignCase', {});
	};

	const getAssignCase = (caseId) => {
		const url = `${BASE_URL}/api/case/assign/case/${caseId}`;
		return get(url, 'getCaseAssignByCaseId', {});
	};
	const getCaseSheetDetailsBycaseId = (caseId) => {
		const url = `${BASE_URL}/api/case/docket/${caseId}`;
		return get(url, 'getCaseSheetDetailsBycaseId', {});
	};

	const getCaseJudgeAssignments = (caseId, page = 0, size = 10) => {
		const url = `${BASE_URL}/api/case/assign/cases/${caseId}?page=${page}&size=${size}`;
		return get(url, 'getCaseJudgeAssignments', {});
	};

	const postReassignJudgeToCase = (body) => {
		const url = `${BASE_URL}/api/case/assign/reassign-judge`;
		return post(url, body, 'reassignJudgeToCase', {});
	};
	const getRecordsCountByCaseId = (caseId) => {
		const url = `${BASE_URL}/api/case/case-management/${caseId}`;
		return get(url, 'getRecordsCountByCaseId', {});
	};

	const getOffenses = () => {
		const url = `${BASE_URL}/api/offense/offenses`;
		return get(url, 'getOffenses', {});
	};

	const getCJISCodes = (filter) => {
		const url = `${BASE_URL}/api/cjis/cjis-codes?page=0&size=100&filter=${filter}`;
		return get(url, 'getCJISCodes', {});
	};

	const getLatestCaseTypeByCaseId = (caseId) => {
		const url = `${BASE_URL}/api/case-type/cases/${caseId}?page=0&size=1`;
		return get(url, 'getLatestCaseTypeByCaseId', {});
	};

	const getCasesExcludingElevatedSecurityRights = (userId, caseNo, page, size) => {
		const url = `${BASE_URL}/api/case/user/${userId}?caseNo=${
			caseNo ? caseNo : ''
		}&page=${page}&size=${size}`;
		return get(url, 'getCasesData', {});
	};

	return {
		newSubstituteJudgeCase,
		replaceSubstituteJudgeCase,
		getJudgeCase,
		getAllCases,
		getAllCasesSelect,
		getCases,
		getCasesByCaseNumber,
		getCategories,
		getSubCategories,
		getCaseTypes,
		getFiledType,
		getOcaCaseType,
		getCourt,
		getFeeGroups,
		getOcaColumn,
		getStates,
		getCaseStatuses,
		getJudges,
		getCaseNumberExistance,
		postTransferCase,
		createCase,
		postSeparateCase,
		postDueDates,
		postRemoveJudicialOfficer,
		getCaseData,
		expungeCase,
		putCaseData,
		postJudgeNote,
		getAllCaseNotesView,
		postCaseNote,
		postCaseNewNote,
		deleteCaseNote,
		deleteJudgeNote,
		validateUpdateCase,
		putCaseNote,
		purgeCase,
		getTransferCaseTableItems,
		getAllCasesToExpungeOrPurgeNew,
		getAllJurisdiction,
		getCourtsByJurisdiction,
		getClerkTypeByUserId,
		getCategoriesByClerkId,
		getOcaCodes,
		getCaseByNo,
		getCaseCardData,
		updateJudgeNote,
		getAttorneysByCaseId,
		getPartiesByCaseId,
		postAssignCase,
		getAssignCase,
		getCourtsByClerkId,
		getCaseSheetDetailsBycaseId,
		getCaseJudgeAssignments,
		postReassignJudgeToCase,
		getRecordsCountByCaseId,
		getOffenses,
		getCJISCodes,
		getLatestCaseTypeByCaseId,
		getCasesExcludingElevatedSecurityRights
	};
};
