import { useEffect, useState } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { HttpService } from '../../../services';
import { BASE_URL } from '../../../utils';
import ResponseAlert, { TOAST_TYPES } from '../../ResponseAlert/ResponseAlert';

const CurrentSignature = () => {
	const userInfo = useSelector((state) => state?.user?.userInfo);
	const { username } = userInfo;

	const { get } = HttpService();
	const [previewSignatureImagePath, setPreviewSignatureImagePath] = useState('');

	const [toast, setToast] = useState({
		type: '',
		text: '',
		show: false
	});

	useEffect(() => {
		const getUserSignature = async () => {
			try {
				const response = await get(
					`${BASE_URL}/api/user/profile/signature?username=${username}`,
					'getUserSignature',
					{}
				);
				if ([200, 201].includes(response.status)) {
					const { data } = response;
					const imageBase64 = `data:image/${data.path};base64,${data.image}`;
					setPreviewSignatureImagePath(imageBase64);
				} else {
					setToast((prev) => ({
						...prev,
						show: true,
						text: 'User signature not found.',
						type: TOAST_TYPES.DANGER
					}));
				}
			} catch (error) {
				console.error(error);

				setToast((prev) => ({
					...prev,
					show: true,
					text: 'Unable to fetch User Signature.',
					type: TOAST_TYPES.DANGER
				}));
			}
		};

		getUserSignature();
	}, [get]);

	return (
		<Container className="p-6">
			{previewSignatureImagePath ? (
				<Row>
					<Form.Label htmlFor="currentSignature">Current Signature</Form.Label>
					<ResponseAlert
						show={toast.show}
						text={toast.text}
						type={toast.type}
						delay={3000}
						close={() => {
							setToast({
								...toast,
								show: false
							});
						}}
					/>
					<img src={previewSignatureImagePath} className="w-100 mr-5 mb-5" alt="signature" />
				</Row>
			) : (
				<>User signature not found.</>
			)}
		</Container>
	);
};

export default CurrentSignature;
