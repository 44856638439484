// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_isRequired__oGHr7 {\n  margin-left: 3px;\n  color: #dc3545;\n  font-weight: bold;\n}\n\n.styles_calendarPosition__g9W9b {\n  bottom: 1.8rem;\n  position: relative;\n  right: 10%;\n  float: right;\n}\n\n.styles_datePickerWrapper__A5STb {\n  position: relative;\n}\n.styles_datePickerWrapper__A5STb i {\n  position: absolute;\n  top: 0.65rem;\n  right: 2rem;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/EventPage/EventDatesFormat/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,cAAA;EACA,iBAAA;AACD;;AAEA;EACC,cAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;AACD;;AAEA;EACC,kBAAA;AACD;AACC;EACC,kBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AACF","sourcesContent":[".isRequired {\n\tmargin-left: 3px;\n\tcolor: #dc3545;\n\tfont-weight: bold;\n}\n\n.calendarPosition {\n\tbottom: 1.8rem;\n\tposition: relative;\n\tright: 10%;\n\tfloat: right;\n}\n\n.datePickerWrapper {\n\tposition: relative;\n\n\ti {\n\t\tposition: absolute;\n\t\ttop: 0.65rem;\n\t\tright: 2rem;\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isRequired": "styles_isRequired__oGHr7",
	"calendarPosition": "styles_calendarPosition__g9W9b",
	"datePickerWrapper": "styles_datePickerWrapper__A5STb"
};
export default ___CSS_LOADER_EXPORT___;
