import React from 'react';
import PropTypes from 'prop-types';
import { InputFormCustom } from '../InputForm';

export const FieldFormTextInput = (props) => <InputFormCustom text="input" {...props} />;

FieldFormTextInput.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	required: PropTypes.bool,
	limitCharacters: PropTypes.number
};
