const useLocalStorage = () => {
	const getValueFromStorage = (key = '') => localStorage.getItem(key);
	const setValueInStorage = (key = '', value = null) =>
		localStorage.setItem(key, JSON.stringify(value));
	const removeValueFromStorage = (key = '') => localStorage.removeItem(key);
	const clearAllStorage = () => localStorage.clear();

	return {
		getValueFromStorage,
		setValueInStorage,
		removeValueFromStorage,
		clearAllStorage
	};
};

export default useLocalStorage;
