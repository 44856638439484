export const determineColumnStructure = (codeType, code) => {
	let defaultColumns = [
		{
			value: 'codeCategory',
			heading: 'Code Category',
			tabIndex: -1,
			disableSearch: true
		},
		{
			value: 'codeType',
			heading: 'Code Type',
			tabIndex: -1,
			disableSearch: true
		},
		{
			value: code ? code : 'code',
			heading: 'Code'
		},
		{
			value: 'description',
			heading: 'Description'
		},
		{
			value: 'activeDate',
			heading: 'Active Date',
			type: 'date'
		},
		{
			value: 'inactiveDate',
			heading: 'Inactive Date',
			type: 'date'
		}
	];
	let part1 = defaultColumns.slice(0, 4);
	let part2 = defaultColumns.slice(4);

	switch (codeType.codeType) {
		case 'feeGroup':
			part1 = defaultColumns.slice(1, 2);
			part1.unshift(
				{
					value: 'feeCategory',
					heading: 'Fee Category'
				},
				{
					value: 'feeCode',
					heading: 'Fee Code'
				}
			);
			part1.push({
				value: 'amount',
				heading: 'Amount'
			});
			part2.push({
				value: 'timesToApply',
				heading: 'Times to Apply'
			});
			defaultColumns = part1.concat(part2);
			return defaultColumns;
		case 'financial_till':
			part1 = defaultColumns.slice(1, 2);
			part1.push(
				{
					value: 'codeCategory',
					heading: 'Code Category'
				},
				{
					value: 'code',
					heading: 'Drawer Number'
				},
				{
					value: 'description',
					heading: 'Description'
				},
				{
					value: 'protected',
					heading: 'Protected'
				}
			);

			defaultColumns = part1.concat(part2);
			return defaultColumns;
		case 'fee':
			part1 = defaultColumns.slice(1, 2);
			part1.unshift(
				{
					value: 'feeCategory',
					heading: 'Fee Category'
				},
				{
					value: 'feeCode',
					heading: 'Fee Code'
				}
			);
			part1.push({
				value: 'amount',
				heading: 'Amount'
			});
			part2.push(
				{
					value: 'priority',
					heading: 'Priority'
				},
				{
					value: 'moneyDistribution',
					heading: 'Money Distribution'
				},
				{
					value: 'timesToApply',
					heading: 'Times to Apply'
				}
			);
			defaultColumns = part1.concat(part2);
			return defaultColumns;
		case 'courtSetting':
			part1.push(
				{
					value: 'office',
					heading: 'Office'
				},
				{
					value: 'clerk',
					heading: 'Clerk'
				}
			);
			defaultColumns = part1.concat(part2);
			return defaultColumns;
		case 'party':
			part1.push({
				value: 'partyGroupCodeByPartyGroupCode.description',
				heading: 'Party Group'
			});
			defaultColumns = part1.concat(part2);
			return defaultColumns;
		case 'disposition_code':
			const codeDescription = part1.slice(2, 4);
			part1 = part1.slice(0, 2);
			part1.push({
				value: 'dispositionType',
				heading: 'Disposition Category'
			});
			part1 = part1.concat(codeDescription);
			defaultColumns = part1.concat(part2);
			return defaultColumns;
		case 'case_type':
			part1.push({
				value: 'caseTypeCategoryCode',
				heading: 'Sub-Category'
			});
			defaultColumns = part1.concat(part2);

			return defaultColumns;
		case 'payment_method':
			part1 = defaultColumns.slice(1, 2);
			part1.unshift({
				value: 'codeCategory',
				heading: 'Code Category'
			});

			part1.push(
				{
					value: 'paymentCode',
					heading: 'Code'
				},
				{
					value: 'description',
					heading: 'Description'
				},
				{
					value: 'currencyCode',
					heading: 'Currency'
				}
			);
			defaultColumns = part1.concat(part2);
			return defaultColumns;
		case 'money_distribution':
			part1 = defaultColumns.slice(0, 4);
			part1.push(
				{
					value: 'caseCategoryCodeDesc',
					heading: 'Case Category'
				},
				{
					value: 'retainPct',
					heading: 'Retain %'
				},
				{
					value: 'statePct',
					heading: 'State %'
				},
				{
					value: 'countyPct',
					heading: 'County %'
				},
				{
					value: 'otherPct',
					heading: 'Other %'
				},
				{
					value: 'glAccount',
					heading: 'GL Account'
				}
			);
			defaultColumns = part1.concat(part2);
			return defaultColumns;
		default:
			return defaultColumns;
	}
};

export const formattedDate = (date) => (date ? new Date(date).toString('en-US') : null);
export const notNullText = (text) => (text ? text : null);
export const findOption = (options, code) => {
	let option = '';
	if (code) {
		option = options?.find((item) => item.value === code);
	}
	return option;
};

export const validateDate = (date) => {
	if (date) {
		const isValidDate = new Date(date);
		isValidDate.setDate(isValidDate.getDate() - 1);
		return isValidDate.toISOString() ? isValidDate.toISOString() : '';
	} else {
		return '';
	}
};
const isDate = (value) => {
	switch (typeof value) {
		case 'string':
			return !isNaN(Date.parse(value));
		case 'object':
			if (value instanceof Date) {
				return !isNaN(value.getTime());
			}
			break;
		default:
			return false;
	}
};
export const formatDateWithoutTime = (date) =>
	[date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/');

export const isGreaterThan = (date1, date2) => {
	if (!isDate(date1) || !isDate(date2)) return false;
	return date1 > date2;
};
export const isLessThan = (date1, date2) => {
	if (!isDate(date2)) return true;
	if (!isDate(date1) || !isDate(date2)) return false;
	return date1 < date2;
};
export const isPasswordConfirm = (password, confirmPassword) => {
	return password === confirmPassword;
};

export const isDescriptionConfirmed = (description) => {
	return Number(description.length) <= 255;
};

export const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);
