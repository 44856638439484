import { useEffect, useRef, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import { AlertErrorDialog } from './Alerts/index';
import { toolBarPdfTron } from './constants';
import LoaderComponent from '../Loader';
import { PDFTronServices } from '../../services/PdfServices/PDFTronServices';

/**
 *
 * @param {boolean} shouldRenderCustomIFrame Render an iframe which will hold the original file URL - This will be used if you wants to print the file directly in the browser
 * @param {string} customIFrameId Id of custom iframe which hold the original file
 */

function PDFTronComponent({
	enableElements,
	toolBarGroup,
	heightContainer,
	setIsLoadingDocument,
	isLoadingDocument,
	setInstanceDocumentViewer,
	instanceDocumentViewer,
	setWebViewer,
	webViewer,
	annotations,
	setAnnotations = () => {},
	setAnnotationManager = () => {},
	existDocument,
	source,
	editable = false,
	compareAnnotations = () => {},
	compareDocumentView = () => {},
	now,
	setProgressDocumentview = () => {},
	delayDocument = false,
	isReadOnly = false,
	fullAPI = false,
	setIsInstanceDocumentViewerLoadInProgress = () => {},
	enableOfficeEditing = false,
	enableEntireToolbar = false,
	setInfoDocumentLoaded = () => {},
	customFileName,
	shouldRenderCustomIFrame = false,
	customIFrameId = 'customIFrame'
}) {
	const viewer = useRef(null);
	const [isSaveDocument, setIsSaveDocument] = useState(false);
	const [showDocumentAlert, setShowDocumentAlert] = useState(false);
	const [fileSelected, setFileSelected] = useState(null);
	const [customIFrameFileUrl, setCustomIFrameFileUrl] = useState(null);
	const [alert, setAlert] = useState({
		title: '',
		body: <></>,
		footer: <></>
	});
	const [progress, setProgress] = useState(0);
	const { getInfoDownloadFile } = PDFTronServices();

	/* Saving Document animation*/
	const loadingPage = useRef(null);
	/**
	 * Functions to show/hide loader component
	 */
	const showLoader = (flagSaveDocument = true) => {
		if (!flagSaveDocument) {
			setIsSaveDocument(false);
		}
		setIsLoadingDocument(true);
	};
	/**
	 * function hideLoader
	 * @param {*} showAlertModal parameter to display a correct alert dialog
	 */
	const hideLoader = (showAlertModal) => {
		if (showAlertModal === 'file') {
			showAlertCorruptFile();
		}
		setIsLoadingDocument(false);
	};

	const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

	useEffect(() => {
		let config = {
			licenseKey: enableOfficeEditing
				? process.env.REACT_APP_PDFTRON_OFFICE_EDITOR
				: process.env.REACT_APP_PDFTRON_FE,
			path: '/pdftron',

			annotationUser: 'Test User',
			isAdminUser: true,
			isReadOnly,
			fullAPI,
			enableOfficeEditing
		};

		WebViewer(config, viewer.current).then(async (i) => {
			setWebViewer(i);

			const { documentViewer, Annotations, annotationManager } = i.Core;
			if (editable) {
				if (enableElements) {
					i.UI.enableElements(enableElements, ['contentEditButton']);
				} else {
					i.UI.enableElements(['contentEditButton']);
				}
			}

			if (!enableEntireToolbar) {
				if (enableElements && !editable) {
					i.UI.enableElements(enableElements);
				} else if (!enableElements && !editable) {
					i.UI.disableElements(Object.values(toolBarPdfTron));
				}

				if (toolBarGroup) {
					i.UI.setToolbarGroup(toolBarGroup);
				} else {
					i.UI.setToolbarGroup(toolBarPdfTron.VIEW);
				}
			}

			i.UI.disableElements(['printButton']);
			i.UI.openElements(['leftPanel']);

			if (existDocument) {
				const docInfo = await getInfoDownloadFile(source);

				if (!docInfo) {
					hideLoader();
					return;
				}

				setInfoDocumentLoaded(docInfo);
				const newUrl = docInfo.url;
				const extension = docInfo.extension;

				let optionsPDFTron = {
					onLoadingProgress: (progress) => {
						if (progress !== Infinity && progress < 1) {
							setProgress(Math.floor(progress * 100));
						}
					}
				};
				if (customFileName) {
					optionsPDFTron['filename'] = customFileName;
				}

				// eslint-disable-next-line default-case
				switch (extension) {
					case 'pdf':
						optionsPDFTron['extension'] = extension;
						break;

					case 'docx':
						optionsPDFTron['enableOfficeEditing'] = true;
						optionsPDFTron['extension'] = extension;
						optionsPDFTron['l'] = process.env.REACT_APP_PDFTRON_OFFICE_EDITOR;
						break;

					default:
						optionsPDFTron['extension'] = extension;
				}

				if (shouldRenderCustomIFrame) {
					let blob = await fetch(newUrl).then((r) => r.blob());
					var blobUrl = URL.createObjectURL(blob);
					setCustomIFrameFileUrl(blobUrl);
				}

				showLoader();
				documentViewer
					.loadDocument(newUrl, optionsPDFTron)
					.then()
					.catch((e) => {
						hideLoader();
					});
			}

			documentViewer.addEventListener('documentLoaded', async () => {
				setInstanceDocumentViewer(documentViewer);
				setIsInstanceDocumentViewerLoadInProgress(false);
				i.UI.setZoomLevel(1);

				if (existDocument && delayDocument) {
					setProgress(99);
					await sleep(5000);
					setProgressDocumentview(100);
				}

				hideLoader();

				if (annotations) {
					setAnnotations(Annotations);
					setAnnotationManager(annotationManager);
					setIsLoadingDocument(false);
				}
			});

			annotationManager.addEventListener('annotationChanged', async () => {
				compareAnnotations(i);
			});

			documentViewer.addEventListener('displayModeUpdated', () => {
				compareDocumentView(i);
			});

			//Accessibility:Sets lang attribute to html element inside PDFtron iframe
			const iFrameWebViewer = document.getElementById('webviewer-1');
			if (iFrameWebViewer) {
				iFrameWebViewer.contentWindow.document.documentElement.lang = 'en';
				iFrameWebViewer.setAttribute('title', 'Document Viewer');
				iFrameWebViewer.contentWindow.document
					.getElementById('thumbnailSize')
					.setAttribute('aria-label', 'thumbnail slider');
				const svgWithoutRole = iFrameWebViewer.contentWindow.document.getElementsByTagName('svg');
				Array.from(svgWithoutRole).forEach((element) => {
					element.setAttribute('role', 'img');
				});
			}
		});

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (instanceDocumentViewer) {
			webViewer.UI.openElements(['leftPanel']);
			webViewer.UI.enableElements(['downloadButton']);
			const FitMode = webViewer.UI.FitMode;
			webViewer.UI.setFitMode(FitMode.FitPage);
		}
		// eslint-disable-next-line
	}, [instanceDocumentViewer, viewer]);

	const closeModalAlertDocument = () => {
		setShowDocumentAlert(false);
	};

	const showAlertCorruptFile = () => {
		const contentBody = `The file you selected was corrupted or broken, try again with another file.`;
		setAlert({
			...alert,
			title: 'Error: File type not supported',
			body: <AlertErrorDialog.Body content={contentBody} />,
			footer: <AlertErrorDialog.Footer closeModal={closeModalAlertDocument} />
		});

		setShowDocumentAlert(true);
	};

	return (
		<>
			{shouldRenderCustomIFrame && (
				<iframe
					id={customIFrameId}
					title="customIFrameForPrint"
					src={customIFrameFileUrl}
					style={{ display: 'none' }}
				></iframe>
			)}
			<div
				className="webviewer"
				ref={viewer}
				style={{ height: heightContainer ? heightContainer : '100vh', margin: 0, width: '100%' }}
				hidden={fileSelected}
			>
				<LoaderComponent
					loadingBar={isLoadingDocument}
					loadingPage={loadingPage}
					now={now > 0 ? now : progress}
				/>
			</div>
		</>
	);
}

export default PDFTronComponent;
