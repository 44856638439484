export const getFullName = (person) => {
	if (!person) {
		return '-';
	}

	const { lastName, firstName, middleName } = person;
	return (
		(lastName ? lastName + ', ' : '') + (firstName ?? '') + (middleName ? ' ' + middleName : '')
	);
};
