import { useState } from 'react';
import { Button } from 'react-bootstrap';

const AnnotateDocumentButton = ({ disable, hidden, children }) => {
	const [showActions, setShowActions] = useState(false);

	return (
		<>
			<Button
				data-testid="enable-annotations-dv"
				disabled={disable}
				hidden={showActions || hidden}
				onClick={() => setShowActions(true)}
			>
				Annotate Document
			</Button>

			{showActions && children}
		</>
	);
};

export default AnnotateDocumentButton;
