export const toolBarPdfTron = {
	ANNOTATE: 'toolbarGroup-Annotate',
	EDIT: 'toolbarGroup-Edit',
	FILL_AND_SIGN: 'toolbarGroup-FillAndSign',
	FORMS: 'toolbarGroup-Forms',
	INSERT: 'toolbarGroup-Insert',
	MEASURE: 'toolbarGroup-Measure',
	SHAPES: 'toolbarGroup-Shapes',
	VIEW: 'toolbarGroup-View',
	PAN: 'panToolButton',
	SELECT_TOOL_BUTTON: 'selectToolButton',
	TOOLS_BUTTON: 'toolsButton',
	DOWNLOAD_BUTTON: 'downloadButton',
	ANNOTATION_POP_UP: 'annotationPopup',
	PRINT_BUTTOM: 'printButton',
	TOGGLE_NOTES_BUTTON: 'toggleNotesButton'
};
