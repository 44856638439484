import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { formatCurrency } from '../../../utils';

/**
 * BackendCalculatedTotalRow.
 * @param {Object} financialItems Individual data registry
 * @param {Object[]} columns Array of information about columns properties
 * @param {boolean} enableSelection boolean turn on/off selection
 * @returns {ReactElement} returns JSX with the component BackendCalculatedTotalRow.
 */

const renderItem = (item, value) => formatCurrency(item[value]) ?? '-';

const BackendCalculatedTotalRow = ({ financialItems, columns, enableSelection, tableId }) => {
	const newColumns = [...columns];

	if (enableSelection && columns[0].value !== 'total') {
		newColumns.unshift({ heading: 'total', value: 'total' });
	}

	return (
		<tr id={`totalRow-${tableId}`} className={`text-center ${styles.totalRow}`} key={'TotalColumn'}>
			{newColumns.map(({ value, editable = false }, index) => (
				<td className={'data-item last-data-item'} key={index}>
					{index === 0 ? 'Total' : renderItem(financialItems, value)}
				</td>
			))}
		</tr>
	);
};

BackendCalculatedTotalRow.propTypes = {
	financialItems: PropTypes.object,
	columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default BackendCalculatedTotalRow;
