const ErrorMessage = ({ error, ...props }) => {
	if (!error) return <></>;
	return (
		<span role="alert" className="text-danger small" style={props.styles}>
			{error}
		</span>
	);
};

export default ErrorMessage;
