import React from 'react';

import { ErrorMessage, Field } from 'formik';

import { InputLabel } from './';
import { NewDatePicker } from '../NewDatePicker';
import { formatCorrectDate } from '../../utils/dateUtils';

export const FieldFormNewDatePicker = ({
	id,
	label = '',
	required,
	maxDate,
	minDate,
	onChange = () => {},
	onBlur = () => {},
	disabled,
	placeHolder,
	dataFormatter,
	reInitialize,
	isClearable,
	isInvalid = false,
	isAutoDate
}) => (
	<>
		<Field name={id}>
			{({ field, form }) => (
				<>
					<InputLabel required={required} name={label} htmlFor={field.name} />
					<NewDatePicker
						id={field.name}
						name={field.name}
						label={label}
						onChange={(e) => {
							form.setFieldValue(
								field.name,
								dataFormatter && e.target.value
									? dataFormatter(e.target.value)
									: e.target.value ?? null
							);
							onChange(e.target.value ?? null);
						}}
						required={required}
						maxDate={maxDate}
						minDate={minDate}
						disabled={disabled}
						onBlur={onBlur}
						type="date"
						placeHolder={placeHolder}
						reInitialize={reInitialize}
						initialValue={field?.value && formatCorrectDate(field?.value)}
						isClearable={isClearable}
						isInvalid={isInvalid}
						autoSaveValue={field?.value && formatCorrectDate(field?.value)}
						isAutoDate={isAutoDate}
					/>
				</>
			)}
		</Field>
		<ErrorMessage
			component="div"
			name={id}
			className={'position-absolute small text-danger'}
			role="alert"
		/>
	</>
);
