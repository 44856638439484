import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

const useUserStore = () => {
	const clerkList = useSelector((state) => state.user.clerks);
	const userInfo = useSelector((state) => state.user.userInfo);
	const [userClerks, setUserClerks] = useState(clerkList);

	useEffect(() => {
		setUserClerks(
			clerkList
				?.map((item) => ({
					...item,
					jurisdiction: `${item?.countyCodeDescription} ${item?.clerkTypeDescription}`
				}))
				.sort((a, b) => a?.jurisdiction.localeCompare(b?.jurisdiction))
		);
	}, [clerkList]);

	const selectedClerkId = useSelector((state) => state.user.jurisdiction);
	const selectedClerk = !isEmpty(clerkList)
		? clerkList.find((e) => e.clerkId === selectedClerkId)
		: null;

	return {
		userClerks,
		selectedClerkId,
		selectedClerk,
		userInfo
	};
};

export default useUserStore;
