import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSaveHistory } from '../hooks/useSaveHistory';
import { WithAccess } from '../hooks/usePageAccess';
import CertifiedPayments from '../pages/CertifiedPayments';
import DocumentView from '../pages/Case/CaseBatchUpdate/DocumentView';
import { MakePayOut } from '../pages/MakePayOut';
const InternalMessagingView = lazy(() =>
	import('../pages/InternalMessaging/InternalMessagingView')
);
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const RegisterPage = lazy(() => import('../pages/RegisterPage'));
const ResetPasswordRoutes = lazy(() => import('./ResetPasswordRoutes'));
const DocumentViewRoutes = lazy(() => import('./DocumentViewRoutes'));
const FormMaintenanceRoutes = lazy(() => import('./FormMaintenanceRoutes'));
const FeeMaintenanceRoutes = lazy(() => import('./FeeMaintenanceRoutes'));
const CourtSettingsRoutes = lazy(() => import('./CourtSettingsRoutes/CourtSettingsRoutes'));
const CourtCalendarRoutes = lazy(() => import('./CourtCalendarRoutes/CourtCalendarRoutes'));
const ResourceCalendarRoutes = lazy(() => import('./ResourceCalendarRoutes/CourtCalendarRoutes'));
const PublicCalendarRoutes = lazy(() => import('./PublicCalendarRoutes/PublicCalendarRoutes'));
const ReportsRoutes = lazy(() => import('./ReportsRoutes'));
const MaintenanceRoutes = lazy(() => import('./MaintenanceRoutes'));
const CaseMaintenanceRoutes = lazy(() => import('./CaseMaintenanceRoutes'));
const EventMaintenanceRoutes = lazy(() => import('./EventMaintenanceRoutes'));
const MoneyDistributionMaintenanceRoutes = lazy(() =>
	import('./MoneyDistributionMaintenanceRoutes')
);
const JudgeCaseTypeRoutes = lazy(() => import('./JudgeCaseTypeRoutes'));
const PersonRecord = lazy(() => import('../pages/PersonRecord/PersonRecord'));
const CaseViewRoutes = lazy(() => import('./CaseViewRoutes'));
const Health = lazy(() => import('../pages/Health/health'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const HomeRoutes = lazy(() => import('./HomeRoutes'));
const NotificationsRoutes = lazy(() => import('./NotificationsRoutes'));
const EfileRoutes = lazy(() => import('./EFileRoutes'));
const MainContainer = lazy(() => import('../components/layout/MainContainer'));
const AttorneyView = lazy(() => import('../pages/Attorneys/View'));
const ViewAllWheels = lazy(() => import('../pages/Attorneys/Wheels/ViewAllWheels'));
const AttorneyWheelMembership = lazy(() =>
	import('../pages/Attorneys/Wheels/Pages/AttorneyWheelMembership/AttorneyWheelMembership')
);
const AdminConsoleRoutes = lazy(() => import('./AdminConsoleRoutes'));
const UserProfileRoutes = lazy(() => import('./UserProfileRoutes'));
const WorkflowsRoutes = lazy(() => import('./WorkflowRoutes'));
// temporary route for verifyResetToken will need to be removed when endpoint is updated
const VerifyToken = lazy(() => import('../pages/VerifyResetToken'));
const ImportPagesRoutes = lazy(() => import('./ImportPageRoutes'));
const JudgeView = lazy(() => import('../pages/Judge/View'));
const JudgeViewAll = lazy(() => import('../pages/Judge/ViewAll/JudgeViewAll'));
const OffenseMaintenanceRoutes = lazy(() => import('./OffenseMaintenanceRoutes'));
const AttorneyViewAll = lazy(() => import('../pages/Attorneys/ViewAll/AttorneyViewAll'));
const AssignedCases = lazy(() => import('../pages/Attorneys/AssignedCases'));
const AttorneyWheelView = lazy(() =>
	import('../pages/Attorneys/Wheels/Pages/AttorneyWheelView/AttorneyWheelView')
);
const CaseSearch = lazy(() => import('../pages/Case/CaseSearch'));
const VerifyInvitationToken = lazy(() => import('../pages/VerifyInvitationToken'));
// const SearchFinancialRoutes = lazy(() => import("./SearchRoutes"));
const UCMSWikiRoutes = lazy(() => import('./UCMSWikiRoutes'));
const CaseBatchUpdate = lazy(() => import('../pages/Case/CaseBatchUpdate'));
const CaseBatchUpdatePayment = lazy(() => import('../pages/Case/CaseBatchUpdatePayment'));
const CriminalDocketReport = lazy(() =>
	import('../pages/CourtCalendar/Modals/CriminalDocketReport')
);
const UCMSMaintenanceRoutes = lazy(() => import('./UCMSMaintenanceRoutes'));
const ExportPagesRoutes = lazy(() => import('./ExportPageRoutes'));
const MiscellaneousPaymentRoutes = lazy(() => import('./MiscellaneousPaymentRoutes'));
const CourtRegistryMaintenanceRoutes = lazy(() => import('./CourtRegistryMaintenanceRoutes'));
// TODO: Login Route useEffect

const Router = () => {
	useSaveHistory();

	return (
		<Routes>
			<Route path="/" element={<LoginPage />} />
			<Route path="/login" element={<LoginPage />} />
			<Route path="/register" element={<RegisterPage />} />
			{/* temporary route for verifyResetToken will need to be removed when endpoint is updated */}
			<Route path="/verify-token" element={<VerifyToken />} />
			{/* official path is within ResetPasswordRoutes */}
			<Route path="/verify-invitation-token" element={<VerifyInvitationToken />} />

			{/*UCMS wiki */}
			<Route
				path="/wiki/*"
				element={
					<PrivateRoute>
						<UCMSWikiRoutes />
					</PrivateRoute>
				}
			/>

			{/* Home routes group */}
			<Route
				path="/home/*"
				element={
					<PrivateRoute>
						<HomeRoutes />
					</PrivateRoute>
				}
			/>

			{/* Notifications routes group */}
			<Route
				path="/notifications/*"
				element={
					<PrivateRoute>
						<NotificationsRoutes />
					</PrivateRoute>
				}
			/>

			{/* Admin Console */}
			<Route
				path="/admin/*"
				element={
					<PrivateRoute>
						<WithAccess pageName={'adminconsole'}>
							<AdminConsoleRoutes />
						</WithAccess>
					</PrivateRoute>
				}
			/>

			{/* End User UserProfile */}
			<Route
				path="/user/:username/*"
				element={
					<PrivateRoute>
						<UserProfileRoutes />
					</PrivateRoute>
				}
			/>

			{/* Forgot password group */}
			<Route path="/forgot-password/*" element={<ResetPasswordRoutes />} />

			{/* DocumentView routes group */}
			<Route
				path="/document-view/*"
				element={
					<PrivateRoute>
						<DocumentViewRoutes />
					</PrivateRoute>
				}
			/>
			{/* Form Maintenance Routes group */}
			<Route
				path="/form-maintenance/*"
				element={
					<PrivateRoute>
						<WithAccess pageName={'formmaint'}>
							<FormMaintenanceRoutes />
						</WithAccess>
					</PrivateRoute>
				}
			/>

			{/* Maintenance routes group */}
			<Route
				path="/maintenance/*"
				element={
					<PrivateRoute>
						<MaintenanceRoutes />
					</PrivateRoute>
				}
			/>

			{/* Extra Maintenance routes groups for functional UCMS Wiki */}
			<Route
				path="/event-maintenance/*"
				element={
					<PrivateRoute>
						<WithAccess pageName={'eventcode'}>
							<EventMaintenanceRoutes />
						</WithAccess>
					</PrivateRoute>
				}
			/>
			<Route
				path="/case-maintenance/*"
				element={
					<PrivateRoute>
						<CaseMaintenanceRoutes />
					</PrivateRoute>
				}
			/>

			{/* Fee Maintenance routes group */}
			<Route
				path="/fee-maintenance/*"
				element={
					<PrivateRoute>
						<FeeMaintenanceRoutes />
					</PrivateRoute>
				}
			/>

			{/* Court Settings routes group */}
			<Route
				path="/court-settings/*"
				element={
					<PrivateRoute>
						<CourtSettingsRoutes />
					</PrivateRoute>
				}
			/>

			{/* Court Calendar routes group */}
			<Route
				path="/court-calendar/*"
				element={
					<PrivateRoute>
						<WithAccess pageName={'calendar'} route={true}>
							<CourtCalendarRoutes />
						</WithAccess>
					</PrivateRoute>
				}
			/>

			{/* Resource Calendar routes group */}
			<Route
				path="/resource-calendar/*"
				element={
					<PrivateRoute>
						<ResourceCalendarRoutes />
					</PrivateRoute>
				}
			/>

			{/* Criminal Docket */}
			<Route
				path="/criminal-docket"
				element={
					<PrivateRoute>
						<CriminalDocketReport />
					</PrivateRoute>
				}
			/>

			{/* Public Calendar routes group */}
			<Route
				path="/public-calendar/*"
				element={
					<PrivateRoute>
						<PublicCalendarRoutes />
					</PrivateRoute>
				}
			/>

			{/* Reports routes group */}
			<Route
				path="/reports/*"
				element={
					<PrivateRoute>
						<ReportsRoutes />
					</PrivateRoute>
				}
			/>

			{/* Person profile group */}
			<Route
				path="/person-record/*"
				element={
					<PrivateRoute>
						<PersonRecord />
					</PrivateRoute>
				}
			/>

			{/* Attorney group */}

			<Route
				path="/attorneys/*"
				element={
					<PrivateRoute>
						<Routes>
							<Route element={<MainContainer />}>
								<Route index element={<AttorneyViewAll />}></Route>
								<Route
									path=":attorneyId/*"
									element={
										<PrivateRoute>
											<Routes>
												<Route index element={<AttorneyView />}></Route>
												<Route exact path="assigned-cases" element={<AssignedCases />}></Route>
												<Route exact path="wheels" element={<AttorneyWheelMembership />}></Route>
											</Routes>
										</PrivateRoute>
									}
								/>
								<Route
									path="wheels/*"
									element={
										<PrivateRoute>
											<Routes>
												<Route index element={<ViewAllWheels />}></Route>
												<Route path=":wheelId" element={<AttorneyWheelView />}></Route>
											</Routes>
										</PrivateRoute>
									}
								/>
							</Route>
						</Routes>
					</PrivateRoute>
				}
			/>
			<Route
				path="/judges/*"
				element={
					<PrivateRoute>
						<Routes>
							<Route element={<MainContainer />}>
								<Route
									index
									element={
										<WithAccess pageName={'judgeview'}>
											<JudgeViewAll />
										</WithAccess>
									}
								></Route>
								<Route path=":id" element={<JudgeView />}></Route>
							</Route>
						</Routes>
					</PrivateRoute>
				}
			/>

			{/* Case */}
			<Route
				path="/case/*"
				element={
					<PrivateRoute>
						<WithAccess pageName={'case'}>
							<CaseViewRoutes />
						</WithAccess>
					</PrivateRoute>
				}
			/>

			<Route
				path="/search/*"
				element={
					<PrivateRoute>
						<Routes>
							<Route element={<MainContainer />}>
								<Route index element={<CaseSearch />} />
							</Route>
						</Routes>
					</PrivateRoute>
				}
			/>

			<Route
				path="/case-batch/*"
				element={
					<PrivateRoute>
						<Routes>
							<Route element={<MainContainer />}>
								<Route index element={<CaseBatchUpdate />} />
							</Route>
						</Routes>
					</PrivateRoute>
				}
			/>

			<Route
				path="/batch-event-document-view/*"
				element={
					<PrivateRoute>
						<Routes>
							<Route element={<MainContainer />}>
								<Route index element={<DocumentView />} />
							</Route>
						</Routes>
					</PrivateRoute>
				}
			/>

			<Route
				path="/certified-payments"
				element={
					<PrivateRoute>
						<Routes>
							<Route element={<MainContainer />}>
								<Route index element={<CertifiedPayments />} />
							</Route>
						</Routes>
					</PrivateRoute>
				}
			/>

			<Route
				path="/case-batch-payment/*"
				element={
					<PrivateRoute>
						<Routes>
							<Route element={<MainContainer />}>
								<Route index element={<CaseBatchUpdatePayment />} />
							</Route>
						</Routes>
					</PrivateRoute>
				}
			/>

			{/* Health page */}
			<Route path="/health" element={<Health />} />

			{/* Payment */}
			<Route
				path="/offense-maintenance/*"
				element={
					<PrivateRoute>
						<OffenseMaintenanceRoutes />
					</PrivateRoute>
				}
			/>

			{/* Money Distribution */}
			<Route
				path="/money-distribution-maintenance"
				element={
					<PrivateRoute>
						<MoneyDistributionMaintenanceRoutes />
					</PrivateRoute>
				}
			/>
			{/* court Registry */}
			<Route
				path="/court-registry-maintenance/*"
				element={
					<PrivateRoute>
						<CourtRegistryMaintenanceRoutes />
					</PrivateRoute>
				}
			/>

			{/*Judge Case Type*/}
			<Route
				path="/judge-case-type/*"
				element={
					<PrivateRoute>
						<WithAccess pageName="casetypejudge">
							<JudgeCaseTypeRoutes />
						</WithAccess>
					</PrivateRoute>
				}
			/>

			{/* Efile */}
			<Route
				path="/efile/*"
				element={
					<PrivateRoute>
						<EfileRoutes />
					</PrivateRoute>
				}
			/>
			{/* ImportPage */}
			<Route
				path="/import/*"
				element={
					<PrivateRoute>
						<ImportPagesRoutes />
					</PrivateRoute>
				}
			/>
			{/* ExportPage */}
			<Route
				path="/export/*"
				element={
					<PrivateRoute>
						<ExportPagesRoutes />
					</PrivateRoute>
				}
			/>
			{/* Workflows */}
			<Route
				path="/workflows/*"
				element={
					<PrivateRoute>
						<WorkflowsRoutes />
					</PrivateRoute>
				}
			/>

			{/* UCMS Configuration routes group */}
			<Route
				path="/ucms-maintenance/*"
				element={
					<PrivateRoute>
						<UCMSMaintenanceRoutes />
					</PrivateRoute>
				}
			/>
			<Route
				path="/miscellaneous-payment/*"
				element={
					<PrivateRoute>
						<MiscellaneousPaymentRoutes />
					</PrivateRoute>
				}
			/>

			<Route
				path="/make-pay-out/*"
				element={
					<PrivateRoute>
						<MakePayOut />
					</PrivateRoute>
				}
			/>

			<Route
				path="/internal-messaging/*"
				element={
					<PrivateRoute>
						<Routes>
							<Route element={<MainContainer />}>
								<Route index element={<InternalMessagingView />}></Route>
								<Route path=":messageId" element={<InternalMessagingView />} />
								<Route path=":messageId/thread/:threadId" element={<InternalMessagingView />} />
							</Route>
						</Routes>
					</PrivateRoute>
				}
			/>

			{/* Search financial transactions
			<Route
				path="/search-financial/*"
				element={
					<PrivateRoute>
						<SearchFinancialRoutes />
					</PrivateRoute>
				}
			/> */}

			<Route path="/not-found" element={<NotFoundPage />} />

			<Route path="*" element={<Navigate to="/not-found" />} />
		</Routes>
	);
};

export default Router;
