import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	formView: {
		selectForm: {},
		selectRecipient: [],
		linkedCase: {},
		documents: []
	}
};

const formViewSlice = createSlice({
	initialState,
	name: 'formViewSlice',
	reducers: {
		clearAll: (state) => {
			state.formView = {};
		},
		persistFormView: (state, action) => {
			state.formView = action.payload;
		}
	}
});

export const { clearAll, persistFormView } = formViewSlice.actions;

export default formViewSlice.reducer;
