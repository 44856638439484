// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (forced-colors: active) {\n  .styles_formCheckInput__QAfhc input:checked[type=checkbox] {\n    forced-color-adjust: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/common/CheckboxInput/styles.module.scss"],"names":[],"mappings":"AAAA;EAEE;IACC,yBAAA;EAAD;AACF","sourcesContent":["@media (forced-colors: active) {\n\t.formCheckInput {\n\t\tinput:checked[type=\"checkbox\"] {\n\t\t\tforced-color-adjust: none;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formCheckInput": "styles_formCheckInput__QAfhc"
};
export default ___CSS_LOADER_EXPORT___;
