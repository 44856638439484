import { Modal, Button } from 'react-bootstrap';
import { removeAutoSaveData } from '../../../utils/autoSave';
import RequestButton from '../../RequestButton';

import styles from './styles.module.scss';

const Dialog = ({
	isShow = false,
	onClose = () => {},
	title = '',
	children,
	onCancelButton = () => {},
	onConfirm = () => {},
	isAForm = false,
	confirmLabel = 'Submit',
	formId = '',
	isLoadingConfirm = false,
	submitDisabled = false,
	footer = null,
	size = 'xl',
	enableSubmit = true,
	centered = false,
	closeButton = true,
	cancelLabel = 'Cancel',
	hasBackdrop = false,
	disabled = false,
	hidden = false
}) => {
	return (
		<Modal
			size={size}
			show={isShow}
			onHide={() => {
				removeAutoSaveData();
				onClose();
			}}
			aria-label={`Dialog ${title}`}
			backdrop="static"
			backdropClassName={hasBackdrop ? styles.backdrop : ''}
			animation={false}
			centered={centered}
		>
			<Modal.Header closeButton={closeButton} closeLabel={`${title} Close Dialog`}>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>

			<Modal.Body>{children}</Modal.Body>

			{footer ? (
				<Modal.Footer>{footer}</Modal.Footer>
			) : isAForm ? (
				<Modal.Footer>
					{!hidden && enableSubmit && !disabled && (
						<RequestButton
							formId={formId}
							handleRequest={() => {
								// As it's a form, clean temporary form data
								removeAutoSaveData();
								onConfirm();
							}}
							disabled={isLoadingConfirm || submitDisabled}
							children={confirmLabel}
						/>
					)}

					<Button
						id="cancelBtn"
						variant={`${hidden ? 'primary' : 'secondary'}`}
						onClick={() => {
							// As it's a form, clean temporary form data
							removeAutoSaveData();
							onCancelButton();
						}}
					>
						{cancelLabel}
					</Button>
				</Modal.Footer>
			) : (
				<></>
			)}
		</Modal>
	);
};

export default Dialog;
