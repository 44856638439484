import {
	caseListTitle,
	dailyEventReportTitle
} from '../pages/Case/CaseSearch/AdvancedSearchForm/AdvancedSearchUtils';
import { formatDate } from '../utils';

export const PdfReportHeader = (props) => {
	const divElement = document.createElement('div');
	divElement.setAttribute('class', 'reportHeader');
	const addHeader = document.createElement('p');

	const title = props.find((item) => item.title)?.title;
	let result = '';
	if (title === dailyEventReportTitle) {
		result = `<b>${dailyEventReportTitle}</b> <br/> Generated On: ${formatDate(new Date())} <br/>`;
	}
	if (title === caseListTitle) {
		result = `<b>${caseListTitle}</b>  <br/>`;
	}

	props.map((item) => {
		result += `${Object.entries(item)
			.filter(([key]) => !['title', 'description'].includes(key))
			.map(([key, value]) => `${key}: ${value} <br/>`)
			.join('')}`;
	});
	addHeader.innerHTML = result;

	divElement.appendChild(addHeader);

	divElement.style.textAlign = 'center';

	return divElement;
};
