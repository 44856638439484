import { useCallback } from 'react';
import axios from 'axios';

import { useAuthContext } from '../hooks/useAuthContext';
import { getClerkIdFromCookies } from '../utils/cookiesUtils';

export const HttpService = () => {
	const { logout, token } = useAuthContext();

	const UNAUTHORIZED_STATUS_CODE = 401;
	const createRequestOptions = useCallback(
		(method, url, body) => {
			return {
				url: url,
				method: method,
				credentials: 'omit',
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json',
					'clerk-id': getClerkIdFromCookies()
				},

				data: body
			};
		},
		[token]
	);

	const createRequestOptionsNoBody = useCallback(
		(method, url, sendNullClerkId, clerkId) => {
			return {
				url: url,
				method: method,
				credentials: 'omit',
				headers: {
					Authorization: `Bearer ${token}`,
					'clerk-id': sendNullClerkId ? '' : clerkId ? clerkId : getClerkIdFromCookies()
				}
			};
		},
		[token]
	);

	const createRequestOptionsNoBodyForExport = useCallback(
		(method, url) => {
			return {
				url: url,
				method: method,
				responseType: 'blob',
				credentials: 'omit',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/vnd.ms-excel',
					'Content-Disposition': 'attachment; filename="export.xls',
					'clerk-id': getClerkIdFromCookies()
				}
			};
		},
		[token]
	);

	const fetchProcess = useCallback(async (_, options, name, errorResponse) => {
		let response;
		try {
			const res = await axios(options);
			if (res) {
				return res;
			}
		} catch (err) {
			response = err?.response;
			if (err?.response?.status === UNAUTHORIZED_STATUS_CODE) {
				logout();
			}
			console.error(name, err);
		}

		return { ...errorResponse, ...response };
	}, []);

	const get = useCallback(
		async (url, name, errorResponse, sendNullClerkId, clerkId, sendClerkType) => {
			const options = createRequestOptionsNoBody(
				'get',
				url,
				sendNullClerkId,
				clerkId,
				sendClerkType
			);
			return fetchProcess(url, options, name, errorResponse);
		},
		[createRequestOptionsNoBody, fetchProcess]
	);

	const post = useCallback(
		async (url, body, name, errorResponse) => {
			const options = createRequestOptions('post', url, body);
			return fetchProcess(url, options, name, errorResponse);
		},
		[createRequestOptions, fetchProcess]
	);

	const getWithoutClerk = useCallback(
		async (url, name, errorResponse) => {
			return fetchProcess(
				url,
				{
					url: url,
					method: 'get',
					credentials: 'omit',
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json'
					}
				},
				name,
				errorResponse
			);
		},
		[fetchProcess]
	);

	const postWithoutClerk = useCallback(
		async (url, body, name, errorResponse) => {
			return fetchProcess(
				url,
				{
					url: url,
					method: 'post',
					credentials: 'omit',
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json',
						'clerk-id': ''
					},

					data: body
				},
				name,
				errorResponse
			);
		},
		[fetchProcess]
	);

	const putWithoutClerk = useCallback(
		async (url, body, name, errorResponse) => {
			return fetchProcess(
				url,
				{
					url: url,
					method: 'put',
					credentials: 'omit',
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json'
					},

					data: body
				},
				name,
				errorResponse
			);
		},
		[fetchProcess]
	);

	const put = useCallback(
		async (url, body, name, errorResponse) => {
			const options = createRequestOptions('put', url, body);
			return fetchProcess(url, options, name, errorResponse);
		},
		[createRequestOptions, fetchProcess]
	);

	const patch = useCallback(
		async (url, body, name, errorResponse) => {
			const options = createRequestOptions('patch', url, body);
			return fetchProcess(url, options, name, errorResponse);
		},
		[createRequestOptions, fetchProcess]
	);

	const deleteFn = useCallback(
		async (url, name, errorResponse) => {
			const options = createRequestOptionsNoBody('delete', url);
			return fetchProcess(url, options, name, errorResponse);
		},
		[createRequestOptionsNoBody, fetchProcess]
	);

	const deleteWithBody = useCallback(
		async (url, body, name, errorResponse) => {
			const options = createRequestOptions('delete', url, body);
			return fetchProcess(url, options, name, errorResponse);
		},
		[createRequestOptions, fetchProcess]
	);

	const getExportDocument = useCallback(
		async (url, name, errorResponse) => {
			const options = createRequestOptionsNoBodyForExport('get', url);
			return fetchProcess(url, options, name, errorResponse);
		},
		[createRequestOptionsNoBodyForExport, fetchProcess]
	);

	return {
		get,
		post,
		put,
		patch,
		deleteFn,
		deleteWithBody,
		getExportDocument,
		postWithoutClerk,
		putWithoutClerk,
		getWithoutClerk
	};
};
