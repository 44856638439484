import PropTypes from 'prop-types';

/**
 * TablePrintDocument.
 * @param {boolean} printEnable Show component
 * @param {boolean} showCheckBox Show component
 * @param {ref} mergeCheckedRef Ref to checkbox
 * @returns {ReactElement} returns JSX with the component TablePrintDocument.
 */

const TablePrintDocument = ({
	printEnable,
	mergeCheckedRef,
	showCheckBox = false,
	entity = ''
}) => {
	return (
		<div style={{ display: printEnable ? 'initial' : 'none' }}>
			<div className="form-check">
				<input
					className="form-check-input"
					type="checkbox"
					value=""
					id={`flexCheckDefault${'-' + entity}`}
					aria-label={`flexCheckDefault${'-' + entity}`}
				/>
				<label className="form-check-label">Print documents as redacted</label>
			</div>

			{showCheckBox && (
				<div className="form-check">
					<input
						className="form-check-input"
						type="checkbox"
						value=""
						ref={mergeCheckedRef}
						id={`flexCheckDefault${'-' + entity}`}
						aria-label={`flexCheckDefault${'-' + entity}`}
					/>
					<label className="form-check-label">Merge documents</label>
				</div>
			)}
		</div>
	);
};

TablePrintDocument.propTypes = {
	printEnable: PropTypes.bool
};

export default TablePrintDocument;
