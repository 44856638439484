import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	totalUnreadMessage: 0
};

const internalMessagingSlice = createSlice({
	initialState,
	name: 'internalMessagingSlice',
	reducers: {
		updateNumberOfUnreadMessages: (state, action) => {
			state.totalUnreadMessage = action.payload;
		}
	}
});

export const { updateNumberOfUnreadMessages } = internalMessagingSlice.actions;

export default internalMessagingSlice.reducer;
