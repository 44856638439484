import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import RequiredMark from '../../common/RequiredMark';

const DragAndDrop = ({
	onChange,
	name,
	label,
	fileType,
	disabled,
	multiple = false,
	maxFiles = 0,
	error,
	required,
	touched
}) => {
	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
		maxFiles,
		multiple,
		accept: fileType,
		onDrop: (acceptedFiles) => {
			if (multiple) {
				onChange(acceptedFiles);
			} else {
				onChange(acceptedFiles[0]);
			}
		}
	});

	const getFileTypesAccepted = () => {
		return fileType && fileType['application/image']
			? fileType['application/image'].join(', ')
			: '';
	};

	return (
		<>
			<p className="my-2 fs-6">
				{label} {required && <RequiredMark />}
			</p>
			<div
				{...getRootProps({ className: 'dropzone' })}
				className="text-center border border-dark border-1 rounded-3 p-3"
				style={{ pointerEvents: disabled ? 'none' : 'auto' }}
				role={'button'}
			>
				<input {...getInputProps()} id={name} name={name} aria-label="fileUpload" />
				<p className="mt-5">Drag and drop file here, or click to select a file</p>
				{fileType && <p>Only allow Image files: {getFileTypesAccepted(fileType)}</p>}
				<Button disabled={disabled}> Open File Explorer</Button>
				{(touched || acceptedFiles.length) && !Boolean(error) ? (
					<p className="text-success fs-3 m-0">
						<i className="fas fa-check-circle p-2" />
					</p>
				) : (
					<p className="text-danger fs-6 m-0 p-2">{error}</p>
				)}
			</div>
			<div className="d-flex aling-items-center my-2 ps-2 ">
				<p className="fs-6 fw-bold">File name: </p>
				{acceptedFiles.map((file) => (
					<p className="fs-6 ms-1" key={file.name}>
						{file.name}
					</p>
				))}
			</div>
		</>
	);
};

export default DragAndDrop;
