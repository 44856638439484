import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const attorneyHTTP = () => {
	const { get, put, post, deleteFn } = HttpService();

	const postAttorney = async (body) => {
		const url = `${BASE_URL}/api/attorney/attorney`;
		return await post(url, body, 'new attorney', {});
	};

	const getAttorneyStatus = () => {
		const url = `${BASE_URL}/api/code-table-management/code-tables/categories/attorney_status_code`;
		return get(url, 'GetStatus', []);
	};

	const getAttorneyInfo = async (id) => {
		const url = `${BASE_URL}/api/attorney/attorney/${id}`;
		return get(url, 'GetAttorney', []);
	};

	const putAttorneyById = async (id, data) => {
		return await put(`${BASE_URL}/api/attorney/attorney/${id}`, data);
	};

	const getAttorneysAll = async (pageSize, pageNumber) => {
		return await get(`${BASE_URL}/api/attorney/attorney/all?page=${pageNumber}&size=${pageSize}`);
	};

	const getAllAttorneyWheels = async (setData) => {
		await get(`${BASE_URL}/api/attorney-wheel-management/attorney/wheel`).then(function (res) {
			setData(res.data);
		});
	};

	const getNewWheelMembershipSelectOptions = async (id, setData) => {
		await get(`${BASE_URL}/api/attorney-wheel-management/attorney/${id}/wheel/select`).then(
			function (res) {
				setData(res.data);
			}
		);
	};

	const postNewAttorneyWheel = async (body) => {
		const url = `${BASE_URL}/api/attorney-wheel-management/attorney/wheel`;
		await post(url, body, 'newWheel', {});
	};

	const deleteWheel = (wheelId, refresh) => {
		const url = `${BASE_URL}/api/attorney-wheel-management/attorney/wheel/${wheelId}`;
		deleteFn(url, 'deleteWheel', {}).then((res) => {
			refresh();
		});
	};

	const getAttorneysByPersonId = (personId) => {
		return get(`${BASE_URL}/api/attorney/attorneys-by-person/${personId}`);
	};

	return {
		getAttorneyStatus,
		getAttorneyInfo,
		putAttorneyById,
		getAttorneysAll,
		getAllAttorneyWheels,
		postNewAttorneyWheel,
		getNewWheelMembershipSelectOptions,
		deleteWheel,
		postAttorney,
		getAttorneysByPersonId
	};
};
