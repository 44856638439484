import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    resetHistory: {
        data: [],
        paginationItems: {
            parentPage: 0,
            size: 0,
            totalElements: 0,
            totalPages: 0
        }
    },
    personLookup: [],
    selectedPersonLookup: [],
    professionalResources: [],
    allCourtSettings: [],
};

export const courtSettingSlice = createSlice({
    name: "courtSetting",
    initialState: initialState,
    reducers: {
        updateResetHistory: (state, action) => {
            const current = [...state.resetHistory.data];
            current.push(action.payload)
            return {
                ...state,
                resetHistory: { ...state.resetHistory, data: current }
            }
        },
        getResetHistory: (state, action) => {
            return { ...state, resetHistory: action.payload };
        },

        clearResetHistory: (state, action) => {
            return { ...state, resetHistory: initialState.resetHistory };
        },

        updatePersonLookup: (state, action) => {
            return {
                ...state,
                personLookup: action.payload
            }
        },
        restorePersonLookup: (state, action) => {
            return { ...state, personLookup: [] };
        },

        updateSelectedPersonLookup: (state, action) => {
            return {
                ...state,
                selectedPersonLookup: action.payload
            }
        },
        updateProfessionalResources: (state, action) => {
            return {
                ...state,
                professionalResources: action.payload
            }
        },
        removeProfessionalResources: (state, action) => {
            const updateResources = state.professionalResources.filter(item => item.id !== action.payload);
            return { ...state, professionalResources: updateResources };
        },
        resetProfessionalResources: (state, action) => {
            return { ...state, professionalResources: [] };
        },
        getAllCourtSettings: (state, action) => {
            return { ...state, allCourtSettings: action.payload };
        }
    }
})

export const { updateResetHistory, updatePersonLookup, updateSelectedPersonLookup,
    updateProfessionalResources,restorePersonLookup, removeProfessionalResources,
    getResetHistory, clearResetHistory, getAllCourtSettings, resetProfessionalResources } = courtSettingSlice.actions;
