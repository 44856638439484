import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	headerDocument: {},
	eventsSelected: []
};

const caseAppealSlice = createSlice({
	initialState,
	name: 'caseAppealSlice',
	reducers: {
		addCaseAppealHeader: (state, action) => {
			state.headerDocument = action.payload;
		},
		addEventsSelected: (state, action) => {
			state.eventsSelected.push(action.payload);
		},
		removeCaseAppeal: (state) => {
			state.headerDocument = {};
			state.eventsSelected = [];
		}
	}
});

export const { addCaseAppealHeader, removeCaseAppeal, addEventsSelected } = caseAppealSlice.actions;

export default caseAppealSlice.reducer;
