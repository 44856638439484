import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import PropTypes from 'prop-types';

import { BASE_URL, fetcher } from '../utils';

/**
 * useFetcher
 * @param {string} url Url without initial part and params table part e.g. /fee/codes
 * @param {Function} mapper Formatter data function
 * @param {boolean} preventFetch Flag to prevent the fetch
 */

export const useFetcher = (url, mapper, preventFetch) => {
	const options = {
		refreshInterval: 0,
		shouldRetryOnError: false
	};
	const formattedUrl = url && !preventFetch ? `${BASE_URL}${url}` : null;

	const { data = [], mutate, isValidating, error } = useSWR(formattedUrl, fetcher, options);
	const isLoading = !data && !error;

	const formattedData = useMemo(() => {
		const objectData = !error ? data : [];
		try {
			return mapper ? objectData?.map(mapper) : objectData;
		} catch (error) {
			console.error(`Error mapping with data: `, error, data, mapper);
			return [];
		}
	}, [data, error, mapper]);

	const refresh = useCallback(() => mutate(), [mutate]);

	return {
		data: formattedData,
		refresh,
		isLoading,
		isValidating
	};
};

useFetcher.propTypes = {
	url: PropTypes.string.isRequired,
	mapper: PropTypes.func,
	preventFetch: PropTypes.bool
};
