import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import { SelectInput } from '../../common';
import RequiredMark from '../RequiredMark';

const CustomSelect = ({ options, customLabel, required, formik }) => {
	const [customOption, setCustomOption] = useState('');
	const [showCustomInput, setShowCustomInput] = useState(false);
	const NewOptions = [{ value: 'new', label: `New ${customLabel}` }, ...options];

	const handleOptionSelect = (option) => {
		if (option.value === 'new') {
			setShowCustomInput(true);
		} else {
			setShowCustomInput(false);
			formik.setFieldValue('bondsmanPartyId', option);
		}
		setCustomOption('');
	};

	const handleInputChange = (event) => {
		formik.setFieldValue('bondsmanPartyId', { value: event.target.value, label: 'newBondsman' });
		setCustomOption(event.target.value);
	};

	const handleRevert = () => {
		setShowCustomInput(false);
		setCustomOption('');
	};

	return (
		<>
			{!showCustomInput ? (
				<SelectInput
					label="Bond Company"
					id="bondsmanPartyId"
					onChange={(e) => handleOptionSelect(e)}
					required
					value={formik.values.bondsmanPartyId}
					placeholderText={'- Select -'}
					options={NewOptions}
					isSearchable
				/>
			) : (
				<Form.Group>
					<Form.Label htmlFor="newInput">
						{customLabel}
						{required && <RequiredMark />}
					</Form.Label>
					<div className="position-relative">
						<Form.Control
							id="newInput"
							type="text"
							placeholder={`Enter New ${customLabel}`}
							value={customOption}
							onChange={handleInputChange}
						/>
						<Button
							variant="light"
							className="position-absolute top-50 translate-middle-y end-0 me-2"
							style={{
								background: 'none',
								border: 'none',
								color: 'darkgrey',
								cursor: 'pointer'
							}}
							onClick={handleRevert}
						>
							X
						</Button>
					</div>
				</Form.Group>
			)}
		</>
	);
};

export default CustomSelect;
