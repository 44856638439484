// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#styles_rightCard__hKOQt {\n  word-break: break-word;\n  padding: 15px;\n  border-radius: 0.375rem;\n  border-style: solid;\n  border-width: 1px;\n  border-color: black;\n  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  background-color: white !important;\n  margin-top: 15px;\n  margin-bottom: 1.5rem;\n}\n\n#styles_fields__NM96d {\n  font-size: 15px;\n  font-weight: bold;\n  margin-bottom: -5px;\n}\n\n@supports (-webkit-touch-callout: none) {\n  #styles_rightCard__hKOQt {\n    display: grid;\n    margin-bottom: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/layout/RightCard/styles.module.scss"],"names":[],"mappings":"AAEA;EACC,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,6CAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kCAAA;EACA,gBAAA;EACA,qBAAA;AADD;;AAIA;EACC,eAAA;EACA,iBAAA;EACA,mBAAA;AADD;;AAIA;EACC;IACC,aAAA;IACA,mBAAA;EADA;AACF","sourcesContent":["@import \"../../../scss/variables\";\n\n#rightCard {\n\tword-break: break-word;\n\tpadding: 15px;\n\tborder-radius: 0.375rem;\n\tborder-style: solid;\n\tborder-width: 1px;\n\tborder-color: black;\n\tbox-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);\n\tdisplay: flex;\n\tflex-direction: column;\n\trow-gap: 6px;\n\tbackground-color: white !important;\n\tmargin-top: 15px;\n\tmargin-bottom: 1.5rem;\n}\n\n#fields {\n\tfont-size: 15px;\n\tfont-weight: bold;\n\tmargin-bottom: -5px;\n}\n\n@supports (-webkit-touch-callout: none) {\n\t#rightCard {\n\t\tdisplay: grid;\n\t\tmargin-bottom: 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightCard": "styles_rightCard__hKOQt",
	"fields": "styles_fields__NM96d"
};
export default ___CSS_LOADER_EXPORT___;
