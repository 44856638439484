import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	intitialValues: {},
	queryParam: {}
};

const eventPageSlice = createSlice({
	initialState,
	name: 'eventPageSlice',
	reducers: {
		newEventValues: (state, action) => {
			state.intitialValues = action.payload;
		},
		newQueryParam: (state, action) => {
			state.queryParam = action.payload;
		}
	}
});

export const { newEventValues, newQueryParam } = eventPageSlice.actions;

export default eventPageSlice.reducer;
