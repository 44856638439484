import { useParams } from 'react-router-dom';

import CaseBlock from '../CaseBlock';

function CaseNumber({ caseId: caseParam, reloadData, setReloadData }) {
	const { caseId, caseNumber } = useParams();

	const selectedCase = caseParam || caseId || caseNumber;

	return <CaseBlock caseId={selectedCase} reloadData={reloadData} setReloadData={setReloadData} />;
}

export default CaseNumber;
