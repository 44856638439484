import React, { useState, useRef } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { RequiredMark, CheckboxInput } from '../../common';
import Error from '../../../pages/Case/Error';
import styles from './styles.module.scss';
import { PLACEHOLDER_TEXT } from '../../../constants/placeholders';

const CreateSignatureTextForm = ({
	setSignatureImage,
	setAcceptTerms,
	acceptTerms,
	validate,
	onSubmit,
	alert,
	termsAlert
}) => {
	const canvasRef = useRef(null);
	const [text, setText] = useState('');
	const initialValues = { files: '', signatureAuthorization: false };

	const onChange = (value) => {
		setText(value);
		const context = canvasRef.current.getContext('2d');
		context.font = '22pt "Tangerine"';
		context.fillText(value, 0, 20);
		context.canvas.toBlob(function (blob) {
			setSignatureImage(blob);
		});
	};

	const handleClear = () => {
		const context = canvasRef.current.getContext('2d');
		context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
		setText('');
	};

	return (
		<Formik initialValues={initialValues} validationSchema={validate} onSubmit={onSubmit}>
			{(formik) => (
				<Form onSubmit={formik.handleSubmit} id="signatureTextForm">
					<Row>
						<Form.Label htmlFor="textSignature">
							Full Name
							<RequiredMark />
						</Form.Label>
						<br />
						<input
							id="textSignature"
							value={text}
							onChange={(e) => onChange(e.target.value)}
							className={`form-control ${alert && 'is-invalid'}`}
							placeholder={`${PLACEHOLDER_TEXT} full name`}
						/>
						<Error condition={alert} msg={'Full Name is required.'} />
					</Row>
					<Row className="justify-content-md-center mt-3">
						<Form.Label htmlFor="textSignaturePreview">Preview</Form.Label>
						<canvas className={styles.textCanvas} ref={canvasRef} />
						<div className="mt-1">
							<Button variant="outline-secondary" onClick={handleClear}>
								Clear Canvas
							</Button>
						</div>
					</Row>
					<Row>
						<CheckboxInput
							className="mt-3"
							id="signatureAuthorization"
							label="By checking this box, I understand that this is a legal representation of my signature."
							name="signatureAuthorization"
							required={true}
							val={acceptTerms}
							checked={acceptTerms}
							onChecked={setAcceptTerms}
						/>
						<Error condition={termsAlert} msg={'Please accept terms.'} />
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export default CreateSignatureTextForm;
