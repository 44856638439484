import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import userReducer from './userDucks';
import urlParamReducer from './urlParam';
import pathsHistoryReducer from './pathsHistory';
import { courtSettingSlice } from './reducers/courtSetting.reducer';
import requirementsSlice from './reducers/communityServiceRequirements.reducer';
import formViewSlice from './reducers/formViewData.reducer';
import hateCrimesSlice from './reducers/hateCrimesData.reducer';
import caseAppealSlice from './reducers/caseAppeal.reducer';
import publicCalendarSlice from './reducers/publicCalendar.reducer';
import courtCalendarSlice from './reducers/courtCalendar.reducer';
import isReportAffectedReducer from './isReportAffected/isReportAffectedReducer';
import advancedSearchReducer from './reducers/advancedSearch.reducer';
import newPaymentReducer from './reducers/newPayment.reducer';
import caseBatchUpdateSlice from './reducers/caseBatchUpdate.reducer';
import eventPageSlice from './reducers/eventPage.reducer';
import internalMessagingReducer from './reducers/internalMessaging.reducer';
import efilingSlice from './reducers/efiling.reducer';
import recordsCountReducer from './reducers/recordsCount.reducer';

const reducer = combineReducers({
	user: userReducer,
	urlParam: urlParamReducer,
	pathsHistory: pathsHistoryReducer,
	courtSetting: courtSettingSlice.reducer,
	eventPage: eventPageSlice,
	requirements: requirementsSlice,
	formView: formViewSlice,
	caseAppeal: caseAppealSlice,
	publicCalendar: publicCalendarSlice,
	courtCalendar: courtCalendarSlice,
	isReportAffected: isReportAffectedReducer,
	advancedSearch: advancedSearchReducer,
	newPaymentReducer: newPaymentReducer,
	hateCrimes: hateCrimesSlice,
	caseBatchUpdate: caseBatchUpdateSlice.reducer,
	internalMessaging: internalMessagingReducer,
	efiling: efilingSlice,
	recordsCount: recordsCountReducer
});

const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

export default store;
