import React from 'react';
import { Modal, Button } from 'react-bootstrap';

/* IMPORTANT: 
    - Use this component alongside the useFormikModal hook, it returns all formik values and handlers
*/

/**
 *
 * @param {boolean} isOpen State that determines whether the modal should be open or not
 * @param {Function} onClose Event triggered when trying to close the modal
 * @param {string} title String containing the modal's title
 * @param {ReactNode} customFooter Prop that lets customize the footer, if not provided, the component will show the default one
 * @param {boolean} closeable Enables closing the modal
 * @param {Function} onSubmit Event that triggers Formik onSubmit when form submitted
 * @param {Function} onCancel Event that is triggered when trying to cancel the form. If not provided, it will use the onClose function
 * @returns {ReactNode} Returns JSX with the FormikModal component
 */

const FormikModal = ({
	isOpen,
	onClose,
	title,
	customFooter,
	closeable,
	onSubmit,
	onCancel,
	children,
	disableSubmit,
	size
}) => {
	return (
		<Modal
			show={isOpen}
			onHide={onClose}
			title={title}
			backdrop="static"
			animation={false}
			keyboard={false}
			size={size ?? 'lg'}
			aria-label={title && 'Form Dialog'}
			centered
		>
			<Modal.Header closeLabel={`${title} Close Dialog`} closeButton={closeable}>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<Modal.Body>{children}</Modal.Body>
				<Modal.Footer>
					{customFooter ? (
						customFooter
					) : (
						<>
							<Button variant="primary" type="submit" disabled={disableSubmit}>
								Submit
							</Button>
							<Button variant="secondary" onClick={onCancel ? onCancel : onClose}>
								Cancel
							</Button>
						</>
					)}
				</Modal.Footer>
			</form>
		</Modal>
	);
};
export default FormikModal;
