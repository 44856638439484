import axios from 'axios';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { cookieFinder } from './cookieFinder';
import { getClerkIdFromCookies } from './cookiesUtils';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL_ENV = process.env.REACT_APP_BASE_URL;
export const COOKIE_KEY = process.env.REACT_APP_COOKIE_KEY;

export const fetcher = (...args) => {
	const token = cookieFinder('session_token');
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
		'clerk-id': getClerkIdFromCookies()
	};

	if (args[1]) {
		headers['uuid'] = args[1];
	}

	const options = {
		url: args[0],
		headers
	};

	return axios(options).then((res) => res?.data);
};

export const SUCCESFUL_REQUEST_CODE = [200, 201, 202, 203, 204, 205, 206, 207, 208, 226];

export function objectToQueryParams(object) {
	if (object) {
		const queryString = new URLSearchParams(omitBy(object, (v) => isNil(v) || v === '')).toString();
		return queryString;
	}

	return '';
}

export const defaultPagination = {
	parentPage: 0,
	setParentPage: () => {},
	setSize: () => {},
	size: 100,
	totalPages: 1,
	setShowAll: () => {},
	totalElements: 10
};

export const formatUrl = (url, sort, queryParams, filter, page, size) => {
	let formattedUrl = null;
	let urlWithoutPagination = null;

	try {
		if (url) {
			let hasBaseUrl = /^https?:/i.test(url);
			let relativeUrl = url.charAt(0) === '/' ? url.slice(1) : url;

			formattedUrl = new URL(hasBaseUrl ? `${relativeUrl}` : `${BASE_URL}/${relativeUrl}`);

			if (sort) {
				formattedUrl.searchParams.append('sort', sort);
			}

			if (queryParams) {
				Object.entries(queryParams).forEach((entry) => {
					formattedUrl.searchParams.append(...entry);
				});
			}

			// Add the filter parameters to the url when the API "filter by" is enabled
			for (const key in filter) {
				formattedUrl.searchParams.append(key, filter[key]);
			}

			urlWithoutPagination = new URL(formattedUrl);
			formattedUrl.searchParams.append('page', page);
			formattedUrl.searchParams.append('size', size);
			formattedUrl = formattedUrl.toString();
		}
	} catch (error) {
		console.error('Unable to format URL', error);
	}

	return { formattedUrl, urlWithoutPagination };
};

export const formatUrlAllEntries = (urlWithoutPagination, totalElements) => {
	let formattedUrlAllEntries = null;
	formattedUrlAllEntries = urlWithoutPagination;
	if (formattedUrlAllEntries) {
		formattedUrlAllEntries.searchParams.append('page', 0);
		formattedUrlAllEntries.searchParams.append('size', totalElements);
		formattedUrlAllEntries = formattedUrlAllEntries.toString();
	}
	return formattedUrlAllEntries;
};

export const removeUrlParameters = () => {
	window.history.replaceState(null, '');
};

export const prepareArrayQuery = (arrayValues, key) => {
	const arrayQuery = arrayValues
		.map((el, idx) => {
			return `${key}=` + el;
		})
		.join('&');

	return arrayQuery;
};
