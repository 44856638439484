import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import useUserStore from '../redux/utils/storeUtils';
import { removeAutoSaveData } from '../utils/autoSave';
import { useAPI } from './useAPI';

const NullComponent = () => <></>;

export const GLOBAL_ADMIN_PERMISSION = 'update:allsystem';

export const usePermissions = (clerkId) => {
	const username = useSelector((state) => state.user?.userInfo?.username);
	const { selectedClerkId } = useUserStore();
	const requestedClerkId = clerkId || selectedClerkId;
	const { data: permissions, error } = useAPI(
		username && selectedClerkId
			? `/permission-management/clerk/${requestedClerkId}/user/${username}/permissions`
			: null
	);

	return {
		permissions,
		isLoading: !error && !permissions,
		error
	};
};

export const useHasAccess = (pageName, specificPermission) => {
	const { permissions, isLoading } = usePermissions();

	const allAccess = !!permissions?.find((permission) =>
		permission.includes(GLOBAL_ADMIN_PERMISSION)
	);

	const hasAccess = permissions?.includes(`${specificPermission}:${pageName}`) || allAccess;

	return {
		hasAccess,
		isLoading,
		allAccess
	};
};

export const useHasAllAccess = () => {
	const { permissions } = usePermissions();
	const allAccess = !!permissions?.find((permission) =>
		permission.includes(GLOBAL_ADMIN_PERMISSION)
	);

	return allAccess;
};

const getPermissionByName = (permissions, pageName) => {
	const filter = permissions
		?.filter((permission) => permission.indexOf(':') > -1 && permission.split(':')[1] === pageName)
		?.map((permission) => permission.split(':')[0]);

	return filter;
};

export const usePagePermissions = (pageName) => {
	const { permissions: data, isLoading } = usePermissions();
	const allAccess = !!data?.find((permission) => permission.includes(GLOBAL_ADMIN_PERMISSION));

	if (Array.isArray(pageName)) {
		let permissions = {};
		for (const name of pageName) {
			const filter = getPermissionByName(data, name);
			const permission = {
				read: filter?.includes('read') || allAccess,
				create: filter?.includes('create') || allAccess,
				delete: filter?.includes('delete') || allAccess,
				update: filter?.includes('update') || allAccess
			};
			permissions = { ...permissions, [name]: permission };
		}

		return { permissions, isLoading };
	}

	const pagePermissions = getPermissionByName(data, pageName);

	const permissions = {
		read: pagePermissions?.includes('read') || allAccess,
		update: pagePermissions?.includes('update') || allAccess,
		create: pagePermissions?.includes('create') || allAccess,
		delete: pagePermissions?.includes('delete') || allAccess
	};

	return {
		permissions,
		isLoading
	};
};

const removeAutoSaveAndRedirect = () => {
	removeAutoSaveData();
	return <Navigate to="/home" />;
};

export const withAccess =
	(Component) =>
	({ pageName, route, specificPermission }) => {
		const WithAccess = (props) => {
			const { hasAccess, isLoading } = useHasAccess(pageName, specificPermission || 'read');

			// TODO: Create a Spinner

			if (isLoading) return <NullComponent />;

			if (hasAccess) {
				return <Component {...props} />;
			}

			if (route && !hasAccess) {
				removeAutoSaveAndRedirect();
			}
			// TODO: Possible creation of a generic error page
			return <NullComponent />;
		};
		return WithAccess;
	};

export const WithAccess = ({ pageName, children, specificPermission }) => {
	const { hasAccess, isLoading, allAccess } = useHasAccess(pageName, specificPermission || 'read');

	const anyPermissionHasAccess = allAccess
		? hasAccess
		: typeof pageName === 'function'
		? pageName()
		: hasAccess;

	if (isLoading) return <NullComponent />;

	return anyPermissionHasAccess ? children : removeAutoSaveAndRedirect();
};
