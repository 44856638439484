import { useEffect, useRef, useState } from 'react';

const useWizard = () => {
	const [wizardStep, setWizardStep] = useState(0);
	const [wizardStepCounter, setWizardStepCounter] = useState('');
	const [numberOfPages, setNumberOfPages] = useState('...loading');
	// Ref for submit button
	const submitFormRef = useRef();

	useEffect(() => {
		setWizardStepCounter(`(Step ${wizardStep + 1} of ${numberOfPages})`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wizardStep, numberOfPages]);

	return {
		wizardStep,
		setWizardStep,
		wizardStepCounter,
		setNumberOfPages,
		submitFormRef
	};
};

export default useWizard;
