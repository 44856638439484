import _, { isNumber, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { PAGINATION_SIZE_ARRAY } from '../constants/tableWithIntegration';

export const useMockTable = ({
	initialValues,
	preventFetch = false,
	key,
	mapper,
	disableMapper,
	constantSize,
	searchTable = false,
	mandatorySort = false,
	paginationSizeArray = PAGINATION_SIZE_ARRAY
}) => {
	const [page, setPage] = useState(0);
	const [size, setSize] = useState(constantSize ?? 10);
	const [data, setData] = useState(preventFetch ? [] : initialValues);
	const [searchResults, setSearchResults] = useState(preventFetch ? [] : initialValues);
	const [isLoading, setIsLoading] = useState(false);

	const values = searchTable ? searchResults : data;
	const pageData = values.slice(page * size, (page + 1) * size);
	const tableData = {
		totalElements: values.length,
		totalPages: Math.ceil(values.length / size),
		[key]: pageData
	};

	const dataFormatter = (row) => {
		return row.map(mapper);
	};

	const { totalElements = 0, totalPages = 0 } = tableData;

	const objectData = key ? tableData[key] : initialValues;

	const formattedData = disableMapper ? objectData : dataFormatter(objectData);

	const paginationItems = {
		parentPage: page,
		setParentPage: setPage,
		size,
		setSize,
		totalElements,
		totalPages,
		allData: values,
		paginationSizeArray
	};

	useEffect(() => {
		if (!_.isEmpty(initialValues)) {
			setData(preventFetch ? [] : initialValues);
			setSearchResults(preventFetch ? [] : initialValues);
		}
	}, [initialValues]);

	const refresh = (event) => {
		if (event === 'delete' && formattedData.length === 1 && page > 0) {
			setPage(page - 1);
			return Promise.resolve();
		} else {
			return Promise.resolve();
		}
	};

	const add = (item) => {
		new Promise((resolve) => {
			setIsLoading(true);
			setTimeout(() => {
				setData((prev) => [...prev, item]);
				resolve();
			}, 200);
		}).then(() => {
			setIsLoading(false);
		});
	};

	const remove = (id) => {
		new Promise((resolve) => {
			setIsLoading(true);
			setTimeout(() => {
				setData(data.filter((item) => item.id !== id));
				resolve();
			}, 200);
		}).then(() => {
			setIsLoading(false);
		});
	};

	const update = (item, condition) => {
		new Promise((resolve) => {
			setIsLoading(true);
			setTimeout(() => {
				setData(item ? data.map((i) => (i.id === item.id ? item : i)) : condition);
				resolve();
			}, 200);
		}).then(() => {
			setIsLoading(false);
		});
	};

	const search = (filterCondition) => {
		new Promise((resolve) => {
			setIsLoading(true);
			setTimeout(() => {
				setSearchResults(data.filter(filterCondition));
				resolve();
			}, 200);
		}).then(() => {
			setIsLoading(false);
		});
	};

	const removeByKey = (keyItem, value) => {
		new Promise((resolve) => {
			setIsLoading(true);
			setTimeout(() => {
				const tmp = [...data];
				const index = tmp.findIndex((item) => item[keyItem] === value);
				tmp.splice(index, 1);
				setData(tmp);

				resolve();
			}, 200);
		}).then(() => {
			setIsLoading(false);
		});
	};

	const sortServerSide = (sortBy) => {
		if (!mandatorySort) return;

		const [key, order] = sortBy.trim().split(',');
		const tmp = [...values];

		tmp.forEach((item) => {
			if (!isNumber(item[key])) {
				item[`copy${key}`] = item[key].trim().toLowerCase();
			} else {
				item[`copy${key}`] = item[key];
			}
		});

		const result = orderBy(tmp, [`copy${key}`], [order]);

		if (result[0].position) {
			result.forEach((item, index) => {
				item.position = index + 1;
			});
		}

		setData(result);
	};

	return {
		data: formattedData,
		paginationItems: { ...paginationItems, sortServerSide },
		refreshTable: refresh,
		add,
		remove,
		update,
		search,
		isLoading,
		searchTable,
		customFunction: setData,
		removeByKey,
		setIsLoading
	};
};
