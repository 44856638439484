/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { DocumentViewService } from '../../../services/DocumentViewService';
import { BASE_URL, fetcher } from '../../../utils';

export const ACTIONS_BUTTONS = {
	WHATS_NEWS: 'Whats News',
	UPLOAD_INSERT_DOCUMENT: 'Upload/Insert Document',
	VIEW_DOCUMENT_INFORMATION: 'View Document Information',
	PRINT_DOCUMENT: 'Print Document',
	SAVE: 'Save',
	SCAN_DOCUMENT: 'Scan Document',
	ANNOTATE_DOCUMENT: 'Annotate Document',
	CREATE_WORKFLOW_ASSIGNMENT: 'Create a Workflow Assignment',
	DELETE_DOCUMENT: 'Delete Document',
	ACCESIBLE_USER_GROUPS: 'Accessible User Groups',
	CHANGE_DOCUMENT_DESCRIPTION: 'Change Document Description',
	RESET_DOCUMENT: 'Reset Document'
};

export const mappingButtons = [
	{
		buttons: [
			ACTIONS_BUTTONS.WHATS_NEWS,
			ACTIONS_BUTTONS.VIEW_DOCUMENT_INFORMATION,
			ACTIONS_BUTTONS.PRINT_DOCUMENT
		],
		privilege: 'open'
	},
	{
		buttons: [
			ACTIONS_BUTTONS.SAVE,
			ACTIONS_BUTTONS.UPLOAD_INSERT_DOCUMENT,
			ACTIONS_BUTTONS.SCAN_DOCUMENT,
			ACTIONS_BUTTONS.ANNOTATE_DOCUMENT,
			ACTIONS_BUTTONS.CREATE_WORKFLOW_ASSIGNMENT,
			ACTIONS_BUTTONS.CHANGE_DOCUMENT_DESCRIPTION,
			ACTIONS_BUTTONS.RESET_DOCUMENT
		],
		privilege: 'edit'
	},
	{
		buttons: [ACTIONS_BUTTONS.DELETE_DOCUMENT],
		privilege: 'deletion'
	},
	{
		buttons: [ACTIONS_BUTTONS.ACCESIBLE_USER_GROUPS],
		privilege: 'configurations'
	}
];

export const useCurrentGroupAccessibility = (documentFilingId) => {
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [accessibility, setAccessibility] = useState();

	const { getCurrentGroupAccesibility } = DocumentViewService();

	useEffect(() => {
		if (!documentFilingId) return;
		currentGroupAccessibility(documentFilingId);
	}, []);

	const currentGroupAccessibility = async (documentFilingId) => {
		try {
			const response = await getCurrentGroupAccesibility(documentFilingId);
			if (response.status === 200) {
				setAccessibility(response.data);
			} else {
				setIsError(true);
			}
		} catch (error) {
			console.error('Unable to get group accessibility', error);
			setIsError(true);
		}
		setIsLoading(false);
	};

	const hideActionButton = (nameButton) => {
		if (!accessibility) return false;

		const buttons = mappingButtons
			// eslint-disable-next-line array-callback-return
			.map((item) => {
				if (item.buttons.includes(nameButton)) {
					return item;
				}
			})
			.filter((item) => item !== undefined);

		let statusCurrentPrivilege = false;

		buttons.forEach((item) => {
			statusCurrentPrivilege = accessibility[item.privilege];
			if (statusCurrentPrivilege) return;
		});

		return !statusCurrentPrivilege;
	};

	return { isLoading, isError, hideActionButton, currentGroupAccessibility };
};

const useAPIScanDocument = (url, uuid, config) => {
	const { dynamic, swrConfig } = config || {};

	const swrUrl = dynamic ? () => `${BASE_URL}/api${url}` : `${BASE_URL}/api${url}`;
	const { data, error, mutate: refetch } = useSWR([url && swrUrl, uuid], fetcher, swrConfig);
	const isLoading = !data && !error;

	return {
		data,
		error,
		isLoading,
		refetch
	};
};

export const useScanDocument = (url, uuid) => {
	const { data, error } = useAPIScanDocument(url, uuid);
	return {
		data,
		error
	};
};

export const useScanRetrieveDocument = () => {
	const [data, setData] = useState();
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const { getScanDocuments } = DocumentViewService();

	const getScanDocumentsHook = async (url, uuid) => {
		setIsLoading(true);
		try {
			const response = await getScanDocuments(url, uuid);
			setData(response);
		} catch (error) {
			console.error('Unable to obtain scanned document', error);
			setError(error);
		}

		setIsLoading(false);
	};

	return { data, error, isLoading, getScanDocumentsHook };
};
