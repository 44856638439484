import { useRef, useEffect, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import SearchBar from '../SearchBar';
import LogoSvg from '../LogoSvg';
import styles from './styles.module.scss';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { CALENDAR_ROUTES } from '../../../router/CourtCalendarRoutes/routeConstants';
import { PUBLIC_CALENDAR_ROUTES } from '../../../router/PublicCalendarRoutes/routeConstants';
import JurisdictionSelect from './JurisdictionSelect';
import { useModalDialog } from '../../../hooks';
import ReportModal from '../../../pages/UCMSWiki/Wiki/ReportModal';
import { NotificationService } from '../../../services/NotificationService';
import { withAccess } from '../../../hooks/usePageAccess';
import SkipToContentLink from '../SkipToContentLink';
import { NotificationBell } from '../../../components/layout/NotificationBell/NotificationBell';
import { usePagePermissions } from '../../../hooks/usePageAccess';
import InternalMessagingIcon from '../../../pages/InternalMessaging/InternalMessagingIcon';
import EfilingNavButton from './EfilingNavButton';

const AdminConsoleButton = () => (
	<Link className="dropdown-item" to="/admin">
		Admin Console
	</Link>
);
const UCMSMaintButton = () => (
	<Link className="dropdown-item" to="/ucms-maintenance/settings">
		UCMS Maintenance
	</Link>
);

const WithAccessAdminConsole = withAccess(AdminConsoleButton)({ pageName: 'adminconsole' });
const WithAccessUCMSmaint = withAccess(UCMSMaintButton)({ pageName: 'UCMSMaint' });

const TopNavBar = () => {
	const { permissions } = usePagePermissions([
		'codes',
		'case',
		'casestatus',
		'importcase',
		'usertranxhistory',
		'userdochistory',
		'userperform',
		'usercaseaccess',
		'workflowlog',
		'ocareport',
		'cjisreport',
		'financialreports',
		'duedate',
		'collections',
		'reportmaint',
		'calendar',
		'resourcecal',
		'efile',
		'UCMSMaint',
		'feecode',
		'attorneys',
		'certifiedpay',
		'casenumber',
		'eventcode',
		'offensemaint',
		'wftask',
		'wftaskservice',
		'batchcase',
		'batchparties',
		'batchbonds',
		'batchpayments',
		'batchsingle',
		'batchevents',
		'formmaint',
		'judgeview',
		'casetypejudge',
		'assignmgmt',
		'queue'
	]);
	const { logout } = useAuthContext();
	const userFirstName = useSelector((state) => state.user.userInfo?.firstName);
	const userLastName = useSelector((state) => state.user.userInfo?.lastName);
	const username = useSelector((state) => state.user.userInfo?.username);

	const topNavMenuRef = useRef(null);
	const toggleTopNavMenuRef = useRef(null);
	const toggleTopSearchBarRef = useRef(null);
	const [showName, setModalTitle] = useState('');

	const urlGet = window.location.pathname
		.replace(/casetypeview/g, 'case-type-view')
		.replace(/[0-9]/g, '')
		.replace(/\/\//g, '/')
		.split('/');
	const cleanUrlGet = urlGet.filter((n) => n);
	const urlPlatform = useRef(cleanUrlGet.join('_'));

	const detectUrlName = (cleanUrlGet) => {
		let urlName = cleanUrlGet[cleanUrlGet.length - 1]?.replace(/-/g, ' ');
		if (cleanUrlGet.length >= 3) {
			urlName = '';
			cleanUrlGet.forEach(function (word) {
				if (word.length > urlName.length) {
					urlName = word?.replace(/-/g, ' ');
					setModalTitle(urlName);
				}
			});
		} else {
			setModalTitle(urlName);
		}
	};

	const [isOpenReportModal, openReportModal, closeReportModal] = useModalDialog();

	useEffect(() => {
		function handleTopMenuClickOutside(event) {
			if (
				topNavMenuRef.current &&
				!topNavMenuRef.current.contains(event.target) &&
				topNavMenuRef.current.classList.contains('show')
			) {
				if (toggleTopNavMenuRef.current) {
					toggleTopNavMenuRef.current.click();
				}
			}
		}

		document.addEventListener('mousedown', handleTopMenuClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleTopMenuClickOutside);
		};
	}, [topNavMenuRef]);

	useEffect(() => {
		function focusTopMenu(event) {
			if (event.keyCode === 84 && event.altKey) {
				const menuButtons = [...topNavMenuRef.current.querySelectorAll('button, a[href]')].filter(
					(el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
				);

				if (menuButtons) {
					menuButtons[0].focus();
				}
			}
		}

		document.addEventListener('keydown', focusTopMenu);

		return () => {
			document.removeEventListener('keydown', focusTopMenu);
		};
	});

	const { getNotifications, getSystemNotification } = NotificationService();

	useEffect(() => {
		getSystemNotification();

		getNotifications();

		const urlSearchParams = window.location.search;

		if (urlSearchParams) {
			const firstVar = urlSearchParams.split('=');
			urlPlatform.current = firstVar[1].replace('-', ' ');
			setModalTitle(urlPlatform.current);
		} else {
			let breadElements = document.getElementsByClassName('breadcrumb-item');
			if (breadElements.length > 0) {
				setModalTitle(breadElements[breadElements.length - 1].innerText);
			} else {
				detectUrlName(cleanUrlGet);
			}
		}
		//breadcrumb-item
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname]);
	const reportsPermissions = () => {
		let permissionsName = [
			'casestatus',
			'usertranxhistory',
			'userdochistory',
			'userperform',
			'usercaseaccess',
			'workflowlog',
			'ocareport',
			'cjisreport',
			'financialreports',
			'duedate',
			'collections',
			'reportmaint'
		];
		return permissionsName.some((permission) => permissions[permission]?.read);
	};

	const maintenanceReadPermission = () => {
		const permissionsName = [
			'codes',
			'feecode',
			'attorneys',
			'jugdeview',
			'casetypjudge',
			'certifiedpay',
			'casenumber',
			'eventcode',
			'offensemaint',
			'formmaint',
			'wftaskservice',
			'wftask'
		];
		return permissionsName.some((permission) => permissions[permission]?.read);
	};

	const reportAuditPermissions = () => {
		const permissionsName = [
			'casestatus',
			'usertranxhistory',
			'userdochistory',
			'userperform',
			'usercaseaccess',
			'workflowlog'
		];
		return permissionsName.some((permission) => permissions[permission]?.read);
	};

	const batchUpdatesPermissions = () => {
		const permissionsName = [
			'batchcase',
			'batchparties',
			'batchbonds',
			'batchpayments',
			'batchsingle',
			'batchevents'
		];
		return permissionsName.some((permission) => permissions[permission]?.update);
	};

	const buildMaintenanceMenu = () => {
		const topNavigation = [];

		if (permissions?.codes?.read) {
			topNavigation.push(
				<li key="all-codes">
					<Link to="/maintenance/code-maintenance" className="dropdown-item">
						All Codes
					</Link>
				</li>
			);
		}

		if (permissions?.feecode?.read) {
			topNavigation.push(
				<li key="fee-maintenance">
					<Link to="/fee-maintenance" className="dropdown-item">
						Fee Maintenance
					</Link>
				</li>
			);
		}

		if (permissions?.attorneys?.read) {
			topNavigation.push(
				<li key="attorneys">
					<Link to="/attorneys" className="dropdown-item">
						Attorneys
					</Link>
				</li>
			);
		}

		if (permissions?.judgeview?.read || permissions?.casetypejudge?.read) {
			topNavigation.push(
				<li key="judges">
					<Link
						to={
							permissions?.judgeview?.read
								? '/judges'
								: permissions?.casetypejudge?.read
								? '/judge-case-type'
								: null
						}
						className="dropdown-item"
					>
						Judges
					</Link>
				</li>
			);
		}

		if (permissions?.certifiedpay?.read) {
			topNavigation.push(
				<li key="certified-payments">
					<Link to="/certified-payments" className="dropdown-item">
						Certified Payments
					</Link>
				</li>
			);
		}

		if (
			permissions?.casenumber?.read ||
			permissions?.casenumber?.delete ||
			permissions?.casenumber?.update
		) {
			topNavigation.push(
				<li key="case-maintenance">
					<Link to="/case-maintenance/case-number" className="dropdown-item">
						Case Maintenance
					</Link>
				</li>
			);
		}

		if (permissions?.eventcode?.read) {
			topNavigation.push(
				<li key="event-maintenance">
					<Link to="/event-maintenance/event-all-codes" className="dropdown-item">
						Event Maintenance
					</Link>
				</li>
			);
		}

		if (permissions?.offensemaint?.read) {
			topNavigation.push(
				<li key="offense-maintenance">
					<Link to="/offense-maintenance" className="dropdown-item">
						Offense Maintenance
					</Link>
				</li>
			);
		}

		if (permissions?.formmaint?.read) {
			topNavigation.push(
				<li key="form-maintenance">
					<Link to="/form-maintenance" className="dropdown-item">
						Form Maintenance
					</Link>
				</li>
			);
		}

		if (batchUpdatesPermissions()) {
			topNavigation.push(
				<li key="case-batch">
					<Link to="/case-batch" className="dropdown-item">
						Batch Updates
					</Link>
				</li>
			);
		}

		if (permissions?.wftask?.read || permissions?.wftaskservice?.read) {
			topNavigation.push(
				<li key="workflows">
					<Link
						to={
							permissions?.wftask?.read
								? '/workflows/maintenance/all-tasks'
								: '/workflows/maintenance/all-task-services'
						}
						className="dropdown-item"
					>
						Workflows
					</Link>
				</li>
			);
		}
		topNavigation.push(
			<li key="money-distribution">
				<Link to={'/money-distribution-maintenance'} className="dropdown-item">
					Money Distribution Maintenance
				</Link>
			</li>
		);
		topNavigation.push(
			<li key="court-registry">
				<Link to={'/court-registry-maintenance'} className="dropdown-item">
					Court Registry Maintenance
				</Link>
			</li>
		);

		return topNavigation;
	};

	const maintenanceOptions = buildMaintenanceMenu();

	return (
		<>
			<ReportModal isOpen={isOpenReportModal} closeModal={closeReportModal} />
			<header>
				<Navbar id={styles.topNavBar} bg="light" expand="xl" fixed="top">
					<Container fluid>
						<div className="col-xxl-3 col-xl-3 col-6 order-first d-flex flex-md-row flex-column justify-content-md-around justify-content-center align-items-center">
							<SkipToContentLink />
							<LogoSvg href="/home" strokeColor="white" strokeWidth="3" />
							<JurisdictionSelect />
						</div>
						<nav
							id="top-nav-menu"
							className={`${styles.topNavMenu} navbar-collapse navbar-expand-xl collapse col-xxl-5 col-xl-6 col-0 justify-content-center bg-light`}
							ref={topNavMenuRef}
							aria-label="Main Menu"
						>
							<ul className={`${styles.menu} navbar-nav`}>
								{(permissions?.case?.create || permissions?.importcase?.read) && (
									<li className="nav-item dropdown">
										<a
											aria-expanded="false"
											className="nav-link"
											data-bs-toggle="dropdown"
											href="/#"
											id="NewCaseDropdown"
											role="button"
										>
											<i className="fas fa-plus"></i> New Case {'      '}
											<i className="fas fa-caret-down fa-xs"></i>
										</a>
										<ul aria-labelledby="NewCaseDropdown" className="dropdown-menu">
											{permissions?.case?.create && (
												<>
													<li>
														<Link to="/case?case-type=new-case" className="dropdown-item">
															Enter New Case
														</Link>
													</li>
													<li>
														<Link to="/case?case-type=old-case" className="dropdown-item">
															Enter Old Case
														</Link>
													</li>
												</>
											)}
											{permissions?.importcase?.read && (
												<li>
													<Link to="/import" className="dropdown-item">
														Import Case
													</Link>
												</li>
											)}
											<li>
												<Link to="/export" className="dropdown-item">
													Export Cases
												</Link>
											</li>
										</ul>
									</li>
								)}
								{reportsPermissions() && (
									<li className="nav-item dropdown">
										<a
											aria-expanded="false"
											className="nav-link"
											data-bs-toggle="dropdown"
											href="/#"
											id="ReportsDropdown"
											role="button"
										>
											<i className="fas fa-chart-bar"></i> Reports {'      '}
											<i className="fas fa-caret-down fa-xs"></i>
										</a>
										<ul aria-labelledby="ReportsDropdown" className="dropdown-menu">
											{reportAuditPermissions() && (
												<li>
													<Link to="/reports/audit-reports" className="dropdown-item">
														Audit Reports
													</Link>
												</li>
											)}
											{permissions?.ocareport?.read || permissions?.cjisreport?.read ? (
												<li>
													<Link to="/reports/state-reports" className="dropdown-item">
														State Reports
													</Link>
												</li>
											) : null}
											{permissions?.financialreports?.read && (
												<li>
													<Link to="/reports/financial-reports" className="dropdown-item">
														Financial Reports
													</Link>
												</li>
											)}
											{permissions?.collections?.read && (
												<li>
													<Link to="/reports/collections" className="dropdown-item">
														Collections
													</Link>
												</li>
											)}
											{permissions?.duedate?.read && (
												<li>
													<Link to="/reports/due-date-reports" className="dropdown-item">
														Due Date Reports
													</Link>
												</li>
											)}
											{permissions?.attorneys?.read && (
												<li>
													<Link to="/reports/attorney-reports" className="dropdown-item">
														Attorney Reports
													</Link>
												</li>
											)}
											<li>
												<Link to="/reports/warrant-reports" className="dropdown-item">
													Warrant Reports
												</Link>
											</li>
											<li>
												<Link to="/reports/court-registry-reports" className="dropdown-item">
													Court Registry Reports
												</Link>
											</li>
										</ul>
									</li>
								)}
								{permissions?.efile?.read && (
									<li className="nav-item">
										<EfilingNavButton />
									</li>
								)}

								<li className="nav-item dropdown">
									<a
										aria-expanded="false"
										className="nav-link"
										data-bs-toggle="dropdown"
										href="/#"
										id="CalendarDropdown"
										role="button"
									>
										<i className="far fa-calendar-check"></i> Calendar {'      '}
										<i className="fas fa-caret-down fa-xs"></i>
									</a>
									<ul aria-labelledby="CalendarDropdown" className="dropdown-menu">
										{permissions?.calendar?.read && (
											<li>
												<Link
													to={CALENDAR_ROUTES.CALENDAR(
														'month',
														moment().year(),
														moment().month() + 1,
														moment().date()
													)}
													className="dropdown-item"
												>
													Court Calendar
												</Link>
											</li>
										)}
										{permissions?.resourcecal?.read && (
											<li>
												<Link
													to={CALENDAR_ROUTES.RESOURCE_CALENDAR(
														'month',
														moment().year(),
														moment().month() + 1,
														moment().format('D')
													)}
													className="dropdown-item"
												>
													Resource Calendar
												</Link>
											</li>
										)}
										<li>
											<Link to={PUBLIC_CALENDAR_ROUTES.PUBLIC_CALENDAR()} className="dropdown-item">
												Public Calendar
											</Link>
										</li>
									</ul>
								</li>
								{(maintenanceReadPermission() || batchUpdatesPermissions()) && (
									<li className="nav-item dropdown">
										<a
											aria-expanded="false"
											className="nav-link"
											data-bs-toggle="dropdown"
											href="/#"
											id="MaintenanceDropdown"
											role="button"
										>
											<i className="far fa-folder"></i> Maintenance {'      '}
											<i className="fas fa-caret-down fa-xs"></i>
										</a>
										<ul aria-labelledby="MaintenanceDropdown" className="dropdown-menu">
											{maintenanceOptions.map((item) => item)}
										</ul>
									</li>
								)}
							</ul>
						</nav>
						<div className="col-xxl-2 col-0">
							<SearchBar toggleBtnRef={toggleTopSearchBarRef} />
						</div>
						<div id={styles.topControls} className="col-xxl-1 col-xl-2 col-6">
							<div>
								<button
									className={`${styles.topNavMenuControlBtn} ${styles.topControlBtn}`}
									aria-label="Toggle Site Menu"
									data-bs-toggle="collapse"
									data-bs-target="#top-nav-menu"
									ref={toggleTopNavMenuRef}
									onClick={() => {
										topNavMenuRef.current.setAttribute('tabindex', '0');
										topNavMenuRef.current.focus();
									}}
								>
									<i className="fas fa-ellipsis-h"></i>
								</button>
							</div>
							<div>
								<button
									className={`${styles.topSearchControlBtn} ${styles.topControlBtn}`}
									aria-label="Smart Search"
									ref={toggleTopSearchBarRef}
								>
									<i className="fas fa-search"></i>
								</button>
							</div>
							<div className="align-content-center">
								<InternalMessagingIcon />
							</div>
							<div className="dropstart">
								<a
									aria-expanded="false"
									className="h3 text-darkblue"
									data-bs-toggle="dropdown"
									href="/#"
									id="HelpDropdown"
									role="button"
									aria-label="Help"
									title="Help"
								>
									<i className="fas fa-question-circle"></i>
								</a>
								<ul aria-labelledby="HelpDropdown" className="dropdown-menu pt-0">
									<li>
										<span className="dropdown-item-text text-center p-2 border-bottom border-dark">
											Help
										</span>
									</li>
									{!window.location.pathname.includes('wiki') && (
										<li>
											<Link
												to={`/wiki/${urlPlatform.current}_${showName}`}
												className="dropdown-item text-capitalize"
											>
												{showName} Help Page
											</Link>
										</li>
									)}
									<li>
										<Link to="/wiki/index" className="dropdown-item">
											UCMS Wiki
										</Link>
									</li>
									<li>
										<a
											href="https://support.idocket.com/"
											rel="noreferrer"
											target="_blank"
											className="dropdown-item"
										>
											Report a Problem / Support Cases
										</a>
									</li>
									<li>
										<a
											href="https://get.teamviewer.com/idocketsupport"
											target="_blank"
											rel="noreferrer"
											className="dropdown-item"
										>
											Remote Assistance {'(VPN)'}
										</a>
									</li>
								</ul>
							</div>
							<NotificationBell />
							<div className="dropstart" autoclose="outside">
								<a
									aria-expanded="false"
									className="h3 text-darkblue"
									data-bs-toggle="dropdown"
									href="/#"
									role="button"
									aria-label="User"
									title="User Profile"
								>
									<i className="fas fa-user"></i>
								</a>
								<ul className="dropdown-menu pt-0">
									<li>
										<span
											className="dropdown-item-text bg-lightblue text-dark text-center p-2 border-bottom border-dark"
											id="user-profile-name"
										>
											{username ? <>{`${userFirstName} ${userLastName}`}</> : <>Some User</>}
										</span>
									</li>
									<li>
										<Link to={`/user/${username}`} className="dropdown-item">
											User Profile
										</Link>
									</li>
									<li>
										<WithAccessUCMSmaint />
									</li>
									<li>
										<WithAccessAdminConsole />
									</li>
									<li>
										<button className="dropdown-item" onClick={logout}>
											Logout
										</button>
									</li>
								</ul>
							</div>
						</div>
					</Container>
				</Navbar>
			</header>
		</>
	);
};

export default TopNavBar;
