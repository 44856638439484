import { useState, useEffect } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import _ from 'lodash';

import styles from './styles.module.scss';

import { PaymentService } from '../../services/PaymentService';
import { SUCCESFUL_REQUEST_CODE } from '../../utils';
import ModalDialog from '../../components/ModalDialog';
import ResponseAlert from '../../components/ResponseAlert';
import { dateFormatMMDDYYYY } from '../../utils/dateUtils';
import { RefundService } from '../../services/RefundService';

const PosReceiptModal = ({ setShow, show, paymentId, refundId }) => {
	const { getReceiptData } = PaymentService();
	const { getRefundReceiptPos } = RefundService();
	const [data, setData] = useState('');
	const [showError, setShowError] = useState(false);

	const getData = async () => {
		try {
			let res;
			if (refundId) {
				res = await getRefundReceiptPos(refundId);
			} else {
				res = await getReceiptData(paymentId);
			}

			if (SUCCESFUL_REQUEST_CODE.includes(res?.status)) {
				if (refundId) {
					const data = res.data;
					let totalAmount = 0;
					data.paymentDate = data.refundDate;

					data.receiptNumber = data.refundNo;

					data.paidBy = data.refundBy;

					data.refundPaybreakDTO.forEach((item) => {
						item.paymentAmount = item.amount;
						item.feeCodeName = item.description;
						totalAmount += item.amount;
					});
					data.amount = totalAmount;
					data.fees = data.refundPaybreakDTO;
					delete data.refundPaybreakDTO;
				}
				setData(res.data);
			} else {
				console.error('Cannot get Data');
				setShowError(true);
			}
		} catch (error) {
			console.error('Cannot get Data');
			setShowError(true);
		}
	};

	useEffect(() => {
		if (paymentId) {
			getData();
		} else {
			setShowError(true);
		}
	}, [paymentId]);

	const printFunction = () => {
		printElement(document.getElementById('printThis'));
	};

	function printElement(elem) {
		var domClone = elem.cloneNode(true);

		if (!$printSection) {
			var $printSection = document.createElement('div');
			$printSection.id = `${styles.printSection}`;
			document.body.appendChild($printSection);
		}

		$printSection.innerHTML = '';
		$printSection.appendChild(domClone);
		window.print();
	}

	const modalFooter = () => {
		return (
			<>
				<Button
					id="btnPrint"
					variant="primary"
					onClick={() => {
						printFunction();
					}}
				>
					Print
				</Button>
				<Button
					id="cancel"
					variant="secondary"
					onClick={() => {
						setShow(false);
					}}
				>
					Cancel
				</Button>
			</>
		);
	};

	const modalContent = () => {
		return data && !showError ? (
			<>
				<div id="printThis">
					<div className="d-flex align-content-center flex-column align-items-center">
						<div className="mb-5 d-flex align-content-center flex-column align-items-center">
							<h6>{refundId ? 'REFUND RECEIPT' : 'PAYMENT RECEIPT'}</h6>
							<h6>{`${_.toUpper(data.county)} COUNTY`}</h6>
							<h6>{`COUNTY CLERK'S OFFICE`}</h6>
							<h6>{`${_.toUpper(data.clerkName)}`}</h6>
						</div>
						{(data.causeNo || data.style) && (
							<div className="mb-5 d-flex align-content-center flex-column align-items-center">
								{data.causeNo && <h6>{`CASE NO: ${_.toUpper(data.causeNo)}`}</h6>}
								{data.style && <h6>{`STYLE: ${_.toUpper(data.style)}`}</h6>}
							</div>
						)}
					</div>
					<Table className="d-flex align-items-center flex-column">
						<tbody>
							<tr className={styles.receiptTable}>
								<td>{refundId ? 'REFUND NUMBER:' : 'RECEIPT NUMBER:'}</td>
								<td>{`${data.receiptNumber}`}</td>
							</tr>
							<tr className={styles.receiptTable}>
								<td>{refundId ? 'REFUND DATE:' : 'PAYMENT DATE:'}</td>
								<td>{`${dateFormatMMDDYYYY(refundId ? data.refundDate : data.paymentDate).slice(
									0,
									-10
								)}`}</td>
							</tr>
							<tr className={styles.receiptTable}>
								<td></td>
							</tr>
							{data &&
								data.fees.map((item, key) => {
									return (
										<tr key={key} className={styles.receiptTable}>
											<td>{`${_.toUpper(item.feeCodeName)}`}</td>
											<td>{`$${item.paymentAmount.toFixed(2)}`}</td>
										</tr>
									);
								})}
							<tr className={styles.receiptTable}>
								<td></td>
							</tr>
							<tr className={styles.receiptTable}>
								<td>{refundId ? 'REFUND AMOUNT:' : 'PAYMENT AMOUNT:'}</td>
								<td>{`$${data?.amount.toFixed(2)}`}</td>
							</tr>
							<tr className={styles.receiptTable}>
								<td>{refundId ? 'REFUND BY:' : 'PAID BY:'}</td>
								<td>{`${_.toUpper(data?.paidBy)}`}</td>
							</tr>
							{!refundId && (
								<tr className={styles.receiptTable}>
									<td>{'DEPUTY:'}</td>
									<td>{`${_.toUpper(data?.deputy)}`}</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</>
		) : (
			<>
				{!showError && (
					<div className="d-flex justify-content-center align-content-center h-50">
						<Spinner />
					</div>
				)}
				<ResponseAlert
					autoClose
					show={showError}
					text={'Unable to generate receipt'}
					type={'DANGER'}
					delay={3000}
				/>
			</>
		);
	};

	return (
		<>
			<ModalDialog
				isOpenModal={show}
				title={'Point Of Sale Receipt'}
				size={'lg'}
				closeModal={() => setShow(false)}
				children={modalContent()}
				footer={modalFooter()}
				centered={false}
			/>
		</>
	);
};
export default PosReceiptModal;
