const baseSelectStyle = (base, isInvalid, isDisabled) => ({
	...base,
	borderColor: isInvalid ? '#dc3545' : '#6C757D',
	'&:hover:not(disabled)': {
		boxShadow: '0 0 0 0.15rem rgb(13 110 253 / 75%) !important'
	},
	cursor: isDisabled ? 'not-allowed' : '',
	pointerEvents: 'all'
});

export const baseSelectStyles = (
	isInvalid,
	isDisabled,
	flagsStyles,
	disableColorRenderThroughLabelValue = false
) => ({
	menu: (provided) => ({
		...provided,
		backgroundColor: '#ebebeb',
		boxShadow: '2px 2px 6px 0 #777',
		border: '1px solid #777'
	}),
	control: (base) => baseSelectStyle(base, isInvalid, isDisabled),
	singleValue: (styles, { data }) => ({
		...styles,
		backgroundColor: disableColorRenderThroughLabelValue
			? 'transparent'
			: typeof data?.label === 'string'
			? data.label.toLowerCase()
			: 'white',
		color: disableColorRenderThroughLabelValue
			? 'black'
			: typeof data?.label === 'string'
			? textWhiteAccessibility.includes(data?.label.toLowerCase())
				? 'white'
				: 'black'
			: 'black'
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: flagsStyles
			? state?.data?.value?.toLowerCase()
			: state.isSelected
			? '#0d6efd'
			: state.isFocused
			? '#6EA8FE'
			: provided?.backgroundColor,
		color: state.isSelected ? 'white' : state.isFocused ? 'black' : provided?.color,
		'&:hover': {
			backgroundColor: state.isSelected ? '#0d6efd' : '#6EA8FE',
			color: state.isSelected ? 'white' : 'black'
		}
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: '#212529'
	})
});

const textWhiteAccessibility = ['blue', 'green', 'black', 'red'];

export const customStylesFlags = (
	isInvalid = false,
	disableColorRenderThroughLabelValue = false
) => ({
	singleValue: (styles, { data }) => ({
		...styles,
		backgroundColor: disableColorRenderThroughLabelValue
			? 'transparent'
			: typeof data?.label === 'string'
			? data.label.toLowerCase()
			: 'white',
		color: disableColorRenderThroughLabelValue
			? 'black'
			: typeof data?.label === 'string'
			? textWhiteAccessibility.includes(data?.label.toLowerCase())
				? 'white'
				: 'black'
			: 'black'
	}),
	control: (base) => baseSelectStyle(base, isInvalid),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state?.label?.toLowerCase(),
		color: textWhiteAccessibility.includes(state?.label?.toLowerCase()) ? 'white' : 'black'
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none'
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: '#212529'
	})
});

export const errorSelectIcon = {
	borderColor: '#dc3545',
	paddingRight: 'calc(1.5em + 0.75rem)',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
	backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
	backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")`,
	position: 'absolute',
	right: 40,
	color: 'red'
};
