import { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import _, { isEmpty, debounce } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import ConfirmationDialog from '../../ConfirmationDialog';
import {
	goToLink,
	onRowSelectedLodash,
	onRadioSelectedLodash,
	onSelectAllLodash,
	searchSpecificColumn,
	eraseSearchFields,
	generateItemsSplitInPages,
	sortData
} from '../../../services/tableFunctions.js';
import { DocumentViewService, downloadFile } from '../../../services/DocumentViewService';
import {
	DEBOUNCE_TEXT_INPUT_TIME,
	DEFAULT_ITEMS_CALCULATED_IN_FE
} from '../../../constants/common';
import { YYYYMMDD } from '../../../constants';
import TableHeadItem from './TableHeadItem';
import TablePaginator from './TablePaginator';
import TableMobilePaginator from './TableMobilePaginator';
import TablePrintDocument from './TablePrintDocument';
import TableRow from './TableRow';
import TotalRow from './TotalRow';
import TableToolbar from './TableToolbar';
import styles from './styles.module.scss';
import LoaderTable from './LoaderTable';
import BackendCalculatedTotalRow from './BackendCalculatedTotalRow';
import SearchInputField from './SearchInputField';
import { InfiniteScrollRows } from './InfiniteScroll';
import { useModalDialog } from '../../../hooks';

/**
 * paginationItems
 * @param {number} parentPage Page index stored on the parent components controlling the table
 * @param {Dispatch<SetStateAction<number>>} setParentPage Return page number to update it on parent component
 * @param {Dispatch<SetStateAction<number>>} setSize Returns page size
 * @param {number} totalElements Total of item accesible from the database
 * @param {number} totalPages Total of pages used in pagination
 * @param {Dispatch<SetStateAction<object>>} setFilter Return filter params to use in the api call
 * @param {Dispatch<SetStateAction<string>>} sortServerSide Return page number to update it on parent component
 */
/**
 * accesibilityLabels
 * @param {string} title Title description
 * @param {string} alt Alt description
 */
/**
 * TableWithIntegration.
 * @param {Object[]} columns Array of information about columns properties
 * @param {boolean} exportCSV Show export to csv action
 * @param {Object[]} data Array of data showed in the table
 * @param {Function} deleteRequest Function with HTTP request to delete a registry
 * @param {boolean} enableActionSeeDocument Enables see document action
 * @param {boolean} enableActionDownloadFile Enables download file action
 * @param {boolean} enableDelete Enables delete action
 * @param {boolean} enableRowLink Enables rows link feature to send row data to another page (requires rowLink enabled)
 * @param {boolean} enableSelection Enables checboxes on rows to select them
 * @param {string} selectionTitle Sets a title to the th instead of the checkbox to select all rows
 * @param {boolean} enableRadio Enables radio button on rows to select one
 * @param {boolean} enableEnvelope Enables envelop on rows
 * @param {boolean} exportPDF Show export to pdf action on toolbar
 * @param {boolean} exportExcel Show export to excel action on toolbar
 * @param {string} urlSearched Returns the API url for the table data
 * @param {boolean} exportCSV Show export to csv action
 * @param {string} formTitle Text showed on the top oh the tbale
 * @param {boolean} limitRows Show column limiter dropdown
 * @param {Function} onSelectedChange Returns an array of selected registries
 * @param {Function} onRadioChange Returns an array of the selected registry
 * @param {Object} initialSelectedRadio Initial selected radio
 * @param {boolean} printEnable Show print document
 * @param {boolean} rowLink Add link behavior to rows
 * @param {boolean} enableRowFunction Add function behavior to rows
 * @param {Function} clickedRowFunction Calls a function when a row is clicked and passes the row info as an argument (requires enableRowFunction enabled)
 * @param {boolean} resetSelected Flag to indicate if selection is reseted
 * @param {Function} setResetSelected Function to be called from parent and resets row selection
 * @param {boolean} showActions Show actions column
 * @param {boolean} showSearch Show search input
 * @param {string} tableId Id assigned to the table component
 * @param {boolean} isLoading boolean if the data is loading
 * @param {Function} onRowFocus Event trigerred when a row is focused for update, it returns rowIndex, column and textContent
 * @param {Object<paginationItems>} paginationItems set of props used for SSR paging of the table
 * @param {boolean} hidePagination boolean to hide pagination
 * @param {string} entity The entity on which the ConfirmationDialog has action
 * @param {object} accesibilityLabels Allows to add title and alt description to delete icon
 * @param {boolean} showTime Show time in date values
 * @param {boolean} showTimeOnly Show only time in date values
 * @param {boolean} enableTime Enables/Disables time in date values. It's true by default
 * @param {boolean} filterWithApiCalls enable to filters in API calls
 * @param {boolean} enableServerSort enables server-side sorting
 * @param {arrayOf<string>} columnsExcludedForServerSort disable server-sorting for specified columns
 * @param {boolean} showDateOnly Show only date part in date values
 * @param {boolean} nowrap add white-space = now to each row
 * @param {boolean} disableAllInitialSelectedRows disable all initial selected rows
 * @param {object} showTableToolbarActionButtons object to hide and show action buttons on the top of the table
 * @param {boolean} enableVoucherAction enables voucher action
 * @param {boolean} disableTableSort disable Table sort
 * @param {Function} formatSearchValues allows you to apply a function to table rows before the search or sort feature is applied to the data.
 * @param {boolean} isSearching Flag to indicate that search field is empty or filled
 * @param {boolean} hideTooltip Flag to show or not the tooltip in each row.
 * @param {string} customRemoveConfirmationDialogTitle Custom Alert title to show in the warning pop-up.
 * @param {Function} hideTooltipOnRows function to choose which rows to hide tooltip
 * @param {string} customTitle Custom Alert title to show in the warning pop-up.
 * @param {boolean} activateOrDeactivateLabel change tooltip for trash icon to handle activation and deactivation
 * @param {boolean} disableCheckboxRowSelection Flag to disable the selection of checkboxes used for row selection
 * @param {Function} handleEnvelopeClick function to perform when clicked on Envelope
 * @param {Function} setTableDataRef set state function - reference to internal table state data for the parent components and this will not effect internal state if we change this variable data from outside
 * @returns {ReactElement} returns JSX with the component TableWithIntegration.
 * @returns {boolean} addHeaderToPDF enable to add header while download table PDF
 * @returns {object} pdfHeaderProps object contain the PDF header data
 */

const TableWithIntegration = ({
	columns,
	data,
	deleteRequest,
	enableActionSeeDocument,
	enableActionDownloadFile,
	expandedCheckboxFn,
	changesWithoutSaving,
	enableExpandRow,
	skipColumnExpandHeading,
	columnExpandHeading,
	expandableItemSelectFunction,
	enableDelete,
	customDelMessage,
	enablePrintAction,
	enableRowLink = false,
	enableSelection,
	selectionTitle = '',
	enableRadio,
	radioColumnTitle,
	exportCSV,
	exportPDF,
	exportExcel,
	urlSearched,
	formTitle = '',
	formTitleHeadingType,
	limitRows,
	onSelectedChange,
	onUnSelectedChange,
	onNewSelectedChange,
	printEnable,
	rowLink = false,
	enableRowFunction,
	clickedRowFunction,
	resetSelected = false,
	setResetSelected,
	showActions,
	showSearch,
	radioBlocksearch,
	tableId,
	paginationItems,
	hidePagination,
	showTableToolbarActionButtons,
	isLoading,
	onRowEdited,
	onRadioChange,
	onKeyDownCell,
	onRowFocus,
	enableEnvelop,
	entity,
	initialSelectedRows,
	initialSelectedRadio,
	totalItem,
	enableCodeChangeSummary,
	setViewSummary,
	mergeCheckedRef,
	showCheckBox,
	showTime,
	showTimeOnly,
	flag,
	customInput,
	disableDateFormatting,
	setToast = false,
	showAsIs,
	refreshTable,
	editMode,
	enableTime,
	filterWithApiCalls,
	enableServerSort,
	columnsExcludedForServerSort,
	showDateOnly,
	zoned = false,
	nowrap = false,
	disableAllInitialSelectedRows,
	showTimeAndDate,
	useBrowserTimeZone = false,
	enableSendInvitationAction,
	handleSendInvitation,
	fieldName,
	enableVoucherAction = false,
	handleVoucherAction = () => {},
	required = false,
	seeDocumentRequest = () => {},
	downloadFileRequest = () => {},
	checkboxes = [],
	changeStatusCheckbox = () => {},
	formatSearchValues,
	refreshSearchData,
	disableTrashIcons,
	hoverMessage,
	deleteRowTooltipLabel = 'Delete',
	disableTableSort,
	hideTooltip,
	customRemoveConfirmationDialogTitle,
	hideTooltipOnRows,
	customTitle,
	activateOrDeactivateLabel = false,
	disableCheckboxRowSelection = false,
	isJasperReport = false,
	handleShowAlertMessage = () => {},
	isRestoreCode,
	restoreCodeRequest,
	selectedExpandableCheckboxes,
	customDateFormat,
	dualOptions,
	disableInfiniteScroll,
	handleEnvelopeClick = () => {},
	addHeaderToPDF,
	pdfHeaderProps,
	setTableDataRef
}) => {
	const {
		parentPage,
		setParentPage,
		setSize,
		size,
		totalElements,
		totalPages,
		setFilter,
		sortServerSide,
		filter,
		allData,
		paginationSizeArray
	} = paginationItems;
	const [allDataPrevSize, setAllDataPrevSize] = useState(0);
	const [tableData, setTableData] = useState([]);
	const [itemsCalculatedOnFE, setItemsCalculatedOnFE] = useState(DEFAULT_ITEMS_CALCULATED_IN_FE);
	const [isSorting, setIsSorting] = useState(false);
	const [sortingColumn, setSortingColumn] = useState();
	const [searchInputValues, setSearchInputValues] = useState({});
	const financialItems = paginationItems?.financialItems;
	const showBackendCalculatedTotalRow = !isEmpty(financialItems) && !isEmpty(data);
	const [rowTooltipPlacement, setRowTooltipPlacement] = useState('left');
	const [windowSize, setWindowSize] = useState(getWindowSize());
	const [selectedRows, setSelected] = useState([]);
	const [disabledRows, setDisabled] = useState([]);
	const [newSelectedRow, setNewSelected] = useState([]);
	const [unSelectedRows, setUnSelected] = useState([]);
	const [radioSelectedRow, setRadioSelectedRow] = useState('');
	const [colDirection, setColDirection] = useState('asc');
	const [rowsDisplayed, setRowsDisplayed] = useState(size || 10);
	const [prevRowsDisplayed, setPrevRowsDisplayed] = useState(10);
	const [clickedRowData, setClickedRowData] = useState();
	const [searchQuery, setSearchQuery] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [searchFieldDates, setSearchFieldDates] = useState({});
	const [dialogParam, setDialogParam] = useState({
		item: {},
		entity: '',
		onClick: () => {},
		isCustomOption: false
	});
	const [isDownloading, setIsDownloading] = useState(false);
	const [clickedItemId, setClickedItemId] = useState();
	const [isSearching, setIsSearching] = useState(false);
	const isSortingOrSearching = searchQuery || isSorting;
	const calculatedSize = itemsCalculatedOnFE?.size ? itemsCalculatedOnFE?.size : size;
	// eslint-disable-next-line no-unused-vars
	const [isVisible, setIsVisible] = useState(true);
	const tableRow = useRef();
	const timerRef = useRef();
	const isLongPress = useRef(false);
	const navigate = useNavigate();

	// This stores the request when you click on a Financial Till for UPDATE. This way the
	// checkboxes are marked as selected if the Financial Till has that transaction type assigned to it.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		if (totalElements !== allDataPrevSize && totalElements !== 0 && !isSearching) {
			const itemsInPages = generateItemsSplitInPages(allData, size);
			setItemsCalculatedOnFE({
				items: allData,
				itemsInPages,
				totalPages: itemsInPages?.length,
				currentPage: itemsCalculatedOnFE?.currentPage || 0,
				totalElements: allData?.length
			});
			setAllDataPrevSize(totalElements);
		}
	}, [allData]);

	useEffect(() => {
		if (setTableDataRef) {
			setTableDataRef([...tableData]);
		}
	}, [tableData]);

	useEffect(() => {
		// eslint-disable-next-line array-callback-return

		columns?.map((item, index) => {
			if (item.type === 'date' && index in searchFieldDates === false) {
				setSearchFieldDates((oldState) => {
					const newState = {
						...oldState,
						[index]: ''
					};
					return newState;
				});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columns]);

	useEffect(() => {
		if (initialSelectedRows) {
			setSelected(initialSelectedRows);
		}
		if (disableAllInitialSelectedRows) {
			initialSelectedRows && setDisabled(initialSelectedRows);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialSelectedRows]);

	useEffect(() => {
		if (resetSelected) {
			setSelected([]);
			setRadioSelectedRow('');
			setResetSelected(false);
		}
		// eslint-disable-next-line
	}, [resetSelected]);

	useEffect(() => {
		if (onSelectedChange && data) {
			onSelectedChange(selectedRows ?? []);
		}
		// eslint-disable-next-line
	}, [selectedRows]);
	useEffect(() => {
		if (onUnSelectedChange && data) {
			onUnSelectedChange(unSelectedRows ?? []);
		}
		// eslint-disable-next-line
	}, [unSelectedRows]);

	useEffect(() => {
		if (onNewSelectedChange && data) {
			onNewSelectedChange(newSelectedRow ?? []);
		}
		// eslint-disable-next-line
	}, [newSelectedRow]);

	const SearchEffectFunction = async () => {
		if (searchQuery) {
			const objValue = columns?.[searchQuery?.index]?.value;

			let formattedData = formatSearchValues ? await formatSearchValues(allData) : allData;

			const newTableData = searchSpecificColumn(
				searchQuery?.reference,
				formattedData,
				objValue,
				searchInputValues,
				setSearchInputValues
			);
			const itemsInPages = generateItemsSplitInPages(newTableData, calculatedSize);

			return {
				newTableData,
				itemsInPages
			};
		}
	};

	// eslint-disable-next-line
	useEffect(async () => {
		const searchedData = await SearchEffectFunction();

		if (searchQuery) {
			setItemsCalculatedOnFE({
				...itemsCalculatedOnFE,
				items: searchedData.newTableData,
				itemsInPages: searchedData.itemsInPages,
				totalPages: searchedData.itemsInPages?.length,
				currentPage: 0,
				size: paginationItems?.size,
				totalElements: searchedData.newTableData?.length
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	useEffect(() => {
		if (radioSelectedRow && onRadioChange && data) {
			onRadioChange(radioSelectedRow);
		}
		// eslint-disable-next-line
	}, [radioSelectedRow]);

	useEffect(() => {
		if (!_.isEmpty(initialSelectedRadio)) {
			setRadioSelectedRow(initialSelectedRadio);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialSelectedRadio]);

	useEffect(() => {
		//Detect Screen Resizing
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	});

	useEffect(() => {
		if (Object.keys(searchInputValues).length === 0) {
			setSearchFieldDates({});
			setSearchQuery(null);
			setIsSearching(false);
		}
	}, [searchInputValues]);

	useEffect(() => {
		if (typeof data === 'object' && !Array.isArray(data)) {
			console.error('Error: table can only map objects of type array!');
		}

		if (Array.isArray(data)) {
			if (
				!isSortingOrSearching ||
				(!isSearching && !searchInputValues && enableServerSort) ||
				(isSearching && filterWithApiCalls && !isSorting)
			) {
				setTableData(data);
				setItemsCalculatedOnFE({
					totalPages: paginationItems?.totalPages,
					currentPage: paginationItems?.parentPage,
					size: paginationItems?.size,
					totalElements: paginationItems?.totalElements
				});
			} else if (!isEmpty(itemsCalculatedOnFE?.items)) {
				isJasperReport
					? setTableData(itemsCalculatedOnFE?.items ?? [])
					: setTableData(
							itemsCalculatedOnFE?.itemsInPages?.[itemsCalculatedOnFE?.currentPage] ?? []
					  );
			} else if (isEmpty(itemsCalculatedOnFE?.items) && !isEmpty(tableData)) {
				setTableData([]);
			} else if (data.length === 0) {
				setTableData([]);
				setItemsCalculatedOnFE(DEFAULT_ITEMS_CALCULATED_IN_FE);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		data,
		tableData,
		allData,
		isSortingOrSearching,
		isSearching,
		itemsCalculatedOnFE?.currentPage,
		itemsCalculatedOnFE?.items,
		itemsCalculatedOnFE?.itemsInPages
	]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		if (allData && !enableServerSort && isSorting && parentPage === 0) {
			let newTableData = formatSearchValues ? await formatSearchValues(data) : data;
			newTableData = sortData(colDirection, setColDirection, newTableData, sortingColumn);
			const itemsInPages = generateItemsSplitInPages(
				isSorting && !formatSearchValues ? allData : newTableData,
				size
			);
			const currentPage = parentPage || 0;
			setItemsCalculatedOnFE({
				items: newTableData,
				itemsInPages,
				totalPages: itemsInPages?.length,
				currentPage,
				totalElements: isSorting && !formatSearchValues ? allData.length : newTableData?.length,
				size
			});
			setTableData(itemsInPages?.[currentPage]);
		}

		if (allData && searchQuery && parentPage === 0) {
			const searchedData = await SearchEffectFunction();
			setItemsCalculatedOnFE({
				...itemsCalculatedOnFE,
				items: searchedData.newTableData,
				itemsInPages: searchedData.itemsInPages,
				totalPages: searchedData.itemsInPages?.length,
				size: paginationItems?.size,
				totalElements: searchedData.newTableData?.length
			});
		}
	}, [allData, refreshSearchData]);

	useEffect(() => {
		clickedRowData && rowLink && goToLink(rowLink, hiddenLink);
	}, [rowLink, clickedRowData]);

	let table = useRef(null);
	let input = useRef(null);
	let outerTable = useRef(null);
	let hiddenLink = useRef(null);
	const [isOpenConfirmDialog, openConfirmDialog, closeConfirmDialog] = useModalDialog();

	const inputReference = flag ? `flag` : tableId ? `${tableId}-input` : 'myInput';

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	const clearAllFilters = () => {
		setSearchFieldDates({});
		setSearchInputValues({});
		setSearchQuery(null);
		setRowsDisplayed(calculatedSize);
		setIsSearching(false);
		setFilter({});
	};

	const rowClicked = (rowData) => {
		if (enableRowFunction) {
			if (Object.keys(rowData).includes('taskDeleted')) {
				if (!rowData.taskDeleted) {
					clickedRowFunction(rowData);
				}
			} else if (Object.keys(rowData).includes('isDisabled')) {
				if (!rowData.isDisabled) {
					clickedRowFunction(rowData);
				}
			} else {
				clickedRowFunction(rowData);
			}
		}
		setClickedRowData(rowData);
	};

	const SelectOneHeader = ({ enableRadio }) => {
		if (!enableRadio) {
			return null;
		}

		return (
			<th tabIndex={0} scope="col" style={{ display: enableRadio ? 'table-cell' : 'none' }}>
				{radioColumnTitle ? radioColumnTitle : 'Select'}
			</th>
		);
	};

	const PrintActionIcon = () => (
		<div
			style={{ display: enablePrintAction ? 'initial' : 'none' }}
			className={`${styles.tableActionButton} mx-1 pe-auto`}
			onClick={(e) => {}}
		>
			<OverlayTrigger rootClose placement="bottom" overlay={<Tooltip>Print</Tooltip>}>
				<button
					aria-label={`Print item`}
					className={`${styles.iconActionButton} border-0 bg-transparent pe-auto`}
				>
					<span className={styles.tableActionIcon}>
						<i alt="Print icon" className={`fas fa-print`}></i>
					</span>
				</button>
			</OverlayTrigger>
		</div>
	);

	const RetrySendInvitationIcon = ({ item }) => (
		<div
			style={{ display: enableSendInvitationAction ? 'initial' : 'none' }}
			className={`${styles.tableActionButton} mx-1 pe-auto`}
			onClick={() => handleSendInvitation(item)}
		>
			<OverlayTrigger rootClose placement="bottom" overlay={<Tooltip>Resend invitation</Tooltip>}>
				<button
					aria-label="Resend invitation"
					className={`${styles.iconActionButton} border-0 bg-transparent pe-auto`}
				>
					<span className={styles.tableActionIcon}>
						<i alt="Resend icon" className={`fas fa-undo`}></i>
					</span>
				</button>
			</OverlayTrigger>
		</div>
	);

	const isSubArr = () => {
		if (tableData) {
			if (fieldName) {
				return tableData.length === _.intersectionBy(tableData, selectedRows, fieldName).length;
			} else if (tableData.some((item) => item?.selected?.isDisabled)) {
				if (tableData.every((item) => item?.selected?.isDisabled)) return false;
				return (
					tableData.filter((item) => !item?.selected?.isDisabled).length ===
					_.intersectionWith(tableData, selectedRows, _.isEqual).length
				);
			} else {
				return tableData.length === _.intersectionWith(tableData, selectedRows, _.isEqual).length;
			}
		}
		return false;
	};

	const isSelected = (item) => selectedRows.find((x) => item.id === x.id) !== undefined;
	const isSelectedDocViewAll = (id, key, item) => {
		if (selectedRows.length <= 0) return false;
		const element = selectedRows.find((x) => x.documentFilingId === id);
		if (!element) return false;

		return element[key] ?? false;
	};
	const isDisabled = (item) => disabledRows.find((x) => item.id === x.id) !== undefined;
	const checkHandler = (item) => _.isEqual(item, radioSelectedRow);
	const isLoadingWithoutData = isLoading && _.isEmpty(tableData);

	const { getInfoDownloadFile } = DocumentViewService();

	const downloadEventFileFromUrl = async (event) => {
		setIsDownloading(true);

		const response = await getInfoDownloadFile(event.eventId);
		if (response) {
			const { url, extension } = response;
			await downloadFile({ url, customHeaders: {}, documentExtension: extension, ...event });
		}

		setIsDownloading(false);
		setClickedItemId();
	};

	const handleKeyUp = debounce((input, index) => {
		const searchFn = () => {
			const inputValue = typeof input === 'string' ? input : input?.target?.value;
			setSearchQuery({
				table: table,
				input: input,
				reference: `${inputReference}-${index}`,
				index: index
			});
			if (inputValue === '') {
				setRowsDisplayed(calculatedSize);
				setSize(calculatedSize);
				setSearchInputValues({});
				setIsSearching(false);
			} else {
				setIsSearching(true);
			}

			setParentPage(0);
			setRefresh(!refresh);
		};
		searchFn();
	}, 550);

	const listSizeFn = (item) => {
		const inputValue = Object.values(searchInputValues)?.[0];
		const inputKey = Object.keys(searchInputValues)?.[0];
		const inputIndex = parseInt(inputKey?.[inputKey?.length - 1]);
		inputValue && handleKeyUp(searchInputValues[inputValue], inputIndex);

		if (searchQuery || inputValue || isSorting) {
			const itemsInPages = generateItemsSplitInPages(
				isSorting && !searchQuery && !inputValue ? allData : itemsCalculatedOnFE?.items,
				item
			);
			setSize(item);
			setItemsCalculatedOnFE({
				...itemsCalculatedOnFE,
				itemsInPages,
				totalPages: itemsInPages?.length,
				currentPage: 0,
				size: item
			});

			setParentPage(0);
			setRowsDisplayed(item);
			setPrevRowsDisplayed(item);
		} else {
			setRowsDisplayed(item);
			setPrevRowsDisplayed(item);
			if (setSize) {
				setParentPage(0);
				setSize(item);
			}
		}
	};

	const changeInputFilter = (input, item) => {
		const {
			target: { value }
		} = input;
		let inputValue = value;

		if (isEmpty(inputValue)) {
			setRowsDisplayed(prevRowsDisplayed);
			setSize(prevRowsDisplayed);
			setFilter((prevFilter) => {
				const filterParam = { ...prevFilter };
				delete filterParam[item.filterQueryParamName ? item.filterQueryParamName : item.value];
				return filterParam;
			});
			setIsSearching(false);
		} else {
			if (item.type === 'date') {
				inputValue = moment(value).format(YYYYMMDD);
			}

			setIsSearching(true);
			setIsSorting(false);
			setFilter((prevFilter) => {
				const filterParam = { ...prevFilter };
				filterParam[item.filterQueryParamName ? item.filterQueryParamName : item.value] =
					item.filterQueryParamValueConverter
						? item.filterQueryParamValueConverter(inputValue)
						: inputValue;
				return filterParam;
			});
		}

		setParentPage(0);
	};

	const debouncedChangeInputFilter = useMemo(
		() => debounce(changeInputFilter, DEBOUNCE_TEXT_INPUT_TIME),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleSearchInput = (input, item, index) => {
		if (filterWithApiCalls) {
			debouncedChangeInputFilter(input, item);
		} else {
			handleKeyUp(input, index);
		}

		if (item.type === 'date') {
			setSearchFieldDates((oldState) => {
				const newState = {
					...oldState,
					[index]: input || ''
				};
				return newState;
			});
		}
	};

	const handleServerSort = (sortBy) => {
		if (columnsExcludedForServerSort) {
			let matchFound =
				columnsExcludedForServerSort.filter((c) => sortBy.indexOf(c) > -1).length > 0;
			if (matchFound) return;
		}
		// Added this validation for Money Distribution Sorting by Case Category
		if (sortBy === 'caseCategory,asc') {
			sortServerSide('caseCategory.code');
		} else if (sortBy === 'caseCategory,desc') {
			sortServerSide('caseCategory.code,desc');
		}
		// Added this validation for Event Type Code
		else if (sortBy === 'ocaCode,asc') {
			sortServerSide('codeTableOcaEntities.ocaCode,asc');
		} else if (sortBy === 'ocaCode,desc') {
			sortServerSide('codeTableOcaEntities.ocaCode,desc');
		} else {
			sortServerSide(sortBy);
		}
	};

	const handleOnClick = (e, item) => {
		if (!isLongPress.current) {
			e.preventDefault();
			setIsVisible(false);
			setViewSummary(item);
		}
	};

	const handleOnMouseDown = () => {
		isLongPress.current = false;
		//setStarPressTime()
	};

	const handleOnMouseUp = () => {
		clearTimeout(timerRef.current);
	};

	const handleOnTouchStart = () => {
		isLongPress.current = false;
		setStarPressTime();
	};

	const handleOnTouchEnd = () => {
		setTimeout(() => {
			clearTimeout(timerRef.current);
			setIsVisible(false);
		}, 2000);
	};
	const setStarPressTime = () => {
		isLongPress.current = false;
		timerRef.current = setTimeout(() => {
			setIsVisible(true);
			isLongPress.current = true;
		}, 2000);
	};

	const handleDeleteOrReactiveItem = (e, item, secondaryOption) => {
		if (!isRestoreCode && (item.disableDelete || item.taskDeleted || item.isDisabled)) {
			return;
		}

		e.preventDefault();
		let rowToDel = item.id ? item.id : item;
		setDialogParam({
			item,
			entity: item[entity] || entity,
			onClick: secondaryOption
				? () => {
						dualOptions.action(item);
						closeConfirmDialog();
				  }
				: () => {
						item.disableDelete
							? restoreCodeRequest(item)
							: !disableTrashIcons && deleteRequest(rowToDel);
						closeConfirmDialog();
						if (parentPage > 0 && parentPage === totalPages - 1) {
							if ((totalElements - 1) % rowsDisplayed === 0) {
								setParentPage(parentPage - 1);
							}
						}
				  },
			isCustomOption: secondaryOption
		});
		openConfirmDialog();
	};

	const formatTitleID = (str) => {
		let formattedStr = str.replace(/([A-Z]+)/g, ' $1');
		formattedStr = formattedStr.replace(/[-_]/g, ' ');
		formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
		return formattedStr;
	};

	const showFiltersRow = tableData?.length > 0 || searchQuery || filter;

	const titleRequired = required ? (
		<>
			{formTitle}
			<span className="text-danger fw-bold mx-1" aria-hidden="true">
				*
			</span>
		</>
	) : (
		formTitle
	);

	return (
		<div id={tableId ? `${tableId}_wrapper` : null} className="my-3">
			<div
				aria-label={formTitle !== '' ? formTitle : formatTitleID(tableId)}
				className="text-center d-flex justify-content-center text-black"
			>
				{!formTitleHeadingType && formTitle !== '' && (
					<h3 className="text-center mb-4 h5" tabIndex={0} role="heading" aria-level="3">
						{titleRequired}
					</h3>
				)}
				{formTitleHeadingType === 'h1' && formTitle && (
					<h1 className="text-center mb-4 h5" tabIndex={0} role="heading" aria-level="1">
						{formTitle}
					</h1>
				)}
				{formTitleHeadingType === 'h2' && formTitle && (
					<h2 className="text-center mb-4 h5" tabIndex={0} role="heading" aria-level="2">
						{formTitle}
					</h2>
				)}
				{formTitleHeadingType === 'h3' && formTitle && (
					<h3 className="text-center mb-4 h5" tabIndex={0} role="heading" aria-level="3">
						{formTitle}
					</h3>
				)}
				{formTitleHeadingType === 'h4' && formTitle && (
					<h4 className="text-center mb-4 h5" tabIndex={0} role="heading" aria-level="4">
						{formTitle}
					</h4>
				)}
				{formTitleHeadingType === 'h5' && formTitle && (
					<h5 className="text-center mb-4 h5" tabIndex={0} role="heading" aria-level="5">
						{formTitle}
					</h5>
				)}
			</div>
			{showTableToolbarActionButtons !== false && (
				<TableToolbar
					items={tableData}
					exportCSV={exportCSV}
					exportPDF={exportPDF}
					exportExcel={exportExcel}
					urlSearched={urlSearched}
					showToolbarActionButtons={showTableToolbarActionButtons}
					changesWithoutSaving={changesWithoutSaving}
					input={input}
					limitRows={limitRows}
					outerTable={outerTable}
					rowsDisplayed={rowsDisplayed}
					showSearch={filterWithApiCalls ? false : showSearch} // TODO we need to apply search by api call on the search field
					table={table}
					windowSize={windowSize?.innerWidth}
					numberOfRecords={allData?.length ?? totalElements}
					listSizeFn={listSizeFn}
					columns={columns}
					allData={itemsCalculatedOnFE?.items || allData}
					enableSelection={enableSelection}
					enableRadio={enableRadio}
					selectedRows={selectedRows}
					radioSelectedRow={radioSelectedRow}
					radioColumnTitle={radioColumnTitle}
					formTitle={formTitle}
					tableId={tableId}
					addHeaderToPDF={addHeaderToPDF}
					pdfHeaderProps={pdfHeaderProps}
					paginationSizeArray={paginationSizeArray}
				/>
			)}

			{/* START OF TABLE HTML */}
			<div className="table-responsive pb-1" ref={outerTable}>
				<Link
					tabIndex="-1"
					ref={hiddenLink}
					to={rowLink ? rowLink : '#'}
					state={clickedRowData}
					className="d-none"
				>
					Row click action for table
				</Link>
				{((isLoading && data?.length === 0) ||
					(!enableServerSort && isSorting && allData?.length === 0) ||
					(!filterWithApiCalls && isSearching && allData?.length === 0)) && <LoaderTable />}
				<table
					aria-label={`${
						formTitle !== ''
							? formTitle?.replace('table', '')
							: formatTitleID(tableId?.replace('table', ''))
					}`}
					ref={table}
					id={tableId ? `${tableId}_table` : null}
					className={`table table-sm table-hover table-striped table-bordered w-100 bg-white border-dark mt-1 mb-0 ${
						isLoadingWithoutData && styles.tableIsLoadingWithoutData
					}`}
				>
					<thead className={styles.theadContainer} data-testid="TheadContainer">
						<tr className="text-center">
							{enableSelection && (
								<th
									tabIndex={0}
									scope="col"
									style={{ display: enableSelection ? 'table-cell' : 'none' }}
								>
									{selectionTitle ? (
										selectionTitle
									) : (
										<input
											type="checkbox"
											name="rowSelectHeader"
											id={`rowSelectHeader-${tableId}-${Math.floor(Math.random() * 10000)}`}
											data-testid={`HeaderCheckbox`}
											className={`${styles.checkboxBlueBackground}`}
											aria-label={`${formTitle} Header all rows select checkbox`}
											checked={isSubArr()}
											disabled={disableCheckboxRowSelection}
											onChange={(e) =>
												onSelectAllLodash(
													e,
													tableData,
													selectedRows,
													setSelected,
													setNewSelected,
													setUnSelected,
													disabledRows
												)
											}
										/>
									)}
								</th>
							)}

							<SelectOneHeader enableRadio={enableRadio} />
							{columns.map((item, index) => (
								<TableHeadItem
									colDirection={colDirection}
									data={searchQuery ? itemsCalculatedOnFE?.items : allData}
									item={item}
									key={index}
									lastItem={columns.length === index + 1}
									length={columns.length}
									setColDirection={setColDirection}
									showActions={showActions}
									enableServerSort={enableServerSort}
									handleServerSort={handleServerSort}
									setTableData={setTableData}
									setItemsCalculatedOnFE={setItemsCalculatedOnFE}
									size={calculatedSize}
									isSorting={isSorting}
									parentPage={parentPage}
									setIsSorting={setIsSorting}
									searchQuery={searchQuery}
									formatSearchValues={formatSearchValues}
									disableTableSort={disableTableSort}
									isSearching={isSearching}
									setSortingColumn={setSortingColumn}
									sortingColumn={sortingColumn}
								/>
							))}

							{showActions && (
								<th tabIndex={0} scope="col" className="actions-cell" role="columnheader">
									Actions
								</th>
							)}
						</tr>
					</thead>
					<tbody>
						{showSearch && (
							<tr className="search-row" style={{ display: showFiltersRow ? '' : 'none' }}>
								<td style={{ display: enableSelection || enableRadio ? 'table-cell' : 'none' }} />
								{columns.map((item, index) => {
									return (
										<td className={showActions ? '' : 'data-item last-data-item'} key={index}>
											<div className={`${styles.searchBarContainer} position-relative-654`}>
												{item.heading === radioBlocksearch ? null : (
													<SearchInputField
														input={input}
														item={item}
														index={index}
														inputReference={inputReference}
														handleSearchInput={handleSearchInput}
														date={searchFieldDates?.[index] || ''}
														searchQuery={searchQuery}
														searchInputValues={searchInputValues}
														setSearchInputValues={setSearchInputValues}
														arialabel={'search'}
														setIsSearching={setIsSearching}
														isClearable={item.isClearable}
													/>
												)}
											</div>
										</td>
									);
								})}
								{showActions && (
									<td className="actions-cell">
										<div className={'d-flex justify-content-center align-items-center'}>
											<OverlayTrigger overlay={<Tooltip>Erase Inputs</Tooltip>}>
												<button
													aria-label="Erase Inputs"
													data-testid="searchEraser"
													className="border-0 bg-transparent"
													type="button"
													onClick={async (e) => {
														e.preventDefault();
														try {
															setSize?.(calculatedSize);
															setIsSorting(false);
															setColDirection('desc');
															eraseSearchFields(table, inputReference, columns.length);
															clearAllFilters();

															setTableData(data);
															let newTableData = formatSearchValues
																? await formatSearchValues(allData)
																: allData;
															const itemsInPages = generateItemsSplitInPages(allData, size);

															setItemsCalculatedOnFE({
																items: newTableData,
																itemsInPages,
																totalPages: paginationItems?.totalPages,
																currentPage: 0,
																size: paginationItems?.size,
																totalElements: paginationItems?.totalElements
															});
														} catch (error) {
															console.error(error);
														}
													}}
												>
													<span className={styles.tableActionIcon}>
														<i alt="Eraser icon" className="fa fa-solid fas fa-eraser text-dark" />
													</span>
												</button>
											</OverlayTrigger>
										</div>
									</td>
								)}
							</tr>
						)}
						{}
						{(!_.isEmpty(tableData) || searchQuery) && (
							<InfiniteScrollRows
								disableInfiniteScroll={disableInfiniteScroll}
								tableRef={outerTable}
								rowSize={size}
								items={tableData}
								isJasperReport={isJasperReport}
								renderRow={(item, index) => (
									<Fragment key={`rowToolTip-${index}`}>
										<CustomRowTooltip
											index={index}
											placement={rowTooltipPlacement}
											hideTooltip={hideTooltipOnRows ? hideTooltipOnRows(item) : hideTooltip}
											overlay={
												enableRowLink || enableRowFunction ? (
													<Tooltip
														key={`rowTooltip-inner-${index}`}
														className="rowToolTip-hover"
														id={`rowToolTip-hover-${index}`}
													>
														{hoverMessage ? hoverMessage : 'Select to View/Edit'}
													</Tooltip>
												) : (
													<></>
												)
											}
										>
											{({ showRowToolTip, setShowRowToolTip }) => {
												return (
													<tr
														onKeyDown={(e) => {
															if (e.code === 'Enter' && e.target.type !== 'submit')
																rowClicked(item);
														}}
														ref={tableRow}
														htmlFor={index}
														key={index}
														onFocus={(e) => {
															showRowToolTip !== `rowToolTip-${index}` &&
																setShowRowToolTip(`rowToolTip-${index}`);
														}}
														onBlur={() => {
															setShowRowToolTip(undefined);
														}}
														tooltip-id={`rowToolTip-${index}`}
														className={`text-center table-row ${
															enableRowLink || (enableRowFunction && styles.noRowPointer)
														}`}
														onMouseLeave={() => {
															setShowRowToolTip(undefined);
														}}
													>
														<>
															{enableSelection && (
																<td>
																	<input
																		onChange={(e) =>
																			onRowSelectedLodash(
																				e,
																				item,
																				selectedRows,
																				setSelected,
																				setNewSelected,
																				setUnSelected
																			)
																		}
																		type="checkbox"
																		name={`rowSelect-${index}-${Math.floor(Math.random() * 10000)}`}
																		data-testid={`RowCheckbox-${index}-${Math.floor(
																			Math.random() * 1000
																		)}`}
																		className={`${styles.checkboxBlueBackground}`}
																		value={item}
																		aria-label={`${columns?.[0]?.heading} ${
																			item?.[columns?.[0]?.value]
																		}`}
																		title={item?.selected?.title ? item?.selected?.title : ''}
																		aria-live={`${isSearching && 'assertive'}`}
																		checked={isSelected(item)}
																		disabled={
																			isDisabled(item) ||
																			disableCheckboxRowSelection ||
																			item?.selected?.isDisabled
																		}
																	/>
																</td>
															)}

															{enableRadio && (
																<td>
																	<input
																		name={`radio-${index}`}
																		data-testid={`radioButton-${index}`}
																		onChange={(e) =>
																			onRadioSelectedLodash(e, item, setRadioSelectedRow)
																		}
																		type="radio"
																		value={item}
																		checked={checkHandler(item)}
																		aria-label={`${columns?.[0]?.heading} Radio ${
																			item?.[columns?.[0]?.value]
																		}`}
																		aria-live={`${isSearching && 'assertive'}`}
																		className={`${checkHandler(item) && 'radioSelected'}`}
																	/>
																</td>
															)}

															<TableRow
																columns={columns}
																enableRowLink={enableRowLink}
																enableRowFunction={enableRowFunction}
																expandedCheckboxFn={expandedCheckboxFn}
																enableExpandRow={enableExpandRow}
																columnExpandHeading={columnExpandHeading}
																skipColumnExpandHeading={skipColumnExpandHeading}
																expandableItemSelectFunction={expandableItemSelectFunction}
																refreshTable={refreshTable}
																editMode={editMode}
																item={item}
																setRowData={rowClicked}
																showActions={showActions}
																onRowEdited={onRowEdited}
																onKeyDownCell={onKeyDownCell}
																rowIndex={index}
																onRowFocus={onRowFocus}
																showTime={showTime}
																showTimeOnly={showTimeOnly}
																disableDateFormatting={disableDateFormatting}
																tableId={tableId}
																setToast={setToast}
																customInput={customInput}
																enableTime={enableTime}
																showAsIs={showAsIs}
																showDateOnly={showDateOnly}
																zoned={zoned}
																nowrap={nowrap}
																isSearching={isSearching}
																showTimeAndDate={showTimeAndDate}
																useBrowserTimeZone={useBrowserTimeZone}
																setShowRowToolTip={() => {
																	showRowToolTip !== `rowToolTip-${index}` &&
																		setShowRowToolTip(`rowToolTip-${index}`);

																	let div = outerTable?.current;
																	const hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
																	const tooltipPosition = hasHorizontalScrollbar ? 'top' : 'left';
																	tooltipPosition !== rowTooltipPlacement &&
																		setRowTooltipPlacement(tooltipPosition);
																}}
																selectedExpandableCheckboxes={selectedExpandableCheckboxes}
																customDateFormat={customDateFormat}
															/>
														</>
														<td
															id={`${tableId}-${index}`}
															aria-label={index}
															aria-rowindex={index}
															onMouseOver={() => {
																showRowToolTip && setShowRowToolTip(undefined);
															}}
															onMouseLeave={() => {
																showRowToolTip && setShowRowToolTip(undefined);
															}}
															style={{ display: showActions ? 'auto' : 'none' }}
															className={'actions-cell pe-none'}
														>
															<div className="d-flex flex-row justify-content-around align-items-center">
																<div
																	aria-label={`changes-${item?.id}`}
																	style={{
																		display:
																			enableCodeChangeSummary &&
																			((typeof item?.hasModifications === 'string' &&
																				item?.hasModifications === 'true') ||
																				(typeof item?.hasModifications === 'boolean' &&
																					item?.hasModifications))
																				? 'initial'
																				: 'none'
																	}}
																	className={`${styles.tableActionButton} w-100 h-100 pe-auto`}
																	onClick={(e) => {
																		handleOnClick(e, item);
																	}}
																	onMouseDown={handleOnMouseDown}
																	onMouseUp={handleOnMouseUp}
																	onTouchStart={handleOnTouchStart}
																	onTouchEnd={handleOnTouchEnd}
																>
																	<OverlayTrigger overlay={<Tooltip>View Changes</Tooltip>}>
																		<button
																			data-testid="historyIcon"
																			aria-label="View changes"
																			className={`${styles.iconActionButton} border-0 bg-transparent`}
																		>
																			<span className={styles.tableActionIcon}>
																				<i
																					alt="History icon"
																					className={'fas fa-history text-dark'}
																				/>
																			</span>
																		</button>
																	</OverlayTrigger>
																</div>
																<div
																	style={{
																		display: enableActionDownloadFile ? 'initial' : 'none'
																	}}
																	className={`${styles.tableActionButton} mx-1 pe-auto`}
																	onClick={() => downloadFileRequest(item)}
																>
																	<OverlayTrigger overlay={<Tooltip>Download File</Tooltip>}>
																		<button
																			className={`${styles.iconActionButton} border-0 bg-transparent`}
																			aria-label="Download File"
																		>
																			<i
																				id={`i-${item?.id}`}
																				alt="File icon"
																				className={'fas fa-download text-secondary pe-auto'}
																			/>
																		</button>
																	</OverlayTrigger>
																</div>
																<div
																	style={{
																		display:
																			enableActionSeeDocument &&
																			(item.document || item.hasDocuments)
																				? 'initial'
																				: 'none'
																	}}
																	className={`${styles.tableActionButton} mx-1 pe-auto`}
																	onClick={() => {
																		const source = item.source;
																		if (source === 'document-view-all' && enableActionSeeDocument) {
																			seeDocumentRequest(item);
																		} else if (source === 'download-document') {
																			const inFile = item.document;
																			setClickedItemId(`i-${item.id}`);
																			if (inFile && typeof inFile === 'string') {
																				downloadEventFileFromUrl(item);
																			} else if (inFile && typeof inFile !== 'string') {
																				const state = inFile.state;
																				navigate(inFile.to, { state });
																			} else {
																				alert("File doesn't exist ");
																			}
																		}
																	}}
																>
																	<OverlayTrigger overlay={<Tooltip>View Document</Tooltip>}>
																		<button
																			className={`${styles.iconActionButton} border-0 bg-transparent`}
																			aria-label="View document"
																		>
																			<i
																				id={`i-${item.id}`}
																				alt="File icon"
																				className={
																					isDownloading &&
																					document.getElementById(`i-${item.id}`)?.id ===
																						clickedItemId
																						? 'fas fa-spinner fa-pulse text-primary pe-auto'
																						: 'fas fa-file text-secondary pe-auto'
																				}
																			/>
																		</button>
																	</OverlayTrigger>
																</div>
																<div
																	data-testid={`voucher-${item?.id}`}
																	className={`${styles.tableActionButton} mx-auto pe-auto ${
																		enableVoucherAction && item.courtApprovedPaymentsCount > 0
																			? 'd-inline-block'
																			: 'd-none'
																	} `}
																	onClick={() => handleVoucherAction(item)}
																>
																	<OverlayTrigger
																		placement="top"
																		overlay={<Tooltip>View Voucher</Tooltip>}
																	>
																		<button
																			aria-label="View Voucher"
																			className={`${styles.iconActionButton} border-0 bg-transparent pe-auto`}
																		>
																			<span className={styles.tableActionIcon}>
																				<i alt="Hand icon" className="fas fa-hand-holding-usd" />
																			</span>
																		</button>
																	</OverlayTrigger>
																</div>
																<div
																	data-testid={`checkboxes-${item?.id}`}
																	className={`${styles.tableActionButton} mx-auto pe-auto ${
																		checkboxes.length > 0
																			? 'd-flex justify-content-center me-2'
																			: 'd-none'
																	} `}
																>
																	{checkboxes.map((value) => (
																		<div
																			className="d-flex justify-content-around align-items-center m-2"
																			key={`checkbox-key-${value.key}${item[value.id]}`}
																		>
																			<Form.Check
																				type="checkbox"
																				name={`${value.key}${item[value.id]}`}
																				id={`${value.key}${item[value.id]}`}
																				data-testid={`HeaderCheckbox`}
																				aria-label={value.label}
																				checked={isSelectedDocViewAll(
																					item[value.id],
																					value.key,
																					item
																				)}
																				onChange={(e) =>
																					changeStatusCheckbox(
																						item[value.id],
																						value.key,
																						e.target.checked,
																						item
																					)
																				}
																				label={value.label}
																				disabled={disableTrashIcons || value.disabled}
																			/>
																		</div>
																	))}
																</div>
																<div
																	className={`${styles.tableActionButton} ${
																		checkboxes.length > 0 && 'me-auto'
																	} ${
																		(enableDelete || item?.deleteable) && !item?.hideDeleteOption
																			? 'd-flex'
																			: 'd-none'
																	} pe-auto justify-content-around align-items-center`}
																	data-testid={`delete-item-${item?.id}`}
																>
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Tooltip>
																				{typeof deleteRowTooltipLabel === 'function'
																					? deleteRowTooltipLabel(item)
																					: item.disableDelete && isRestoreCode
																					? 'Reactivate'
																					: deleteRowTooltipLabel}
																			</Tooltip>
																		}
																	>
																		<span>
																			<button
																				aria-label={`${
																					deleteRowTooltipLabel ? deleteRowTooltipLabel : 'Delete'
																				} -item`}
																				className={`${styles.iconActionButton} border-0 bg-transparent pe-auto`}
																				disabled={
																					!isRestoreCode &&
																					(disableTrashIcons ||
																						item.disableDelete ||
																						item.taskDeleted ||
																						item.isDisabled)
																				}
																				onClick={(e) => {
																					if (
																						!item.disableDelete ||
																						!item.taskDeleted ||
																						item.isDisabled
																					) {
																						item.showWarningMessage
																							? handleShowAlertMessage(item)
																							: handleDeleteOrReactiveItem(e, item, false);
																					}
																				}}
																				onMouseDown={(e) =>
																					item.showWarningMessage
																						? handleShowAlertMessage(item)
																						: handleDeleteOrReactiveItem(e, item, false)
																				}
																			>
																				<span className={styles.tableActionIcon}>
																					<i
																						alt="Trash icon"
																						className={`fas fa-trash${
																							item.disableDelete ||
																							item.taskDeleted ||
																							item.isDisabled ||
																							disableTrashIcons ||
																							item.active === false ||
																							item.showWarningMessage
																								? isRestoreCode
																									? '-restore text-success'
																									: ' text-muted'
																								: ' text-danger'
																						}`}
																					/>
																				</span>
																			</button>
																		</span>
																	</OverlayTrigger>
																	{dualOptions && (
																		<OverlayTrigger
																			placement="top"
																			overlay={
																				<Tooltip>
																					{typeof dualOptions.toolTip === 'function'
																						? dualOptions.toolTip(item)
																						: dualOptions.toolTip}
																				</Tooltip>
																			}
																		>
																			<span>
																				<button
																					aria-label={`${
																						typeof dualOptions.toolTip === 'function'
																							? dualOptions.toolTip(item)
																							: dualOptions.toolTip
																					} -item`}
																					className={`${styles.iconActionButton} border-0 bg-transparent pe-auto`}
																					disabled={
																						!isRestoreCode &&
																						(disableTrashIcons ||
																							item.disableDelete ||
																							item.taskDeleted ||
																							item.isDisabled)
																					}
																					onClick={(e) => {
																						if (
																							!item.disableDelete ||
																							!item.taskDeleted ||
																							item.isDisabled
																						) {
																							item.showWarningMessage
																								? handleShowAlertMessage(item)
																								: handleDeleteOrReactiveItem(e, item, true);
																						}
																					}}
																					onMouseDown={(e) =>
																						item.showWarningMessage
																							? handleShowAlertMessage(item)
																							: handleDeleteOrReactiveItem(e, item, true)
																					}
																				>
																					<span className={styles.tableActionIcon}>
																						<i
																							alt="Custom icon"
																							className={`fas ${dualOptions.icon} ${
																								item.disableDelete ||
																								item.taskDeleted ||
																								item.isDisabled ||
																								disableTrashIcons ||
																								item.active === false ||
																								item.showWarningMessage
																									? isRestoreCode
																										? '-restore text-success'
																										: ' text-muted'
																									: ' text-danger'
																							}`}
																						/>
																					</span>
																				</button>
																			</span>
																		</OverlayTrigger>
																	)}
																</div>
																<div
																	style={{
																		display:
																			enableEnvelop && item.isReviewed !== null ? 'initial' : 'none'
																	}}
																	className={`${styles.deleteButton} w-100 h-100 pe-auto`}
																>
																	<OverlayTrigger
																		overlay={
																			<Tooltip>
																				{item.isReviewed ? 'Dismissed' : 'Not Dismissed'}
																			</Tooltip>
																		}
																	>
																		<button
																			aria-label={
																				item.isReviewed ? 'Item dismissed' : 'Item not dismissed'
																			}
																			className={`${styles.iconActionButton} border-0 bg-transparent pe-auto`}
																			disabled={
																				item.disableDelete || item.taskDeleted || item.isDisabled
																			}
																			onClick={() => {
																				handleEnvelopeClick(item);
																			}}
																		>
																			<span className={styles.tableActionIcon}>
																				<i
																					alt="Envelope icon"
																					className={
																						item.isReviewed
																							? 'fas fa-envelope-open text-secondary'
																							: 'fas fa-envelope text-danger'
																					}
																				></i>
																			</span>
																		</button>
																	</OverlayTrigger>
																</div>
																<PrintActionIcon index={index} />
																<RetrySendInvitationIcon
																	item={item.id ? item.id : item}
																	index={index}
																/>
															</div>
														</td>
													</tr>
												);
											}}
										</CustomRowTooltip>
									</Fragment>
								)}
							/>
						)}

						<TotalRow columns={columns} item={totalItem} tableId={tableId} />
						{showBackendCalculatedTotalRow && (
							<BackendCalculatedTotalRow
								tableId={tableId}
								columns={columns}
								financialItems={financialItems}
								enableSelection={enableSelection}
							/>
						)}
					</tbody>
				</table>
			</div>
			{/* END OF TABLE HTML */}

			{tableData?.length > 0 && (
				<>
					{windowSize?.innerWidth >= 768 ? (
						<TablePaginator
							formTitle={formTitle}
							hidePagination={hidePagination}
							totalPages={isSearching || isSorting ? itemsCalculatedOnFE?.totalPages : totalPages}
							currentPage={isSearching || isSorting ? itemsCalculatedOnFE?.currentPage : parentPage}
							setCurrentPage={
								isSorting && !enableServerSort
									? (page) => {
											setItemsCalculatedOnFE({ ...itemsCalculatedOnFE, currentPage: page });
											setParentPage(page);
									  }
									: (isSearching && !filterWithApiCalls) || (isSorting && !isSearching)
									? (page) => {
											setItemsCalculatedOnFE({ ...itemsCalculatedOnFE, currentPage: page });
									  }
									: setParentPage
							}
							data={tableData}
							totalElements={
								isSearching || isSorting ? itemsCalculatedOnFE?.totalElements : totalElements
							}
							rowsDisplayed={isSearching || isSorting ? calculatedSize : rowsDisplayed}
						/>
					) : (
						<TableMobilePaginator
							hidePagination={hidePagination}
							totalPages={isSearching ? itemsCalculatedOnFE?.totalPages : totalPages}
							currentPage={isSearching ? itemsCalculatedOnFE?.currentPage : parentPage}
							setCurrentPage={
								isSorting && !enableServerSort
									? (page) => {
											setItemsCalculatedOnFE({ ...itemsCalculatedOnFE, currentPage: page });
											setParentPage(page);
									  }
									: isSearching && !filterWithApiCalls
									? (page) => setItemsCalculatedOnFE({ ...itemsCalculatedOnFE, currentPage: page })
									: setParentPage
							}
							data={tableData}
							totalElements={isSearching ? itemsCalculatedOnFE?.totalElements : totalElements}
							rowsDisplayed={isSearching ? calculatedSize : rowsDisplayed}
						/>
					)}
				</>
			)}

			<TablePrintDocument
				printEnable={printEnable}
				showCheckBox={showCheckBox}
				mergeCheckedRef={mergeCheckedRef}
				entity={entity}
			/>
			{!disableTrashIcons && isOpenConfirmDialog && (
				<ConfirmationDialog
					customDelMessage={
						dialogParam.isCustomOption
							? dualOptions.customMessage(dialogParam.item)
							: typeof customDelMessage === 'function' && !dialogParam.item.disableDelete
							? customDelMessage(dialogParam.item)
							: dialogParam.item.disableDelete
							? `Are you sure you want to reactivate ${dialogParam.entity} ?`
							: customDelMessage
					}
					entity={dialogParam.entity}
					onConfirm={dialogParam.onClick}
					onCancel={closeConfirmDialog}
					customTitle={
						dialogParam.isCustomOption
							? dualOptions.customTitle(dialogParam.item)
							: typeof customRemoveConfirmationDialogTitle === 'function'
							? customRemoveConfirmationDialogTitle(dialogParam.item)
							: dialogParam.item.disableDelete
							? `Warning: ${dialogParam.entity} will be reactivated`
							: customRemoveConfirmationDialogTitle
					}
				/>
			)}
			{tableData?.length === 0 && !isLoading && <EmptyMessage />}
		</div>
	);
};

TableWithIntegration.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object),
	data: PropTypes.arrayOf(PropTypes.object),
	deleteRequest: PropTypes.func,
	enableActionSeeDocument: PropTypes.bool,
	enableActionDownloadFile: PropTypes.bool,
	enableDelete: PropTypes.bool,
	enableRowLink: PropTypes.bool,
	enableSelection: PropTypes.bool,
	selectionTitle: PropTypes.string,
	enableRadio: PropTypes.bool,
	radioColumnTitle: PropTypes.string,
	exportCSV: PropTypes.bool,
	exportPDF: PropTypes.bool,
	exportExcel: PropTypes.bool,
	urlSearched: PropTypes.string,
	formTitle: PropTypes.string,
	limitRows: PropTypes.bool,
	onRowFocus: PropTypes.func,
	onSelectedChange: PropTypes.func,
	onUnSelectedChange: PropTypes.func,
	onNewSelectedChange: PropTypes.func,
	onRadioChange: PropTypes.func,
	initialSelectedRadio: PropTypes.object,
	printEnable: PropTypes.bool,
	rowLink: PropTypes.string,
	resetSelected: PropTypes.bool,
	setResetSelected: PropTypes.func,
	showActions: PropTypes.bool,
	tableId: PropTypes.string.isRequired,
	paginationItems: PropTypes.object.isRequired,
	hidePagination: PropTypes.bool,
	showTableToolbarActionButtons: PropTypes.object,
	enableEnvelop: PropTypes.bool,
	entity: PropTypes.string,
	accesibilityLabels: PropTypes.object,
	enableRowFunction: PropTypes.bool,
	clickedRowFunction: PropTypes.func,
	filterWithApiCalls: PropTypes.bool,
	nowrap: PropTypes.bool,
	disableAllInitialSelectedRows: PropTypes.bool,
	formatSearchValues: PropTypes.func,
	disableTableSort: PropTypes.bool
};

export default TableWithIntegration;

const EmptyMessage = () => (
	<p
		role="alert"
		aria-live="assertive"
		data-testid="EmptyMessage"
		aria-label="No data available"
		id="TableEmptyMessage"
		className="d-flex justify-content-center align-items-center my-2"
	>
		No data available
	</p>
);

function CustomRowTooltip({ children, index, placement, overlay, hideTooltip }) {
	const [showRowToolTip, setShowRowToolTip] = useState();

	return (
		<>
			{hideTooltip ? (
				<>{children({ showRowToolTip, setShowRowToolTip })}</>
			) : (
				<OverlayTrigger
					hide
					placement={placement}
					defaultShow={true}
					trigger={['focus', 'hover']}
					show={showRowToolTip === `rowToolTip-${index}`}
					overlay={overlay}
				>
					{children({ showRowToolTip, setShowRowToolTip })}
				</OverlayTrigger>
			)}
		</>
	);
}
