import { useRef, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import styles from './styles.module.scss';

import { useClosePanelsOnMobileNavigation } from '../../../hooks/useClosePanelsOnMobileNavigation';

const RightPanel = (props) => {
	const [
		leftPanelOpen,
		handleOpenLeftPanel,
		closeLeftPanel,
		rightPanelOpen,
		handleOpenRightPanel,
		closeRightPanel
	] = useOutletContext();

	const rightPanelRef = useRef(null);
	const toggleRightPanelBtnRef = useRef(null);

	useClosePanelsOnMobileNavigation();

	useEffect(() => {
		function handleRightPanelClickOutside(event) {
			if (
				rightPanelRef &&
				!rightPanelRef.current.contains(event.target) &&
				!toggleRightPanelBtnRef.current.contains(event.target) &&
				rightPanelOpen
			) {
				closeRightPanel();
			}
		}

		document.addEventListener('mousedown', handleRightPanelClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleRightPanelClickOutside);
		};
	});

	useEffect(() => {
		function focusRightPanel(event) {
			if (event.keyCode === 82 && event.altKey) {
				const panelButtons = [
					...rightPanelRef.current.querySelectorAll(
						'button[class~="btn"], a[href], input, select, [tabindex]:not([tabindex="-1"])'
					)
				].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));

				if (panelButtons) {
					panelButtons[0].focus();
				}
			}
		}

		document.addEventListener('keydown', focusRightPanel);

		return () => {
			document.removeEventListener('keydown', focusRightPanel);
		};
	});

	return (
		<>
			<button
				type="button"
				className={`${styles.showRightPanelBtn} ${rightPanelOpen ? styles.show : ''}`}
				onClick={handleOpenRightPanel}
				id={'global-rightpanel-trigger'}
				aria-label={'Toggle Right Panel'}
				ref={toggleRightPanelBtnRef}
			>
				{rightPanelOpen ? (
					<i className="fas fa-angle-double-right"></i>
				) : (
					<i className="fas fa-angle-double-left"></i>
				)}
			</button>
			<section
				id={styles.rightPanel}
				className={rightPanelOpen ? styles.show : null}
				ref={rightPanelRef}
				aria-label="Right Panel"
			>
				{props.children}
			</section>
		</>
	);
};

export default RightPanel;
