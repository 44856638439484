import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import TopNavBar from '../TopNavBar';
import styles from './styles.module.scss';
import { QueryClient, QueryClientProvider } from 'react-query';

const MainContainer = () => {
	const [leftPanelOpen, setLeftPanelOpen] = useState(false);
	const [rightPanelOpen, setRightPanelOpen] = useState(false);
	const queryClient = new QueryClient();

	const handleOpenLeftPanel = () => {
		setLeftPanelOpen(!leftPanelOpen);
	};

	const handleOpenRightPanel = () => {
		setRightPanelOpen(!rightPanelOpen);
	};

	const closeRightPanel = () => {
		setRightPanelOpen(false);
	};

	const closeLeftPanel = () => {
		setLeftPanelOpen(false);
	};

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<TopNavBar />
				<div id={styles.mainContainer}>
					<Outlet
						context={[
							leftPanelOpen,
							handleOpenLeftPanel,
							closeLeftPanel,
							rightPanelOpen,
							handleOpenRightPanel,
							closeRightPanel
						]}
					/>
				</div>
			</QueryClientProvider>
		</>
	);
};

export default MainContainer;
