import * as Yup from 'yup';

export const payOutSchema = Yup.object().shape({
	refundTo: Yup.string().required('Type is a required field.'),
	refundAmount: Yup.number().required('Refund Amount is a required field.'),
	refundBy: Yup.string().required('Refund By is a required field.'),
	checkDate: Yup.date().required('Check Date is required')
});

export const payoutPaidFeesColumn = [
	{ heading: 'Fees/Fine Type', value: 'description' },
	{ heading: 'Payment Date', value: 'createdDate', type: 'date' },
	{ heading: 'Amount', value: 'totalAmount' },
	{
		heading: 'Refund Amount applied',
		value: 'refundAmountApplied',
		editable: true,
		startSymbol: '$'
	}
];

export const disbursementDistributionColumn = [
	{ heading: 'Description', value: 'description' },
	{ heading: 'Amount', value: 'totalAmount' },
	{ heading: 'Refund Date', value: 'createdDate', type: 'date' }
];

export const payoutInitialValues = (values) => ({
	refundTo: values?.refundTo,
	refundAmount: values?.refundAmount ?? '',
	refundDate: values?.refundDate ?? new Date(),
	refundBy: values?.refundBy,
	refundMethod: values?.refundMethod ?? '',
	checkNumber: values?.checkNumber ?? '',
	checkDate: values?.checkDate ?? new Date(),
	refundAddress: values?.refundAddress ?? '',
	description: values?.description ?? ''
});
