// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Tangerine);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_textCanvas__mSdH- {\n  border: 1px black solid;\n}\n\n.styles_drawSignature__BLBJK div:first-child {\n  width: 100% !important;\n}\n\n.styles_tools__yWbpA {\n  display: flex;\n  justify-content: space-between;\n  width: 400px;\n}", "",{"version":3,"sources":["webpack://./src/components/CreateSignatureDialog/Forms/styles.module.scss"],"names":[],"mappings":"AAEA;EACC,uBAAA;AAAD;;AAGA;EACC,sBAAA;AAAD;;AAGA;EACC,aAAA;EACA,8BAAA;EACA,YAAA;AAAD","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Tangerine\");\n\n.textCanvas {\n\tborder: 1px black solid;\n}\n\n.drawSignature div:first-child {\n\twidth: 100% !important;\n}\n\n.tools {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\twidth: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textCanvas": "styles_textCanvas__mSdH-",
	"drawSignature": "styles_drawSignature__BLBJK",
	"tools": "styles_tools__yWbpA"
};
export default ___CSS_LOADER_EXPORT___;
