import { useContext, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { getClerkIdFromCookies } from '../utils/cookiesUtils';

export const useRequestsWithProgress = () => {
	const [progress, setProgress] = useState(0);
	const { token } = useContext(AuthContext);

	const postDocument = async (data) => {
		const { body, url, contentType = 'multipart/form-data' } = data;
		setProgress(0);
		try {
			const res = await axios.post(url, body, {
				headers: {
					'Content-Type': { contentType },
					Authorization: `Bearer ${token}`,
					'clerk-id': getClerkIdFromCookies()
				},
				onUploadProgress: (progressEvent) => {
					const { loaded, total } = progressEvent;
					const percent = Math.floor((loaded * 100) / total);
					if (percent < 100) {
						setProgress(percent);
					}
				}
			});
			setProgress(0);
			return res;
		} catch (err) {
			console.log(err.response);
			setProgress(0);
			return err.response;
		}
	};

	const putDocument = async (data) => {
		const { body, url, contentType = 'multipart/form-data' } = data;
		setProgress(0);
		try {
			const res = await axios.put(url, body, {
				headers: {
					'Content-Type': { contentType },
					Authorization: `Bearer ${token}`,
					'clerk-id': getClerkIdFromCookies()
				},
				onUploadProgress: (progressEvent) => {
					const { loaded, total } = progressEvent;
					const percent = Math.floor((loaded * 100) / total);
					if (percent < 100) {
						setProgress(percent);
					}
				}
			});
			setProgress(0);
			return res;
		} catch (err) {
			console.log(err.response);
			setProgress(0);
			return err.response;
		}
	};

	const getDocument = async (data, withHeaders = true) => {
		const { url, responseType = 'blob' } = data;
		setProgress(0);
		try {
			const headers = withHeaders
				? {
						Authorization: `Bearer ${token}`
				  }
				: {};
			const res = await axios.get(url, {
				headers,
				responseType,
				onDownloadProgress: (progressEvent) => {
					const { loaded, total } = progressEvent;
					const percent = Math.floor((loaded * 100) / total);
					if (percent < 100) {
						setProgress(percent);
					}
				}
			});
			setProgress(0);
			return res;
		} catch (err) {
			console.log(err.response);
			setProgress(0);
			return err.response;
		}
	};
	return {
		getDocument,
		postDocument,
		putDocument,
		progress
	};
};
