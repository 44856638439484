import { Button } from 'react-bootstrap';
import RequestButton from '../RequestButton';

/**
 *
 * @param {Function} onSave Handles onSave event
 * @param {Function} onCancel Handles onCancel event
 * @param {Function} onEdit Handles onEdit event
 * @param {Boolean} editMode Triggers editMode, if active, it will show the Save and Cancel buttons and hide the Edit button
 * @param {Boolean} disableSave Triggers disable prop in save button
 * @param {Boolean} disableCancel Triggers disable prop in cancel button
 * @param {Boolean} disableEdit Triggers disable prop in edit button
 * @returns
 */

const EditActionButtons = (props) => {
	const { onSave, onCancel, onEdit, editMode, disableSave, disableCancel, disableEdit } = props;

	return (
		<>
			{!editMode && (
				<Button variant="primary" onClick={onEdit} className="w-100" disabled={disableEdit}>
					Edit
				</Button>
			)}

			{editMode && (
				<>
					<RequestButton variant="primary" onClick={onSave} disabled={disableSave}>
						Save
					</RequestButton>
					<Button variant="secondary" onClick={onCancel} className="w-100" disabled={disableCancel}>
						Cancel
					</Button>
				</>
			)}
		</>
	);
};

export default EditActionButtons;
