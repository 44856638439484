import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';

import styles from './styles.module.scss';
import Logo from '../../images/idocket_logo_sep_2022.png';

const LoaderComponent = ({ loadingBar, now = 0, loadingPage, onlyProgress = false }) => {
	return (
		<>
			{loadingBar && now > 0 && onlyProgress && <ProgressBar now={now} label={`${now}%`} />}
			<div
				className={loadingBar && !onlyProgress ? styles.saveDocument : styles.hidden}
				ref={loadingPage}
			>
				{now > 0 && <ProgressBar className="mb-4" now={now} label={`${now}%`} />}
				<div className={styles.documentContainer}>
					<div className={styles.loadingAnimation}>
						<img src={Logo} alt="Logo iDocket" className={styles.image} />
					</div>
				</div>
			</div>
		</>
	);
};

LoaderComponent.propTypes = {
	loadingBar: PropTypes.bool,
	now: PropTypes.number,
	loadingPage: PropTypes.object,
	onlyProgress: PropTypes.bool
};

export default LoaderComponent;
