import { useRef, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Clock from '../../Clock';
import styles from './styles.module.scss';

const LeftPanel = ({ children, customHeading = 'Views' }) => {
	const [
		leftPanelOpen,
		handleOpenLeftPanel,
		closeLeftPanel,
		rightPanelOpen,
		handleOpenRightPanel,
		closeRightPanel
	] = useOutletContext();

	const leftPanelRef = useRef(null);
	const toggleLeftPanelBtnRef = useRef(null);

	useEffect(() => {
		function handleLeftPanelClickOutside(event) {
			if (
				leftPanelRef.current &&
				!leftPanelRef.current.contains(event.target) &&
				!toggleLeftPanelBtnRef.current.contains(event.target) &&
				leftPanelOpen
			) {
				closeLeftPanel();
			}
		}

		document.addEventListener('mousedown', handleLeftPanelClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleLeftPanelClickOutside);
		};
	});

	useEffect(() => {
		function focusLeftPanel(event) {
			if (event.keyCode === 76 && event.altKey) {
				const panelButtons = [...leftPanelRef.current.querySelectorAll('button, a[href]')].filter(
					(el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
				);

				if (panelButtons) {
					panelButtons[0].focus();
				}
			}
		}

		document.addEventListener('keydown', focusLeftPanel);

		return () => {
			document.removeEventListener('keydown', focusLeftPanel);
		};
	});

	return (
		<>
			<button
				type="button"
				className={`${styles.showLeftPanelBtn} ${leftPanelOpen ? styles.show : ''}`}
				onClick={handleOpenLeftPanel}
				aria-label={'Toggle Left Panel'}
				ref={toggleLeftPanelBtnRef}
			>
				{leftPanelOpen ? (
					<i className="fas fa-angle-double-left"></i>
				) : (
					<i className="fas fa-angle-double-right"></i>
				)}
			</button>
			<nav
				id={styles.leftPanel}
				className={`${leftPanelOpen ? styles.show : ''}`}
				ref={leftPanelRef}
				aria-label="Left Panel"
			>
				<div className={styles.topSection}>{!isEmpty(children) && <h3>{customHeading}</h3>}</div>
				<div className={styles.middleSection}>{children}</div>
				<div className={styles.bottomSection}>
					<Clock />
				</div>
			</nav>
		</>
	);
};

export default LeftPanel;
