import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: '',
	date: '',
	startTime: '',
	endTime: '',
	filters: {
		court: '',
		fromDate: '',
		toDate: ''
	},
	filteredSearch: false,
	currentSettings: []
};

const courtCalendarSlice = createSlice({
	initialState,
	name: 'courtCalendarSlice',
	reducers: {
		criminalDocket: (state, action) => {
			state = action.payload;
		},
		clearAll: (state) => {
			state.filters = {
				court: '',
				fromDate: '',
				toDate: ''
			};
		},
		addFilters: (state, action) => {
			state.filters[`${action.payload?.[0]}`] = action.payload?.[1];
		},
		filteredSearch: (state) => {
			state.filteredSearch = !state.filteredSearch;
		},
		currentSettings: (state, action) => {
			state.currentSettings = action.payload;
		}
	}
});

export const { criminalDocket, clearAll, addFilters, filteredSearch, currentSettings } =
	courtCalendarSlice.actions;

export default courtCalendarSlice.reducer;
