import { useCallback } from 'react';

import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const usePersonViewServices = () => {
	const { get, deleteFn, put, post } = HttpService();

	const getPersonData = useCallback(
		async (personId) => {
			const result = await get(`${BASE_URL}/api/person-management/person/${personId}`);
			let resourceAvailabilityData = [];
			if (
				result?.data?.professionalResource &&
				typeof result?.data?.professionalResourceDTO?.prId === 'number'
			) {
				const resourceAvailabilityResult = await get(
					`${BASE_URL}/api/resource-management/resource-availability/${result?.data?.professionalResourceDTO?.prId}?page=0&size=100`
				);
				resourceAvailabilityData = resourceAvailabilityResult.data?.resourceAvailabilityDTOS || [];
			}
			return {
				personData: result.data,
				resourceAvailabilityData
			};
		},
		[get]
	);

	const deleteResourceAvailability = useCallback(
		async (resourceAvailabilityId) =>
			await deleteFn(
				`${BASE_URL}/api/resource-management/resource-availability/${resourceAvailabilityId}`
			),
		[deleteFn]
	);

	const putResourceAvailability = useCallback(
		async (resourceAvailabilityId, body) =>
			await put(
				`${BASE_URL}/api/resource-management/resource-availability/${resourceAvailabilityId}`,
				body
			),
		[put]
	);
	const postResourceAvailability = useCallback(
		async (body) => await post(`${BASE_URL}/api/resource-management/resource-availability`, body),
		[post]
	);

	return {
		getPersonData,
		deleteResourceAvailability,
		putResourceAvailability,
		postResourceAvailability
	};
};
