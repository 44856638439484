import { capitalizeEachWord } from './stringUtils';

export const formPathsFromUrl = (pathName) => {
	const splittedPathname = pathName.split('/').filter((path) => path !== '');
	let paths = [{ name: 'Home', url: '/home' }];

	splittedPathname.forEach((path) => {
		const pathUrl = pathName.slice(0, pathName.indexOf(path) + path.length);
		if (!isNaN(path)) {
			const lastPath = paths.pop();
			paths.push({ ...lastPath, url: pathUrl });
		} else {
			paths.push({ name: capitalizeEachWord(path), url: pathUrl });
		}
	});

	return paths;
};

export const formPathsFromMultipleUrls = (pathsHistory) => {
	const actualPathname = pathsHistory[pathsHistory.length - 1];
	const lastPathname = pathsHistory[pathsHistory.length - 2];

	const actualSplittedPathname = actualPathname.split('/').filter((path) => path !== '');

	let actualPaths = [];

	actualSplittedPathname.forEach((path) => {
		const pathUrl = actualSplittedPathname.slice(
			0,
			actualSplittedPathname.indexOf(path) + path.length
		);
		if (!isNaN(path)) {
			const lastPath = actualPaths.pop();
			actualPaths.push({ ...lastPath, url: pathUrl });
		} else {
			actualPaths.push({ name: capitalizeEachWord(path), url: pathUrl });
		}
	});

	const lastSplittedPathname = lastPathname.split('/').filter((path) => path !== '');

	let lastPaths = [{ name: 'Home', url: '/home' }];

	lastSplittedPathname.forEach((path) => {
		const pathUrl = lastSplittedPathname.slice(0, lastSplittedPathname.indexOf(path) + path.length);
		if (!isNaN(path)) {
			const lastPath = lastPaths.pop();
			lastPaths.push({ ...lastPath, url: pathUrl });
		} else {
			lastPaths.push({ name: capitalizeEachWord(path), url: pathUrl });
		}
	});

	return [...lastPaths, actualPaths[actualPaths.length - 1]];
};
