import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ls from 'localstorage-slim';
import { isEmpty } from 'lodash';
import { GLOBAL_ADMIN_PERMISSION, usePermissions } from '../hooks/usePageAccess';

const setInLocalStorage = (key, data) => {
	try {
		ls.config.ttl = 2592000; // Expires in 30 days (value in seconds)

		ls.set(key, data);
	} catch (error) {
		console.error('Unable to Auto Save', error);
	}
};

const getFromLocalStorage = (key) => {
	try {
		ls.config.ttl = 2592000; // Expires in 30 days (value in seconds)

		return ls.get(key);
	} catch (error) {
		console.error('Unable to retrieve Auto Save', error);
		return null;
	}
};

export const getAutoSaveData = () => {
	let autoSaveData = getFromLocalStorage('autoSave');

	if (autoSaveData) {
		return isEmpty(autoSaveData) ? null : autoSaveData;
	} else {
		return null;
	}
};

export const removeAutoSaveData = () => {
	ls.remove('autoSave');
};

export const useRecoverAutoSaveData = ({
	formId,
	setInitialValues,
	tableName,
	tableSelectedItemsSetter = () => {},
	noRedirect
}) => {
	const autoSaveValue = getAutoSaveData();
	const locationState = useLocation()?.state ?? noRedirect ? { resumeForm: true } : {};
	const { resumeForm } = locationState;

	useEffect(() => {
		if (autoSaveValue?.formId === formId && resumeForm) {
			const { values } = autoSaveValue;
			if (!isEmpty(values)) {
				setInitialValues(values);
				tableSelectedItemsSetter(values[tableName]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export const AutoSave = ({ formId, permissions = [], locationState = null }) => {
	const { userInfo, jurisdiction: clerkId } = useSelector((state) => state.user);
	// To prevent UT failing as we don't mock the Redux Store
	const { id: userId } = userInfo ?? {};
	const autoSavePath = window.location.pathname;

	// TODO: include params for path & params for form (like when selecting an item to edit)
	const autoSaveField = (name, value) => {
		const autoSaveValue = getAutoSaveData();
		const formDataObject = { formId, autoSavePath, userId, clerkId, permissions, locationState };
		if (autoSaveValue?.formId !== formId) {
			removeAutoSaveData();
			formDataObject.values = { [name]: value };
		} else {
			formDataObject.values = { ...autoSaveValue?.values, [name]: value };
		}

		// TODO: Set TTL for the local storage key, and add encryption
		setInLocalStorage('autoSave', formDataObject);
	};

	return { autoSaveField };
};

export const useCheckIfUserCanRecoverData = (openRecoverAutoSaveModal) => {
	const { userInfo } = useSelector((state) => state.user);
	const autoSaveData = getAutoSaveData();
	const { clerkId, userId, permissions: formPermissions } = autoSaveData ?? {};
	// user's permissions
	const { permissions: userPermissions } = usePermissions(clerkId);
	const [canOpenForm, setCanOpenForm] = useState(undefined);

	const hasPermissions = () => {
		return (
			userPermissions.includes(GLOBAL_ADMIN_PERMISSION) ||
			formPermissions.every((formPermission) => userPermissions.includes(formPermission))
		);
	};
	useEffect(() => {
		if (userPermissions && !isEmpty(autoSaveData)) {
			const _canOpenForm = isEmpty(formPermissions) || (userId === userInfo.id && hasPermissions());
			setCanOpenForm(_canOpenForm);
			_canOpenForm && openRecoverAutoSaveModal();
			!_canOpenForm && removeAutoSaveData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userPermissions]);

	return { autoSaveData, canOpenForm };
};

export const useOpenAutoSaveForm = (formMapper) => {
	const locationState = useLocation()?.state ?? {};
	const { resumeForm, formId } = locationState;

	useEffect(() => {
		if (resumeForm && formMapper[formId]) {
			return formMapper[formId]();
		}
		return;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
