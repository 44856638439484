import { Col, Container, Modal, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import { ConfirmationDialogUtils } from './utils';

/**
 * ConfirmationDialog.
 * @param {Object} need to have "entity" (event, flag, etc), "onConfirm" (function to do on YES click), "onCancel" (function to do on NO click)
 * and can have "customDelMessage" and "customTitle" to customize title and body texts.
 * @returns {ReactElement} returns JSX with the component ConfirmationDialog.
 */

const ConfirmationDialog = ({ entity, onConfirm, onCancel, customDelMessage, customTitle }) => {
	const { buildTitle, getModalBody } = ConfirmationDialogUtils();

	// eslint-disable-next-line react-hooks/exhaustive-deps

	return (
		<Modal
			show
			onHide={onCancel}
			backdrop="static"
			keyboard={false}
			size="md"
			aria-labelledby="confirmation-modal-title"
			centered
		>
			<Modal.Header closeButton closeLabel={`${buildTitle({ entity, customTitle })} Close Dialog`}>
				<Modal.Title className={styles.title} id="confirmation-modal-title">
					{buildTitle({ entity, customTitle })}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row className="align-items-center">
						<Col xs={2}>
							<h1 className="text-danger">
								<i className="fas fa-exclamation-circle"></i>
							</h1>
						</Col>
						<Col>
							{getModalBody({
								entity,
								customDelMessage
							})}
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onConfirm} variant="primary" className={styles.button}>
					Yes
				</Button>
				<Button onClick={onCancel} variant={'secondary'} className={styles.button}>
					No
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

ConfirmationDialog.propTypes = {
	entity: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	customTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	customDelMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

export default ConfirmationDialog;
