import { Form } from 'react-bootstrap';
import React from 'react';

import PropTypes from 'prop-types';
import { RequiredMark } from '../common';

export const FormSelect = ({
	id,
	label = '',
	value,
	options,
	className,
	onChange,
	required,
	name
}) => {
	return (
		<Form.Group controlId={id}>
			<Form.Label>
				{label}
				{required && <RequiredMark />}
			</Form.Label>
			<Form.Select
				autoComplete="off"
				name={name}
				className={className}
				onChange={(e) => onChange(e.target.value)}
				value={value}
			>
				{options?.map((option, index) => (
					<option value={option.value} key={`${option.value}-${index}`}>
						{option.label}
					</option>
				))}
			</Form.Select>
		</Form.Group>
	);
};

FormSelect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	className: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool
};
