import { BASE_URL } from '../../utils/apiUtils';
import { HttpService } from '../HttpService';

export const documentSources = (id) => {
	const documentViewSource = `/api/document-filing/document-filing/${id}`;
	const formMaintenanceSource = `/api/form-management/form/${id}/document`;
	return { documentViewSource, formMaintenanceSource };
};

export const PDFTronServices = () => {
	const { get } = HttpService();

	const getInfoDownloadFile = async (source) => {
		try {
			const url = `${BASE_URL}${source}`;
			const response = await get(url, 'getInfoDownloadFile', []);

			if (response.status === 200) {
				return response.data;
			}
		} catch (error) {
			console.log(error);
		}

		return null;
	};
	return { getInfoDownloadFile };
};
