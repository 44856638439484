import { useRef, useState, useEffect, useMemo } from 'react';

export function useIsInViewport(ref, items) {
	const [isIntersecting, setIsIntersecting] = useState(false);

	const observer = useMemo(
		() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
		[]
	);

	useEffect(() => {
		if (ref && ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref && ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [ref, observer, items]);

	return isIntersecting;
}

export function InfiniteScrollRows({ items, renderRow, rowSize, disableInfiniteScroll }) {
	const [finalItems, setFinalItems] = useState([]);
	const loadingLogo = useRef(null);
	const isInViewport1 = useIsInViewport(loadingLogo, items);
	const sameSize = items?.length === finalItems?.length;
	const style = {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		margin: '15px 0px'
	};

	const fetchMoreData = () => {
		const finalSize = finalItems?.length + 25;
		setFinalItems(items?.slice(0, finalSize));
	};

	useEffect(() => {
		const finalSize = rowSize <= 50 ? 50 : 25;
		setFinalItems(items?.slice(0, finalSize));
	}, [items]);

	useEffect(() => {
		finalItems?.length < 25
			? fetchMoreData()
			: setTimeout(() => {
					fetchMoreData();
			  }, 1000);
	}, [isInViewport1]);

	return (
		<>
			{disableInfiniteScroll ? (
				<>{items?.map((i, index) => renderRow(i, index))}</>
			) : (
				<>
					{finalItems?.map((i, index) => renderRow(i, index))}
					{items?.length >= 25 && rowSize >= 50 && !sameSize && (
						<tr>
							<td colSpan={'100%'}>
								<p style={style} ref={loadingLogo}>
									Loading...
								</p>
							</td>
						</tr>
					)}
				</>
			)}
		</>
	);
}
