export const blockInvalidChar = (e) =>
	(['e', 'E', '+', '-', '.'].includes(e.key) || preventZeroes(e)) && e.preventDefault();

const preventZeroes = (e) => e.target.valueAsNumber === 0 && e.key === '0';

export const allowOnlyNumberKeys = (e) => {
	const regex = /^[0-9\b]+$/;

	if (!regex.test(e.key)) {
		e.preventDefault();
	}
};
export const allowUnderScoreAndDash = (value) => {
	const regex = RegExp(/^[a-zA-Z0-9-_]+$/);
	return regex.test(value);
};

export const percentageRegex = /^$|^100(\.0{0,2})? *%?$|^\d{1,2}(\.\d{1,2})? *%?$/;
export const decimalNumberRegex = /^\d*\.?\d*$/;
export const decimalNumberTwoPlaceRegex = /^$|^[0-9]*(\.[0-9]{0,2})?$/;
export const currencyRegex = /\$\d*[0-9]*(\.[0-9]{0,2})?$/;

export const errorClassSelector = (errors, touched, key, addStyles) =>
	errors[key] && touched[key]
		? `is-invalid form-control ${addStyles && addStyles}`
		: `form-control ${addStyles && addStyles}`;

export const optionsMapper = ({ value, label }, i) => (
	<option key={i} value={value}>
		{label}
	</option>
);

export const setFocus = (ref) =>
	setTimeout(() => {
		ref.current?.focus();
	}, 0);
