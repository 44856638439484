import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { generateItemsSplitInPages, sortData } from '../../../services/tableFunctions.js';

/**
 * TableHeadItem.
 * @param {string} colDirection Sort direction of the columns
 * @param {Object[]} data Array of data showed in the table
 * @param {Object} item Individual item with column information
 * @param {boolean} isLastItem Boolean to decide with style will be used on the last item
 * @param {Function} setColDirection Handle colDirection change
 * @param {boolean} showActions Show actions columns
 * @param {boolean} enableServerSort Enables server-side sorting
 * @param {Function} handleServerSort Handles server-side sorting
 * @param {number} length Number of columns in the table
 * @param {Dispatch SetStateAction Array} setTableData set data of the table
 * @param {Dispatch SetStateAction Object} setItemsCalculatedOnFE handle sort columns in the FE
 * @param {Boolean} isSorting boolean if sorting is enabled
 * @param {Dispatch SetStateAction Boolean} setIsSorting handle is sorting
 * @param {number} size size of the items per page
 * @param {Boolean} searchQuery boolean if search query is enabled
 * @returns {ReactElement} returns JSX with the component TableHeadItem.
 */

const TableHeadItem = ({
	colDirection,
	data,
	item,
	lastItem,
	setColDirection,
	showActions,
	enableServerSort,
	handleServerSort,
	length,
	setTableData,
	setItemsCalculatedOnFE,
	size,
	searchQuery,
	isSorting,
	setIsSorting,
	formatSearchValues,
	disableTableSort,
	isSearching,
	parentPage,
	setSortingColumn,
	sortingColumn
}) => {
	const arrowRef = useRef(null);

	useEffect(() => {
		if (typeof searchQuery === 'undefined' && isSorting) {
			handleRotate();
		}

		if (!isSorting) {
			let elements = document.getElementsByClassName('heading-svg');
			for (let i = 0, len = elements.length; i < len; i++) {
				elements[i]?.classList?.remove(styles.descending);
				elements[i]?.classList?.remove(styles.ascending);
			}
		}
	}, [searchQuery, isSorting, setColDirection]);

	const handleRotate = async () => {
		let elements = document.getElementsByClassName('heading-svg');
		for (let i = 0, len = elements.length; i < len; i++) {
			elements[i]?.classList?.remove(styles.descending);
			elements[i]?.classList?.remove(styles.ascending);
		}

		if (enableServerSort && !isSearching) {
			colDirection === 'desc' && arrowRef.current.classList.add(styles.descending);
			colDirection === 'asc' && arrowRef.current.classList.add(styles.ascending);
		} else {
			colDirection === 'desc' && arrowRef.current.classList.add(styles.ascending);
			colDirection === 'asc' && arrowRef.current.classList.add(styles.descending);
		}
		setSortingColumn(item);
		//Sort server-side if enabled o/w sort client-side
		if (enableServerSort && !isSearching) {
			const sortOrder = colDirection === 'desc' ? 'asc' : 'desc';
			setColDirection(sortOrder);
			handleServerSort(
				item.value === 'protected'
					? 'isProtected' + ',' + sortOrder
					: item.value === 'caseCategoryCodeDesc'
					? 'caseCategory' + ',' + sortOrder
					: item.value + ',' + sortOrder
			);
		} else {
			let newTableData = formatSearchValues ? await formatSearchValues(data) : data;

			if (newTableData?.length === 0) return;
			newTableData = sortData(colDirection, setColDirection, newTableData, item);
			const itemsInPages = generateItemsSplitInPages(newTableData, size);
			const currentPage = parentPage || 0;
			setItemsCalculatedOnFE({
				items: newTableData,
				itemsInPages,
				totalPages: itemsInPages?.length,
				currentPage,
				totalElements: newTableData?.length,
				size
			});
			setTableData(itemsInPages?.[currentPage]);
			setIsSorting(true);
		}
	};

	if (disableTableSort || item?.disableColumnSort) {
		return (
			<>
				<th
					tabIndex={0}
					scope="col"
					id={`row-${item.value}`}
					data-testid={item.heading}
					data-field={item.value}
					className={`${length === 2 && !showActions ? 'w-50 ' : ''}align-middle`}
				>
					{item.heading}
				</th>
				<th style={{ display: 'none' }}>Accesibility Column Fix</th>
			</>
		);
	}

	return (
		<>
			<th
				tabIndex={0}
				scope="col"
				role="columnheader"
				id={`row-${item.value}`}
				data-testid={item.heading}
				data-field={item.value}
				data-sortable="true"
				onClick={() => handleRotate()}
				onKeyDown={(e) => {
					if (e.code === 'Enter') handleRotate();
				}}
				className={`${length === 2 && !showActions ? 'w-50 ' : ''}align-middle ${styles.cPointer}`}
			>
				<span className="d-flex justify-content-center align-items-baseline">
					<span>{item.heading}</span>
					<span
						tabIndex={item?.tabIndex ? item.tabIndex : 0}
						data-heading={item.heading}
						ref={arrowRef}
						className={`heading-svg ${styles.sortBy} d-flex flex-column`}
						role="button"
						aria-label={colDirection === 'desc' ? `ascending-order` : `descending-order`}
					>
						<i
							className={`fas fa-sort-up ${
								sortingColumn?.value === item.value && colDirection === 'asc'
									? 'text-dark'
									: 'text-muted'
							}`}
							style={{ height: '0' }}
						></i>
						<i
							className={`fas fa-sort-down ${
								sortingColumn?.value === item.value && colDirection === 'desc'
									? 'text-dark'
									: 'text-muted'
							}`}
							style={{ height: '0' }}
						></i>
					</span>
				</span>
			</th>
			<th style={{ display: 'none' }}>Accesibility Column Fix</th>
		</>
	);
};

TableHeadItem.propTypes = {
	colDirection: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.object),
	item: PropTypes.object.isRequired,
	lastItem: PropTypes.bool.isRequired,
	setColDirection: PropTypes.func,
	showActions: PropTypes.bool
};

export default TableHeadItem;
