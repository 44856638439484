import { Button } from 'react-bootstrap';
import styles from '../Alerts/style.module.scss';

const AlertCloseTab = () => {
	return <></>;
};

const Body = ({ content }) => {
	return (
		<div>
			{content || <p>Are you sure you want to cancel? Any changes you made will not be saved.</p>}
		</div>
	);
};

const Footer = ({ closeTab, closeModal }) => {
	return (
		<>
			<Button onClick={() => closeTab()} variant="primary" className={styles.button}>
				Yes
			</Button>

			<Button onClick={() => closeModal()} variant="secondary" className={styles.button}>
				No
			</Button>
		</>
	);
};

AlertCloseTab.Body = Body;
AlertCloseTab.Footer = Footer;

export default AlertCloseTab;
