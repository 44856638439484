import axios from 'axios';
import { SUCCESFUL_REQUEST_CODE } from '../../utils';

export const loginActivate = async (user, token, action) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/login-tracker?username=${user}&action=${action}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${token}`
				}
			}
		);

		if (SUCCESFUL_REQUEST_CODE.includes(res.status)) {
			return res.data;
		} else {
			return {
				isActive: false
			};
		}
	} catch (error) {
		console.error(error);
	}
};
