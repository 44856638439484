import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { RequiredMark } from '../common';

export const FormDateInput = ({ id, label = '', value, onChange, required, maxDate }) => {
	return (
		<Form.Group controlId={id}>
			<Form.Label>
				{label}
				{required && <RequiredMark />}
			</Form.Label>
			<Form.Control
				type="date"
				value={value}
				onChange={({ target }) => onChange(target.value)}
				max={maxDate}
				min={'1900-01-01'}
			/>
		</Form.Group>
	);
};

FormDateInput.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool
};
