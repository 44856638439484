import React from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';
import { CheckboxInput } from '../common';

export const FieldFormCheckboxInput = ({
	id,
	label = '',
	required,
	onChange,
	isDisabled = false
}) => (
	<Field name={id}>
		{({ field, form }) => (
			<CheckboxInput
				name={id}
				id={id}
				label={label}
				checked={field.value}
				onChecked={(value) => {
					form.setFieldValue(field.name, value);
					onChange?.(value);
				}}
				required={required}
				disabled={isDisabled}
			/>
		)}
	</Field>
);

FieldFormCheckboxInput.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	required: PropTypes.bool
};
