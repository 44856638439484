import React, { useRef, useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import Styles from './styles.module.scss';
import { useEffect } from 'react';
import { addYears } from 'date-fns';
import DatePickerMask from '../DatePickerMask';

export const NewDatePicker = ({
	name,
	placeholder = 'mm/dd/yyyy',
	onChange,
	onBlur,
	initialValue,
	isInvalid,
	isValid,
	disabled,
	maxDate = addYears(new Date(), 100),
	minDate = new Date(1900, 0, 1),
	required,
	reInitialize = false,
	className = '',
	dateFormat = 'MM/dd/yyyy',
	showTimeSelect = false,
	showTimeSelectOnly = false,
	selectsStart = false,
	selectsEnd = false,
	startDate,
	endDate,
	arialabel,
	minTime = undefined,
	maxTime = undefined,
	withPortalId,
	showMonthYearPicker = false,
	isClearable,
	autoSaveValue,
	timeFormat,
	isAutoDate = false
}) => {
	const ref = useRef(null);
	const initialDate = initialValue ?? new Date();
	const [date, setDate] = useState(initialDate);

	const handleDataChange = (value) => {
		try {
			if (onChange) {
				onChange({
					target: {
						name,
						value: !isNaN(Date.parse(value)) ? value.toISOString() : value ?? undefined,
						raw: value
					}
				});
			}
			setDate(value);
		} catch (error) {
			console.error('Preventing boundary error:', error);
		}
	};

	useMemo(() => {
		if (!initialValue) {
			setDate(undefined);
		} else if (disabled || reInitialize) {
			setDate(initialValue);
		}
	}, [initialValue, disabled, reInitialize]);

	useEffect(() => {
		const finalValue = autoSaveValue && autoSaveValue !== '' ? new Date(autoSaveValue) : date;
		setDate(finalValue);
	}, [autoSaveValue]);

	useEffect(() => {
		if (isAutoDate && (autoSaveValue === '' || autoSaveValue === undefined)) {
			handleDataChange(new Date());
		}
	}, []);

	const onPressEnter = (keyEvent) => {
		if (keyEvent.code.toLowerCase() === 'enter') {
			keyEvent.preventDefault();
			const datepickerElement = ref.current;
			if (!datepickerElement.state.open) datepickerElement.setOpen(true);
		}
	};
	return (
		<div style={{ zIndex: '9999  !important' }}>
			<DatePicker
				preventOpenOnFocus
				portalId={withPortalId ? 'react-date-picker-id' : undefined}
				popperClassName={withPortalId ? Styles.reactDatepickerPopper : undefined}
				enableTabLoop={false}
				placeholderText={placeholder}
				dateFormat={dateFormat}
				showTimeSelect={showTimeSelect}
				showTimeSelectOnly={showTimeSelectOnly}
				timeFormat={timeFormat ? timeFormat : 'HH:mm a'}
				timeIntervals={15}
				timeCaption="Time"
				selectsStart={selectsStart}
				selectsEnd={selectsEnd}
				peekNextMonth
				selected={date || null}
				showMonthDropdown
				showYearDropdown
				showMonthYearPicker={showMonthYearPicker}
				onBlur={
					onBlur
						? onBlur
						: (e) => {
								if (dateFormat && dateFormat.toString().toLowerCase() === 'mm/dd/yyyy') {
									onChange(e.target.value ? new Date(e.target.value) : null);
								} else {
									onChange(e);
								}
						  }
				}
				dropdownMode="select"
				ref={ref}
				onChange={handleDataChange}
				className={`form-control ${disabled ? 'notAllowed' : ''} ${className} ${
					isInvalid ? 'is-invalid' : isValid ? 'is-valid' : ''
				}`}
				maxDate={maxDate}
				name={name}
				id={name}
				disabled={disabled}
				autoComplete="off"
				minDate={minDate}
				required={required}
				customInput={
					<DatePickerMask
						showtimeselectonly={String(showTimeSelectOnly)}
						disabled={String(disabled)}
						ref={ref}
						isinvalid={String(isInvalid)}
						arialabel={arialabel}
						isvalid={String(isValid)}
						mindate={minDate}
						maxdate={maxDate}
						rendericon={'true'}
						dateformat={dateFormat}
					/>
				}
				startDate={startDate}
				endDate={endDate}
				minTime={minTime}
				maxTime={maxTime}
				isClearable={isClearable}
				onKeyDown={onPressEnter}
			/>
		</div>
	);
};

NewDatePicker.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	maxDate: PropTypes.instanceOf(Date),
	initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	isInvalid: PropTypes.bool,
	disabled: PropTypes.bool,
	minDate: PropTypes.instanceOf(Date),
	reInitialize: PropTypes.bool,
	dateFormat: PropTypes.string,
	showTimeSelect: PropTypes.bool,
	selectsStart: PropTypes.bool,
	selectsEnd: PropTypes.bool,
	startDate: PropTypes.instanceOf(Date),
	endDate: PropTypes.instanceOf(Date),
	maxTime: PropTypes.instanceOf(Date),
	minTime: PropTypes.instanceOf(Date),
	withPortalId: PropTypes.bool,
	showMonthYearPicker: PropTypes.bool
};

export default NewDatePicker;
