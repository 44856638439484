import { useState } from 'react';

const useToast = () => {
	const [toast, setIsToastOpen] = useState({
		text: '',
		show: false,
		type: ''
	});

	const openToast = (text, type) => {
		setIsToastOpen({ text, type, show: true });
	};

	const closeToast = () => {
		setIsToastOpen({ ...toast, show: false });
	};

	return [toast, openToast, closeToast];
};

export default useToast;
