export const PARTY_LOOKUP_RESULT_TABLE_COLUMNS = [
	{ heading: 'Selected', value: 'selected' },
	{ heading: 'Name', value: 'name' },
	{ heading: 'Organization', value: 'organizationName' },
	{ heading: 'Date of Birth', value: 'birthDate' },
	{ heading: 'Gender', value: 'gender' },
	{ heading: 'Identification', value: 'identification' },
	{ heading: 'Phone', value: 'phone' },
	{ heading: 'Primary Address', value: 'primaryAddress' }
];
