import { FILES_CONTENT_TYPES_VIDEO } from '../constants';

export const checkImageFile = (file) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(true);
		img.onerror = () => reject(false);
		img.src = URL.createObjectURL(file);
	});
};

export const checkAudioFile = (file) => {
	return new Promise((resolve, reject) => {
		const audio = new Audio();
		audio.oncanplay = () => resolve(true);
		audio.onerror = () => reject(false);
		audio.src = URL.createObjectURL(file);
	});
};

export const checkTiffFile = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const buffer = e.target.result;
			const uint8Array = new Uint8Array(buffer);
			// The first two bytes of the file header should be the ASCII values for the letters "II" or "MM" (73 & 77 in decimal)
			const isValidTiff =
				(uint8Array[0] === 73 && uint8Array[1] === 73) ||
				(uint8Array[0] === 77 && uint8Array[1] === 77);
			if (isValidTiff) resolve(true);
			reject(false);
		};
		reader.readAsArrayBuffer(file);
	});
};

export const checkVideoFile = (file) => {
	return new Promise((resolve, reject) => {
		if (!FILES_CONTENT_TYPES_VIDEO.includes(file.type)) {
			reject(new Error('Invalid file type'));
		}
		const reader = new FileReader();
		reader.onload = () => resolve(true);
		reader.onerror = () => reject(false);
		reader.readAsArrayBuffer(file);
	});
};
