import { Button } from 'react-bootstrap';
import styles from './styles.module.scss';

const AlertSuccessDialog = () => {
    return <></>
};

const Body = ({ content }) => {
    return (
        <div>
            <p>
                {content}
            </p>
        </div>
    );
};

const Footer = ({ closeModal }) => {
    return (
        <>
            <Button
                onClick={() => closeModal()}
                variant="primary"
                className={styles.button}
            >
                Close
            </Button>
        </>
    );
}

AlertSuccessDialog.Body = Body;
AlertSuccessDialog.Footer = Footer;

export default AlertSuccessDialog;