import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Yup from 'yup';
// Global Imports
import { Dialog } from '../common';
import { HttpService } from '../../services/HttpService';
import { BASE_URL } from '../../utils';
import { TOAST_TYPES } from '../ResponseAlert';

// local imports
import CreateSignatureTextForm from './Forms/CreateSignatureText';
import CreateSignatureDrawForm from './Forms/CreateSignatureDraw';
import CreateSignatureImageForm from './Forms/CreateSignatureImage';
import CurrentSignature from './Forms/CurrentSignature';

const CreateSignatureModal = ({
	username,
	isModalActive,
	onClose,
	openToast = () => {},
	type = ''
}) => {
	const { post } = HttpService();
	const [signatureImage, setSignatureImage] = useState(null);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [alert, setAlert] = useState(false);
	const [termsAlert, setTermsAlert] = useState(false);
	const [handleFormSubmit, setHandleFormSubmit] = useState('signatureDrawForm');
	const validationSchema = Yup.object().shape({
		files: Yup.mixed().required('File is required'),
		signatureAuthorization: Yup.bool().oneOf([true], 'Terms must be accepted')
	});
	const [form, setForm] = useState();

	useEffect(() => {
		if (isModalActive) {
			setForm(type === 'edit' ? 'currentSignature' : 'drawSignature');
		}
	}, [isModalActive, type]);

	const renderForm = () => {
		return COMPONENTS[form];
	};

	const handleShowCurrentSignatureForm = () => {
		setForm('currentSignature');
	};
	const handleShowDrawSignatureForm = () => {
		setAcceptTerms(false);
		setAlert(false);
		setTermsAlert(false);
		setSignatureImage(null);
		setHandleFormSubmit('signatureDrawForm');
		setForm('drawSignature');
	};
	const handleShowTextToImageForm = () => {
		setAcceptTerms(false);
		setSignatureImage(null);
		setAlert(false);
		setTermsAlert(false);
		setHandleFormSubmit('signatureTextForm');
		setForm('textSignature');
	};
	const handleShowImageForm = () => {
		setAcceptTerms(false);
		setSignatureImage(null);
		setAlert(false);
		setTermsAlert(false);
		setHandleFormSubmit('signatureImageForm');
		setForm('imageSignature');
	};

	const handleSubmit = async () => {
		if (acceptTerms && signatureImage) {
			validationSchema
				.validate({ files: signatureImage, signatureAuthorization: acceptTerms })
				.then(async (res) => {
					const formData = new FormData();
					formData.append('file', res.files, `${username}-new-signature.png`);
					formData.append('signatureAuthorization', res.signatureAuthorization);
					const result = await post(
						`${BASE_URL}/api/user/profile/signature?username=${username}`,
						formData,
						'newUserSignature'
					);
					if (result?.status >= 200 && result?.status < 300) {
						setAcceptTerms(false);
						onClose({
							message: 'Electronic signature has been successfully created.',
							type: TOAST_TYPES.SUCCESS
						});
						openToast(
							`Electronic signature has been successfully ${
								type === 'edit' ? 'updated' : 'created'
							}.`,
							TOAST_TYPES.SUCCESS
						);
					} else {
						onClose({ message: 'Unable to update signature.', type: TOAST_TYPES.DANGER });
						openToast(
							`Unable to ${type === 'edit' ? 'update' : 'create'} signature.`,
							TOAST_TYPES.DANGER
						);
					}
				});
		} else if (!acceptTerms && signatureImage) {
			setTermsAlert(true);
		} else if (acceptTerms && !signatureImage) {
			setAlert(true);
		} else {
			setTermsAlert(true);
			setAlert(true);
		}
	};
	const FUNCTIONS = {
		drawSignature: handleSubmit,
		textSignature: handleSubmit,
		imageSignature: handleSubmit
	};
	const onConfirm = () => {
		FUNCTIONS[form]();
	};

	let COMPONENTS = {
		drawSignature: (
			<CreateSignatureDrawForm
				onSubmit={handleSubmit}
				setAcceptTerms={setAcceptTerms}
				acceptTerms={acceptTerms}
				setSignatureImage={setSignatureImage}
				validate={validationSchema}
				termsAlert={termsAlert}
			/>
		),
		textSignature: (
			<CreateSignatureTextForm
				onSubmit={handleSubmit}
				setAcceptTerms={setAcceptTerms}
				acceptTerms={acceptTerms}
				setSignatureImage={setSignatureImage}
				validate={validationSchema}
				alert={alert}
				termsAlert={termsAlert}
			/>
		),
		imageSignature: (
			<CreateSignatureImageForm
				onSubmit={handleSubmit}
				setAcceptTerms={setAcceptTerms}
				acceptTerms={acceptTerms}
				setSignatureImage={setSignatureImage}
				validate={validationSchema}
				alert={alert}
				termsAlert={termsAlert}
			/>
		)
	};

	if (type === 'edit') {
		COMPONENTS['currentSignature'] = <CurrentSignature />;
	}

	return (
		<Dialog
			title="Signature"
			isShow={isModalActive}
			onClose={() => onClose({})}
			onCancelButton={() => onClose({})}
			onConfirm={onConfirm}
			isAForm
			formId={handleFormSubmit}
			enableSubmit={form === 'currentSignature' ? false : true}
		>
			<Row>
				<Col xs={3} lg={2}>
					{type === 'edit' ? (
						<Row>
							<OverlayTrigger
								rootClose
								placement="right"
								overlay={<Tooltip>{'Current Signature'}</Tooltip>}
							>
								<Button
									variant="outline-secondary"
									size="lg"
									onClick={handleShowCurrentSignatureForm}
									aria-label="Current Signature"
								>
									<i className="fas fa-signature"></i>
								</Button>
							</OverlayTrigger>
						</Row>
					) : null}
					<Row>
						<OverlayTrigger
							rootClose
							placement="right"
							overlay={<Tooltip>{'Sign by hand'}</Tooltip>}
						>
							<Button
								variant="outline-secondary"
								size="lg"
								onClick={handleShowDrawSignatureForm}
								aria-label="Sign by hand"
							>
								<i className="fas fa-pencil-alt"></i>
							</Button>
						</OverlayTrigger>
					</Row>
					<Row>
						<OverlayTrigger
							rootClose
							placement="right"
							overlay={<Tooltip>{'Sign with keyboard'}</Tooltip>}
						>
							<Button
								variant="outline-secondary"
								size="lg"
								onClick={handleShowTextToImageForm}
								aria-label="Sign with keyboard"
							>
								<i className="fas fa-keyboard"></i>
							</Button>
						</OverlayTrigger>
					</Row>
					<Row>
						<OverlayTrigger
							rootClose
							placement="right"
							overlay={<Tooltip>{'Upload signature image'}</Tooltip>}
						>
							<Button
								variant="outline-secondary"
								size="lg"
								onClick={handleShowImageForm}
								aria-label="Upload signature image"
							>
								<i className="fas fa-camera"></i>
							</Button>
						</OverlayTrigger>
					</Row>
				</Col>
				<Col xs={9} lg={10}>
					<Container className="p-6">{renderForm()}</Container>
				</Col>
			</Row>
		</Dialog>
	);
};

export default CreateSignatureModal;
