import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import ResponseAlert, { TOAST_TYPES } from '../../components/ResponseAlert';
import { SUCCESFUL_REQUEST_CODE } from '../../utils';
import { PaymentService } from '../../services/PaymentService';
import PDFTronComponent from '../../components/PDFTronComponent';

const FullReceiptModal = ({
	show,
	setShow,
	paymentId,
	buttonText,
	recipientData,
	caseId,
	setMainToast,
	partyId,
	refundId
}) => {
	const { postSendReceiptNotification, postSendRefundReceiptNotification } = PaymentService();

	const [toast, setToast] = useState({
		type: '',
		text: '',
		show: false
	});

	const [webViewer, setWebViewer] = useState();
	const [isLoadingDocument, setIsLoadingDocument] = useState(false);
	const [instanceDocumentViewer, setInstanceDocumentViewer] = useState();
	const [loadedDocInfo, setLoadedDocInfo] = useState();

	const downloadDoc = () => {
		const { UI } = webViewer;
		UI.downloadPdf({ filename: 'Payment Receipt' });
	};

	const postFunction = async (body) => {
		try {
			const res = refundId
				? await postSendRefundReceiptNotification(body)
				: await postSendReceiptNotification(body, paymentId);

			if (SUCCESFUL_REQUEST_CODE.includes(Number(res?.status))) {
				setMainToast({
					text: 'The Receipt was sent successfully.',
					show: true,
					type: TOAST_TYPES.SUCCESS
				});
			} else {
				const error = res.data.errors ?? `Error: unable to send Receipt.`;

				setMainToast({
					text: error,
					show: true,
					type: TOAST_TYPES.DANGER
				});
			}
		} catch (body) {
			setMainToast({
				text: `Error: unable to send Receipt.`,
				show: true,
				type: TOAST_TYPES.DANGER
			});
		}
		setShow(false);
	};

	const printFunction = () => {
		const { UI } = webViewer;
		UI.setPrintQuality(5);
		UI.print();
	};

	return (
		<>
			<Modal
				size="lg"
				show={show}
				onHide={() => {
					setShow(false);
				}}
			>
				<Modal.Header closeLabel={`Full Receipt Close Dialog`} closeButton></Modal.Header>
				<Modal.Body className="d-flex justify-content-center" id="printThis">
					<ResponseAlert
						show={toast.show}
						text={toast.text}
						type={toast.type}
						delay={3000}
						close={() => {
							setToast({
								...toast,
								show: false
							});
						}}
					/>
					<PDFTronComponent
						isLoadingDocument={isLoadingDocument}
						setIsLoadingDocument={setIsLoadingDocument}
						instanceDocumentViewer={instanceDocumentViewer}
						setInstanceDocumentViewer={setInstanceDocumentViewer}
						setInfoDocumentLoaded={(data) =>
							setLoadedDocInfo((prevState) => ({ ...prevState, ...data }))
						}
						webViewer={webViewer}
						setWebViewer={setWebViewer}
						existDocument
						source={
							refundId
								? `/api/refund/generate/disbursement-receipt/${refundId}`
								: `/api/report-generation/payment-receipt?case-id=${caseId}&payment-id=${paymentId}&responsible-party=${
										partyId ? partyId : 0
								  }`
						}
						fullAPI
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							if (buttonText === 'Print' || buttonText === 'Download') {
								if (isMobile) {
									downloadDoc();
									return;
								}
								printFunction();
							} else if (buttonText === 'Send Email') {
								const body = refundId
									? {
											method: 'EMAIL',
											recipient: recipientData,
											receiptId: Number(refundId)
									  }
									: {
											method: 'EMAIL',
											objectKey: loadedDocInfo.objectKey,
											recipient: recipientData,
											caseId: Number(caseId)
									  };
								postFunction(body);
							} else if (buttonText === 'Send SMS') {
								const body = refundId
									? {
											method: 'SMS',
											recipient: recipientData,
											receiptId: Number(refundId)
									  }
									: {
											method: 'SMS',
											objectKey: loadedDocInfo.objectKey,
											recipient: recipientData,
											caseId: Number(caseId)
									  };
								postFunction(body);
							}
						}}
					>
						{/* TODO: Add dynamic submit function to change between SMS, Email, and Print when Twillio integration is ready */}
						{buttonText}
					</Button>
					<Button variant="secondary" onClick={() => setShow(false)}>
						{isMobile ? 'Close' : 'Cancel'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default FullReceiptModal;
