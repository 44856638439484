import PropTypes from 'prop-types';

/**
 * @param {string} id Id passed to the button component
 * @param {string} text Text to show inside the button
 * @param {function} onClick Function triggered on clicked
 * @returns {JSX} Returns a JSX Element button that is used inside Side Panel
 */

const SidePanelButton = ({ id, text, onClick, disabled = false }) => (
	<button className="btn btn-primary" id={id} type="button" onClick={onClick} disabled={disabled}>
		{text}

		{disabled && (
			<div className="spinner-border spinner-border-sm ms-2" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		)}
	</button>
);

SidePanelButton.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

export default SidePanelButton;
