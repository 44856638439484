import styles from './styles.module.scss';

const LoaderTable = () => (
	<div className={`spinner-container ${styles.loaderContainer}`}>
		<div className="spinner-grow text-primary" role="status">
			<span className="visually-hidden">Loading...</span>
		</div>
		<div className="spinner-grow text-primary" role="status"></div>
		<div className="spinner-grow text-primary" role="status"></div>
	</div>
);

export default LoaderTable;
