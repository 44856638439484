import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, parse, isValid, addYears } from 'date-fns';
import DatePicker from 'react-datepicker';
import styles from './styles.module.scss';
import DatePickerMask from '../DatePickerMask';

/**
 * Date Input component.
 * @param {String} dateFormat Actual date format you expect , predefine 'MM/dd/yyyy'.
 * @param {Boolean} disabled  To enable/disable the input date.
 * @param {*} id Id to match the date picker.
 * @param {String} incomingFormat Incoming date format , predefine 'yyyy-MM-dd'.
 * @param {Object<Date>} maxDate Max date allowed.
 * @param {Object<Date>} minDate Min date allowed.
 * @param {String} name Name to match the date picker.
 * @param {Function} onChange Function to set the value.
 * @param {String} placeholder Current placeholder for the input.
 * @param {String} returnFormat Return format, predefine 'yyyy-MM-dd'.
 * @param {String} value Value that stores the date.
 * @param {Boolean} required Add the required label so it can comply with the arc tools.
 * @returns {ReactElement} returns JS with the component DateInput.
 */

export const DateInput = ({
	dateFormat = 'MM/dd/yyyy',
	disabled,
	id,
	incomingFormat = 'yyyy-MM-dd',
	isInvalid,
	maxDate = addYears(new Date(), 100),
	minDate = new Date(1900, 0, 1),
	name,
	onChange,
	placeholder = 'MM/dd/yyyy',
	returnFormat = 'yyyy-MM-dd',
	value,
	required,
	isAutoDate,
	arialabel
}) => {
	const ref = useRef(null);
	const [date, setDate] = useState();

	useEffect(() => {
		if (value && value !== '') {
			const dateFromString = parse(value, incomingFormat, new Date());
			setDate(dateFromString);
		} else {
			setDate();
		}
	}, [value]);

	const handleChange = (value) => {
		if (value === null) {
			onChange(null);
			return;
		}

		if (isValid(value)) {
			const dateAsString = format(value, returnFormat);
			onChange(dateAsString);
		}
	};
	useEffect(() => {
		if (isAutoDate && value === '') {
			setDate(new Date());
			const dateAsString = format(new Date(), returnFormat);
			onChange(dateAsString);
		}
	}, [isAutoDate]);

	return (
		<div className={styles.container}>
			<DatePicker
				autoComplete="off"
				className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
				dateFormat={dateFormat}
				disabled={disabled}
				dropdownMode="select"
				id={id}
				maxDate={maxDate}
				minDate={minDate}
				name={name}
				onChange={handleChange}
				peekNextMonth
				placeholderText={placeholder}
				ref={ref}
				selected={date}
				showMonthDropdown
				showYearDropdown
				required={required}
				customInput={
					<DatePickerMask
						ref={ref}
						arialabel={arialabel}
						date={date}
						mindate={minDate}
						maxdate={maxDate}
						rendericon={'false'}
						dateformat={dateFormat}
					/>
				}
			/>
			{!isInvalid && (
				<i
					className={`far fa-calendar ${styles.icon}`}
					onClick={() => ref.current.onInputClick()}
				/>
			)}
		</div>
	);
};

DateInput.propTypes = {
	dateFormat: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.any,
	incomingFormat: PropTypes.string,
	maxDate: PropTypes.instanceOf(Date),
	minDate: PropTypes.instanceOf(Date),
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	returnFormat: PropTypes.string,
	value: PropTypes.string,
	required: PropTypes.bool
};

export default DateInput;
