import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Col, ListGroup, Row, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useRequestsWithProgress } from '../../../../hooks/useRequestsWithProgress';

import { useAuthContext } from '../../../../hooks/useAuthContext';

import { documentSources } from '../../../../services/PdfServices/PDFTronServices';
import { DocumentViewService } from '../../../../services/DocumentViewService';

import { CenterPanel, PageHeading, RightPanel } from '../../../../components/layout';
import { isMediaFile } from '../../../../constants';
import { TOAST_TYPES } from '../../../../components/ToastAlert';
import AlertDialog from '../../../../components/AlertDialog';
import CaseBlock from '../../../../components/CaseBlock';
import CreateSignatureModal from '../../../../components/CreateSignatureDialog';
import EditActionButtons from '../../../../components/EditActionButtons';
import LoaderComponent from '../../../../components/Loader';
import PDFTronComponent from '../../../../components/PDFTronComponent';
import ResponseAlert from '../../../../components/ResponseAlert';

import { AlertCloseTab, AlertErrorDialog } from '../../../DocumentView/Alerts/index';
import AnnotateDocumentButton from '../../../DocumentView/InsertAnnotations/AnnotateDocumentButton';
import InsertDocument from '../../../DocumentView/InsertDocument/SubmitFile';
import ModalDocumentDescription from '../../../DocumentView/DocumentDescription';
import ModalDocumentInformation from '../../../DocumentView/DocumentInformation';
import ModalInsertAnnotations from '../../../DocumentView/InsertAnnotations';
import { useDispatch } from 'react-redux';
import { addFile } from '../../../../redux/reducers/caseBatchUpdate.reducer';
import { useEffect } from 'react';
import { toolBarPdfTron } from '../../../../components/PDFTronComponent/constants';
import ScanButton from './ScanButton';

const DocumentView = () => {
	const loadingPage = useRef(null);
	const navigate = useNavigate();
	const [closeRightPanel] = useOutletContext();
	const dispatch = useDispatch();

	const userInfo = useSelector((state) => state?.user?.userInfo);
	// TODO - check if there is a userInfo / user logged in
	const { username } = userInfo;
	const { token } = useAuthContext();

	const location = useLocation();
	const locationState = location.state;
	const caseId = locationState?.caseId;
	const batchEventId = locationState?.batchEventId;
	const description = locationState?.documentDescription;
	const documentFilingId = locationState?.documentFilingId;
	const fileStoredURL = locationState?.fileStoredURL;
	const sourceView = !documentFilingId ? locationState?.source : undefined;

	const { documentViewSource } = documentSources(documentFilingId);
	const { progress } = useRequestsWithProgress();
	const { getUserAuthorizedSignatures } = DocumentViewService();

	const [documentDescription, setDocumentDescription] = useState(description || null);
	const [existDocument, setExistDocument] = useState(locationState?.existDocument);
	const [flagAnnotation, setFlagAnnotation] = useState(0);
	const [imageSelection, setImageSelection] = useState();
	const [insertDocumentModalShow, setInsertDocumentModalShow] = useState(false);
	const [isLoadingDocument, setIsLoadingDocument] = useState(false);
	const [mediaFile, setMediaFile] = useState(null);
	const [otherFilesFormat, setOtherFilesFormat] = useState(false);
	const [typeAnnotation, setTypeAnnotation] = useState();

	const [currentAnnotationsDocument, setCurrentAnnotationsDocument] = useState(0);
	const [mousePointer, setMousePointer] = useState();

	const [webViewer, setWebViewer] = useState();
	const [existSignature, setExistSignature] = useState(false);
	const [instanceDocumentViewer, setInstanceDocumentViewer] = useState();
	const [isSaveDocument, setIsSaveDocument] = useState(false);
	const [signaturesAuthorized, setSignaturesAuthorized] = useState([]);

	const [enableButton, setEnableButton] = useState(false);
	const [progressDocumentview, setProgressDocumentview] = useState(0);
	const [showDocumentAlert, setShowDocumentAlert] = useState(false);
	const [showSigturareModal, setShowSignatureModal] = useState(false);

	const [alert, setAlert] = useState({
		title: '',
		body: <></>,
		footer: <></>,
		type: 'error',
		onClose: () => {}
	});
	const [fileLoaded, setFileLoaded] = useState({
		title: null,
		type: null,
		file: null,
		pages: 0,
		annotationsAdded: null
	});
	const [toast, setToast] = useState({
		type: '',
		text: '',
		show: false
	});

	useEffect(() => {
		if (!imageSelection) return;

		insertAnnotationSelected();
	}, [imageSelection]);

	useEffect(() => {
		if (flagAnnotation !== currentAnnotationsDocument) {
			setEnableButton(true);
		} else {
			setEnableButton(false);
		}
	}, [flagAnnotation, currentAnnotationsDocument]);

	useEffect(() => {
		if (webViewer) {
			const { UI } = webViewer;
			const iframeDoc = UI.iframeWindow.document;
			const tabs = iframeDoc.querySelector('[class="TabsHeader"]');
			if (tabs) {
				const addButton = tabs.querySelector('[class="add-button"]');
				addButton.style.visibility = 'hidden';
			}
		}
	}, [webViewer]);

	useEffect(() => {
		if (progressDocumentview !== 100 || !webViewer) return;
		getCurrentAnnotationsOnDocument();
	}, [progressDocumentview, webViewer]);

	useEffect(() => {
		if (!instanceDocumentViewer) return;

		enableOptionsDocViewer();

		// eslint-disable-next-line
	}, [instanceDocumentViewer]);

	useEffect(() => {
		if (instanceDocumentViewer && !signaturesAuthorized) {
			getSignatures();
		}
	}, [signaturesAuthorized]);

	useEffect(() => {
		if (webViewer && fileStoredURL) {
			loadFileFromLocalStore();
		}
	}, [fileStoredURL, webViewer]);

	const insertAnnotationSelected = async () => {
		const dimensions = await getImageDimensions(imageSelection);

		let w = dimensions.w;
		let h = dimensions.h;

		if (w > 300 || h > 150) {
			w = w / 3;
			h = h / 3;
		}

		const { Annotations, annotationManager } = webViewer.Core;
		const stampAnnot = new Annotations.StampAnnotation();
		stampAnnot.PageNumber = !mousePointer ? 1 : mousePointer.pageNumber;
		stampAnnot.X = typeAnnotation === 'SIGN' ? 27 : 100;
		stampAnnot.Y = typeAnnotation === 'SIGN' ? 30 : 250;
		stampAnnot.Width = w;
		stampAnnot.Height = h;
		const keepAsSVG = true;

		stampAnnot.setImageData(imageSelection, keepAsSVG);
		stampAnnot.Author = annotationManager.getCurrentUser();

		annotationManager.addAnnotation(stampAnnot);
		annotationManager.redrawAnnotation(stampAnnot);

		setImageSelection(null);
		setSignaturesAuthorized(null);
		closeRightPanel();
	};

	const getCurrentAnnotationsOnDocument = async () => {
		const { annotationManager } = webViewer.Core;
		const size = await annotationManager.getAnnotationsList().length;

		setCurrentAnnotationsDocument(size);
	};

	const enableOptionsDocViewer = () => {
		webViewer.UI.enableElements(['downloadButton', toolBarPdfTron.ANNOTATION_POP_UP]);
		webViewer.UI.disableElements([
			'annotationCommentButton',
			'annotationStyleEditButton',
			'linkButton'
		]);

		webViewer.UI.setZoomLevel(1);

		isMobile ? webViewer.UI.closeElements(['leftPanel']) : webViewer.UI.openElements(['leftPanel']);
		existDocument && setIsSaveDocument(true);

		instanceDocumentViewer?.addEventListener('mouseMove', (e) => {
			setMousePointer(instanceDocumentViewer?.getViewerCoordinatesFromMouseEvent(e));
		});

		getSignatures();
	};

	const getImageDimensions = async (file) => {
		return new Promise(function (resolved, rejected) {
			const i = new Image();
			i.onload = function () {
				resolved({ w: i.width, h: i.height });
			};
			i.src = file;
		});
	};

	const loadFileFromLocalStore = async () => {
		setIsLoadingDocument(true);
		try {
			if (sourceView === 'add-document') {
				const fileBlob = await fetch(fileStoredURL).then((r) => r.blob());
				webViewer.UI.loadDocument(fileBlob, {
					extension: 'pdf'
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const viewFormatValidateFile = async (file) => {
		const isFileTypeNotDocument = isMediaFile(file);
		setOtherFilesFormat(isFileTypeNotDocument);

		if (isFileTypeNotDocument) {
			setMediaFile(file);
		}

		hideLoader();
	};

	const compareAnnotations = async (instanceWebViewer) => {
		const { annotationManager } = instanceWebViewer.Core;
		const sizeAnnotations = await annotationManager.getAnnotationsList().length;
		setFlagAnnotation(sizeAnnotations);
	};

	const getDocumentFileLoaded = async () => {
		let name = '';
		let annotationsAdded = [];
		let file = null;
		let pages = 0;
		let type = '';

		if (mediaFile) {
			const fileType = `${mediaFile.name.split('.').pop()}`;
			name = `file.${fileType}`;
			file = await fileToBlob(mediaFile);
			type = fileType;
		} else {
			const doc = instanceDocumentViewer.getDocument();
			pages = doc.getPageCount();
			const { annotationManager } = webViewer.Core;
			const xfdfString = await annotationManager.exportAnnotations();
			annotationsAdded = await annotationManager.getAnnotationsList();

			const data = await doc.getFileData({
				// saves the document with annotations in it
				xfdfString
			});

			const arr = new Uint8Array(data);
			file = new Blob([arr], { type: 'application/pdf' });
			name = `file.pdf`;
			type = 'pdf';
		}

		setFileLoaded((prev) => ({
			title: name,
			type,
			file,
			pages,
			annotationsAdded
		}));

		return { title: name, type, file, pages, annotationsAdded };
	};

	const fileToBlob = (fileData) =>
		new Promise((resolve) => {
			const fReader = new FileReader();
			fReader.onload = () => {
				const blob = new Blob([fReader.result], { type: fileData.type });
				resolve(blob);
			};
			fReader.readAsArrayBuffer(fileData);
		});

	const saveDocument = async () => {
		setIsLoadingDocument(true);
		const { title: name, file, pages, annotationsAdded } = await getDocumentFileLoaded();

		dispatch(
			addFile({
				id: batchEventId,
				documentDescription: documentDescription,
				file: window.URL.createObjectURL(file),
				totalPages: pages,
				name: name,
				dirty: true,
				validBatch: true
			})
		);

		setIsLoadingDocument(false);

		const state = {
			selectedBatchCategory: 'events'
		};
		navigate(`/case-batch`, { state });
	};

	const closeTabNavigation = () => {
		const state = {
			selectedBatchCategory: 'events'
		};
		navigate(`/case-batch`, { state });
	};

	const closeModalToSave = () => {
		setShowDocumentAlert(false);
	};

	const onCancelChanges = () => {
		const contentBody =
			'Are you sure you want to leave this page? Any changes you made will not be saved.';
		setAlert({
			title: 'Warning: Changes Will Not Be Saved',
			body: <AlertCloseTab.Body content={contentBody} />,
			footer: <AlertCloseTab.Footer closeTab={closeTabNavigation} closeModal={closeModalToSave} />,
			onClose: closeModalToSave
		});
		setShowDocumentAlert(true);
	};

	const showLoader = (flagSaveDocument = true) => {
		if (!flagSaveDocument) {
			setIsSaveDocument(false);
		}
		setIsLoadingDocument(true);
	};

	const hideLoader = (alertOptions) => {
		if (alertOptions) {
			const { type, message } = alertOptions;
			if (type === 'file') {
				showAlertCorruptFile(message);
			}
		}
		setIsLoadingDocument(false);
	};

	const closeModalAlertDocument = () => {
		setShowDocumentAlert(false);
	};

	const showAlertCorruptFile = (message) => {
		setAlert({
			...alert,
			title: 'Error: File type not supported',
			body: <AlertErrorDialog.Body content={message} />,
			footer: <AlertErrorDialog.Footer closeModal={closeModalAlertDocument} />,
			onClose: closeModalAlertDocument
		});

		setShowDocumentAlert(true);
	};

	const getSignatures = async () => {
		try {
			const response = await getUserAuthorizedSignatures(username);
			if (response.status === 200) {
				const { data } = response;
				const { authorizedSignatures } = data;

				if (authorizedSignatures.length <= 0) return;

				const signatures = authorizedSignatures.map((item) => {
					return {
						user: item.fullname,
						file: `data:image/jpeg;base64,${item.image}`
					};
				});

				setSignaturesAuthorized(signatures);
				const userSignature = authorizedSignatures.filter((item) => item.user === username);
				userSignature.length > 0 && setExistSignature(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const openUserSignature = (text, type) => {
		setToast((prev) => ({
			...prev,
			show: false
		}));

		setToast((prev) => ({
			...prev,
			show: true,
			text,
			type
		}));

		if (type === TOAST_TYPES.SUCCESS) {
			getSignatures();
		}
	};

	const printDoc = async () => {
		const { UI } = webViewer;
		const { annotationManager } = webViewer.Core;

		if (isMobile) {
			const xfdfString = await annotationManager.exportAnnotations();
			const options = {
				filename: 'document.pdf',
				xfdfString,
				downloadType: 'pdf'
			};
			UI.downloadPdf(options);
		} else {
			const iframeDoc = UI.iframeWindow.document;
			const modal = iframeDoc.querySelector('[data-element="printModal"]');
			const element = modal.querySelector(
				'[class="ui__base ui__choice ui__choice--checked ui__choice--center"]'
			);
			element.style.visibility = 'hidden';
			UI.openElements(['printModal']);
			closeRightPanel();
		}
	};

	const downloadDoc = async () => {
		const { UI } = webViewer;
		UI.downloadPdf();
	};

	return (
		<>
			<CenterPanel>
				<Breadcrumb>
					<BreadcrumbItem linkAs={Link} linkProps={{ to: '/home' }}>
						Home
					</BreadcrumbItem>
					<BreadcrumbItem linkAs={Link} linkProps={{ to: `/case-batch` }}>
						Case Batch
					</BreadcrumbItem>
					<BreadcrumbItem active>Document View</BreadcrumbItem>
				</Breadcrumb>

				<PageHeading title="Document View" subTitle="Document Editor" />

				<ResponseAlert
					show={toast.show}
					text={toast.text}
					type={toast.type}
					delay={3000}
					close={() => {
						setToast({
							...toast,
							show: false
						});
					}}
				/>

				{otherFilesFormat &&
					mediaFile &&
					(isLoadingDocument && loadingPage ? (
						<LoaderComponent
							loadingBar={isLoadingDocument}
							loadingPage={loadingPage}
							now={progress}
						/>
					) : (
						<>
							<ListGroup>
								<ListGroup.Item>{mediaFile.name}</ListGroup.Item>
							</ListGroup>
						</>
					))}

				{!otherFilesFormat && !mediaFile && (
					<PDFTronComponent
						heightContainer={'100vh'}
						isLoadingDocument={isLoadingDocument}
						setIsLoadingDocument={setIsLoadingDocument}
						instanceDocumentViewer={instanceDocumentViewer}
						setInstanceDocumentViewer={setInstanceDocumentViewer}
						webViewer={webViewer}
						setWebViewer={setWebViewer}
						existDocument={existDocument}
						compareAnnotations={compareAnnotations}
						now={progress}
						source={documentViewSource}
						setProgressDocumentview={setProgressDocumentview}
						fullAPI
					/>
				)}
			</CenterPanel>

			<RightPanel data-testid="right-panel-dv">
				<CaseBlock caseId={caseId} />
				<h4 className="text-center mt-3">Actions</h4>

				<EditActionButtons
					onSave={saveDocument}
					onCancel={() => {
						onCancelChanges();
					}}
					editMode={true}
					disableSave={isLoadingDocument || (!mediaFile && !instanceDocumentViewer)}
					disableCancel={false}
					enableButton={enableButton}
				/>

				<InsertDocument
					show={insertDocumentModalShow}
					onHide={() => setInsertDocumentModalShow(false)}
					optionActivated={webViewer}
					instanceDocumentViewer={instanceDocumentViewer}
					showLoader={showLoader}
					hideLoader={hideLoader}
					formatFile={viewFormatValidateFile}
					setFileSelected={setMediaFile}
					existDocument={existDocument}
				/>

				<ScanButton
					token={token}
					webViewer={webViewer}
					instanceDocumentViewer={instanceDocumentViewer}
					setIsLoadingDocument={setIsLoadingDocument}
					setToast={setToast}
				/>

				<ModalDocumentDescription setDocumentDescription={setDocumentDescription} />

				<AnnotateDocumentButton>
					<Button onClick={() => setShowSignatureModal(true)}>
						{existSignature ? 'Edit Signature' : 'Create Signature'}
					</Button>
					<ModalInsertAnnotations
						title="Insert Signature"
						textModal="Select Signature image"
						textButton="Insert Signature"
						typeAnnotation={'SIGN'}
						disabled={!instanceDocumentViewer}
						setImageSelection={setImageSelection}
						setToast={() => {}}
						data={signaturesAuthorized}
						setTypeAnnotation={setTypeAnnotation}
					/>
					<ModalInsertAnnotations
						title="Insert Court Seal"
						textModal="Select Court seal image"
						textButton="Insert Court Seal"
						typeAnnotation={'CS'}
						disabled={!instanceDocumentViewer}
						setImageSelection={setImageSelection}
						setToast={setToast}
						setTypeAnnotation={setTypeAnnotation}
					/>
					<ModalInsertAnnotations
						title="Insert Stamp"
						textModal="Select stamp image"
						textButton="Insert File Stamp"
						typeAnnotation={'FS'}
						disabled={!instanceDocumentViewer}
						setImageSelection={setImageSelection}
						setToast={setToast}
						setTypeAnnotation={setTypeAnnotation}
					/>
					<ModalInsertAnnotations
						title="Insert File Mark"
						textModal="Select a file mark image"
						textButton="Insert File Mark"
						typeAnnotation={'FM'}
						disabled={!instanceDocumentViewer}
						setImageSelection={setImageSelection}
						setToast={setToast}
						setTypeAnnotation={setTypeAnnotation}
					/>
				</AnnotateDocumentButton>

				<ModalDocumentInformation
					getDocumentFileLoaded={getDocumentFileLoaded}
					fileLoaded={fileLoaded}
					disabled={isLoadingDocument || (!mediaFile && !instanceDocumentViewer)}
				/>

				{/* <Button
					disabled={isLoadingDocument || !instanceDocumentViewer}
					onClick={() => {
						printDoc();
					}}
				>
					Print Document
				</Button> */}

				<Button
					disabled={isLoadingDocument || !instanceDocumentViewer}
					onClick={() => {
						downloadDoc();
					}}
				>
					Download Document
				</Button>
			</RightPanel>

			<AlertDialog
				open={showDocumentAlert}
				title={alert.title}
				children={alert.body}
				footer={alert.footer}
				type={alert.type}
				onClose={() => {
					setShowDocumentAlert(false);
					alert.onClose();
				}}
			/>

			<CreateSignatureModal
				username={username}
				isModalActive={showSigturareModal}
				onClose={() => setShowSignatureModal(false)}
				openToast={openUserSignature}
			/>
		</>
	);
};

export default DocumentView;
