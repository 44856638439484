import React from 'react';

import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import { FormDateInput } from './';

export const FieldFormDateInput = ({ id, label = '', required, maxDateAble }) => (
	<>
		<Field name={id}>
			{({ field, form }) => (
				<FormDateInput
					id={field.name}
					label={label}
					value={field.value}
					onChange={(value) => form.setFieldValue(field.name, value)}
					required={required}
					maxDate={maxDateAble}
				/>
			)}
		</Field>
		<ErrorMessage
			component="div"
			name={id}
			className={'position-absolute small text-danger'}
			role="alert"
		/>
	</>
);

FieldFormDateInput.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	required: PropTypes.bool
};
