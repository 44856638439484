import { useEffect, useState } from 'react';
import { DocumentViewService } from '../services/DocumentViewService';

export const useInfoDownloadFile = (id) => {
	const { getInfoDownloadFile } = DocumentViewService();
	const [infoDownloadFile, setInfoDownloadFile] = useState({
		url: '',
		extension: '',
		size: ''
	});

	useEffect(() => {
		if (id) {
			getInfoDownloadFile(id).then((response) => {
				if (response === null) {
					setInfoDownloadFile({
						url: '',
						extension: '',
						size: ''
					});
					return;
				}
				setInfoDownloadFile({
					url: response.url,
					extension: response.extension.trim(),
					size: response.size
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return infoDownloadFile;
};
