import { Button } from 'react-bootstrap';
import AlertDialog from '../AlertDialog';
import { useEffect, useState } from 'react';

const SessionTimeoutModal = ({ isOpenTimeoutModal, onProceed, onDismiss, checkRemaining }) => {
	const [remaining, setRemaining] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(checkRemaining() / 1000));
		}, 500);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AlertDialog
			open={isOpenTimeoutModal}
			title="Session Timeout"
			onClose={() => {}}
			closeable={false}
			footer={
				<>
					<Button onClick={onProceed}>Continue</Button>
					<Button variant="secondary" onClick={onDismiss}>
						Logout
					</Button>
				</>
			}
		>
			<div className="d-flex flex-column">
				<span>Your session will be timing out due to inactivity, click 'Continue' to proceed.</span>
				{remaining && <span>Time remaining before logout, {remaining} seconds</span>}
			</div>
		</AlertDialog>
	);
};

export default SessionTimeoutModal;
