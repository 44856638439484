import { DEFAULT_SELECT_OPTION } from '../constants/forms';

export const RELATIVES_TABLE_COLUMNS = [
	{ heading: 'Name', value: 'affiliateName' },
	{ heading: 'Relationship', value: 'descriptionRelation' },
	{ heading: 'Date Created', value: 'dateAdded' }
];

export const GROUPS_GANGS_TABLE_COLUMNS = [
	{ heading: 'Group Name', value: 'affiliateName' },
	{ heading: 'Date Created', value: 'dateAdded' }
];

export const CATEGORY_SELECT_OPTIONS = [
	DEFAULT_SELECT_OPTION,
	{ value: 'affiliation_relative', label: 'Relative' },
	{ value: 'affiliation_group_gang', label: 'Group/Gang' }
];

export const RELATED_PARTIES_TABLE_COLUMNS = [
	{ heading: 'Name', value: 'fullName' },
	{ heading: 'Case Number', value: 'caseNo' },
	{ heading: 'Relationship', value: 'partyCode' },
	{ heading: 'Date Added', value: 'dateActive' }
];

export const PROFESSIONAL_RESOURCES_TABLE_COLUMNS = [
	{ heading: 'Name', value: 'fullName' },
	{ heading: 'Party Type', value: 'partyType' },
	{ heading: 'Category', value: 'category' },
	{ heading: 'Company', value: 'company' },
	{ heading: 'Case Number', value: 'causeNo' }
];

export const CHOOSE_MASTER_RECORD_TABLE_COLUMNS = [
	{ heading: 'Select', value: 'select' },
	{ heading: 'Name', value: 'name' },
	{ heading: 'Date of Birth', value: 'birthDate' },
	{ heading: 'Gender', value: 'gender' },
	{ heading: 'Identification', value: 'identification' },
	{ heading: 'Phone', value: 'phone' },
	{ heading: 'Action', value: 'action' }
];

export const MERGE_PERSON_RECORDS_EMAIL_ADDRESSES_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Person Name', value: 'personName' },
	{ heading: 'Email Address', value: 'email' },
	{ heading: 'Primary', value: 'isPrimary' },
	{ heading: 'Action', value: 'action' }
];

export const MERGE_PERSON_RECORDS_PHONE_NUMBERS_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Person Name', value: 'personName' },
	{ heading: 'Phone Number', value: 'phone' },
	{ heading: 'Phone Type', value: 'phoneCode' },
	{ heading: 'Primary', value: 'isPrimary' },
	{ heading: 'Action', value: 'action' }
];

export const MERGE_PERSON_RECORDS_ADDRESSES_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Person Name', value: 'personName' },
	{ heading: 'Address Line 1', value: 'street1' },
	{ heading: 'Address Line 2', value: 'street2' },
	{ heading: 'City', value: 'city' },
	{ heading: 'State', value: 'state' },
	{ heading: 'Zip', value: 'zip' },
	{ heading: 'Primary', value: 'isPrimary' },
	{ heading: 'Action', value: 'action' }
];

export const MERGE_PERSON_RECORDS_ALIASES_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Person Name', value: 'personName' },
	{ heading: 'Alias type', value: 'aliasCodeDescription' },
	{ heading: 'Description', value: 'firstName' },
	{ heading: 'Primary', value: 'isPrimary' },
	{ heading: 'Action', value: 'action' }
];

export const MERGE_PERSON_RECORDS_OFFICIAL_IDENTIFICATIONS_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Person Name', value: 'personName' },
	{ heading: 'ID Type', value: 'descCode' },
	{ heading: 'ID Number', value: 'idValue' },
	{ heading: 'Action', value: 'action' }
];

export const MERGE_SUMMARY_PERSON_RECORDS_SELECTED_COLUMNS = [
	{ heading: 'Person Number', value: 'personId' },
	{ heading: 'Name', value: 'name' },
	{ heading: 'Date of Birth', value: 'birth' },
	{ heading: 'Gender', value: 'genderDescription' },
	{ heading: 'ID Type', value: 'idType' },
	{ heading: 'ID Number', value: 'idNumber' },
	{ heading: 'Phone', value: 'phone' }
];

export const MERGE_SUMMARY_EMAIL_ADDRESSES_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Name', value: 'personName' },
	{ heading: 'Email Address', value: 'email' }
];

export const MERGE_SUMMARY_PHONE_NUMBERS_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Name', value: 'personName' },
	{ heading: 'Phone Number', value: 'phone' },
	{ heading: 'Phone Type', value: 'phoneCode' }
];

export const MERGE_SUMMARY_ADDRESSES_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Name', value: 'personName' },
	{ heading: 'Address Line 1', value: 'street1' },
	{ heading: 'Address Line 2', value: 'street2' },
	{ heading: 'City', value: 'city' },
	{ heading: 'State', value: 'state' },
	{ heading: 'Zip', value: 'zip' }
];

export const MERGE_SUMMARY_ALIASES_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Name', value: 'personName' },
	{ heading: 'Alias type', value: 'aliasCodeDescription' },
	{ heading: 'Description', value: 'firstName' }
];

export const MERGE_SUMMARY_OFFICIAL_IDENTIFICATIONS_COLUMNS = [
	{ heading: 'Person Number', value: 'personNumber' },
	{ heading: 'Name', value: 'personName' },
	{ heading: 'ID Type', value: 'descCode' },
	{ heading: 'ID Number', value: 'idValue' }
];

export const LITERAL_DAYS_STANDARD_WORK_TIMES = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday'
];

export const DAYS_STANDARD_WORK_TIMES = [1, 2, 3, 4, 5];

export const HOURS_STANDARD_WORK_TIMES = [];

for (
	let hour = new Date(0, 0, 0, 8, 0, 0);
	hour.getTime() <= -2208997644000;
	hour = new Date(hour.getTime() + 1800000)
) {
	const minutes = (hour.getMinutes() + '').padStart(2, '0');
	const hours = (hour.getHours() + '').padStart(2, '0');
	HOURS_STANDARD_WORK_TIMES.push({
		value: `${hours}:${minutes}`,
		label: `${hours}:${minutes}`,
		index: HOURS_STANDARD_WORK_TIMES.length
	});
}
