import { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import styles from './styles.module.scss';

const CenterPanel = (props) => {
	const centerPanelRef = useRef(null);

	useEffect(() => {
		function focusCenterPanel(event) {
			if (event.keyCode === 67 && event.altKey) {
				const panelButtons = [
					...centerPanelRef.current.querySelectorAll(
						'button[class~="btn"], a[href], input, select, [tabindex]:not([tabindex="-1"])'
					)
				].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));

				if (panelButtons) {
					panelButtons[0].focus();
				}
			}
		}

		document.addEventListener('keydown', focusCenterPanel);

		return () => {
			document.removeEventListener('keydown', focusCenterPanel);
		};
	});

	return (
		<Container
			id="main-content"
			fluid
			className={styles.centerPanel}
			role="main"
			aria-label="Center Panel"
			ref={centerPanelRef}
		>
			{props.children}
		</Container>
	);
};

export default CenterPanel;
