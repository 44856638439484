import { useEffect, useState } from 'react';
import styles from '../TopNavBar/styles.module.scss';
import { SystemNotificationsService } from '../../../services/SystemNotificationsService';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import useUserStore from '../../../redux/utils/storeUtils.js';
import { useModalDialog } from '../../../hooks';
import ModalDialog from '../../ModalDialog/index.jsx';
import menuStyles from './styles.module.scss';
import { isMobile } from 'react-device-detect';

const menuHeaderStyle = {
	borderWidth: '1px',
	borderStyle: 'solid',
	display: 'flex'
};
const footerStyle = {
	textAlign: 'center',
	borderWidth: '1px',
	borderStyle: 'solid',
	cursor: 'pointer'
};

const chkOnlyNewStyle = {
	width: '15px',
	minWidth: '15px',
	height: '15px',
	minHeight: '15px'
};

const notifMenuStyle = { padding: '0px', width: isMobile ? '300px' : '425px' };
const truncateTitle = (title, limit) => {
	return title.length > limit ? title.substring(0, limit - 3) + '..' : title;
};

const getTruncateLimitForTitle = (caseNumber) => {
	return caseNumber.length > 10 ? 25 : 30;
};

const truncateMaxCaseNumber = (caseNumber) => {
	return caseNumber.length > 15 ? caseNumber.substring(0, 15) : caseNumber;
};

const NotificationItem = ({ title, text, isNew, caseNumber, handleClick }) => {
	return (
		<li key={title}>
			<a className="dropdown-item" href="/#" onClick={handleClick}>
				<div className={menuStyles.itemContainer}>
					<div className={menuStyles.bulletSection}>
						<p>
							<span className={isNew ? menuStyles.filledDotStyle : menuStyles.emptyDotStyle}></span>
						</p>
					</div>
					<div className={menuStyles.textSection}>
						<div className={menuStyles.textUpperSection}>
							<div className={menuStyles.typeTextContainer}>
								<h6>
									{truncateTitle(
										title,
										getTruncateLimitForTitle(truncateMaxCaseNumber(caseNumber))
									)}
								</h6>
							</div>
							<div className={menuStyles.caseNumberContainer}>
								{isMobile ? '' : <span className={menuStyles.caseNumberLabel}>{caseNumber}</span>}
							</div>
						</div>

						<p className={menuStyles.notificationTextStyle}>{text?.substring(0, 49) + '...'}</p>
						{isMobile && (
							<div className={menuStyles.mobileCaseNumberContainer}>
								<span className={menuStyles.caseNumberLabel}>{caseNumber}</span>
							</div>
						)}
					</div>
				</div>
			</a>
			<hr></hr>
		</li>
	);
};
export const NotificationBell = () => {
	const [notifications, setNotifications] = useState([]);
	const [originalSourceNotifications, setOriginalSourceNotifications] = useState([]);
	const [newNotifsCount, setNewNotifsCount] = useState(0);
	const [showNewOnly, setShowNewOnly] = useState(false);
	const { getAllNotifications, updateMsgAsRead, updateAllMsgAsDismissed } =
		SystemNotificationsService();
	const userId = useSelector((state) => state.user.userInfo?.id);
	const { selectedClerkId } = useUserStore();

	const [notificationToShow, setNotificationToShow] = useState({});

	const pullNotifications = async () => {
		try {
			const { data, status } = await getAllNotifications(userId, selectedClerkId);
			if (status === 200) {
				setNotifications(data);
				setOriginalSourceNotifications(data);
				updateCounts(data);
			} else {
				setNotifications([]);
				setOriginalSourceNotifications([]);
				updateCounts([]);
			}
		} catch (error) {
			console.error('Unable to get notifications ', error);
		}
	};

	const [isOpenNotificationModal, openNotificationModal, closeNotificationModal] = useModalDialog();

	useEffect(() => {
		if (selectedClerkId) {
			pullNotifications();
			const interval = setInterval(() => {
				pullNotifications();
			}, 10000);

			return () => clearInterval(interval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClerkId]);

	useEffect(() => {
		const its = notifications?.slice();
		if (showNewOnly) {
			let newOnly = its.filter((x) => !x.isRead);
			setNotifications(newOnly);
			updateCounts(newOnly);
		} else {
			setNotifications(originalSourceNotifications);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showNewOnly, originalSourceNotifications]);

	const dismissAll = () => {
		//clear all notifications
		setNotifications([]);
		setNewNotifsCount(0);
		updateAllMsgAsDismissed(userId, selectedClerkId);
	};

	const updateCounts = (its = notifications) => {
		let count = its.filter((x) => !x.isRead).length;
		setNewNotifsCount(count);
	};

	const handleNotificationClick = (e, item) => {
		e.preventDefault();
		const { systemNotificationId, isRead } = item;
		setNotificationToShow(item);
		openNotificationModal();
		if (!isRead) {
			updateMsgAsRead(userId, systemNotificationId);
			pullNotifications();
		}
	};

	return (
		<>
			<ModalDialog
				isOpenModal={isOpenNotificationModal}
				closeModal={closeNotificationModal}
				title={notificationToShow.typeNotification}
				children={
					<div style={{ whiteSpace: 'pre-wrap' }}>
						{isMobile && notificationToShow.longMessage
							? notificationToShow.longMessage.replace(/\t+/g, ':')
							: notificationToShow.longMessage}
					</div>
				}
			/>
			<div className="dropstart">
				<a
					aria-expanded="false"
					className={`${styles.alertIcon} h3 text-darkblue`}
					data-bs-toggle="dropdown"
					href="/#"
					role="button"
					title="Notifications"
				>
					<i className="fas fa-bell"></i>
					<span className={styles.alertCounter} data-testid="notificationsCount">
						<span className="visually-hidden">Notifications</span>
						{newNotifsCount}
					</span>
				</a>
				<div className="dropdown-menu pt-0" style={notifMenuStyle} data-testid="notificationsMenu">
					<div
						className="dropdown-item-text bg-lightblue text-dark text-center p-2 border-bottom border-dark"
						style={menuHeaderStyle}
					>
						<div style={{ minWidth: '30%' }}></div>
						<div style={{ width: '40%' }}>
							<span>Notifications {`(${newNotifsCount})`}</span>
						</div>
						<div style={{ width: '30%' }}>
							<label className={menuStyles.smallTextStyle}>
								<input
									type="checkbox"
									id="chkNew"
									style={chkOnlyNewStyle}
									checked={showNewOnly}
									onChange={() => setShowNewOnly(!showNewOnly)}
								/>
								{` Show only new`}
							</label>
						</div>
					</div>
					<ul className={menuStyles.expandedList}>
						{notifications && notifications.length > 0 ? (
							notifications.map((item, index) => (
								<NotificationItem
									key={index}
									title={item?.typeNotification}
									text={item?.longMessage}
									isNew={!item?.isRead}
									caseNumber={item?.caseNumber}
									handleClick={(e) => handleNotificationClick(e, item)}
								></NotificationItem>
							))
						) : (
							<p className="text-center">No new notifications found</p>
						)}
					</ul>

					<div
						className="dropdown-item-text bg-lightblue text-dark text-center p-2 border-bottom border-dark"
						style={footerStyle}
					>
						<Button
							variant="link"
							className={menuStyles.smallTextStyle}
							onClick={(e) => {
								e.preventDefault();
								dismissAll();
							}}
						>
							Dismiss All
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};
