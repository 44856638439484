// constantes
const initialState = {
	pathsHistory: []
};

// types
const SET_HISTORY_PATHS = 'SET_HISTORY_PATHS';

// reducer
export default function urlParamReducer(state = initialState, action) {
	switch (action.type) {
		case SET_HISTORY_PATHS:
			return { ...state, pathsHistory: [...state.pathsHistory, action.payload] };
		default:
			return state;
	}
}

// actions
export const setHistoryPaths = (newPath) => async (dispatch) =>
	dispatch({
		type: SET_HISTORY_PATHS,
		payload: newPath
	});
