import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';

import ModalDialog from '../../components/ModalDialog';
import { MoneyInput } from '../../components/common';
import { InputLabel } from '../../components/forms';
import { Table } from '../../components/layout/TableWithIntegration';
import { modalColumns, modalMapper } from '../../constants/certifiedPayments';
import { PaymentService } from '../../services/PaymentService';
import { useBackendPaginationFetcher } from '../../hooks';
import DatePicker from '../../components/_DatePicker';

const CertifiedPaymentModal = ({ show, setShow, paymentId, status }) => {
	const { getCertifiedPayment } = PaymentService();
	const [paymentData, setPaymentData] = useState({});
	const [caseNumber, setCaseNumber] = useState('');
	const [redirect, setRedirect] = useState();
	const [stateData, setStateData] = useState({});
	const [inputError, setInputError] = useState(false);
	const [tableUrl, setTableUrl] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			const fieldData = await getCertifiedPayment(paymentId);
			setPaymentData({ ...fieldData?.data });
		};
		fetchData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setStateData({
			initialPaymentAmount: paymentData.amount,
			initialPaymentDate: paymentData.transactionDate,
			isCertifiedPayment: true,
			certPaymentId: paymentId
		});
		// eslint-disable-next-line
	}, [paymentData]);

	useEffect(() => {
		if (!redirect) {
			return;
		}
		navigate(`/case/${redirect}/payment/new-payment`, {
			state: stateData
		});
		// eslint-disable-next-line
	}, [redirect]);

	const { data, isLoading, paginationItems } = useBackendPaginationFetcher({
		url: tableUrl,
		mapper: modalMapper,
		constantSize: 10000,
		preventFetch: !caseNumber
	});

	const modalContent = () => {
		return (
			<>
				<Formik initialValues={paymentData} validationSchema={''} onSubmit={() => {}}>
					{({ values: val, errors, touched, handleChange, setFieldTouched, setFieldValue }) => (
						<Form id="certifiedPaymentForm">
							<Row>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'transactionNumber'}>Transaction Number</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="transactionNumber"
										name="transactionNumber"
										onChange={handleChange}
										value={paymentData?.transactionId}
										disabled
									/>
								</Col>
								<Col xs={12} md={6} className="mb-3">
									<InputLabel name={'Transaction Date'} htmlFor={'transactionDate'} />
									<DatePicker
										name="transactionDate"
										onChange={handleChange}
										type="date"
										disabled
										defaultValue={paymentData?.transactionDate}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'caseNumber'}>Case Number</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="caseNumber"
										name="caseNumber"
										onChange={handleChange}
										value={paymentData?.caseNumber}
										disabled
									/>
								</Col>
								<Col xs={12} md={6} className="mb-3">
									<MoneyInput
										id="paymentAmount"
										name="paymentAmount"
										label="Payment Amount"
										handlerinput={handleChange}
										value={paymentData?.amount?.toString()}
										disabled
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'lastName'}>Last Name</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="lastName"
										name="lastName"
										onChange={handleChange}
										value={paymentData?.lastName}
										disabled
									/>
								</Col>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'firstName'}>First Name</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="firstName"
										name="firstName"
										onChange={handleChange}
										value={paymentData?.firstName}
										disabled
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'middleName'}>Middle Name</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="middleName"
										name="middleName"
										onChange={handleChange}
										value={paymentData?.middleName}
										disabled
									/>
								</Col>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'address'}>Address</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="address"
										name="address"
										onChange={handleChange}
										value={paymentData?.address}
										disabled
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'city'}>City</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="city"
										name="city"
										onChange={handleChange}
										value={paymentData?.city}
										disabled
									/>
								</Col>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'state'}>State</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="state"
										name="state"
										onChange={handleChange}
										value={paymentData?.state}
										disabled
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={6} className="mb-5">
									<Form.Label htmlFor={'zip'}>Zip</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="zip"
										name="zip"
										onChange={handleChange}
										value={paymentData?.zip}
										disabled
									/>
								</Col>
								<Col xs={12} md={6} className="mb-5"></Col>
							</Row>
							<Row>
								<Col xs={12} md={6} className="mb-3">
									<Form.Label htmlFor={'caseNumber'}>Case Number</Form.Label>
									<Form.Control
										className={'form-control'}
										type="text"
										id="caseNumber"
										name="caseNumber"
										isInvalid={inputError}
										onChange={(e) => {
											setCaseNumber(e.target.value);
										}}
										onBlur={(e) => {
											!e.target.value ? setInputError(true) : setInputError(false);
										}}
									/>
									{inputError && (
										<div className={`text-danger small`}>{'Case Number is required.'}</div>
									)}
								</Col>
								<Col xs={12} md={6} className="mt-4 pt-2">
									<Button
										variant="primary"
										onClick={() => {
											setTableUrl(`/api/case/cases/case-number/${caseNumber}`);
										}}
									>
										Search
									</Button>
								</Col>
							</Row>
							<Table
								data={data}
								columns={modalColumns}
								tableId={'case-number-table'}
								showSearch={true}
								paginationItems={paginationItems}
								enableRowFunction
								clickedRowFunction={(e) => {
									!status && setRedirect(e.caseId);
								}}
								hidePagination={true}
								isLoading={isLoading}
							/>
						</Form>
					)}
				</Formik>
			</>
		);
	};

	const modalFooter = () => {
		return (
			<Button
				variant="secondary"
				onClick={() => {
					setShow(false);
				}}
			>
				Cancel
			</Button>
		);
	};

	return (
		<ModalDialog
			isOpenModal={show}
			size={'lg'}
			closeModal={() => setShow(false)}
			title="Process Certified Payment"
			children={modalContent()}
			footer={modalFooter()}
			centered={false}
		/>
	);
};

export default CertifiedPaymentModal;
