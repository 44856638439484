// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_custom-input-content__iEWeZ input {\n  min-width: 135px;\n}\n\n.styles_NewDatePickerStyle__dK28B {\n  position: absolute;\n  bottom: 50%;\n  right: 2%;\n  transform: translate(0%, 50%);\n  cursor: pointer;\n}\n\n.styles_reactDatepickerPopper__bgAp2 {\n  z-index: 1100 !important;\n}", "",{"version":3,"sources":["webpack://./src/components/NewDatePicker/styles.module.scss"],"names":[],"mappings":"AACC;EACC,gBAAA;AAAF;;AAIA;EACC,kBAAA;EACA,WAAA;EACA,SAAA;EACA,6BAAA;EACA,eAAA;AADD;;AAIA;EACC,wBAAA;AADD","sourcesContent":[".custom-input-content {\n\tinput {\n\t\tmin-width: 135px;\n\t}\n}\n\n.NewDatePickerStyle {\n\tposition: absolute;\n\tbottom: 50%;\n\tright: 2%;\n\ttransform: translate(0%, 50%);\n\tcursor: pointer;\n}\n\n.reactDatepickerPopper {\n\tz-index: 1100 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-input-content": "styles_custom-input-content__iEWeZ",
	"NewDatePickerStyle": "styles_NewDatePickerStyle__dK28B",
	"reactDatepickerPopper": "styles_reactDatepickerPopper__bgAp2"
};
export default ___CSS_LOADER_EXPORT___;
