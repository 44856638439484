import { format } from 'date-fns-tz';
import { DateTime } from 'luxon';
import { timezoneFormat } from '../context/ConfigsContext/utils';
import { useConfigs } from './useConfigs';

export const useSystemDate = () => {
	const { timeZone, dateFormat } = useConfigs();

	const toSystemDate = (date) => {
		return DateTime.fromJSDate(date, { setZone: true })
			.setZone(timezoneFormat(timeZone), { keepLocalTime: true })
			.toISO();
	};

	const toSystemFormat = (date) => {
		return format(new Date(date), dateFormat, { timeZone: timezoneFormat(timeZone) });
	};

	return {
		toSystemDate,
		toSystemFormat
	};
};
