import React from 'react';

export default function Flag({ flag, cardFlags = false }) {
	let box;
	if (flag === 'DP') {
		box = (
			<div>
				<div className={'btn-flag-dangerous'}>D</div>
				{cardFlags === false && <span>Dangerous Party</span>}
			</div>
		);
	} else if (flag === 'WA') {
		box = (
			<div>
				<div className={'btn-flag-warrant'}>W</div>
				{cardFlags === false && <span>Warrant</span>}
			</div>
		);
	} else if (flag === 'RO') {
		box = (
			<div>
				<div className={'btn-flag-repeatoffender'}>R</div>
				{cardFlags === false && <span>Repeat Offender</span>}
			</div>
		);
	} else if (flag === 'CI') {
		box = (
			<div>
				<div className={'btn-flag-conflictofinterest'}>C</div>
				{cardFlags === false && <span>Conflict of Interest</span>}
			</div>
		);
	} else if (flag === 'IN') {
		box = (
			<div>
				<div className={'btn-flag-interpreterneeded'}>I</div>
				{cardFlags === false && <span>Interpreter Needed</span>}
			</div>
		);
	} else if (flag === 'RED_NEW') {
		box = (
			<div>
				<div className={'btn-flag-redupdated'}>RU</div>
				{cardFlags === false && <span>RED FLAG CODE UPDATED</span>}
			</div>
		);
	} else if (flag === 'KIV') {
		box = (
			<div>
				<div className={'btn-flag-keepinview'}>K</div>
				{cardFlags === false && <span>Keep In View </span>}
			</div>
		);
	}
	return (
		(flag === 'DP' ||
			flag === 'WA' ||
			flag === 'RO' ||
			flag === 'CI' ||
			flag === 'IN' ||
			flag === 'RED_NEW' ||
			flag === 'KIV') && <div className={'btn-flag'}>{box}</div>
	);
}
