import { useEffect, createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useConfigsServices } from './utils';
import _ from 'lodash';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useIdleTimer } from 'react-idle-timer';
import useUserStore from '../../redux/utils/storeUtils';
import { useModalDialog } from '../../hooks';
import SessionTimeoutModal from '../../components/SessionTimeoutModal';

export const ConfigsContext = createContext({});

const ConfigsContextProvider = ({ children }) => {
	const [configs, setConfigs] = useState([]);
	const [isOpenTimeoutModal, openTimeoutModal, closeTimeoutModal] = useModalDialog();
	const { selectedClerk } = useUserStore();
	const { token, logout } = useAuthContext();
	const location = useLocation();
	const [activeConfigs, setActiveConfigs] = useState([]);
	const { getConfigs, filterActiveConfigs } = useConfigsServices();
	const county = selectedClerk
		? `${selectedClerk?.countyCodeDescription} ${selectedClerk?.clerkTypeDescription}`
		: '';

	// Session Timeout defaults to 30 min (1800000 ms)
	const timeout = !_.isEmpty(activeConfigs)
		? activeConfigs.filter((item) => item.name === 'User Session Timeout Limit (seconds)')[0]
				.value * 1000
		: 1800000;
	const timeoutInSeconds = Math.ceil(timeout / 1000);

	const { start, activate, getRemainingTime } = useIdleTimer({
		crossTab: true,
		syncTimers: 500,
		promptBeforeIdle: 60000,
		timeout: timeout,
		startOnMount: false,
		onPrompt: async () => {
			let currentPath = location.pathname;

			if (currentPath !== '/' && currentPath !== '/login') {
				openTimeoutModal();
			}
		},
		onIdle: () => {
			console.warn(`User session timed out. (Timeout = ${timeoutInSeconds}s)`);
			closeTimeoutModal();
			logout();
		}
	});

	const onProceed = () => {
		closeTimeoutModal();
		activate();
	};

	const onDismiss = () => {
		console.warn(`User session logout from modal. (Timeout = ${timeoutInSeconds}s)`);
		closeTimeoutModal();
		logout();
	};

	useEffect(() => {
		if (token && !_.isEmpty(county)) {
			getConfigs(county)
				.then((res) => {
					setConfigs(res);
				})
				.catch((e) => {
					console.error('Error getting configs', e);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [county, token]);

	useEffect(() => {
		if (!_.isEmpty(configs)) {
			setActiveConfigs(filterActiveConfigs(configs));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configs]);

	useEffect(() => {
		if (token) {
			start();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	return (
		<ConfigsContext.Provider value={{ configs, setConfigs, county, activeConfigs }}>
			<SessionTimeoutModal
				onProceed={onProceed}
				onDismiss={onDismiss}
				isOpenTimeoutModal={isOpenTimeoutModal}
				checkRemaining={getRemainingTime}
			/>
			{children}
		</ConfigsContext.Provider>
	);
};

export default ConfigsContextProvider;
