// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_isRequired__Dsx-0 {\n  margin-left: 3px;\n  color: #dc3545;\n  font-weight: bold;\n}\n\n.styles_searchSelectIcon__BTnMd {\n  border-color: #dc3545;\n  padding-right: calc(1.5em + 0.75rem);\n  background-repeat: no-repeat;\n  background-position: right calc(0.375em + 0.1875rem) center;\n  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", "",{"version":3,"sources":["webpack://./src/components/SelectFormSearchable/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,cAAA;EACA,iBAAA;AACD;;AACA;EACC,qBAAA;EACA,oCAAA;EACA,4BAAA;EACA,2DAAA;EACA,gEAAA;EACA,yDAAA;AAED","sourcesContent":[".isRequired {\n\tmargin-left: 3px;\n\tcolor: #dc3545;\n\tfont-weight: bold;\n}\n.searchSelectIcon {\n\tborder-color: #dc3545;\n\tpadding-right: calc(1.5em + 0.75rem);\n\tbackground-repeat: no-repeat;\n\tbackground-position: right calc(0.375em + 0.1875rem) center;\n\tbackground-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);\n\tbackground-image: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isRequired": "styles_isRequired__Dsx-0",
	"searchSelectIcon": "styles_searchSelectIcon__BTnMd"
};
export default ___CSS_LOADER_EXPORT___;
