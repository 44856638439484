import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { conflictOfInterestServices } from '../services/ConflictOfInterestService';
import { useAsyncFunc } from './';

const useCheckCaseAccess = (caseId) => {
	const { checkCaseAccessStatus } = conflictOfInterestServices();
	const [isAccessible, setIsAccessible] = useState(false);
	const [callMade, setCallMade] = useState(false);
	const userId = useSelector((state) => state?.user?.userInfo?.id);

	const [checkAccessStatus, loadingCheck] = useAsyncFunc(async () => {
		const accessDeny = await checkCaseAccessStatus(caseId, userId);
		setCallMade(true);
		setIsAccessible(!accessDeny?.data);
	});

	useEffect(() => {
		checkAccessStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [caseId, userId]);

	return [isAccessible, loadingCheck, callMade];
};

export default useCheckCaseAccess;
