import { useState, useEffect } from 'react';
import InputMask from 'comigo-tech-react-input-mask';
import { ErrorMessage, useField } from 'formik';
import AffectsReportAlertTooltip from '../AffectsReportAlertTooltip/AffectsReportAlertTooltip';

export const InputFormCustom = ({
	placeholder = '',
	id = '',
	name = id,
	required = false,
	...props
}) => {
	const [field, meta] = useField({ name, id, ...props });
	const [charactersError, setCharactersError] = useState(false);
	const customMockChange = (e) => {
		props.onChange(e);
		field.onChange(e);
	};

	const handleGeneralChange = (e) => {
		if (props.onlyNumber) {
			const newVal = e.target.value.replace(/\D/g, '');
			e.target.value = newVal;
		}
		props?.onChange ? customMockChange(e) : field.onChange(e);
	};

	useEffect(() => {
		props?.limitCharacters && field?.value?.length > props?.limitCharacters
			? setCharactersError(true)
			: setCharactersError(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field.value]);

	return (
		<>
			<AffectsReportAlertTooltip isReportAffected={props?.isReportAffected}>
				<label htmlFor={id} className="form-label">
					{props.label}
					{required && (
						<>
							<span className="text-danger fw-bold mx-1" aria-hidden="true">
								*
							</span>
							<span className="sr-only">required</span>
						</>
					)}
				</label>
			</AffectsReportAlertTooltip>
			{props.mask ? (
				<InputMask
					className={`form-control ${
						((meta.touched && meta.error) || props.error) && 'is-invalid'
					} ${props.disabled ? 'notAllowed' : ''}`}
					id={id}
					aria-label={
						props?.isReportAffected
							? `${props.label}, ${props.isReportAffected?.tooltip}`
							: props.label
					}
					name={name}
					ref={props?.inputRef}
					type={props.type}
					mask={props.mask}
					disabled={props.disabled}
					placeholder={placeholder}
					value={field.value || props.defaultValue}
					onBlur={field.onBlur}
					onChange={handleGeneralChange}
					onKeyUp={props.onKeyUp}
					autoComplete={props.autoComplete}
					autoFocus={props.autoFocus}
					readOnly={props.readOnly}
					required={required}
				/>
			) : props.type === 'textarea' ? (
				<textarea
					className={`form-control ${
						((meta.touched && meta.error) || props.error || charactersError) && 'is-invalid'
					} ${props.disabled ? 'notAllowed' : ''}`}
					id={id}
					name={name}
					ref={props?.inputRef}
					type={props.type}
					disabled={props.disabled}
					value={field.value || props.defaultValue || ''}
					onBlur={field.onBlur}
					onChange={handleGeneralChange}
					onKeyUp={props.onKeyUp}
					autoComplete={props.autoComplete}
					autoFocus={props.autoFocus}
					maxLength={props.maxLength}
					placeholder={placeholder}
					onPaste={props?.onPaste}
					readOnly={props.readOnly}
					required={required}
					rows={props?.rows}
					min={props.min}
					tabIndex={props.readOnly ? '-1' : '0'}
				/>
			) : (
				<input
					className={`form-control ${
						((meta.touched && meta.error) || props.error || charactersError) && 'is-invalid'
					} ${props.disabled ? 'notAllowed' : ''}`}
					id={id}
					name={name}
					ref={props?.inputRef}
					type={props.type}
					disabled={props.disabled}
					value={field.value || props.defaultValue || ''}
					onBlur={field.onBlur}
					onChange={handleGeneralChange}
					onKeyUp={props.onKeyUp}
					autoComplete={props.autoComplete}
					autoFocus={props.autoFocus}
					maxLength={props.maxLength}
					placeholder={placeholder}
					onPaste={props?.onPaste}
					readOnly={props.readOnly}
					required={required}
					min={props.min}
					tabIndex={props.readOnly ? '-1' : '0'}
				/>
			)}
			{charactersError && (
				<div className={'small text-danger'} role="alert">
					{`Character limit exceeded: ${
						field.value ? -(field.value.length - props.limitCharacters) : ''
					}`}
				</div>
			)}
			<ErrorMessage
				component="div"
				name={field.name}
				className={'small text-danger'}
				role="alert"
			/>
		</>
	);
};
