import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import AlertDialog from '../../AlertDialog';

/**
 * AlertDialog that alerts user about unsaved changes and ask for confirmation before cancel action.
 * @param {boolean} isOpen - Condition for displaying the alert
 * @param {function} onDiscardChanges - Function called when Yes button is pressed
 * @param {function} onDismissDialog - Function called when No button is pressed
 * @returns {ReactElement} returns JSX with the AlertDialog component.
 */
const UnsavedChangesAlert = ({ isOpen, onDiscardChanges, onDismissDialog }) => {
	return (
		<AlertDialog
			open={isOpen}
			closeable={false}
			title="Warning: Changes Will Not Be Saved"
			children="Are you sure you want to cancel? Any changes you made will not be saved."
			footer={
				<>
					<Button variant="primary" onClick={onDiscardChanges}>
						Yes
					</Button>
					<Button variant="secondary" onClick={onDismissDialog}>
						No
					</Button>
				</>
			}
		/>
	);
};

UnsavedChangesAlert.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onDiscardChanges: PropTypes.func.isRequired,
	onDismissDialog: PropTypes.func.isRequired,
};

export default UnsavedChangesAlert;
