import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	hateCrimes: {
		elements: 0
	}
};

const hateCrimesSlice = createSlice({
	initialState,
	name: 'hateCrimesSlice',
	reducers: {
		clearAll: (state) => {
			state.hateCrimes = { ...initialState.hateCrimes };
		},
		persistHateCrimes: (state, action) => {
			state.hateCrimes = action.payload;
		}
	}
});

export const { clearAll, persistHateCrimes } = hateCrimesSlice.actions;

export default hateCrimesSlice.reducer;
