import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	filters: [],
	settings: {
		publicView: [''],
		publicPrint: [''],
		sealImage: [''],
		fields: [],
		selectedFields: []
	},
	sizeElements: 0,
	courtSettingsToPrint: []
};

const publicCalendarSlice = createSlice({
	initialState,
	name: 'PublicCalendarSlice',
	reducers: {
		clearAll: (state) => {
			state.filters = [];
		},
		addFilters: (state, action) => {
			state.filters = action.payload;
		},
		setSettings: (state, action) => {
			state.settings = action.payload;
		},
		setSizeElements: (state, action) => {
			state.sizeElements = action.payload;
		},
		setCourtSettingsToPrint: (state, action) => {
			state.courtSettingsToPrint = action.payload;
		}
	}
});

export const {
	clearAll,
	addFilters,
	setSettings,
	setSizeElements,
	setCourtSettingsToPrint
} = publicCalendarSlice.actions;

export default publicCalendarSlice.reducer;
