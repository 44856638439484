import { useState } from 'react';

const useModalDialog = (initialValue = false) => {
	const [isOpenModal, setIsOpenModal] = useState(initialValue);

	const openModal = () => {
		setIsOpenModal(true);
	};

	const closeModal = () => {
		setIsOpenModal(false);
	};

	return [isOpenModal, openModal, closeModal];
};

export default useModalDialog;
