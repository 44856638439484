const CalendarMainRoute = '/public-calendar';

export const PUBLIC_CALENDAR_PANELS = {
	PUBLIC_CALENDAR: 'public-calendar'
};

export const PUBLIC_CALENDAR_ROUTES = {
	PUBLIC_CALENDAR: () => {
		return '/public-calendar';
	}
};
