import { Fragment, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Col, Modal, Form, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Formik } from 'formik';
import { jsPDF } from 'jspdf';

import styles from './styles.module.scss';
import {
	FILES_TYPES_ACCEPTED,
	MAX_SIZE_GENERAL,
	FILES_TYPES_DOCUMENT,
	isMediaFile,
	isPDFFile
} from '../../../../constants';
import { fixFilterOptions } from '../../../../components/FormikFactory/utils/Select';

function Index({
	show,
	onHide,
	onSubmit,
	optionActivated,
	totalPagesDocumentViewer,
	instanceCurrentPage,
	showLoader,
	hideLoader,
	formatFile,
	setFileSelected,
	existDocument
}) {
	/* Formik */
	const insertDocument = useRef(null);
	/* Merge Modal */
	const [modalMerge, setModalMerge] = useState(false);
	/* Dropzone */
	const [errors, setErrors] = useState('');
	const [accepted, setAccepted] = useState(false);

	const validateSize = (file) => {
		if (file.size > MAX_SIZE_GENERAL) {
			return {
				code: 'file-too-large',
				message: `File is bigger than ${Math.round(MAX_SIZE_GENERAL / Math.pow(1024, 2))}MB`
			};
		}

		return null;
	};

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		maxFiles: 0,
		multiple: false,
		accept: {
			'application/image': existDocument ? FILES_TYPES_DOCUMENT : FILES_TYPES_ACCEPTED
		},
		validator: validateSize,
		onDrop: (acceptedFiles, fileRejections) => {
			fileRejections.map((file) => {
				file.errors.map((err) => {
					if (err.code === 'file-invalid-type') {
						setAccepted(false);
						setErrors('Error: File type not supported');
					} else if (err.code === 'file-too-large') {
						setAccepted(false);
						setErrors(err.message);
					}
					return 0;
				});
				return 0;
			});

			if (acceptedFiles.length !== 0) {
				setAccepted(true);
			}
		}
	});
	/* Option select */
	const [location] = useState([
		{ label: 'After', value: 1 },
		{ label: 'Before', value: 2 }
	]);
	/* Default Options */
	const [optionSelectedLocation, setOptionSelectedLocation] = useState({
		label: 'After',
		value: 1
	});
	/* Radio Checkboxes */
	const [optionPage, setOptionPage] = useState(true);
	const [optionSetFirst, setOptionSetFirst] = useState(false);
	const [optionSetLast, setOptionSetLast] = useState(false);
	/* Get input count */
	const [inputNumberPage, setInputNumberPage] = useState(1);
	/* Show file's name uploaded */
	const files = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>);
	/* Erase file selected */
	const emptyFiles = () => {
		acceptedFiles.splice(0, acceptedFiles.length);
	};

	const isTXT = acceptedFiles[0]?.name.split('.').pop() === 'txt';

	const fileTxtToBlob = async (file) => {
		return new Promise((resolve, reject) => {
			if (!file.type.match('plain')) {
				return reject(new Error('INVALID_FILE'));
			}
			const reader = new FileReader();
			reader.readAsText(file);
			reader.onloadend = function () {
				const text = reader.result;
				// TODO: Add pages if text is too long
				const doc = new jsPDF();
				doc.setFontSize(12);
				doc.text(text, 10, 10, { maxWidth: 185 });
				const blob = doc.output('blob');
				resolve(blob);
			};
		});
	};

	const selectFile = async () => {
		if (isTXT) return fileTxtToBlob(acceptedFiles[0]);
		if (isPDFFile(acceptedFiles[0])) return acceptedFiles[0];

		const officeToPDF = await optionActivated.Core.officeToPDFBuffer(acceptedFiles[0]);
		return new Blob([officeToPDF], { type: 'application/pdf' });
	};

	const openDocuments = async () => {
		try {
			if (acceptedFiles.length >= 1 && files) {
				const isFileTypeNotDocument = isMediaFile(acceptedFiles[0]);
				if (isFileTypeNotDocument && !existDocument) {
					formatFile(acceptedFiles[0]);
					setModalMerge(false);
					hideLoader();
					return;
				}

				setFileSelected(null);

				const file = await selectFile();
				optionActivated?.UI?.loadDocument(file, {
					extension: 'pdf',
					l: process.env.REACT_APP_PDFTRON_FE
				});

				setModalMerge(true);
			}
		} catch (error) {
			const optionsAlert = {
				type: 'file',
				message: `${files[0].key} could not be uploaded as the file is corrupted.`
			};
			hideLoader(optionsAlert); //parameter for show alert when the user try upload a corrupt or broken file
		}
	};

	/* Merge document depending what checkbox is selected */
	const mergeDocuments = async () => {
		if (acceptedFiles.length >= 1 && files) {
			const isFileTypeNotDocument = isMediaFile(acceptedFiles[0]);
			if (isFileTypeNotDocument) {
				formatFile(acceptedFiles[0]);
				setModalMerge(false);
				return;
			}

			const file = await selectFile();
			const docToInsert = await optionActivated.Core.createDocument(file, {
				extension: 'pdf',
				l: process.env.REACT_APP_PDFTRON_FE
			});
			const currentDoc = instanceCurrentPage.getDocument();
			let pageCount = currentDoc.getPageCount();
			let pageIndexToInsert;

			const listCurrentFilePages = Array.from(
				{ length: docToInsert.getPageCount() },
				(_, i) => i + 1
			);

			/* option: Merge by number */
			if (optionPage) {
				pageIndexToInsert =
					optionSelectedLocation.value === 1
						? pageCount - (totalPagesDocumentViewer - (inputNumberPage + 1))
						: pageCount - (totalPagesDocumentViewer - inputNumberPage);
			}
			/* option: Last Page */
			if (optionSetLast) {
				pageIndexToInsert = optionSelectedLocation.value === 1 ? pageCount + 1 : pageCount;
			}
			/* Option: First Page */
			if (optionSetFirst) {
				pageIndexToInsert =
					optionSelectedLocation.value === 1
						? pageCount - (pageCount - 1) + 1
						: pageCount - (pageCount - 1);
			}

			currentDoc.insertPages(docToInsert, listCurrentFilePages, pageIndexToInsert).then(() => {
				hideLoader();
			});
		}
	};

	/* Function to set input page checkbox */
	const handleChange = (event) => {
		const value = Math.max(Math.min(totalPagesDocumentViewer, Number(event.target.value)));
		setInputNumberPage(value);
	};

	const resetModal = () => {
		onHide();
		setInputNumberPage(1);
		emptyFiles();
		setAccepted(false);
		setErrors('');
	};

	useEffect(() => {
		if (modalMerge) {
			if (optionSetLast) {
				setOptionSetLast(true);
			}

			if (optionSetFirst) {
				setOptionSetFirst(true);
			}
			if (optionPage) {
				setOptionPage(true);
			}
		}
	}, [modalMerge, optionSetLast, optionSetFirst, optionPage]);

	useEffect(() => {
		if (instanceCurrentPage && totalPagesDocumentViewer > 0) {
			setModalMerge(true);
		} else {
			setModalMerge(false);
		}

		//eslint-disable-next-line
	}, [instanceCurrentPage]);

	/* HTML */
	return (
		<>
			<Modal
				show={show}
				onHide={resetModal}
				size="xl"
				aria-labelledby="insert-document-title"
				centered
			>
				<div ref={insertDocument}>
					<Formik
						enableReinitialize
						initialValues={{
							pageCheckBox: optionPage,
							lastCheckBox: optionSetLast,
							firstCheckBox: optionSetFirst,
							numberInput: '',
							locationLabel: ''
						}}
						onSubmit={(values) => {
							onSubmit(values);
						}}
					>
						{(props) => (
							<Form onSubmit={props.handleSubmit}>
								<Modal.Header
									closeLabel={`${modalMerge ? 'Insert File' : 'Upload File'} Close Dialog`}
									className="mb-4"
									closeButton
								>
									<Modal.Title id="insert-document-title">
										{modalMerge ? 'Insert File' : 'Upload File'}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<section className="container">
										<div
											{...getRootProps({ className: 'dropzone' })}
											className={`text-center ${styles.containerDropZone}`}
										>
											<input {...getInputProps()} id="fileSupport" name="fileSupport" />
											<p className={styles.subTitle}>
												Drag and drop file here, or click to select a file
											</p>
											<Button>Open File Explorer</Button>
											{accepted ? (
												<p style={{ color: 'green', padding: 5, margin: 0, fontSize: 23 }}>
													<i className="fas fa-check-circle"></i>
												</p>
											) : (
												<p style={{ color: 'red', padding: 5, margin: 0, fontSize: 14 }}>
													{errors}
												</p>
											)}
										</div>

										<aside>
											<h5 className={styles.subTitle}>File name: </h5>
											<ul>{files}</ul>
										</aside>

										{modalMerge && (
											<Fragment>
												<div className={styles.grid}>
													<div>
														<h6>Page: </h6>
														<Col className="mb-4" xs={12} lg={4}>
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="radio"
																	name="flexRadioDefault"
																	id="firstCheckBox"
																	onChange={(e) => {
																		setOptionSetFirst(e.target.checked);
																		setOptionPage(false);
																		setOptionSetLast(false);
																	}}
																	checked={props.values.firstCheckBox}
																/>
																<label className="form-check-label" htmlFor="firstCheckBox">
																	First
																</label>
															</div>
														</Col>

														<Col xs={12} lg={4} className="mb-4">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="radio"
																	name="flexRadioDefault"
																	id="lastCheckBox"
																	onChange={(e) => {
																		setOptionSetLast(e.target.checked);
																		setOptionPage(false);
																		setOptionSetFirst(false);
																	}}
																	checked={props.values.lastCheckBox}
																/>
																<label className="form-check-label" htmlFor="lastCheckBox">
																	Last
																</label>
															</div>
														</Col>

														<Col xs={12} lg={12} className="mb-4">
															<input
																className="form-check-input"
																type="radio"
																name="flexRadioDefault"
																id="pageCheckBox"
																checked={props.values.pageCheckBox}
																onChange={(e) => {
																	setOptionPage(e.target.checked);
																	setOptionSetLast(false);
																	setOptionSetFirst(false);
																}}
															/>
															&nbsp;&nbsp;
															<label className="form-check-label" htmlFor="pageCheckBox">
																Page:
															</label>{' '}
															&nbsp;
															<input
																min="1"
																id="numberInput"
																name="numberInput"
																type="number"
																className={styles.inputPages}
																value={inputNumberPage || 1}
																onChange={handleChange}
															></input>
															&nbsp;
															<label className="form-check-label" htmlFor="flexRadioDefault2">
																of {totalPagesDocumentViewer}
															</label>
														</Col>
													</div>
													<div>
														<Col xs={12} lg={4} className="mb-4">
															<label htmlFor="Party" className="form-label">
																Location:
															</label>
															<Select
																id="locationLabel"
																name="locationLabel"
																options={location}
																isSearchable={false}
																getOptionLabel={(option) => option.label}
																getOptionValue={(option) => option.value}
																defaultValue={{
																	label: 'After',
																	value: 1
																}}
																value={optionSelectedLocation}
																onChange={(selected) => setOptionSelectedLocation(selected)}
																filterOption={fixFilterOptions()}
															/>
														</Col>
													</div>
												</div>
											</Fragment>
										)}
									</section>
								</Modal.Body>

								<Modal.Footer>
									<button
										type="submit"
										onClick={() => {
											if (acceptedFiles.length >= 1 && files) {
												showLoader(false);
											}
											!modalMerge ? openDocuments() : mergeDocuments();
											resetModal();
										}}
										className="btn btn-primary"
									>
										Submit
									</button>
									<button onClick={resetModal} type="button" className="btn btn-secondary">
										Cancel
									</button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</>
	);
}

export default Index;
