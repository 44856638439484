import { Container, Row, Col } from 'react-bootstrap';

const PageLoading = () => {
	return (
		<Container className="vh-100 d-flex flex-column justify-content-center">
			<Row className="flex-grow-1 align-items-center justify-content-center">
				<Col lg={8} sm={10}>
					<div
						className="d-flex flex-column gap-3 text-center"
						aria-live="polite"
						role="alert"
						aria-busy="true"
					>
						<i className="fas fa-spinner fa-pulse fa-3x text-primary"></i>
						<span className="fw-bold h4 text-secondary">Loading, please wait</span>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default PageLoading;
