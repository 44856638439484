import { formatInTimeZone } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { useEffect, useRef, useState } from 'react';
import { formatTimeFormat, timezoneFormat } from '../../context/ConfigsContext/utils';
import { useConfigs } from '../../hooks/useConfigs';

const Clock = () => {
	const [clock, setClock] = useState();
	const { timeZone, timeFormat } = useConfigs();

	const clockRef = useRef();

	useEffect(() => {
		clearInterval(clockRef.current);
		clockRef.current = null;
		clockRef.current = setInterval(() => {
			const date = formatInTimeZone(
				new Date(),
				timezoneFormat(timeZone),
				`${formatTimeFormat(timeFormat)} b zzz`,
				{
					locale: enUS
				}
			);
			setClock(date);
		}, 1000);

		return () => clearInterval(clockRef.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeZone]);

	return <div>{clock}</div>;
};

export default Clock;
