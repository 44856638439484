import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InternalMessagingService } from '../../services/InternalMessageService';
import classes from './styles.module.scss';
import { SUCCESFUL_REQUEST_CODE } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateNumberOfUnreadMessages } from '../../redux/reducers/internalMessaging.reducer';
const InternalMessagingIcon = () => {
	const { totalUnreadMessage } = useSelector((state) => state.internalMessaging);
	const { getMessagesWithStatusFilter } = InternalMessagingService();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const fetchUnReadMessages = async () => {
		try {
			const response = await getMessagesWithStatusFilter();
			if (SUCCESFUL_REQUEST_CODE.includes(response.status)) {
				dispatch(updateNumberOfUnreadMessages(response.data.totalElements));
			} else {
				dispatch(updateNumberOfUnreadMessages(0));
			}
		} catch (error) {
			dispatch(updateNumberOfUnreadMessages(0));
			console.error(error);
		}
	};

	useEffect(() => {
		fetchUnReadMessages();
	}, []);

	return (
		<a className="text-darkblue">
			<i
				className={`fa fa-envelope h3 m-0 p-0 ${classes.alertIcon}`}
				aria-hidden="true"
				aria-label="Internal Messaging"
				title="Internal Messaging"
				onClick={() => navigate('/internal-messaging')}
				role="button"
			>
				<span className={classes.alertCounter}>{totalUnreadMessage}</span>
			</i>
		</a>
	);
};

export default InternalMessagingIcon;
