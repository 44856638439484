import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useConfigs } from '../../../hooks/useConfigs';
import { format, isValid } from 'date-fns';
import styles from './styles.module.scss';
import Flag from '../../Flags';
import { getFormattedDate, wordInString } from '../../../utils';
import { useState } from 'react';
import WarrantLabel from '../../CaseBlock/WarrantLabel';

/**
 * TableRow.
 * @param {Object} item Individual data registry
 * @param {Object[]} columns Array of information about columns properties
 * @param {boolean} enableRowLink Enables rows link feature
 * @param {Function} onRowFocus Event trigerred when a row is focused for update, it returns rowIndex, column and textContent
 * @param {Function} setRowData Handle row data change
 * @param {boolean} showActions Show actions column
 * @param {func} onRowEdited handle on row edition
 * @param {number} rowIndex index of row
 * @param {boolean} showTime Show time in date values
 * @param {boolean} showTimeOnly Show only time in date values
 * @param {showTimeAndDate} Show date and 12hr time format Am/Pm
 * @param {boolean} showDateOnly Show only date part in date values
 * @returns {ReactElement} returns JSX with the component TableRow.
 */

const DOCUMENT_ICON = 'documentIcon';

const TableRow = ({
	item,
	columns,
	enableRowLink,
	enableRowFunction,
	expandedCheckboxFn,
	skipColumnExpandHeading,
	columnExpandHeading,
	expandableItemSelectFunction,
	enableExpandRow,
	refreshTable,
	editMode,
	setRowData,
	showActions,
	onRowEdited,
	onKeyDownCell,
	rowIndex,
	onRowFocus,
	customInput,
	showTime = false,
	showTimeOnly = false,
	disableDateFormatting = false,
	showTimeAndDate = false,
	showAsIs,
	nowrap = false,
	showTooltip,
	setShowRowToolTip,
	isSearching,
	customDateFormat
}) => {
	const { dateFormat, timeFormat } = useConfigs();
	const [isExpanded, setIsExpanded] = useState(false);

	const renderItem = (item, value, dateValue) => {
		const { heading } = columns.find((column) => column.value === value);
		if (showAsIs) {
			return item[value] || '-';
		}
		const checkIfNumber = isNaN(Number(item[value]));
		const date = new Date(item[value]);
		const isValidDate = isValid(date);

		const checkIfIncludesDate = () => {
			return wordInString(heading.toLowerCase(), 'date');
		};

		if ((!checkIfNumber && !isValidDate) || disableDateFormatting) return item[value] ?? '-';

		if (isValidDate && checkIfNumber && (checkIfIncludesDate() || dateValue)) {
			const dateLength = item[value].length;
			const newDate =
				dateLength === 10 ? new Date(`${item[value].replace(/-/g, '/')}`) : new Date(item[value]);
			if (showTime) {
				return format(newDate, `${dateFormat} ${timeFormat}`);
			}

			if (showTimeOnly) {
				return format(newDate, timeFormat);
			}
			if (showTimeAndDate) {
				return format(newDate, customDateFormat ?? `${dateFormat} ${'hh:mm a'}`);
			}

			return format(newDate, dateFormat);
		}

		const checkIfIncludesFlags = () => {
			return wordInString(heading.toLowerCase(), 'flags');
		};

		if (checkIfIncludesFlags()) {
			const ReturnedFlags = () => {
				return (
					<>
						{item[value].map((element, index) => {
							return (
								<div key={index} className={styles.flagColumn}>
									<Flag flag={element.description} cardFlags={false} />
								</div>
							);
						})}
					</>
				);
			};

			return <ReturnedFlags />;
		}

		// Check if column displays Case active Warrant status
		if (wordInString(heading.toLowerCase(), 'warrant')) {
			return <WarrantLabel activeWarrant={item[value]} className={'text-dark'} />;
		}

		return item[value] ?? '-';
	};

	const rowClickedFn = (heading, isDisableRedirect) => {
		heading !== skipColumnExpandHeading &&
			(enableRowLink || enableRowFunction) &&
			!isDisableRedirect &&
			setRowData(item);
		if (enableExpandRow && heading !== skipColumnExpandHeading) {
			setIsExpanded(!isExpanded);
		}
	};

	return (
		<>
			{columns.map(
				(
					{
						heading,
						value,
						type,
						editable = false,
						isCustomInput = false,
						render,
						startSymbol,
						endSymbol,
						date
					},
					index
				) => {
					const onChange = (e) =>
						onRowEdited &&
						editable &&
						onRowEdited({
							item: item,
							textContent: e.target.textContent,
							column: value,
							rowIndex: rowIndex
						});

					const onFocus = (e) =>
						onRowFocus &&
						onRowFocus({
							textContent: e.target.textContent,
							column: value,
							rowIndex: rowIndex
						});
					if (type === 'checkbox') {
						return (
							<td key={index} onMouseOver={() => setShowRowToolTip()}>
								<Form.Check
									className={`label-options checkbox-${value}`}
									aria-label={`checkbox-${value}`}
									aria-live={isSearching ? 'assertive' : 'off'}
									type="checkbox"
									role="row"
									data-testid={`checkbox-${value}-${item.id}`}
									name={`checkbox-${value}-${item.id}`}
									checked={item[value] ?? false}
									onChange={(e) =>
										onRowEdited({
											id: item.id,
											value,
											checked: e.target.checked
										})
									}
								/>
							</td>
						);
					}

					if (type === 'dateFormat') {
						return (
							<td
								onMouseOver={() => setShowRowToolTip()}
								key={index}
								aria-live={isSearching ? 'assertive' : 'off'}
								onClick={() => {
									(enableRowLink || enableRowFunction) && setRowData(item);
								}}
								onMouseDown={() => {
									(enableRowLink || enableRowFunction) && setRowData(item);
								}}
								style={enableRowLink || enableRowFunction ? { cursor: 'pointer' } : null}
							>
								{getFormattedDate(item[value], dateFormat)}
							</td>
						);
					}

					const isDisableRedirect = type === 'disableRedirect';

					const HeaderOrData = index === 0 ? 'th' : 'td';
					let scopeAttribute = {};
					if (index === 0) {
						scopeAttribute.scope = 'row';
					}

					return (
						<HeaderOrData
							{...scopeAttribute}
							onMouseOver={() => value !== DOCUMENT_ICON && setShowRowToolTip()}
							className={showActions ? 'data-item' : 'data-item last-data-item'}
							onClick={() => {
								if (value !== DOCUMENT_ICON) {
									!item.disableUniqueRowClick && rowClickedFn(heading, isDisableRedirect);
								}
							}}
							key={index}
							style={
								(enableRowLink || enableRowFunction || item[enableExpandRow]) &&
								heading !== skipColumnExpandHeading
									? {
											cursor: value !== DOCUMENT_ICON ? 'pointer' : 'default',
											textAlign: enableExpandRow && 'start',
											paddingLeft: enableExpandRow && '1rem',
											fontWeight: '300'
									  }
									: { fontWeight: '300' }
							}
						>
							{enableExpandRow ? (
								<div className={`${value !== 'enablePermission' ? 'd-flex' : null}`}>
									{item[enableExpandRow] && heading === columnExpandHeading ? (
										<i className={`fas fa-caret-${isExpanded ? 'down' : 'right'} mt-1 me-2`}></i>
									) : null}
									{editable && isCustomInput && customInput ? (
										customInput(rowIndex)
									) : (
										<div className={editable ? 'editable-container' : ''}>
											{startSymbol !== null ? <span>{startSymbol}</span> : ''}
											<div
												tabIndex={enableRowFunction || editable ? 0 : -1}
												role={'cell'}
												aria-label={`${
													render ? render(item, value) : renderItem(item, value, date)
												} ${enableRowFunction && 'clickable'}`}
												aria-live={isSearching ? 'assertive' : 'off'}
												contentEditable={editable}
												suppressContentEditableWarning
												onKeyDown={onKeyDownCell}
												style={{
													display: 'inline'
												}}
												onFocus={() => setShowRowToolTip()}
												onInput={onChange}
											>
												{render ? render(item, value) : renderItem(item, value, date)}
											</div>
											{endSymbol !== null ? <span>{endSymbol}</span> : ''}
										</div>
									)}
								</div>
							) : editable && isCustomInput && customInput ? (
								customInput(rowIndex)
							) : (
								<div className={editable ? 'editable-container' : ''}>
									{startSymbol !== null ? <span>{startSymbol}</span> : ''}
									<div
										tabIndex={enableRowFunction || editable ? 0 : -1}
										role={'cell'}
										aria-label={`${render ? render(item, value) : renderItem(item, value, date)} ${
											enableRowFunction && 'clickable'
										}`}
										aria-live={isSearching ? 'assertive' : 'off'}
										contentEditable={editable}
										suppressContentEditableWarning
										onKeyDown={onKeyDownCell}
										style={{
											display: 'inline'
										}}
										onFocus={() => setShowRowToolTip()}
										onInput={onChange}
									>
										{render ? render(item, value) : renderItem(item, value, date)}
									</div>
									{endSymbol !== null ? <span>{endSymbol}</span> : ''}
								</div>
							)}
							{isExpanded && (
								<table
									style={{
										display: 'flex',
										justifyContent: heading === skipColumnExpandHeading ? 'center' : 'start'
									}}
								>
									<tbody>
										{item[enableExpandRow].map((i, index) => (
											<tr key={'row-' + index}>
												{heading !== skipColumnExpandHeading ? (
													<td
														key={'description-' + index}
														style={{
															paddingTop: index === 0 ? '25px' : '0',
															paddingBottom: '10px',
															paddingLeft: '5%',
															whiteSpace: 'nowrap'
														}}
													>
														<i
															className="fas fa-circle"
															style={{ transform: 'scale(.3)', opacity: '.7' }}
														></i>{' '}
														{i.description}
													</td>
												) : (
													<td key={'checkbox-' + index}>
														<Form.Check
															className={`label-options ${styles['checkbox-align']}`}
															style={{
																marginTop: index === 0 ? '15px' : '0',
																marginBottom: '5px'
															}}
															type="checkbox"
															data-testid={`checkbox-${index}`}
															aria-label={`label-checkbox-${index}`}
															aria-live={isSearching ? 'assertive' : 'off'}
															name={`checkbox-${index}`}
															checked={i.assigned}
															onChange={() => {
																i.assigned = !i.assigned;
																expandedCheckboxFn && expandedCheckboxFn(i, item[enableExpandRow]);
																expandableItemSelectFunction(item);
															}}
															disabled={i.value === 'update:allsystem' ? true : !editMode}
														/>
													</td>
												)}
											</tr>
										))}
									</tbody>
								</table>
							)}
						</HeaderOrData>
					);
				}
			)}
		</>
	);
};

TableRow.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	enableRowLink: PropTypes.bool,
	item: PropTypes.object.isRequired,
	onRowFocus: PropTypes.func,
	setRowData: PropTypes.func,
	showActions: PropTypes.bool
};

export default TableRow;
