import { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import TableWithIntegration from '../../../components/layout/TableWithIntegration/TableWithIntegration';
import { useMockTable } from '../../../hooks/useMockTable';

const ModalDocumentInformation = ({ getDocumentFileLoaded, fileLoaded, disabled, hidden }) => {
	const { title, type, file } = fileLoaded;

	const [show, setShow] = useState(false);

	const columns = useMemo(() => [
		{ heading: 'Property', value: 'property' },
		{ heading: 'Details', value: 'details' }
	]);

	const mapper = (item) => ({
		...item
	});

	const {
		isLoading,
		data,
		customFunction: setData,
		paginationItems
	} = useMockTable({
		initialValues: null,
		key: 'documentInformation',
		mapper,
		preventFetch: true
	});

	useEffect(async () => {
		if (show && title && type && file) {
			const size = formatBytes(file.size);

			const data = [
				{ property: 'File Title', details: title },
				{ property: 'File Type', details: type },
				{ property: 'File Size', details: size }
			];

			setData(data);
		}
	}, [show, fileLoaded]);

	const formatBytes = (a, b = 2) => {
		if (!+a) return '0 Bytes';
		const c = 0 > b ? 0 : b,
			d = Math.floor(Math.log(a) / Math.log(1024));
		return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
			['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
		}`;
	};

	const hideModal = () => setShow(false);

	const ModalComponent = () => {
		return (
			<Modal
				data-testid="modal-document-information"
				show={show}
				onHide={hideModal}
				aria-labelledby="document-information-modal-title"
				centered
				backdrop="static"
				size="lg"
			>
				<Modal.Header closeLabel={`Document Information Close Dialog`} closeButton>
					<Modal.Title id="document-information-modal-title">Document Information</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<TableWithIntegration
						tableId="table-document-information"
						data={data ?? []}
						columns={columns}
						exportCSV
						exportPDF
						customOrder={false}
						showSearch
						limitRows
						printEnable={false}
						paginationItems={paginationItems}
						isLoading={isLoading}
					/>
				</Modal.Body>

				<Modal.Footer>
					<Button type="button" onClick={hideModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	return (
		<>
			<Button
				disabled={disabled}
				onClick={() => {
					getDocumentFileLoaded();
					setShow(true);
				}}
				hidden={hidden}
			>
				View Document Information
			</Button>
			{ModalComponent()}
		</>
	);
};

export default ModalDocumentInformation;
