import { HttpService } from './HttpService';
import { BASE_URL } from '../utils/apiUtils';

export const TableServices = () => {
	const { getExportDocument } = HttpService();

	const exportTableToExcel = async (urlSearched) => {
		const url = `${BASE_URL}${urlSearched}`;
		let tempURL = url.split('?');
		tempURL = tempURL.join('/excel?');

		try {
			const response = await getExportDocument(tempURL, 'Export file', {});

			var blob = new Blob([response.data], {
				type: response.headers['content-type']
			});
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `report_${new Date().getTime()}.xlsx`;
			link.click();
			return response.data;
		} catch {
			console.error('exportTableToExcel func is not working propertly');
		}
	};

	return {
		exportTableToExcel
	};
};
