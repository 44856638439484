import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';
import { useSelector } from 'react-redux';
import useUserStore from '../redux/utils/storeUtils';

export const HomeAdminService = () => {
	const { get, post, put, deleteFn } = HttpService();
	const username = useSelector((state) => state.user.userInfo?.username);
	const userId = useSelector((state) => state.user.userInfo?.id);
	const { selectedClerkId } = useUserStore();

	const serialize = (obj, excludeNullValues) => {
		var str = [];
		for (var p in obj)
			if (obj.hasOwnProperty(p)) {
				if (excludeNullValues && (obj[p] === null || obj[p] === undefined)) {
					continue;
				}

				str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
			}
		return str.join('&');
	};

	const getBookmark = (userName) => {
		const url = `${BASE_URL}/api/bookmark-management/bookmarks-by-username-and-clerk?userName=${username}&clerkId=${selectedClerkId}`;
		return get(url, 'getBookmark', {});
	};

	const delBookmark = (targetItem) => {
		const url = `${BASE_URL}/api/bookmark-management/bookmark/delete/${targetItem}`;
		return deleteFn(url, 'delBookmark', {});
	};

	const updateBookmark = (body, id, userName) => {
		const url = `${BASE_URL}/api/bookmark-management/bookmark/update/${id}?username=${userName}&clerkId=${selectedClerkId}`;
		return put(url, body, 'updateBookmark', {});
	};

	const insertBookmark = (body, userName) => {
		const url = `${BASE_URL}/api/bookmark-management/bookmark/save?username=${userName}&clerkId=${selectedClerkId}`;
		return post(url, body, 'insertBookmark', {});
	};

	const getRecentNotifications = () => {
		const url = `${BASE_URL}/api/code-table-management/code-tables/categories/fee_code_priority`;
		return get(url, 'getBookmark', {});
	};

	const getRecentAccess = () => {
		const url = `${BASE_URL}/api/code-table-management/code-tables/categories/fee_code_priority`;
		return get(url, 'getBookmark', {});
	};

	const getTableSelect = (urlTable) => {
		const url = `${BASE_URL}/api/page-view/setting?userId=${userId}&clerkId=${selectedClerkId}&pageUrl=${urlTable}`;
		return get(url, 'getTableSelect', {});
	};

	const insertTableSelect = (body, urlTable) => {
		const url = `${BASE_URL}/api/page-view/setting?userId=${userId}&clerkId=${selectedClerkId}&pageUrl=${urlTable}`;
		return post(url, body, 'insertTableSelect', {});
	};

	const searchByCaseNumber = (caseNumber, clerkId) => {
		const url = `${BASE_URL}/api/case/search?caseNumber=${caseNumber}&category=case&clerkDataId=${clerkId}&fromQuickSearch=false&page=0&size=10`;
		return get(url, 'searchByCaseNumber', {});
	};

	const searchByPartyLastName = (lastName, clerkId) => {
		const url = `${BASE_URL}/api/party-management/search?lastName=${lastName}&category=party&clerkDataId=${clerkId}&page=0&size=10`;
		return get(url, 'searchByPartyLastName', {});
	};

	const searchByPartyFirstName = (firstName, clerkId) => {
		const url = `${BASE_URL}/api/party-management/search?firstName=${firstName}&category=party&clerkDataId=${clerkId}&page=0&size=10`;
		return get(url, 'searchByPartyFirstName', {});
	};

	const searchByPartyName = (searchParams) => {
		const url = `${BASE_URL}/api/party-management/search?${serialize(searchParams, true)}`;
		return get(url, 'searchByPartyLastName', {});
	};

	const searchByOrganization = (organizationName, clerkId) => {
		const url = `${BASE_URL}/api/party-management/search?organizationName=${organizationName}&category=party&clerkDataId=${clerkId}&page=0&size=10`;
		return get(url, 'searchByOrganization', {});
	};

	const searchByCaseNumberFromQuickSearch = (caseNumber, clerkId) => {
		const url = `${BASE_URL}/api/case/search?caseNumber=${caseNumber}&category=case&clerkDataId=${clerkId}&fromQuickSearch=true&page=0&size=10`;
		return get(url, 'searchByCaseNumber', {});
	};

	const getIsInvetoryAvailable = () => {
		const url = `${BASE_URL}/api/inventory/availability`;
		return get(url, 'getIsInvetoryAvailable', {});
	};

	return {
		getBookmark,
		delBookmark,
		updateBookmark,
		insertBookmark,
		getRecentNotifications,
		getRecentAccess,
		getTableSelect,
		insertTableSelect,
		searchByCaseNumber,
		searchByPartyLastName,
		searchByPartyFirstName,
		searchByOrganization,
		searchByPartyName,
		searchByCaseNumberFromQuickSearch,
		getIsInvetoryAvailable
	};
};
