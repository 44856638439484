/* eslint-disable */
import { useEffect } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';

import { scrollToTop } from '../utils/behaviours';

export const useClosePanelsOnMobileNavigation = () => {
	const [
		leftPanelOpen,
		handleOpenLeftPanel,
		closeLeftPanel,
		rightPanelOpen,
		handleOpenRightPanel,
		closeRightPanel
	] = useOutletContext();
	const location = useLocation();

	useEffect(() => {
		try {
			closeLeftPanel();
			closeRightPanel();
			scrollToTop();
		} catch (error) {}
	}, [location]);

	return null;
};
