import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col } from 'react-bootstrap';

import styles from './styles.module.scss';

import AlertDialog from '../../AlertDialog/index.jsx';

import {
	exportTableToCSV,
	allDataToCSV,
	allDataToPDF,
	createPDF
} from '../../../services/tableFunctions.js';
import { TableServices } from '../../../services/tableServices';

/**
 * TableToolbar.
 * @param {boolean} exportCSV Show export to csv action
 * @param {boolean} exportPDF Show export to pdf action
 * @param {boolean} exportExcel Show export to excel action
 * @param {string} urlSearched Returns the API url for the table data
 * @param {boolean} limitRows Show column limiter dropdown
 * @param {ref} outerTable Reference to the table iteration render to print it as pdf
 * @param {number} rowsDisplayed Number of rows showed in table
 * @param {boolean} showSearch Show search input
 * @param {ref} table Reference to the table component to print it as pdf
 * @param {number} numberOfRecords Total of elements accesible from the database
 * @param {object} showToolbarActionButtons object to hide and show action buttons on the top of the table
 * @param {object} paginationSizeArray array to show the option of pagination sizes
 * @returns {ReactElement} returns JSX with the component TableToolbar.
 */

const TableToolbar = ({
	exportCSV,
	exportPDF,
	exportExcel,
	urlSearched,
	showToolbarActionButtons = {
		showCsvButton: true,
		showPdfButton: true,
		showExcelButton: true,
		showNumberOfRecordButton: true
	},
	limitRows,
	outerTable,
	items,
	rowsDisplayed,
	table,
	changesWithoutSaving,
	showSearch,
	windowSize,
	numberOfRecords,
	listSizeFn,
	columns,
	allData,
	enableSelection,
	selectedRows,
	enableRadio,
	radioSelectedRow,
	radioColumnTitle,
	formTitle,
	tableId,
	addHeaderToPDF,
	pdfHeaderProps,
	paginationSizeArray
}) => {
	const [showDialogModal, setShowDialogModal] = useState({ open: false, itemClicked: '' });
	const { exportTableToExcel } = TableServices();
	//Reset to all if setRows has value not found in paginationSizeArray
	rowsDisplayed = paginationSizeArray?.includes(rowsDisplayed) ? rowsDisplayed : '';
	const { showCsvButton, showPdfButton, showExcelButton, showNumberOfRecordButton } =
		showToolbarActionButtons;

	const printSelectOptions = {
		enableSelection,
		selectedRows,
		enableRadio,
		radioSelectedRow,
		radioColumnTitle,
		tableId
	};

	const footer = (
		<>
			<Button
				onClick={() => {
					listSizeFn(showDialogModal.item);
					setShowDialogModal({ open: false, itemClicked: '' });
				}}
				variant="primary"
				className={styles.button}
			>
				Yes
			</Button>
			<Button
				onClick={() => setShowDialogModal({ open: false, itemClicked: '' })}
				variant={'secondary'}
				className={styles.button}
			>
				No
			</Button>
		</>
	);

	return (
		<div className={`${styles.toolBarContainer}`} data-testid="TableToolbar">
			<AlertDialog
				title={'Changes will not be saved'}
				open={showDialogModal.open}
				onClose={() => {
					setShowDialogModal({ open: false, itemClicked: '' });
				}}
				footer={footer}
			>
				<Col>
					Please, save the changes before continuing otherwise, your changes will be lost. Do you
					want to continue?
				</Col>
			</AlertDialog>
			<div className={`${styles.buttonContainer}`}>
				{showCsvButton && (
					<button
						type="button"
						className="btn btn-sm btn-primary"
						style={{ display: exportCSV ? 'inline-block' : 'none' }}
						onClick={() => {
							allData
								? allDataToCSV(columns, allData, printSelectOptions)
								: exportTableToCSV('filename.csv', ',', table, tableId);
						}}
						aria-label={`${formTitle}-Export table to CSV`}
					>
						CSV
					</button>
				)}
				{showPdfButton && (
					<button
						type="button"
						className="btn btn-sm btn-primary pdf-table-button"
						style={{ display: exportPDF ? 'inline-block' : 'none' }}
						onClick={() => {
							const currentData = allData || items;
							currentData
								? allDataToPDF(
										columns,
										currentData,
										printSelectOptions,
										pdfHeaderProps,
										addHeaderToPDF
								  )
								: createPDF(outerTable);
						}}
						aria-label={`${formTitle}-Export table to PDF`}
					>
						PDF
					</button>
				)}
				{showExcelButton && (
					<button
						type="button"
						className="btn btn-sm btn-primary"
						style={{ display: exportExcel ? 'inline-block' : 'none' }}
						onClick={() => exportTableToExcel(urlSearched)}
						aria-label={`${formTitle}-Export table to Excel`}
					>
						Excel
					</button>
				)}
				{showNumberOfRecordButton && (
					<>
						<button
							type="button"
							aria-expanded="false"
							className="btn btn-sm btn-primary"
							data-bs-toggle="dropdown"
							aria-label={`${formTitle}-Show ${rowsDisplayed === '' ? 'All' : rowsDisplayed}`}
							style={{ display: limitRows ? 'inline-block' : 'none' }}
						>
							Show {rowsDisplayed === '' ? 'All' : rowsDisplayed}
							<i className="fas fa-caret-down fa-xs"></i>
						</button>
						<ul aria-label="Limit table rows" className={`dropdown-menu ${styles.listLimit}`}>
							{paginationSizeArray?.map((item, index) => (
								<li key={index}>
									<a
										href="/#"
										role="button"
										aria-label={`Show ${item} rows`}
										className={`${styles.dropdownItem} dropdown-item`}
										onClick={(e) => {
											e.preventDefault();

											if (changesWithoutSaving) {
												setShowDialogModal({ open: true, itemClicked: item });
											} else {
												listSizeFn(item);
											}
										}}
									>
										{item}
									</a>
								</li>
							))}
						</ul>
					</>
				)}
			</div>
		</div>
	);
};

TableToolbar.propTypes = {
	exportCSV: PropTypes.bool,
	exportPDF: PropTypes.bool,
	exportExcel: PropTypes.bool,
	urlSearched: PropTypes.string,
	showToolbarActionButtons: PropTypes.shape({
		showCsvButton: PropTypes.bool,
		showPdfButton: PropTypes.bool,
		showExcelButton: PropTypes.bool,
		showNumberOfRecordButton: PropTypes.bool
	}),
	limitRows: PropTypes.bool,
	outerTable: PropTypes.any,
	rowsDisplayed: PropTypes.number || PropTypes.string,
	showSearch: PropTypes.bool,
	table: PropTypes.any
};

export default TableToolbar;
