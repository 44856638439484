// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_button__2aztg {\n  padding-left: 3rem !important;\n  padding-right: 3rem !important;\n}\n\n.style_text-error__fQs0m {\n  color: red;\n}", "",{"version":3,"sources":["webpack://./src/components/PDFTronComponent/Alerts/style.module.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;EACA,8BAAA;AACD;;AAEA;EACC,UAAA;AACD","sourcesContent":[".button {\n\tpadding-left: 3rem !important;\n\tpadding-right: 3rem !important;\n}\n\n.text-error {\n\tcolor: red\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "style_button__2aztg",
	"text-error": "style_text-error__fQs0m"
};
export default ___CSS_LOADER_EXPORT___;
