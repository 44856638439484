import { BASE_URL } from '../../../../utils';
import { format } from 'date-fns';

export const AdvancedSearchUtils = () => {
	const getCommaSeperatedCodes = (codes) =>
		codes ? codes.map((code) => code.value).join(',') : '';

	const buildDataToSubmit = (category, values) => {
		const dataToSubmit = {
			...values[category],
			category,
			jurisdiction: [values?.jurisdiction],
			courtId: values?.courtId?.value
		};
		if (category === 'event') {
			dataToSubmit.categoryCode = values?.[category]?.categoryCode.value;
		}
		if (category === 'case') {
			dataToSubmit.sealed = values[category].sealed ? 1 : 0;
			dataToSubmit.categoryCode = getCommaSeperatedCodes(values.case.categoryCode);
			dataToSubmit.caseNumber = values[category].caseNumber.trim();
		}
		if (category === 'party') {
			dataToSubmit.soundsLike = values[category].soundsLike === true ? 'true' : 'false';
			dataToSubmit.groupByPerson = values[category].groupByPerson === true ? 'true' : 'false';
		}

		if (values?.jurisdiction === '' || values?.jurisdiction?.value === 'all') {
			dataToSubmit.jurisdiction = values.allJurisdictions
				.filter((item) => item.value !== 'all')
				.map((item) => item);

			delete dataToSubmit.courtId;
		}

		return dataToSubmit;
	};

	const buildSearchUrls = (dataToSubmit, searchPath, renderJurisdictionForm, category = '') => {
		let searchUrls = [];
		let jurisdications = renderJurisdictionForm ? dataToSubmit.jurisdiction : [null];
		jurisdications.forEach((jurisdiction) => {
			let params = new URLSearchParams();
			for (const [key, value] of Object.entries(dataToSubmit)) {
				if (value) {
					switch (key) {
						case 'jurisdiction':
							const clerkDataId =
								category === 'financial'
									? jurisdiction?.clerkId
									: jurisdiction?.clerkDataId?.clerkDataId;

							if (renderJurisdictionForm)
								params.append(category === 'financial' ? 'clerkId' : 'clerkDataId', clerkDataId);

							break;
						case 'paymentCode':
							if (value.value) params.append('paymentCode', value.value);
							break;
						case 'paymentDateFrom':
							params.append('paymentDateFrom', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'paymentDateTo':
							params.append('paymentDateTo', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'eventType':
							if (value.value) params.append('eventType', value.value);
							break;
						case 'eventCode': //Events Event Type
							if (value.value) params.append('eventCode', value.value);
							break;
						case 'partyCode':
							if (value.value) params.append('partyCode', value.value);
							break;
						case 'idType':
							if (value.value) params.append('idType', value.value);
							break;
						case 'classification':
							if (value.value) params.append('classification', value.value);
							break;
						case 'dateOfBirth':
							params.append('dateOfBirth', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'filingDateTo':
							params.append('filingDateTo', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'filingDateFrom':
							params.append('filingDateFrom', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'offenseDateFrom':
							params.append('offenseDateFrom', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'offenseDateTo':
							params.append('offenseDateTo', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'hearingDateTo':
							params.append('hearingDateTo', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'hearingDateFrom':
							params.append('hearingDateFrom', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'dateFrom':
							params.append('dateFrom', format(new Date(value), 'MM/dd/yyyy'));
							break;
						case 'dateTo':
							params.append('dateTo', format(new Date(value), 'MM/dd/yyyy'));
							break;
						case 'enteredDateFrom': //Events Entered Date From
							params.append('enteredDateFrom', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'enteredDateTo': //Events Entered Date To
							params.append('enteredDateTo', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'dueDateFrom': //Events Due Date From
							params.append('dueDateFrom', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'dueDateTo': //Events Due Date To
							params.append('dueDateTo', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'dispositionDateFrom': //Events Due Date From
							params.append('dispositionDateFrom', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'dispositionDateTo': //Events Due Date From
							params.append('dispositionDateTo', format(new Date(value), 'yyyy-MM-dd'));
							break;
						case 'judge':
							if (value?.value) params.append('judgeId', value?.value);
							break;
						case 'hearingStatus':
							if (value?.value) params.append('hearingStatusId', value?.value);
							break;
						case 'hearingType':
							if (value?.value) params.append('hearingTypeId', value?.value);
							break;
						case 'genderId':
							if (value?.value) params.append('genderId', value.value);
							break;
						case 'categoryCode':
							params.append('categoryCode', `${value}`);
							break;
						case 'subCategoryCode':
							params.append('subCategoryCode', value?.code);
							break;
						case 'caseType':
							params.append('caseTypeCode', value?.code);
							break;
						case 'caseStatus':
							params.append('caseStatusId', value?.value);
							break;
						case 'cjisCode':
							params.append('cjisCode', value?.value);
							break;
						case 'soundsLike':
							params.append('soundsLike', value);
							break;
						case 'groupByPerson':
							params.append('groupByPerson', value);
							break;
						case 'firstName':
							params.append('firstName', value?.toString()?.trim());
							break;
						case 'middleName':
							params.append('middleName', value?.toString()?.trim());
							break;
						case 'lastName':
							params.append('lastName', value?.toString()?.trim());
							break;
						case 'organizationName':
							params.append('organizationName', value?.toString()?.trim());
							break;
						case 'filingType':
							value.length > 0 &&
								params.append('filingType', value.map((item) => item.value).join(','));
							break;
						case 'dispositionType':
							value.length > 0 &&
								params.append('dispositionType', value.map((item) => item.value).join(','));
							break;
						default:
							params.append(key, value);
							break;
					}
				}
			}

			const url =
				category === 'party' && params.get('groupByPerson') === 'true'
					? `${BASE_URL}/api/${searchPath}/groupByPerson?${params}`
					: `${BASE_URL}/api/${searchPath}/search?${params}`;

			searchUrls.push({ path: url, jurisdiction: jurisdiction });
		});
		return searchUrls;
	};

	const categoryCodeLabelMapper = {
		C: 'Criminal',
		V: 'Civil',
		F: 'Family',
		P: 'Probate',
		G: 'Guardianship',
		J: 'Juvenile'
	};

	return {
		buildDataToSubmit,
		buildSearchUrls,
		categoryCodeLabelMapper
	};
};

export const caseListTitle = 'Case List Report';
export const dailyEventReportTitle = 'Daily Event Report';
