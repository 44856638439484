import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';
import _ from 'lodash';

import styles from './styles.module.scss';

/**
 * TablePaginator.
 * @param {number} currentPage Current page index
 * @param {Object[]} data Array of data showed in the table
 * @param {number} rowsDisplayed Number of rows to display
 * @param {Function} setCurrentPage Handle current page change
 * @param {number} totalElements Total of item accesible from the database
 * @param {number} totalPages Total of pages used in pagination
 * @returns {ReactElement} returns JSX with the component TablePaginator.
 */

const TableMobilePaginator = ({
	hidePagination,
	currentPage,
	data,
	rowsDisplayed,
	setCurrentPage,
	totalElements,
	totalPages
}) => {
	const paginationClickHandler = (page) => setCurrentPage(page);

	const EntriesCounter = () => (
		<div
			data-testid={`PaginationEntries-Text`}
			className="d-flex flex-row justify-content-lg-start justify-content-center align-items-center"
		>
			Showing {currentPage ? currentPage * rowsDisplayed + 1 : _.isEmpty(data) ? 0 : 1} to{' '}
			{data && !_.isNaN(currentPage) ? currentPage * rowsDisplayed + data.length : 0} of{' '}
			{totalElements ? totalElements : 0} {totalElements === 1 ? 'entry' : 'entries'}
		</div>
	);

	const Previous = () => (
		<Pagination.Prev
			data-testid={`Pagination-Prev`}
			disabled={currentPage > 0 ? false : true}
			onClick={() => {
				paginationClickHandler(currentPage - 1);
			}}
			onMouseDown={() => {
				paginationClickHandler(currentPage - 1);
			}}
		>
			Previous
		</Pagination.Prev>
	);

	const FirstPage = () => (
		<Pagination.Item
			data-testid={`PaginationItem`}
			active={currentPage + 1 === 1 ? true : false}
			onClick={() => {
				let goTo =
					currentPage + 1 === totalPages && totalPages >= 3
						? totalPages - 3
						: currentPage < 3
						? 0
						: currentPage - 1;
				paginationClickHandler(goTo);
			}}
			onMouseDown={() => {
				let goTo =
					currentPage + 1 === totalPages && totalPages >= 3
						? totalPages - 3
						: currentPage < 3
						? 0
						: currentPage - 1;
				paginationClickHandler(goTo);
			}}
			style={totalPages < 1 || !totalPages ? { display: 'none' } : { display: 'initial' }}
		>
			{currentPage >= totalPages - 2 && totalPages >= 3
				? totalPages - 2
				: currentPage >= 3
				? currentPage
				: 1}
		</Pagination.Item>
	);

	const SecondPage = () => (
		<Pagination.Item
			data-testid={`PaginationItem`}
			active={
				(currentPage + 1 === totalPages - 1 && totalPages >= 3) ||
				(currentPage + 2 < totalPages && currentPage + 1 !== 1 && currentPage + 1 !== 3) ||
				currentPage + 1 === 2
					? true
					: false
			}
			onClick={() => {
				let goTo =
					currentPage + 1 === totalPages && totalPages >= 3
						? totalPages - 2
						: currentPage > 2
						? currentPage
						: 1;
				paginationClickHandler(goTo);
			}}
			onMouseDown={() => {
				let goTo =
					currentPage + 1 === totalPages && totalPages >= 3
						? totalPages - 2
						: currentPage > 2
						? currentPage
						: 1;
				paginationClickHandler(goTo);
			}}
			style={totalPages < 2 || !totalPages ? { display: 'none' } : { display: 'initial' }}
		>
			{currentPage >= totalPages - 2 && totalPages >= 3
				? totalPages - 1
				: currentPage >= 3
				? currentPage + 1
				: 2}
		</Pagination.Item>
	);

	const ThirdPage = () => (
		<Pagination.Item
			data-testid={`PaginationItem`}
			active={currentPage + 1 === 3 || currentPage + 1 === totalPages ? true : false}
			onClick={() => {
				let goTo =
					currentPage + 1 === totalPages && totalPages >= 3
						? totalPages - 1
						: currentPage > 2
						? currentPage + 1
						: 2;
				paginationClickHandler(goTo);
			}}
			onMouseDown={() => {
				let goTo =
					currentPage + 1 === totalPages && totalPages >= 3
						? totalPages - 1
						: currentPage > 2
						? currentPage + 1
						: 2;
				paginationClickHandler(goTo);
			}}
			style={totalPages < 3 || !totalPages ? { display: 'none' } : { display: 'initial' }}
		>
			{currentPage >= totalPages - 2 && totalPages >= 3
				? totalPages
				: currentPage >= 3
				? currentPage + 2
				: 3}
		</Pagination.Item>
	);

	const Next = () => (
		<Pagination.Next
			data-testid={`Pagination-Next`}
			disabled={currentPage + 1 === totalPages || totalPages < 2 || !totalPages}
			onClick={() => paginationClickHandler(currentPage + 1)}
			onMouseDown={() => paginationClickHandler(currentPage + 1)}
		>
			Next
		</Pagination.Next>
	);

	return (
		<div
			className={`${
				hidePagination ? 'd-none' : 'd-flex'
			} flex-lg-row flex-column gap-2 justify-content-lg-between justify-content-center my-2`}
		>
			<EntriesCounter />

			<div className="d-flex justify-content-lg-end justify-content-center align-items-center ">
				<div className={`d-flex flex-row align-items-center ${styles.paginationContainer}`}>
					<Pagination>
						<Previous />
						<FirstPage />
						<SecondPage />
						<ThirdPage />
						<Next />
					</Pagination>
				</div>
			</div>
		</div>
	);
};

TableMobilePaginator.propTypes = {
	currentPage: PropTypes.number.isRequired,
	data: PropTypes.arrayOf(PropTypes.object),
	rowsDisplayed: PropTypes.number.isRequired,
	setCurrentPage: PropTypes.func.isRequired,
	totalElements: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired
};

export default TableMobilePaginator;
