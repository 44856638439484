import React from 'react';

const WarrantLabel = ({ activeWarrant, className }) => {
	return activeWarrant ? (
		<div className={`badge badge-sm bg-orange ${className}`}>Warrant</div>
	) : (
		<span>-</span>
	);
};

export default WarrantLabel;
