import { Button } from 'react-bootstrap';
import AlertDialog from '../AlertDialog';
import { createModal } from 'react-modal-promise';

const PromiseAlert = ({
	isOpen,
	onReject,
	onResolve,
	type,
	children,
	title,
	submitText,
	cancelText,
	onResolveCondition,
	onRejectCondition
}) => {
	const onResolveFn = async () => {
		if (onResolveCondition) {
			try {
				await onResolveCondition();
				onResolve(true);
			} catch (e) {
				console.log(e);
			}
		} else {
			onResolve(true);
		}
	};

	const onRejectFn = async () => {
		if (onRejectCondition) {
			try {
				await onRejectCondition();
				onReject();
			} catch (e) {
				console.log(e);
			}
		} else {
			onReject();
		}
	};

	return (
		<AlertDialog
			open={isOpen}
			onClose={() => onReject()}
			backdrop="static"
			keyboard={false}
			animation={false}
			title={title}
			type={type}
			footer={
				<>
					<Button onClick={onResolveFn}>{submitText}</Button>
					<Button onClick={onRejectFn} variant="secondary">
						{cancelText}
					</Button>
				</>
			}
		>
			{children}
		</AlertDialog>
	);
};

const promiseAlert = createModal(PromiseAlert);

export default promiseAlert;
