export const columns = [
	{ heading: 'Transaction Number', value: 'transactionId' },
	{ heading: 'Transaction Date', value: 'transactionDate' },
	{ heading: 'Payment Amount', value: 'amount' },
	{ heading: 'Case Number', value: 'caseNumber' },
	{ heading: 'Last Name', value: 'lastName' },
	{ heading: 'First Name', value: 'firstName' },
	{ heading: 'Processed By', value: '' }
];

export const mapper = (item) => ({
	...item,
	transactionId: item.transactionId,
	transactionDate: item.transactionDate,
	amount: `$${item.amount}`,
	caseNumber: item.caseNumber,
	lastName: item.lastName,
	firstName: item.firstName,
	processedBy: item.processedBy
});

export const modalMapper = (item) => ({
	...item,
	caseNumber: item.caseNo,
	style: item.style
});

export const statusOptions = [
	{
		label: 'Pending',
		value: false
	},
	{
		label: 'Processed',
		value: true
	}
];

export const modalColumns = [
	{ heading: 'Case Number', value: 'caseNo' },
	{ heading: 'Style', value: 'style' }
];
