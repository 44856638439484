import * as Yup from 'yup';

export const initialValues = {
	requester: '',
	type: '',
	priority: '',
	subject: '',
	description: ''
};

export const validationSchema = Yup.object().shape({
	requester: Yup.string().required('Requester is required'),
	type: Yup.string().required('Type is required'),
	priority: Yup.string().required('Priority is required'),
	subject: Yup.string().required('Subject is required'),
	description: Yup.string().required('Description is required')
});

export const typeOptions = [
	{ value: 'Bug', label: 'Bug' },
	{ value: 'Feature Request', label: 'Feature Request' },
	{ value: 'Other', label: 'Other' }
];

export const priorityOptions = [
	{ value: 'Low', label: 'Low' },
	{ value: 'Medium', label: 'Medium' },
	{ value: 'High', label: 'High' }
];
