import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { CheckboxInput } from '../../common';
import Error from '../../../pages/Case/Error';
import DragAndDrop from '../../FormikFactory/utils/DragAndDrop';

const CreateSignatureImageForm = ({
	setSignatureImage,
	setAcceptTerms,
	acceptTerms,
	validate,
	onSubmit,
	alert,
	termsAlert
}) => {
	const initialValues = { files: '', signatureAuthorization: false };

	return (
		<>
			<Formik initialValues={initialValues} validationSchema={validate} onSubmit={onSubmit}>
				{(formik) => (
					<Form onSubmit={formik.handleSubmit} id="signatureImageForm">
						<Row>
							<DragAndDrop
								onChange={(file) => {
									formik.setFieldValue(file);
									setSignatureImage(file);
								}}
								name={'files'}
								label={'Signature File'}
								fileType={{
									'application/image': ['.jpg', '.png', '.tiff']
								}}
								required={true}
								disabled={false}
							/>
							<Error condition={alert} msg={'Please upload a signature file.'} />
						</Row>
						<Row>
							<CheckboxInput
								className="mt-3"
								id="signatureAuthorization"
								label="By checking this box, I understand that this is a legal representation of my signature."
								name="signatureAuthorization"
								required={true}
								val={acceptTerms}
								checked={acceptTerms}
								onChecked={setAcceptTerms}
							/>
							<Error condition={termsAlert} msg={'Please accept terms.'} />
						</Row>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CreateSignatureImageForm;
