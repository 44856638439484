import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const InternalMessagingService = () => {
	const { get, post, put, deleteFn } = HttpService();

	const getMessages = (page = 0, size = 10) => {
		const url = `${BASE_URL}/api/inbox?page=${page}&size=${size}`;
		return get(url, 'getMessagesByUserId', []);
	};

	const GET_MESSAGE_LIST_URL = () => {
		return '/api/inbox';
	};

	const messagingGetActiveUsers = () => {
		const url = `${BASE_URL}/api/user-management/users/active`;
		return get(url, 'messagingGetActiveUsers', []);
	};
	const getMessageById = (messageId, threadId = null) => {
		const url = `${BASE_URL}/api/message/${messageId}${threadId ? `?threadId=${threadId}` : ''}`;
		return get(url, 'getMessageById', []);
	};
	const deleteMessageById = (messageId, threadId) => {
		const url = `${BASE_URL}/api/message/${messageId}${threadId ? `?threadId=${threadId}` : ''} `;
		return deleteFn(url, 'deleteMessageByIdAndRecipient', {});
	};
	const updateMessageStatus = (messageId) => {
		const url = `${BASE_URL}/api/inbox/message/${messageId}/is-read/true`;
		return put(url, 'updateMessageStatus', {});
	};
	const getMessagesWithStatusFilter = (isRead = false, page = 0, size = 1) => {
		const url = `${BASE_URL}/api/inbox?isRead=${isRead}&page=${page}&size=${size}`;
		return get(url, 'getMessagesWithStatusFilter', []);
	};
	const sendNewMessage = (body, isReply = false) => {
		const url = `${BASE_URL}/api/message${isReply ? '?isReply=true' : ''}`;
		return post(url, body, 'sendNewMessage', {});
	};

	return {
		messagingGetActiveUsers,
		getMessageById,
		getMessages,
		deleteMessageById,
		updateMessageStatus,
		getMessagesWithStatusFilter,
		sendNewMessage,
		GET_MESSAGE_LIST_URL
	};
};
