import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	newPayement: {
		party: '',
		caseId: '',
		sender: '',
		courtRegistry: {},
		isDisbursementFromEscrow: null
	}
};

const newPayementSlice = createSlice({
	initialState,
	name: 'newPayementSlice',
	reducers: {
		clearAll: (state) => {
			state.newPayement = { ...initialState.newPayement };
		},
		persistNewPayment: (state, action) => {
			state.newPayement = action.payload;
		}
	}
});

export const { clearAll, persistNewPayment } = newPayementSlice.actions;

export default newPayementSlice.reducer;
