// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_containerDropZone__63stk {\n  background-color: #f7f7f9;\n  border-radius: 0.5rem;\n  width: 100%;\n  height: 12rem;\n  margin: 0px;\n  border-width: 0.1 0.5rem;\n  border-style: solid;\n  border-color: gray;\n  color: rgba(0, 0, 0, 0.277);\n  justify-content: center;\n  align-items: center;\n}\n\n.styles_subTitle__HGdp8 {\n  margin-top: 2rem;\n}\n\ninput {\n  width: 25%;\n}\n\n.styles_grid__91BTw {\n  display: grid;\n  grid-template-columns: 50% 50%;\n}", "",{"version":3,"sources":["webpack://./src/pages/DocumentView/InsertDocument/Modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,qBAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,wBAAA;EACA,mBAAA;EACA,kBAAA;EACA,2BAAA;EACA,uBAAA;EACA,mBAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,UAAA;AACD;;AACA;EACC,aAAA;EACA,8BAAA;AAED","sourcesContent":[".containerDropZone {\n\tbackground-color: #f7f7f9;\n\tborder-radius: 0.5rem;\n\twidth: 100%;\n\theight: 12rem;\n\tmargin: 0px;\n\tborder-width: 0.1.5rem;\n\tborder-style: solid;\n\tborder-color: gray;\n\tcolor: rgba(0, 0, 0, 0.277);\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.subTitle {\n\tmargin-top: 2rem;\n}\n\ninput {\n\twidth: 25%;\n}\n.grid {\n\tdisplay: grid;\n\tgrid-template-columns: 50% 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerDropZone": "styles_containerDropZone__63stk",
	"subTitle": "styles_subTitle__HGdp8",
	"grid": "styles_grid__91BTw"
};
export default ___CSS_LOADER_EXPORT___;
