import { useRef, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import { HomeAdminService } from '../../../services/HomeAdminService';
import { SUCCESFUL_REQUEST_CODE } from '../../../utils';
import { formatDate } from '../../../utils';
import { debounce } from 'lodash';
import { QuickSearchService } from '../../../services/QuickSearchService';

const SearchBar = ({ toggleBtnRef }) => {
	const topSearchBarRef = useRef(null);
	const topSearchResultsRef = useRef(null);
	const topSearchInputRef = useRef(null);
	const { searchByFilter } = QuickSearchService();
	const { getBookmark } = HomeAdminService();
	const [listData, setListData] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const userInfo = useSelector((state) => state.user);
	const [selectedJurisdiction, setSelectedJurisdiction] = useState(null);

	useEffect(() => {
		if (Array.isArray(userInfo?.clerks) && userInfo?.jurisdiction) {
			setSelectedJurisdiction(userInfo.clerks.find((_) => _.clerkId === userInfo.jurisdiction));
		}
	}, [userInfo?.jurisdiction, userInfo?.clerks]);

	useEffect(() => {
		function handleTopSearchBarClickOutside(event) {
			if (
				topSearchBarRef.current &&
				!topSearchBarRef.current.contains(event.target) &&
				!toggleBtnRef.current.contains(event.target)
			) {
				// Hide Top Search Bar
				if (topSearchBarRef.current.classList.contains(`${styles.showTopSearchBar}`)) {
					topSearchBarRef.current.classList.remove(`${styles.showTopSearchBar}`);
				}
				// Hide Top Results
				if (topSearchResultsRef.current.classList.contains(`${styles.showTopResults}`)) {
					topSearchResultsRef.current.classList.remove(`${styles.showTopResults}`);
				}
			} else {
				if (event.which === 27) {
					// Hide Top Search Bar
					if (topSearchBarRef.current.classList.contains(`${styles.showTopSearchBar}`)) {
						topSearchBarRef.current.classList.remove(`${styles.showTopSearchBar}`);
					}
					// Hide Top Results
					if (topSearchResultsRef.current.classList.contains(`${styles.showTopResults}`)) {
						topSearchResultsRef.current.classList.remove(`${styles.showTopResults}`);
					}

					topSearchInputRef.current.blur();
				}
			}
		}

		document.addEventListener('mousedown', handleTopSearchBarClickOutside);
		document.addEventListener('keydown', handleTopSearchBarClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleTopSearchBarClickOutside);
			document.removeEventListener('keydown', handleTopSearchBarClickOutside);
		};
	});

	useEffect(() => {
		function focusSearchBar(event) {
			if ((event.keyCode === 191 || event.keyCode === 111) && event.altKey) {
				topSearchInputRef.current.focus();
			}
		}

		document.addEventListener('keydown', focusSearchBar);

		return () => {
			document.removeEventListener('keydown', focusSearchBar);
		};
	});

	useEffect(() => {
		const toggleBtn = toggleBtnRef.current;

		function handleToggleSearchBar() {
			if (topSearchBarRef.current.classList.contains(`${styles.showTopSearchBar}`)) {
				topSearchBarRef.current.classList.remove(`${styles.showTopSearchBar}`);
				topSearchResultsRef.current.classList.remove(`${styles.showTopResults}`);
			} else {
				topSearchBarRef.current.classList.add(`${styles.showTopSearchBar}`);
				topSearchResultsRef.current.classList.add(`${styles.showTopResults}`);
				topSearchInputRef.current.focus();
			}
		}

		toggleBtn.addEventListener('click', handleToggleSearchBar);

		return () => {
			toggleBtn.removeEventListener('click', handleToggleSearchBar);
		};
	});

	const updateList = () => {
		getBookmark()
			.then((res) => {
				setListData(res.data.bookmarkDTOS);
			})
			.catch((error) => console.log(error));
	};

	const handleTopSearchInputFocus = () => {
		if (
			topSearchResultsRef &&
			!topSearchResultsRef.current.classList.contains(`${styles.showTopResults}`)
		) {
			topSearchResultsRef.current.classList.add(`${styles.showTopResults}`);
		}

		if (
			topSearchBarRef &&
			!topSearchBarRef.current.classList.contains(`${styles.showTopSearchBar}`)
		) {
			topSearchBarRef.current.classList.add(`${styles.showTopSearchBar}`);
		}
		updateList();
	};

	const cleanUrl = (url) => {
		const cleanThis = new URL(url);
		return cleanThis.pathname;
	};

	const debouncedSearch = debounce((query) => {
		let cancelled = false;
		const fetchData = async (searchQuery) => {
			setIsLoading(true);
			const { status, data } = await searchByFilter(searchQuery?.trim(), 0, 10);
			if (SUCCESFUL_REQUEST_CODE.includes(status) && !cancelled) {
				setSearchResults(data.content);
				setIsLoading(false);
			} else {
				setSearchResults([]);
				setIsLoading(false);
			}
		};

		if (query) {
			fetchData(query);
		} else {
			setSearchResults([]);
			setIsLoading(false);
		}

		return () => {
			cancelled = true;
		};
	}, 1000);

	const handleSearchChange = (e) => {
		debouncedSearch(e.target.value);
	};

	const handlePromiseComplete = (res, array) => {
		if (SUCCESFUL_REQUEST_CODE.includes(res.status)) {
			const newData = res.data.content;
			newData.forEach((data) => {
				const existingResult = array.find((result) => result.caseId === data.caseId);
				if (!existingResult) {
					array.push(data);
				}
			});
		} else {
			console.error(res);
		}
	};

	return (
		<div className={`${styles.topSearchBar}`} ref={topSearchBarRef}>
			<form
				id={styles.topSearchForm}
				onSubmit={(e) => {
					e.preventDefault();
				}}
				role="search"
			>
				<i className="fas fa-search"></i>
				<input
					aria-label="Search"
					className="form-control"
					placeholder="Search"
					type="search"
					autoComplete="off"
					onFocus={handleTopSearchInputFocus}
					onChange={handleSearchChange}
					ref={topSearchInputRef}
				/>
				<nav className={`${styles.searchResultsWrap}`} ref={topSearchResultsRef}>
					<Row>
						<Col className={`${styles.searchLinks}`}>
							<div className="h6 fw-bold">
								Search results for {selectedJurisdiction?.countyCodeDescription}{' '}
								{selectedJurisdiction?.clerkTypeDescription}{' '}
							</div>
							{isLoading && (
								<div className="d-flex justify-content-center mb-2">
									<div className="spinner-border" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							)}
							{searchResults?.length > 0 && (
								<ul className={styles.resultList}>
									{searchResults?.slice(0, 10).map((data, index) => (
										<li key={index} className={styles.resultItem}>
											<Link to={`/case/${data.caseId}`}>
												<div className={styles.resultInfo}>
													<span className={styles.resultLabel}>Case Number: </span>
													<span>{data.caseNo}</span>
												</div>
												<div className={styles.resultInfo}>
													<span className={styles.resultLabel}>Case Style: </span>
													<span>{data.style}</span>
												</div>
												<div className={styles.resultInfo}>
													<span className={styles.resultLabel}>Filing Date: </span>
													<span>{formatDate(data.filingDate)}</span>
												</div>
											</Link>
										</li>
									))}
								</ul>
							)}

							<ul>
								<li>
									<Link to="/search">Advanced Search</Link>
								</li>
							</ul>
							<span className="h6 fw-bold">Bookmarks</span>
							<ul>
								{listData?.slice(0, 15).map((data, index) => (
									<li key={index}>
										<Link to={cleanUrl(data.url)}>{data.name}</Link>
									</li>
								))}
							</ul>
						</Col>
					</Row>
				</nav>
			</form>
		</div>
	);
};

export default SearchBar;
