import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { isEmpty, isUndefined } from 'lodash';

import AttachModal from '../Modal';

function Index({
	optionActivated,
	instanceDocumentViewer,
	showLoader,
	hideLoader,
	formatFile,
	setFileSelected,
	buttonTextUpload = 'Upload Document',
	buttonTextInsert = 'Insert Document',
	buttonClassName = '',
	existDocument,
	hidden,
	disabled,
	mediaFile
}) {
	const [newAnnotation, setNewAnnotation] = useState(false);
	const [optionActive, setOptionActive] = useState({});
	const [pagesDocumentViewer, setTotalPagesDocumentViewer] = useState([]);
	const [instanceCurrentPage, setInstanceCurrentPage] = useState([]);

	useEffect(() => {
		if (instanceDocumentViewer) {
			passAction(false);
		}
	}, [instanceDocumentViewer]);

	const passAction = (withAnnotation = true) => {
		setOptionActive(optionActivated);

		setNewAnnotation(withAnnotation);
		setInstanceCurrentPage(instanceDocumentViewer);

		if (instanceDocumentViewer) {
			setTotalPagesDocumentViewer(
				optionActivated.Core?.documentViewer?.getPageCount(instanceDocumentViewer)
			);
		}
	};

	return (
		<>
			<Button
				id="upload-insert-doc"
				disabled={isEmpty(optionActivated) || isUndefined(optionActivated) || disabled || mediaFile}
				onClick={() => passAction(true)}
				className={buttonClassName}
				hidden={hidden}
			>
				{instanceDocumentViewer ? buttonTextInsert : buttonTextUpload}
			</Button>
			<AttachModal
				optionActivated={optionActive}
				show={newAnnotation}
				onHide={() => {
					setNewAnnotation(false);
				}}
				onSubmit={() => {}}
				totalPagesDocumentViewer={pagesDocumentViewer}
				instanceCurrentPage={instanceCurrentPage}
				showLoader={showLoader}
				hideLoader={hideLoader}
				formatFile={formatFile}
				setFileSelected={setFileSelected}
				existDocument={existDocument}
			/>
		</>
	);
}

export default Index;
