import axios from 'axios';

// constantes
const initialState = {
	userInfo: null,
	clerks: null,
	jurisdiction: null
};

// types
const INIT_USER_INFO = 'INIT_USER_INFO';
const GET_USER_INFO = 'GET_USER_INFO';
const GET_USER_CLERKS = 'GET_USER_CLERKS';
const SET_USER_SELECTED_CLERK_ID = 'SET_USER_SELECTED_CLERK_ID';
const LOG_OUT = 'LOG_OUT';

// reducer
export default function userReducer(state = initialState, action) {
	const item = () => {
		switch (action.type) {
			case INIT_USER_INFO:
				return { ...state, ...action.payload };
			case GET_USER_INFO:
				return { ...state, userInfo: action.payload };
			case GET_USER_CLERKS:
				return { ...state, clerks: action.payload };
			case SET_USER_SELECTED_CLERK_ID:
				return { ...state, jurisdiction: action.payload };
			case LOG_OUT:
				return { ...state, userInfo: [], clerks: [], jurisdiction: null };
			default:
				return state;
		}
	};

	const cookieItem = JSON.stringify(item());

	switch (action.type) {
		case INIT_USER_INFO:
			return item();
		case GET_USER_INFO:
			localStorage.setItem('userInfo', cookieItem);
			return item();
		case GET_USER_CLERKS:
			localStorage.setItem('userInfo', cookieItem);
			return item();
		case SET_USER_SELECTED_CLERK_ID:
			localStorage.setItem('userInfo', cookieItem);
			return item();
		case LOG_OUT:
			localStorage.removeItem('userInfo');
			return item();
		default:
			return state;
	}
}

// actions
export const fecthUserInfo = (user, accessToken) => async (dispatch) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/user-management/user-context?username=${user}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`
				}
			}
		);

		dispatch({
			type: GET_USER_INFO,
			payload: res.data
		});
	} catch (error) {
		console.error('Unable to fetch User info', error);
	}
};

export const initUserInfo = (userInfo) => (dispatch) => {
	dispatch({
		type: INIT_USER_INFO,
		payload: userInfo
	});
};

export const fetchUserClerks = (user, accessToken) => async (dispatch) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/clerk/clerks-by-username?username=${user}`,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`
				}
			}
		);

		const selectedClerkId = res.data.find((e) => e.preference)
			? res.data.find((e) => e.preference).clerkId
			: res.data[0].clerkId;

		dispatch({
			type: GET_USER_CLERKS,
			payload: res.data
		});
		dispatch({
			type: SET_USER_SELECTED_CLERK_ID,
			payload: selectedClerkId
		});
	} catch (error) {
		console.error('Unable to fetch User assigned clerks', error);
	}
};

export const setUserSelectedClerkId = (clerkId) => (dispatch) => {
	dispatch({
		type: SET_USER_SELECTED_CLERK_ID,
		payload: clerkId
	});
};
