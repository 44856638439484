import { formatCurrency } from '../utils/currencyUtils';
import { formatDate, dateFormatYYYYMMDDslashed } from '../utils/dateUtils';

export const FEES_COLUMNS = [
	{ heading: 'Fees/Fines', value: 'fees' },
	{ heading: 'Amount', value: 'amount' },
	{ heading: 'Payment Amount', value: 'paymentAmount' },
	{ heading: 'Credit / Waived Amount', value: 'creditWaivedAmount' },
	{ heading: 'Current Balance', value: 'currentBalance' },
	{ heading: 'Date Due', value: 'dateDue', type: 'date' },
	{ heading: 'Party Responsible', value: 'responsibleParty' }
];

export const PAYMENT_HISTORY_COLUMNS = [
	{ heading: 'Receipt Number', value: 'paymentNo' },
	{ heading: 'Date', value: 'paymentDate', type: 'date' },
	{ heading: 'Category', value: 'caseCategory' },
	{ heading: 'Method', value: 'paymentMethods' },
	{ heading: 'Amount', value: 'amount' },
	{ heading: 'Party Responsible', value: 'partyResponsible' },
	{ heading: 'Paid By', value: 'paidBy' },
	{ heading: 'Voided', value: 'isVoid' }
];

export const VOIDED_AND_REVERSED_PAYMENTS = [
	{ heading: 'Reversed Payment Number', value: 'paymentNo' },
	{ heading: 'Reversal Receipt Number', value: 'reversedBy' },
	{ heading: 'Date Reversed', value: 'paymentDate', type: 'date' },
	{ heading: 'Type', value: 'type' },
	{ heading: 'Reasoning', value: 'reason' }
];

export const DISBURSEMENT_TABLE_COLUMNS = [
	{ heading: 'Disbursement Date', value: 'refundDate', type: 'date' },
	{ heading: 'Disbursed By', value: 'refundBy' },
	{ heading: 'Refund To', value: 'refundTo' },
	{ heading: 'Refund Method', value: 'refundMethod' },
	{ heading: 'Refund Check No', value: 'checkNumber' },
	{ heading: 'Check Date', value: 'checkDate', type: 'date' },
	{ heading: 'Amount', value: 'refundAmount' }
];

export const SELECTED_FIELDS = [
	{
		id: 5,
		section: 'Fees/Fines'
	},
	{
		id: 6,
		section: 'Payment History'
	},
	{
		id: 7,
		section: 'Disbursement History'
	}
];

export const URL_OPEN = '/api/fees';
export const URL_CLOSED = '/api/fees';

export const MAPPER = ({
	feeId,
	description = '',
	amount = 0,
	moneyDistReference = 0,
	dateDue,
	responsibleParty,
	feeCode
}) => ({
	id: feeId,
	fees: description,
	amount: formatCurrency(amount),
	paymentAmount: formatCurrency(moneyDistReference),
	creditWaivedAmount: formatCurrency(0),
	currentBalance: formatCurrency(amount - moneyDistReference),
	dateDue: formatDate(dateDue),
	responsibleParty: responsibleParty ?? '-',
	feeCode
});
export const KEY = 'fees';
export const keyFeesPerParty = 'feesPerParty';
export const mapperFeesPerParty = (data) => {
	const mappedFees = data.fees.map((fee) => ({
		...fee,
		id: fee.feeId,
		fees: fee.description,
		dateDue: formatDate(fee.dateDue),
		amountPaid: formatCurrency(fee.paymentAmount),
		creditWaivedAmount: formatCurrency(fee.waivedAmount),
		totalAmount: formatCurrency(fee.amount),
		remainingBalance: formatCurrency(fee.currentBalance)
	}));

	return {
		...data,
		fees: mappedFees
	};
};
export const urlPaymentHistory = (caseId) => `/api/payments/case/${caseId}`;
export const MAPPER_PAYMENT_HISTORY = (payment) => ({
	...payment,
	id: payment.paymentNo,
	paymentDate: dateFormatYYYYMMDDslashed(payment.paymentDate ?? ''),
	amount: formatCurrency(payment.paymentAmount ?? 0),
	receiptNumber: payment.receiptNumber ?? '-',
	isVoid: payment.isVoid ? 'Y' : 'N',
	methods:
		payment.methods && payment.methods.length > 0
			? payment.methods.map((x) => x.description).join(', ')
			: '-'
});
export const KEY_PAYMENT_HISTORY = 'content';
export const URL_REVERSED_PAYMENT = (caseId) => `/api/${caseId}/payments/reversed`;
export const KEY_REVERSED_PAYMENT = 'reversedPayments';
export const MAPPER_REVERSED_PAYMENT = (payment) => ({
	...payment,
	id: payment.paymentNo,
	paymentDate: dateFormatYYYYMMDDslashed(payment.reversedDate ?? ''),
	reason: payment.reason ?? '-',
	type: payment.type ?? '-'
});

const addUndefined = (val1, val2) => {
	const a = val1 ?? 0;
	const b = val2 ?? 0;
	return a + b;
};

export const EMPTY_FEES_TOTAL = {
	fees: 'Total',
	amount: formatCurrency(0),
	paymentAmount: formatCurrency(0),
	creditWaivedAmount: formatCurrency(0),
	currentBalance: formatCurrency(0),
	dateDue: '-',
	responsibleParty: '-'
};

export const totalfeeReducer = (item, prev) => ({
	fees: 'Total',
	amount: item.amount + prev.amount,
	paymentAmount: addUndefined(item.paymentAmount, prev.paymentAmount),
	creditWaivedAmount: addUndefined(item.creditWaivedAmount, prev.creditWaivedAmount),
	currentBalance: addUndefined(item.currentBalance, prev.currentBalance)
});

export const totalFormatter = (reduced) => ({
	fees: 'Total',
	amount: formatCurrency(reduced.amount),
	paymentAmount: formatCurrency(reduced.paymentAmount),
	creditWaivedAmount: formatCurrency(reduced.creditWaivedAmount),
	currentBalance: formatCurrency(reduced.currentBalance),
	dateDue: '-',
	responsibleParty: '-'
});
