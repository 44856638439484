import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const NotificationService = () => {
	const { get, post, put, deleteFn } = HttpService();

	const getNotifications = () => {
		const url = `${BASE_URL}/api/recent-notification`;
		return get(url, 'getNotifications', {});
	};

	const getSystemNotification = () => {
		const url = `${BASE_URL}/api/slr-notification/recent-notification`;
		return get(url, 'getSystemNotification', {});
	};

	return {
		getNotifications,
		getSystemNotification
	};
};
