import { useFormik, FormikProvider } from 'formik';
import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import { RECEIPT_FORM_SCHEMA } from './ReceiptFormSchema';

import SelectInput from '../../components/common/SelectInput';
import { ErrorLabel } from '../../components/forms';
import { InputFormCustom } from '../../components/InputForm';
import { isMobile } from 'react-device-detect';
import RequiredMark from '../../components/common/RequiredMark';

const ReceiptFormModal = ({ setShow, show, setShowPDF, setShowPos, setButtonText, setData }) => {
	const INITIAL_VALUES = {
		option: 'print',
		receiptSize: '',
		emailAdress: '',
		phoneNumber: ''
	};

	const pageSizes = [
		{ value: 'F', label: 'Full Page' },
		{ value: 'S', label: 'POS 2 1/4 in.' }
	];
	const onSubmit = async (e) => {
		if (e.option === 'email') {
			setData(e.emailAdress);
			setButtonText('Send Email');
			setShowPDF(true);
		} else if (e.option === 'text') {
			setData(e.phoneNumber);
			setButtonText('Send SMS');
			setShowPDF(true);
		}
		if (e.option === 'print' && e.receiptSize.value === 'S') {
			setShowPos(true);
		} else if (e.receiptSize.value === 'F') {
			if (e.option === 'print') {
				if (!isMobile) {
					setButtonText('Print');
				} else {
					setButtonText('Download');
				}
			}
			setShowPDF(true);
		}
		setShow(false);
	};
	const formik = useFormik({
		initialValues: INITIAL_VALUES,
		validationSchema: RECEIPT_FORM_SCHEMA,
		onSubmit: onSubmit
	});

	return (
		<FormikProvider value={formik}>
			<Modal
				size="lg"
				show={show}
				onHide={() => {
					formik.resetForm();
					setShow(false);
				}}
				aria-label="Dialog Print Send Form"
				backdrop="static"
			>
				<Form>
					<Modal.Header closeLabel={`Print/Send Receipt Close Dialog`} closeButton>
						<Modal.Title>Print/Send Receipt</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<h5>Receipt Options</h5>
							<Row>
								<Col className="mb-3">
									<Form.Check
										onChange={() => {
											formik.resetForm();
											formik.setFieldValue('option', 'print');
										}}
										type={'radio'}
										label={`Print`}
										name="option"
										value="print"
										checked={formik.values.option === 'print'}
										id={`print`}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={12} className="mb-3">
									<SelectInput
										id={'receiptSize'}
										isSearchable={true}
										label={'Receipt Size'}
										onChange={(e) => formik.setFieldValue('receiptSize', e)}
										isInvalid={formik.touched.receiptSize && formik.errors.receiptSize}
										options={pageSizes}
										placeholderText={'- Select -'}
										required={formik.values.option === 'print'}
										value={formik.values.receiptSize || ''}
										isDisabled={formik.values.option !== 'print'}
									></SelectInput>
									<ErrorLabel
										errors={formik.errors}
										touched={formik.touched}
										value={'receiptSize'}
									/>
								</Col>
							</Row>
						</div>
						<Row>
							<Col className="mb-3">
								<Form.Check
									onChange={() => {
										formik.resetForm();
										formik.setFieldValue('option', 'email');
									}}
									type={'radio'}
									label={`Email`}
									name="option"
									value="email"
									checked={formik.values.option === 'email'}
									id={`email`}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={12} className="mb-3">
								<Form.Label htmlFor="emailAdress">
									Email Address
									{formik.values.option === 'email' && <RequiredMark />}
								</Form.Label>
								<Form.Control
									id="emailAdress"
									name="emailAdress"
									type="text"
									placeholder="example@domain.com"
									className={
										formik.values.email &&
										formik.errors.emailAdress &&
										formik.touched.emailAdress &&
										'is-invalid form-control'
									}
									isInvalid={formik.touched.emailAdress && formik.errors.emailAdress}
									onChange={formik.handleChange}
									value={formik.values.emailAdress || ''}
									disabled={formik.values.option !== 'email'}
								/>
								<ErrorLabel errors={formik.errors} touched={formik.touched} value={'emailAdress'} />
							</Col>
						</Row>
						<Row>
							<Col className="mb-3">
								<Form.Check
									onChange={() => {
										formik.resetForm();
										formik.setFieldValue('option', 'text');
									}}
									type={'radio'}
									label={`Text`}
									name="option"
									checked={formik.values.option === 'text'}
									value="text"
									id={`text`}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={12} className="mb-3">
								<InputFormCustom
									id="phoneNumber"
									label="Phone Number"
									required={formik.values.option === 'text'}
									name="phoneNumber"
									placeholder="(xxx) xxx-xxxx"
									type="text"
									mask="(999) 999-9999"
									defaultValue={formik.values.option === 'text' ? formik.values.phoneNumber : ''}
									onChange={formik.handleChange}
									disabled={formik.values.option !== 'text'}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="primary"
							onClick={() => {
								formik.handleSubmit();
							}}
						>
							Submit
						</Button>
						<Button
							variant="secondary"
							onClick={() => {
								formik.resetForm();
								setShow(false);
							}}
						>
							Close
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</FormikProvider>
	);
};
export default ReceiptFormModal;
