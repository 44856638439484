import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SvgLogoImage = ({ className }) => {
	return (
		<svg
			aria-label={`iDocket logo`}
			id="iDocket_logo"
			className={className || 'brand-logo'}
			viewBox="0 0 1024 310"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			focusable="false"
		>
			<path
				id="dots"
				fill="#0070b9"
				stroke="none"
				strokeWidth="1"
				d="M 45.43,114.02
           C 50.03,112.21 60.67,112.91 66.00,113.00
             68.52,113.05 71.40,112.95 72.98,115.31
             74.21,117.16 74.00,120.81 74.00,123.00
             74.01,127.09 74.90,139.70 72.26,142.40
             70.36,144.34 66.53,143.99 64.00,144.00
             60.20,144.01 48.12,144.68 45.43,142.83
             42.53,140.83 43.01,136.12 43.00,133.00
             42.99,127.40 41.55,118.27 45.43,114.02 Z
           M 229.31,173.02
           C 233.92,171.27 244.66,171.91 250.00,172.00
             251.95,172.04 254.63,171.90 255.98,173.60
             257.23,175.19 257.00,179.01 257.00,181.00
             257.01,184.45 257.68,198.26 255.98,200.40
             254.41,202.38 250.30,202.00 248.00,202.00
             244.55,202.01 230.74,202.68 228.60,200.98
             226.62,199.41 227.00,195.30 227.00,193.00
             226.99,187.40 225.46,177.15 229.31,173.02 Z
           M 45.43,232.02
           C 50.03,230.21 60.67,230.91 66.00,231.00
             68.52,231.05 71.40,230.95 72.98,233.31
             74.62,235.78 74.02,248.40 74.00,252.00
             73.99,254.33 74.25,258.40 72.26,260.01
             70.72,261.25 66.94,261.00 65.00,261.00
             61.27,261.01 47.82,261.65 45.31,259.98
             42.55,258.14 43.01,253.91 43.00,251.00
             42.99,245.40 41.56,236.27 45.43,232.02 Z"
			/>
			<path
				id="bars"
				fill="#c8012a"
				stroke="none"
				strokeWidth="1"
				d="M 108.23,113.74
           C 113.87,112.25 138.44,113.00 146.00,113.00
             146.00,113.00 223.00,113.00 223.00,113.00
             223.00,113.00 248.00,113.00 248.00,113.00
             250.35,113.02 253.61,112.81 255.40,114.60
             257.32,116.52 256.99,120.46 257.00,123.00
             257.01,126.57 257.93,139.98 255.40,141.98
             253.81,143.23 249.99,143.00 248.00,143.00
             248.00,143.00 139.00,143.00 139.00,143.00
             139.00,143.00 114.00,143.00 114.00,143.00
             112.06,143.00 108.28,143.25 106.74,142.01
             104.62,140.30 105.00,135.50 105.00,133.00
             104.99,127.31 103.26,117.32 108.23,113.74 Z
           M 46.15,172.74
           C 51.86,171.27 76.38,172.00 84.00,172.00
             84.00,172.00 161.00,172.00 161.00,172.00
             161.00,172.00 186.00,172.00 186.00,172.00
             188.37,172.04 191.58,171.96 193.40,173.74
             195.34,175.64 194.99,179.47 195.00,182.00
             195.01,186.16 196.20,198.64 192.69,200.98
             190.84,202.21 187.19,202.00 185.00,202.00
             185.00,202.00 76.00,202.00 76.00,202.00
             76.00,202.00 52.00,202.00 52.00,202.00
             49.65,201.98 46.39,202.19 44.60,200.40
             42.68,198.48 43.01,194.54 43.00,192.00
             42.99,188.55 42.38,176.81 44.02,174.43
             44.91,173.13 45.55,173.15 46.15,172.74 Z
           M 108.15,231.74
           C 113.86,230.27 138.38,231.00 146.00,231.00
             146.00,231.00 223.00,231.00 223.00,231.00
             223.00,231.00 248.00,231.00 248.00,231.00
             250.37,231.04 253.58,230.96 255.40,232.74
             257.34,234.64 256.99,238.47 257.00,241.00
             257.01,244.55 257.93,257.85 255.43,259.83
             253.62,261.25 249.26,261.00 247.00,261.00
             247.00,261.00 138.00,261.00 138.00,261.00
             138.00,261.00 114.00,261.00 114.00,261.00
             111.63,260.96 108.42,261.04 106.60,259.26
             104.66,257.36 105.01,253.53 105.00,251.00
             104.99,247.55 104.38,235.81 106.02,233.43
             106.91,232.13 107.55,232.15 108.15,231.74 Z"
			/>
			<path
				id="idocket"
				fill="black"
				stroke="white"
				strokeWidth="2"
				d="M 303.60,46.02
           C 307.86,44.21 318.92,44.91 324.00,45.00
             326.52,45.05 329.40,44.95 330.98,47.31
             332.61,49.76 332.06,64.29 332.00,68.00
             331.96,70.14 332.04,72.77 330.40,74.40
             328.48,76.32 324.54,75.99 322.00,76.00
             318.49,76.01 305.88,76.64 303.57,74.83
             301.59,73.26 302.00,69.30 302.00,67.00
             301.99,61.81 300.79,49.94 303.60,46.02 Z
           M 360.00,259.00
           C 358.26,249.30 359.00,236.14 359.00,226.00
             359.00,226.00 359.00,170.00 359.00,170.00
             359.00,170.00 359.00,74.00 359.00,74.00
             359.00,74.00 359.00,54.00 359.00,54.00
             359.04,51.63 358.96,48.42 360.74,46.60
             362.64,44.66 366.47,45.01 369.00,45.00
             369.00,45.00 405.00,45.00 405.00,45.00
             410.34,45.03 418.86,46.10 424.00,47.49
             427.37,48.40 433.04,50.69 436.00,52.48
             458.64,66.17 458.00,89.05 458.00,112.00
             458.00,112.00 458.00,145.00 458.00,145.00
             458.00,145.00 458.00,174.00 458.00,174.00
             458.00,195.27 461.13,221.96 448.21,240.00
             434.15,259.64 413.39,259.00 392.00,259.00
             392.00,259.00 360.00,259.00 360.00,259.00 Z
           M 726.00,259.00
           C 726.00,259.00 704.00,259.00 704.00,259.00
             702.22,258.99 699.18,259.17 697.74,258.01
             695.62,256.30 696.00,251.50 696.00,249.00
             696.00,249.00 696.00,92.00 696.00,92.00
             696.00,92.00 696.00,60.00 696.00,60.00
             696.00,56.90 695.55,49.76 697.17,47.31
             699.03,44.53 703.06,45.01 706.00,45.00
             706.00,45.00 719.00,45.00 719.00,45.00
             726.18,45.14 726.90,47.16 727.00,54.00
             727.00,54.00 727.00,105.00 727.00,105.00
             727.00,105.00 727.00,149.00 727.00,149.00
             727.00,155.77 726.25,166.84 728.00,173.00
             728.00,173.00 745.86,140.00 745.86,140.00
             745.86,140.00 757.69,119.00 757.69,119.00
             759.49,115.69 761.90,109.89 765.18,108.02
             768.04,106.39 779.18,106.99 783.00,107.00
             795.20,107.02 792.53,111.45 787.58,120.00
             787.58,120.00 768.06,153.00 768.06,153.00
             766.21,156.02 760.05,166.01 759.88,169.00
             759.69,172.47 766.85,185.12 768.74,189.00
             768.74,189.00 790.76,235.00 790.76,235.00
             790.76,235.00 798.52,251.00 798.52,251.00
             799.48,253.10 800.52,256.26 798.52,257.98
             796.85,259.23 792.96,259.00 791.00,259.00
             791.00,259.00 775.00,259.00 775.00,259.00
             773.63,258.97 771.65,259.01 770.39,258.43
             767.17,256.92 764.20,247.44 762.72,244.00
             762.72,244.00 750.01,214.00 750.01,214.00
             748.84,211.15 745.12,201.22 743.31,199.54
             740.08,196.53 737.38,200.55 735.70,203.02
             731.69,208.90 727.13,214.72 727.00,222.00
             726.81,233.36 727.93,248.22 726.00,259.00 Z
           M 978.00,259.00
           C 965.52,259.00 952.66,260.56 942.01,252.45
             932.47,245.17 929.14,232.48 928.98,221.00
             928.98,221.00 928.98,141.00 928.98,141.00
             928.98,141.00 928.98,137.00 928.98,137.00
             928.09,127.26 919.69,133.49 915.60,129.40
             913.26,127.07 913.94,117.43 914.00,114.00
             914.22,102.45 921.30,109.57 926.69,105.98
             929.45,104.14 928.99,99.91 929.00,97.00
             929.00,97.00 929.00,69.00 929.00,69.00
             929.03,66.85 928.89,63.48 930.74,61.99
             932.28,60.75 936.06,61.00 938.00,61.00
             938.00,61.00 953.00,61.00 953.00,61.00
             960.80,61.14 959.99,64.14 960.11,71.00
             960.11,71.00 960.11,99.00 960.11,99.00
             960.02,100.11 960.03,101.89 960.11,102.94
             962.28,110.13 972.60,105.01 976.40,108.02
             978.83,109.94 978.05,120.76 978.00,124.00
             977.79,136.13 968.30,128.03 962.31,132.02
             959.55,133.86 960.01,138.09 960.00,141.00
             960.00,141.00 960.00,214.00 960.00,214.00
             960.02,227.73 964.59,230.88 978.00,231.00
             978.00,231.00 978.00,259.00 978.00,259.00 Z
           M 392.31,74.60
           C 389.71,76.79 390.07,77.62 390.00,81.00
             390.00,81.00 390.00,112.00 390.00,112.00
             390.00,112.00 390.00,203.00 390.00,203.00
             390.00,203.00 390.00,223.00 390.00,223.00
             390.01,225.14 389.71,228.36 391.57,229.83
             393.38,231.25 397.74,231.00 400.00,231.00
             400.00,231.00 404.00,231.00 404.00,231.00
             422.91,230.77 427.97,220.22 428.00,203.00
             428.00,203.00 428.00,101.00 428.00,101.00
             427.97,94.10 426.90,85.75 422.21,80.33
             416.16,73.32 400.71,72.57 392.31,74.60 Z
           M 517.00,105.68
           C 523.11,104.65 527.95,104.86 534.00,105.68
             538.07,106.64 543.36,107.99 547.00,109.91
             574.36,124.34 570.00,155.05 570.00,181.00
             570.00,181.00 570.00,204.00 570.00,204.00
             570.00,224.16 569.22,242.61 550.00,254.53
             547.34,256.18 542.99,258.33 540.00,259.22
             533.84,261.04 527.36,261.07 521.00,261.00
             515.75,260.93 510.90,260.10 506.00,258.17
             479.57,247.79 479.00,226.07 479.00,202.00
             479.00,202.00 479.00,172.00 479.00,172.00
             479.00,156.30 477.17,139.01 485.63,125.00
             492.98,112.82 503.63,107.91 517.00,105.68 Z
           M 625.00,105.43
           C 638.94,103.71 654.95,107.72 664.91,118.04
             671.19,124.55 674.80,133.18 676.38,142.00
             676.91,144.91 677.71,150.09 675.40,152.40
             672.86,154.94 659.01,154.07 655.00,154.00
             644.76,153.81 648.40,149.30 644.28,143.00
             640.35,137.00 632.79,134.23 626.00,137.01
             618.68,140.00 617.09,146.89 617.00,154.00
             617.00,154.00 617.00,214.00 617.00,214.00
             617.07,219.87 617.92,224.29 623.09,227.87
             628.64,231.71 636.75,231.89 641.61,226.78
             647.69,220.38 643.42,215.16 655.00,215.00
             655.00,215.00 667.00,215.00 667.00,215.00
             669.26,215.00 673.62,214.75 675.43,216.17
             677.47,217.79 677.01,221.72 676.71,224.00
             675.84,230.80 673.21,237.43 669.21,243.00
             659.10,257.12 645.54,261.19 629.00,261.00
             610.52,260.78 594.27,250.18 588.91,232.00
             586.24,222.95 586.00,207.65 586.00,198.00
             586.00,198.00 586.00,158.00 586.00,158.00
             586.00,158.00 586.83,149.00 586.83,149.00
             587.72,139.70 587.42,134.71 592.46,126.00
             600.09,112.84 610.61,107.79 625.00,105.43 Z
           M 850.00,105.43
           C 877.88,101.98 901.66,120.11 902.00,149.00
             902.00,149.00 902.00,180.00 902.00,180.00
             902.00,182.90 902.45,190.47 900.98,192.69
             899.14,195.45 894.91,194.99 892.00,195.00
             892.00,195.00 850.00,195.00 850.00,195.00
             843.72,195.12 842.12,196.72 842.00,203.00
             841.80,213.90 840.03,229.14 855.00,230.75
             860.63,231.36 865.74,228.80 868.64,223.98
             870.08,221.59 871.12,217.69 873.41,216.17
             875.55,214.76 879.48,215.00 882.00,215.00
             890.76,214.99 902.80,212.01 901.82,223.00
             901.30,228.85 898.10,237.18 894.78,242.00
             884.53,256.91 871.28,261.20 854.00,261.00
             835.53,260.78 819.23,250.12 813.77,232.00
             811.12,223.19 811.00,208.36 811.00,199.00
             811.00,199.00 811.00,157.00 811.00,157.00
             811.05,145.83 812.82,132.44 819.10,123.00
             826.58,111.76 837.30,107.52 850.00,105.43 Z
           M 303.60,108.02
           C 307.97,106.16 318.80,106.97 324.00,107.00
             325.82,107.01 328.92,106.85 330.40,108.02
             332.38,109.59 332.00,113.70 332.00,116.00
             332.00,116.00 332.00,224.00 332.00,224.00
             332.00,224.00 332.00,250.00 332.00,250.00
             332.00,251.99 332.23,255.81 330.98,257.40
             329.41,259.38 325.30,259.00 323.00,259.00
             319.33,259.01 305.03,259.94 303.02,257.40
             301.96,256.06 302.03,253.62 302.00,252.00
             302.00,252.00 302.00,147.00 302.00,147.00
             302.00,147.00 302.00,120.00 302.00,120.00
             302.00,116.01 301.20,111.37 303.60,108.02 Z
           M 520.00,136.34
           C 509.76,139.33 509.01,147.79 509.00,157.00
             509.00,157.00 509.00,209.00 509.00,209.00
             509.02,219.01 510.77,229.57 523.00,230.80
             530.29,231.53 536.81,227.15 538.62,220.00
             540.43,212.89 540.00,203.40 540.00,196.00
             540.00,196.00 540.00,160.00 540.00,160.00
             539.98,156.07 539.24,146.31 537.55,143.02
             533.88,135.90 527.02,135.52 520.00,136.34 Z
           M 852.00,136.22
           C 839.54,140.42 841.83,153.45 842.00,164.00
             842.04,165.96 841.85,168.47 843.57,169.83
             845.26,171.16 848.92,170.99 851.00,171.00
             867.12,171.08 871.81,172.96 872.00,163.00
             872.20,151.88 873.24,136.73 858.00,136.22
             855.87,135.94 854.10,136.11 852.00,136.22 Z"
			/>
		</svg>
	);
};

const LogoSvg = ({ href, className }) => {
	return href ? (
		<Link to={href} className={className} title="Go to home">
			<SvgLogoImage></SvgLogoImage>
		</Link>
	) : (
		<SvgLogoImage className={className}></SvgLogoImage>
	);
};

LogoSvg.propTypes = {
	href: PropTypes.string,
	strokeColor: PropTypes.string,
	strokeWidth: PropTypes.string
};

export default LogoSvg;
