import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

const LeftNavBar = ({ links }) => {
	const leftNavBarRef = useRef(null);

	const handleSubMenuClick = (event) => {
		let subMenu = event.currentTarget.nextSibling;
		let subMenuIcon = event.currentTarget.getElementsByClassName(`${styles.subMenuIcon}`)[0]
			.firstChild;

		if (subMenu.classList.contains(`${styles.show}`)) {
			let childMenus = subMenu.getElementsByTagName('ul');
			let childIcons = subMenu.getElementsByClassName('fa-chevron-up');

			subMenu.classList.remove(`${styles.show}`);

			if (subMenuIcon) {
				subMenuIcon.classList.remove('fa-chevron-up');
				subMenuIcon.classList.add('fa-chevron-down');
			}

			if (childMenus.length > 0) {
				Array.from(childMenus).forEach((item) => {
					item.classList.remove(`${styles.show}`);
				});
			}

			if (childIcons.length > 0) {
				Array.from(childIcons).forEach((item) => {
					item.classList.remove('fa-chevron-up');
					item.classList.add('fa-chevron-down');
				});
			}
		} else {
			subMenu.classList.add(`${styles.show}`);
			if (subMenuIcon) {
				subMenuIcon.classList.remove('fa-chevron-down');
				subMenuIcon.classList.add('fa-chevron-up');
			}
		}
	};

	const renderMenuItems = (items) => {
		let navStatus = false;

		return items.map((item, index) =>
			item?.children && item?.children.length ? (
				(item?.children.forEach((item) => {
					if (item.href === window.location.pathname) navStatus = true;
				}),
				(
					<li key={index} className={item.blockChildren ? 'd-none' : ''}>
						<button onClick={handleSubMenuClick}>
							{item.icon && <i className={item.icon}></i>}

							<div className={styles.linkText}>{item.text}</div>

							<div className={styles.subMenuIcon}>
								{navStatus === true ? (
									<i className="fas fa-chevron-up"></i>
								) : (
									<i className="fas fa-chevron-down"></i>
								)}
							</div>
						</button>

						<ul className={`${styles.subMenu} ${navStatus ? styles.show : null}`}>
							{(navStatus = false)}
							{renderMenuItems(item.children)}
						</ul>
					</li>
				))
			) : (
				<li key={index}>
					<NavLink
						end
						style={({ isActive }) => {
							return {
								backgroundColor: isActive ? '#084298' : '',
								color: isActive ? 'white' : ''
							};
						}}
						to={{ pathname: item.href, state: { openHateCrime: true } }}
						state={{ params: item?.params }}
						onClick={
							item.onClick
								? () => {
										item.onClick();
								  }
								: null
						}
					>
						{item.icon && <i className={item.icon}></i>}
						<div className={styles.linkText}>{item.icon ? item.text : '— ' + item.text}</div>
					</NavLink>
				</li>
			)
		);
	};

	return (
		links && (
			<div id={styles.leftNavBar} ref={leftNavBarRef}>
				<ul>{renderMenuItems(links)}</ul>
			</div>
		)
	);
};

export default LeftNavBar;

export function CustomLeftNavBar({ links, activeItem, onClick }) {
	return (
		links && (
			<div id={styles.leftNavBar}>
				<ul>
					{links.map((item) => {
						const activeClass = item.value === activeItem?.value ? styles.activeItem : undefined;
						if (item?.permission) {
							return (
								<button
									className={activeClass}
									key={item.value}
									data-testid={`testId-${item.value}`}
									onClick={() => {
										onClick(item);
									}}
								>
									{item.icon && <i className={item.icon}></i>}
									<div className={styles.linkText}>{item.text}</div>
								</button>
							);
						}
						return null;
					})}
				</ul>
			</div>
		)
	);
}
