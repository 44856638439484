import { useContext } from 'react';
import { HttpService } from './HttpService';
import { BASE_URL, fetcher } from '../utils/apiUtils';
import { AuthContext } from '../context/AuthContext';

export const urlDocumentFiling = (eventId) =>
	eventId ? `${BASE_URL}/api/document-filing/${eventId}` : `${BASE_URL}/api/document-filing`;

export const DocumentViewService = () => {
	const { token } = useContext(AuthContext);

	const { get, post, deleteFn } = HttpService();

	const configRequest = (eventId) => {
		return {
			headers: {
				Authorization: `Bearer ${token}`
			},
			url: `${BASE_URL}/api/document-filing/${eventId}`
		};
	};

	const getDocument = (eventId) => {
		const url = urlDocumentFiling(eventId);
		return get(url, 'getDocument', []);
	};

	const postDocument = (body) => {
		const url = urlDocumentFiling();
		return post(url, body, 'postDocument', {});
	};

	const deleteDocument = (documentFilingId) => {
		const url = urlDocumentFiling(documentFilingId);
		return deleteFn(url, 'deleteDocument', {});
	};

	const getSignatures = () => {
		const url = `${BASE_URL}/api/annotation-management/signatures`;
		return get(url, 'getSignatures', []);
	};

	const getUserAuthorizedSignatures = (userName) => {
		const url = `${BASE_URL}/api/user/profile/authorized-signatures?username=${userName}`;
		return get(url, 'getUserAuthorizedSignatures', {});
	};

	const getUserSignature = (userName) => {
		const url = `${BASE_URL}/api/user/profile/signature?username=${userName}`;
		return get(url, 'getUserSignature', {});
	};

	const getSeals = () => {
		const url = `${BASE_URL}/api/annotation-management/seals`;
		return get(url, 'getSeals', []);
	};

	const getRolesAccessibility = (documentFilingId) => {
		const url = `${BASE_URL}/api/document-filing-access-management/document-filing/${documentFilingId}/document-filing-accesses`;
		return get(url, 'getRolesAccessibility', []);
	};

	const saveRolesAccessibilities = (documentFilingId, body) => {
		const url = `${BASE_URL}/api/document-filing-access-management/document-filing/${documentFilingId}/document-filing-accesses`;
		return post(url, body, 'saveRolesAccessibilities', []);
	};

	const getImagesFromLibrary = (typeAnnotation, clerkID) => {
		const url = `${BASE_URL}/api/image-library/${typeAnnotation}?clerk-id=${clerkID}`;
		return get(url, 'getImages', []);
	};

	const getCurrentGroupAccesibility = (documentFilingId) => {
		const url = `${BASE_URL}/api/document-filing-access-management/document-filing/${documentFilingId}/document-filing-access`;
		return get(url, 'getCurrentGroupAccesibility', []);
	};

	const getInfoDownloadFile = async (documentId) => {
		try {
			const url = `${BASE_URL}/api/document-filing/document-filing/${documentId}`;
			const response = await get(url, 'getInfoDownloadFile', []);
			if (response.status === 200) {
				return response.data;
			}
		} catch (error) {
			console.log(error);
		}

		return null;
	};

	const getScanDocuments = (url, uuid) => {
		return url && fetcher(`${BASE_URL}/api${url}`, uuid);
	};

	const getStamp = () => {
		const url = `${BASE_URL}/api/documentation-file/file-stamp`;
		return get(url, 'getStamp', {});
	};

	return {
		getDocument,
		postDocument,
		deleteDocument,
		configRequest,
		getSignatures,
		getSeals,
		getRolesAccessibility,
		saveRolesAccessibilities,
		getCurrentGroupAccesibility,
		getImagesFromLibrary,
		getUserAuthorizedSignatures,
		getInfoDownloadFile,
		getUserSignature,
		getScanDocuments,
		getStamp
	};
};

export const downloadFile = async (data) => {
	const { url, customHeaders, eventId, documentExtension, documentFilingId, downloadFileName } =
		data;
	const fileName = `${
		downloadFileName ? downloadFileName : eventId || documentFilingId
	}.${documentExtension.toLowerCase()}`.trim();
	const response = await fetch(url, {
		method: 'GET',
		headers: customHeaders
	});
	const fileBlob = await response.blob();
	const fileURL = URL.createObjectURL(fileBlob);
	const link = document.createElement('a');
	link.href = fileURL;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const multiDownload = async (urls) => {
	if (!urls) {
		throw new Error('urls required');
	}
	for (const url of urls) {
		await downloadFile(url);
	}
};
