import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

/**
 * TableRow.
 * @param {Object} item Individual data registry
 * @param {Object[]} columns Array of information about columns properties
 * @returns {ReactElement} returns JSX with the component TableRow.
 */

const renderItem = (item, value) => item[value] ?? '-';

const TotalRow = ({ item, columns, tableId }) => {
	if (isEmpty(item)) {
		return null;
	}

	return (
		<>
			<tr
				id={`totalRow-${tableId}`}
				className={`text-center ${styles.totalRow} `}
				key={'TotalColumn'}
			>
				{item?.hasSelector && (
					<td className={'data-item last-data-item'} key={0}>
						-
					</td>
				)}
				{columns.map(({ value }, index) => {
					return (
						<td className={'data-item last-data-item'} key={index}>
							{renderItem(item, value)}
						</td>
					);
				})}
			</tr>
		</>
	);
};

TotalRow.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	item: PropTypes.object
};

export default TotalRow;
