import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

import { URL_CLOSED, URL_OPEN, urlPaymentHistory } from '../constants/paymentView';

export const PaymentService = () => {
	const { post, get, put } = HttpService();

	const postPayment = (body) => {
		const url = `${BASE_URL}/api/payment`;
		return post(url, body, 'postPayment', {});
	};

	const postCreditPayment = (body) => {
		const url = `${BASE_URL}/api/payment/credit`;
		return post(url, body, 'postPayment', {});
	};

	const postSendReceipt = (body, paymentId) => {
		const url = `${BASE_URL}/api/payment/${paymentId}/receipt/send`;
		return post(url, body, 'postSendReceipt', {});
	};

	const postSendReceiptNotification = (body, paymentId) => {
		const url = `${BASE_URL}/api/report-generation/payment-receipt/${paymentId}/notification`;
		return post(url, body, 'postSendReceiptNotification', {});
	};

	const getPayment = (id) => {
		const url = `${BASE_URL}/api/payment/${id}`;
		return get(url, 'getPayment', {});
	};

	const getPayments = () => {
		const url = `${BASE_URL}${URL_OPEN}`;
		return get(url, 'getPayments', {});
	};

	const getOpenPaymentsFees = () => {
		const url = `${BASE_URL}${URL_OPEN}?page=0&size=1000`;
		return get(url, 'getOpenPaymentsFees', {});
	};

	const getClosedPaymentsFees = () => {
		const url = `${BASE_URL}${URL_CLOSED}?page=0&size=1000`;
		return get(url, 'getClosedPaymentsFees', {});
	};

	const getPaymentHistorial = (case_id) => {
		const url = `${BASE_URL}${urlPaymentHistory(case_id)}?page=0&size=1000`;
		return get(url, 'getPaymentHistorial', {});
	};

	const getPaymentSumByCaseId = (case_id) => {
		const url = `${BASE_URL}/api/${case_id}/payments-sum`;
		return get(url, 'getPaymentSumByCaseId', {});
	};

	const getReceiptData = (paymentId) => {
		const url = `${BASE_URL}/api/payment/${paymentId}/receipt`;
		return get(url, 'getReceiptData', {});
	};

	const getFeesByPartyId = (caseId, partyId) => {
		const url = `${BASE_URL}/api/fees/case/${caseId}/party?partyId=${partyId}&page=0&size=100`;
		return get(url, 'GetFees', {});
	};

	const putTransferPayment = (body, paymentId, caseNumber) => {
		const url = `${BASE_URL}/api/payment/${paymentId}/transfer/${caseNumber}`;
		return put(url, body, 'putFee', {});
	};

	const getCertifiedPayment = (paymentId) => {
		const url = `${BASE_URL}/api/certified-payment/${paymentId}`;
		return get(url, 'getCertifiedPayment', {});
	};

	const requestCertifiedPayments = (body) => {
		const url = `${BASE_URL}/api/certified-payment/external`;
		return post(url, body, 'requestCertifiedPayments', {});
	};

	const getMiscCode = () => {
		const url = `${BASE_URL}/api/fee/codes/misc`;
		return get(url, 'getMiscCode', {});
	};
	const postMiscPayment = (body) => {
		const url = `${BASE_URL}/api/payment/miscellaneous`;
		return post(url, body, 'postMiscPayment', {});
	};

	const postSendRefundReceiptNotification = (body) => {
		const url = `${BASE_URL}/api/refunds/notification`;
		return post(url, body, 'postSendRefundReceiptNotification', {});
	};

	return {
		postPayment,
		postCreditPayment,
		postSendReceipt,
		postSendReceiptNotification,
		getFeesByPartyId,
		getPayment,
		getPayments,
		getOpenPaymentsFees,
		getClosedPaymentsFees,
		getPaymentHistorial,
		getPaymentSumByCaseId,
		putTransferPayment,
		getReceiptData,
		getCertifiedPayment,
		requestCertifiedPayments,
		getMiscCode,
		postMiscPayment,
		postSendRefundReceiptNotification
	};
};
