import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';

const RequestButton = ({ formId, handleRequest, children, disabled, ...props }) => {
	const [isRequesting, setIsRequesting] = useState(false);
	const doRequest = async (e) => {
		e.preventDefault();
		setIsRequesting(true);
		await handleRequest();
		setIsRequesting(false);
	};

	return (
		<Button
			id="submitBtn"
			variant="primary"
			type="submit"
			form={formId}
			onClick={(e) => doRequest(e)}
			disabled={isRequesting || disabled}
			{...props}
		>
			{isRequesting ? <Spinner size="sm" animation="border" color="white" /> : children}
		</Button>
	);
};

RequestButton.propTypes = {
	formId: PropTypes.string,
	handleRequest: PropTypes.func,
	children: PropTypes.elementType.isRequired,
	disabled: PropTypes.bool
};

export default RequestButton;
