import Form from 'react-bootstrap/Form';
import Select, { components } from 'react-select';

import { NOOP } from '../../../constants';
import AffectsReportAlertTooltip from '../../AffectsReportAlertTooltip/AffectsReportAlertTooltip';
import { baseSelectStyles, customStylesFlags, errorSelectIcon } from './styles';
import RequiredMark from '../RequiredMark';
import { fixFilterOptions } from '../../FormikFactory/utils/Select';

const Control = ({ children, ...props }) => {
	const { className, isInvalid, isDisabled } = props.selectProps;
	return (
		<components.Control
			{...props}
			className={
				(className || '') + (isInvalid ? ' is-invalid' : '') + (isDisabled ? ' is-disabled' : '')
			}
		>
			{isInvalid ? (
				<div style={errorSelectIcon} aria-hidden="true">
					&nbsp;
				</div>
			) : null}
			{children}
		</components.Control>
	);
};

const SelectInput = (props) => {
	const {
		id = '',
		options = [],
		formatGroupLabel,
		className = '',
		name = '',
		isSearchable = false,
		label = '',
		isLoading = false,
		onChange = NOOP,
		placeholderText = '- Select -',
		defaultValue = null,
		value = null,
		required = false,
		menuPosition = 'absolute',
		isMultiSelect = false,
		feeVariant = false,
		isDisabled = false,
		onBlur = NOOP,
		isInvalid = false,
		setFlags = NOOP,
		setCardFlags = NOOP,
		onSelectHandler = NOOP,
		onFocus = NOOP,
		error = false,
		isReportAffected,
		isClearable,
		inputRef = null,
		disableColorRenderThroughLabelValue = false
	} = props;
	return (
		<Form.Group className={className}>
			<AffectsReportAlertTooltip isReportAffected={isReportAffected}>
				<Form.Label htmlFor={`${id}-container`}>
					{label}
					{required && <RequiredMark />}
					{isReportAffected?.isAffected && (
						// The period is required to pause for the screenreader to read the tooltip
						<span className="visually-hidden">. {isReportAffected?.tooltip}</span>
					)}
				</Form.Label>
			</AffectsReportAlertTooltip>
			<Select
				{...props}
				ref={inputRef}
				components={{ Control }}
				isDisabled={isDisabled}
				options={options}
				formatGroupLabel={formatGroupLabel}
				value={onChange === NOOP ? undefined : value}
				id={`${id}-container`}
				inputId={id}
				name={name}
				aria-label={
					isReportAffected?.isAffected
						? `${label}, ${isReportAffected?.tooltip}.`
						: label
						? label
						: `Select option for ${id}`
				}
				isSearchable={isSearchable}
				styles={{
					...(feeVariant
						? customStylesFlags(isInvalid, disableColorRenderThroughLabelValue)
						: baseSelectStyles(isInvalid, isDisabled, null, disableColorRenderThroughLabelValue)),
					container: (provided) => ({
						...provided,
						border: error && '1px solid #dc3545 !important',
						borderRadius: '5px',
						pointerEvents: 'auto',
						cursor: isDisabled ? 'not-allowed' : 'auto'
					})
				}}
				isLoading={isLoading}
				onChange={(e) => {
					onChange(e);
					setFlags !== NOOP && setFlags(e);
					setCardFlags !== NOOP && setCardFlags(e);
					onSelectHandler();
				}}
				placeholder={placeholderText}
				defaultValue={defaultValue}
				menuPosition={!menuPosition && !feeVariant ? 'fixed' : 'absolute'}
				isMulti={isMultiSelect}
				menuPlacement="bottom"
				onBlur={
					onBlur &&
					function (e) {
						setTimeout(() => {
							onBlur(e);
						}, 50);
					}
				}
				onFocus={onFocus}
				isClearable={isClearable}
				filterOption={fixFilterOptions()}
			/>
		</Form.Group>
	);
};

export default SelectInput;
