import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { TOAST_TYPES } from '../../../components/ResponseAlert';
import { DocumentViewService } from '../../../services/DocumentViewService';
import useUserStore from '../../../redux/utils/storeUtils';
import { SUCCESFUL_REQUEST_CODE } from '../../../utils';

const ModalInsertAnnotations = ({
	title,
	textModal,
	textButton,
	typeAnnotation = 'FM' | 'FS' | 'CS',
	disabled,
	setImageSelection,
	setToast,
	data,
	setTypeAnnotation
}) => {
	const [show, setShow] = useState(false);
	const [imageToRender, setImageToRender] = useState([]);
	const [imageSelected, setImageSelected] = useState();
	const [itemSelected, setItemSelected] = useState();

	const { getImagesFromLibrary, getStamp } = DocumentViewService();
	const { selectedClerkId } = useUserStore();

	useEffect(() => {
		const abortController = new AbortController();

		if (show) {
			setImageSelected(null);
			setItemSelected(null);
			setTypeAnnotation(null);
		}
		return () => {
			abortController.abort();
		};
		// eslint-disable-next-line
	}, [show]);

	const getImages = async () => {
		try {
			const { status, data } =
				typeAnnotation !== 'FS'
					? await getImagesFromLibrary(typeAnnotation, selectedClerkId)
					: await getStamp();

			if (SUCCESFUL_REQUEST_CODE.includes(status)) {
				if (typeAnnotation !== 'FS') {
					const imagesConverted = data.map((img) => {
						const decodedStringAtoB = atob(img.fileBytes);
						const imageBase64 = `data:image/${img.fileExtension};base64,${decodedStringAtoB}`;
						return {
							file: imageBase64,
							description: img.description
						};
					});
					setImageToRender(imagesConverted);
					setShow(true);
				} else {
					let stamp = [];
					const canvas = document.createElement('canvas');
					canvas.width = 1500;
					canvas.height = 200;

					const ctx = canvas.getContext('2d');
					ctx.font = '30px Arial';

					ctx.fillText(data, 0, 90);
					const dataUrl = canvas.toDataURL();

					stamp.push({
						file: dataUrl,
						description: 'File Stamp'
					});
					setImageToRender(stamp);
					setShow(true);
				}
			} else {
				setToast({
					text: `Unable to get the information for ${title}. Please try later.`,
					show: true,
					type: TOAST_TYPES.DANGER
				});
			}
		} catch (error) {
			hideModal();
			setToast({
				text: `Unable to get the information for ${title}. Please try later.`,
				show: true,
				type: TOAST_TYPES.DANGER
			});
		}
	};

	const ImageContainer = (props) => {
		const content = (
			<Row xs={2} md={4} lg={4} className="g-4">
				{props?.map((item, index) =>
					typeAnnotation === 'FS' ? (
						<img
							className={`${item.selected ? styles.stampSelected : 'form-control'}`}
							onClick={() => {
								setBorderImage(index + 1, item.file);
								setItemSelected(index + 1);
							}}
							src={item.file}
							key={index}
						/>
					) : (
						<Col key={`col-${index.toString()}`}>
							<Card
								key={`card-${index.toString()}`}
								className={`${item.selected ? styles.borderSelected : ''} ${
									isMobile ? styles.cardContainerMobile : styles.cardContainer
								}`}
							>
								<Card.Img
									key={`img-${index.toString()}`}
									className={`${styles['card-img-top']} ${styles.img}`}
									style={{
										objectFit: typeAnnotation === 'SIGN' ? 'contain' : 'cover'
									}}
									src={item.file}
									onClick={() => {
										setBorderImage(index + 1, item.file);
										setItemSelected(index + 1);
									}}
									alt={`${typeAnnotation === 'SIGN' ? item.user : item.description}`}
								/>
							</Card>
						</Col>
					)
				)}
			</Row>
		);

		return <div className={typeAnnotation === 'FS' ? '' : styles.imageContainer}>{content}</div>;
	};

	const setBorderImage = (idxElement, img) => {
		if (idxElement === itemSelected) return;

		const tmp = [...imageToRender];
		tmp[idxElement - 1]['selected'] = true;
		if (itemSelected) {
			tmp[itemSelected - 1]['selected'] = false;
		}

		setImageToRender(tmp);
		setImageSelected(img);
	};

	const displayImages = () => {
		if (typeAnnotation === 'SIGN') {
			const tmp = data.map((item) => {
				item['selected'] = false;
				return item;
			});
			setShow(true);
			setImageToRender(tmp);
			ImageContainer(tmp);
		} else {
			getImages();
		}
	};

	const hideModal = () => setShow(false);

	const ModalComponent = () => {
		return (
			<Modal
				data-testid="modal-insert-annotations"
				id="insert-annotations-modal-title"
				aria-labelledby="insert-annotations-modal-title"
				backdrop="static"
				size="lg"
				centered
				show={show}
				onHide={hideModal}
			>
				<Modal.Header closeLabel={`${title} Close Dialog`} closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form.Group className="mb-3">
						<Form.Label id="textInsertAnnotations" htmlFor="textInsertAnnotations">
							{textModal}
						</Form.Label>
					</Form.Group>
					{imageToRender?.length <= 0 && (
						<Form.Group className="mb-3">
							<Form.Label htmlFor="textWarningAnnotations">
								{imageToRender?.length <= 0 && `[No images found]`}
							</Form.Label>
						</Form.Group>
					)}
					{imageToRender && ImageContainer(imageToRender)}
				</Modal.Body>

				<Modal.Footer>
					<Button
						disabled={!imageSelected}
						type="button"
						variant="primary"
						onClick={() => {
							setTypeAnnotation(typeAnnotation);
							setImageSelection(imageSelected);
							hideModal();
						}}
					>
						Submit
					</Button>
					<Button type="button" variant="secondary" onClick={hideModal}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	return (
		<>
			<Button disabled={disabled} onClick={displayImages}>
				{textButton}
			</Button>
			{ModalComponent()}
		</>
	);
};

ModalInsertAnnotations.propTypes = {
	title: PropTypes.string,
	textModal: PropTypes.string,
	textButton: PropTypes.string,
	typeAnnotation: PropTypes.oneOf(['FM', 'FS', 'CS', 'SIGN']),
	disabled: PropTypes.bool,
	setImageSelection: PropTypes.func,
	setToast: PropTypes.func
};

export default ModalInsertAnnotations;
