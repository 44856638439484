// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen {\n  #styles_printSection__6VPjd {\n    display: none;\n  }\n}\n@media print {\n  body * {\n    visibility: hidden;\n  }\n  #styles_printThis__glqQ- {\n    background-color: blue;\n  }\n  #styles_printSection__6VPjd,\n  #styles_printSection__6VPjd * {\n    visibility: visible;\n  }\n  #styles_printSection__6VPjd {\n    position: absolute;\n    left: 0;\n    top: 0;\n  }\n}\n.styles_receiptTable__x9AmT {\n  border-style: hidden !important;\n  word-break: break-word;\n}", "",{"version":3,"sources":["webpack://./src/pages/PaymentCompleted/styles.module.scss"],"names":[],"mappings":"AAAA;EACC;IACC,aAAA;EACA;AACF;AAEA;EACC;IACC,kBAAA;EAAA;EAED;IACC,sBAAA;EAAA;EAED;;IAEC,mBAAA;EAAA;EAED;IACC,kBAAA;IACA,OAAA;IACA,MAAA;EAAA;AACF;AAGA;EACC,+BAAA;EACA,sBAAA;AADD","sourcesContent":["@media screen {\n\t#printSection {\n\t\tdisplay: none;\n\t}\n}\n\n@media print {\n\tbody * {\n\t\tvisibility: hidden;\n\t}\n\t#printThis {\n\t\tbackground-color: blue;\n\t}\n\t#printSection,\n\t#printSection * {\n\t\tvisibility: visible;\n\t}\n\t#printSection {\n\t\tposition: absolute;\n\t\tleft: 0;\n\t\ttop: 0;\n\t}\n}\n\n.receiptTable {\n\tborder-style: hidden !important;\n\tword-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"printSection": "styles_printSection__6VPjd",
	"printThis": "styles_printThis__glqQ-",
	"receiptTable": "styles_receiptTable__x9AmT"
};
export default ___CSS_LOADER_EXPORT___;
