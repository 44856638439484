import useControls from '../../hooks/controls/useControls';

const FormLabel = ({ label, value, pageName, htmlFor }) => {
	const { controls, isLoading } = useControls(pageName);

	if (isLoading) return <></>;
	return (
		<div className="mb-2">
			<label htmlFor={htmlFor}>
				{label}
				{controls[value] && <span className="text-danger small"> *</span>}
			</label>
		</div>
	);
};

export default FormLabel;
