import { useState, useEffect } from 'react';

const initSate = {
	isLoading: false,
	data: null,
	error: false
};

const loadingState = {
	isLoading: true,
	data: null,
	error: false
};

const successState = {
	isLoading: false,
	data: null,
	error: false
};

const failureState = {
	isLoading: false,
	data: null,
	error: true,
	errorMessage: null
};

export const useFetchData = (fetchDataCallback, queryParams, preventFetch = false) => {
	const [state, setState] = useState(initSate);

	const fetchData = async () => {
		const response = await fetchDataCallback(queryParams);
		if (response.error) {
			setState({
				...failureState,
				errorMessage: response.error
			});
		} else {
			setState({
				...successState,
				data: response.data
			});
		}
	};

	const fetch = () => {
		setState(loadingState);
		fetchData();
	};

	useEffect(() => {
		!preventFetch && fetch();
		return () => {
			setState(initSate);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { ...state, fetch };
};
