const dollarUSLocale = Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: '2',
	minimumFractionDigits: '2',
	currencySign: 'accounting'
});

export const formatCurrency = (price) =>
	dollarUSLocale.format(price).includes('NaN') ? '-' : dollarUSLocale.format(price);

export const handleCleanAmount = (amount) =>
	typeof amount === 'string' ? parseFloat(amount.replace('$', '').replace(/,/g, '')) : amount;

export const handleCleanAmountFloat = (amount) =>
	parseFloat(amount.replace('$', '').replace(',', ''));

export const addDecimalsIfInterger = (amount) =>
	Number.isInteger(amount)
		? `${amount}.00`
		: typeof amount === 'string'
		? parseFloat(amount).toFixed(2)
		: amount.toFixed(2);

export const decimalCount = (num) => {
	const numStr = String(num);

	if (numStr.includes('.')) {
		return numStr.split('.')[1].length;
	}

	return 0;
};
export const cleanAmount = (amount) => (amount.includes('NaN') ? '0.00' : amount);

export const handleClearAmount = (amount) =>
	typeof amount === 'string' ? parseFloat(amount.replace('$', '').replaceAll(',', '')) : amount;
