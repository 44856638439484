import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { ConfigsContext } from '../context/ConfigsContext/index.js';

export const useConfigs = () => {
	const { setConfigs, county, activeConfigs, configs } = useContext(ConfigsContext);

	const filters = {
		dateFormat: activeConfigs && activeConfigs.filter((config) => config.name === 'Date Format'),
		timeZone: activeConfigs && activeConfigs.filter((config) => config.name === 'Time Zone'),
		maxHitCount:
			activeConfigs &&
			activeConfigs.filter((config) => config.name === 'Max Advanced Search Hit Count'),
		timeFormat: activeConfigs && activeConfigs.filter((config) => config.name === 'Time Format'),
		maxSearchTime:
			activeConfigs &&
			activeConfigs.filter((config) => config.name === 'Max Advanced Search Time in Seconds'),
		timeOutLimit:
			activeConfigs &&
			activeConfigs.filter((config) => config.name === 'User Session Timeout Limit (seconds)')
	};

	const dateFormat = !isEmpty(filters.dateFormat)
		? filters.dateFormat[0].value
			? filters.dateFormat[0].value
			: 'yy-MMM-dd'
		: 'yy-MMM-dd';
	const timeZone = !isEmpty(filters.timeZone)
		? filters.timeZone[0].value
		: 'Central Standard Time - (GMT-6)';
	const maxHitCount = !isEmpty(filters.maxHitCount) ? filters.maxHitCount[0].value : 20;
	const timeFormat = !isEmpty(filters.timeFormat) ? filters.timeFormat[0].value : 'HH:ss';
	const maxSearchTime = !isEmpty(filters.maxSearchTime) ? filters.maxSearchTime[0].value : 2000;
	const timeOutLimit = !isEmpty(filters.timeOutLimit) ? filters.timeOutLimit[0].value : 3600;

	const isLoading = isEmpty(activeConfigs);

	return {
		isLoading,
		dateFormat,
		timeZone,
		maxHitCount,
		configs: activeConfigs,
		setConfigs,
		timeFormat,
		maxSearchTime,
		county,
		allConfigs: configs,
		timeOutLimit
	};
};
