import { HttpService } from '../../services/HttpService';
import { BASE_URL } from '../../utils/apiUtils';

export const useConfigsServices = () => {
	const { get, put, post, deleteFn } = HttpService();

	const url = `${BASE_URL}/api/system-setting`;

	const filterCountyConfigs = (configs, county) => {
		const filteredConfigs = configs.filter(
			(item) => item.jurisdiction === county || item.type === 'statewide'
		);

		return filteredConfigs;
	};

	const filterActiveConfigs = (configs) => {
		const today = new Date();
		const activeConfigs = configs.filter((config) => {
			if (config.type === 'jurisdiction') {
				const activeDate = new Date(config.activeDate) <= today;

				if (activeDate) {
					return config;
				}
			}

			if (config.type === 'statewide') {
				const activeStatewide = configs.filter(
					(cg) =>
						cg.type === 'jurisdiction' &&
						cg.name === config.name &&
						new Date(cg.activeDate) <= today
				);
				if (activeStatewide.length === 0) {
					return config;
				}
			}

			return null;
		});

		return activeConfigs;
	};

	const getConfigs = async (county) => {
		const countyURL = `${url}/${county}`;
		const countyConfigs = await get(countyURL, 'GetConfigs', []);
		const filteredConfigs = filterCountyConfigs(countyConfigs.data.content, county);
		return filteredConfigs;
	};

	const postConfig = async (values) => {
		if (values.jurisdiction.length > 1) {
			const configs = values.jurisdiction.map((jurisdiction) => ({
				...values,
				jurisdiction
			}));

			const configPromises = configs.map((config) => post(url, config, 'PostConfig'));

			const configsResponse = await Promise.all(configPromises);
			return configsResponse;
		}

		const config = await post(
			url,
			{ ...values, jurisdiction: values.jurisdiction[0] },
			'PostConfig'
		);
		return config;
	};

	const putConfig = async (values) => {
		const updatedConfig = { ...values, jurisdiction: values.jurisdiction[0] };
		const config = await put(`${url}/${values.id}`, updatedConfig, 'PutConfig');
		return config;
	};

	const deleteConfig = async (id) => {
		await deleteFn(`${url}/${id}`, 'DeleteConfig');
	};

	return {
		getConfigs,
		filterCountyConfigs,
		filterActiveConfigs,
		url,
		postConfig,
		deleteConfig,
		putConfig
	};
};

export const filterCountyConfigs = (configs, county) => {
	const filteredConfigs = configs.filter(
		(item) => item.jurisdiction === county || item.type === 'statewide'
	);

	return filteredConfigs;
};

export const filterActiveConfigs = (configs) => {
	const today = new Date();
	const activeConfigs = configs.filter((config) => {
		if (config.type === 'jurisdiction') {
			const activeDate = new Date(config.activeDate) <= today;

			if (activeDate) {
				return config;
			}
		}

		if (config.type === 'statewide') {
			const activeStatewide = configs.filter(
				(cg) =>
					cg.type === 'jurisdiction' && cg.name === config.name && new Date(cg.activeDate) <= today
			);
			if (activeStatewide.length === 0) {
				return config;
			}
		}

		return null;
	});

	return activeConfigs;
};

export const timezoneFormat = (timezone) => {
	switch (timezone) {
		case 'Atlantic Standard Time (AST)':
			return 'America/Halifax';
		case 'Eastern Standard Time (EST)':
			return 'America/New_York';
		case 'Central Standard Time (CST)':
			return 'America/Chicago';
		case 'Mountain Standard Time (MST)':
			return 'America/Denver';
		case 'Pacific Standard Time (PST)':
			return 'America/Los_Angeles';
		case 'Alaskan Standard Time (AKST)':
			return 'America/Anchorage';
		case 'Hawaii-Aleutian Standard Time (HST)':
			return 'Pacific/Honolulu';
		case 'Samoa Standard Time (UTC-11)':
			return 'Pacific/Pago_Pago';
		case 'Chamorro Standard Time (UTC+10)':
			return 'Pacific/Guam';
		default:
			return 'America/New_York';
	}
};

export const formatTimeFormat = (timeFormat) => timeFormat ?? 'HH:mm';
