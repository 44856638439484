import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchUnReviewedEfilings = createAsyncThunk(
	'get/UnReviewedEfile',
	async (apiCallback) => {
		try {
			const response = await apiCallback();
			return response.data;
		} catch (error) {
			console.error(error);
		}
	}
);

const initialState = {
	totalUnReviewedEfilings: 0
};

const efilingSlice = createSlice({
	initialState,
	name: 'efilingSlice',
	reducers: {
		updateUnReviewedEfilings: (state, action) => {
			state.totalUnReviewedEfilings = action.payload;
		}
	},
	extraReducers: (builder) => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(fetchUnReviewedEfilings.fulfilled, (state, action) => {
			// Add user to the state array
			state.totalUnReviewedEfilings = action.payload;
		});
	}
});

export const { updateUnReviewedEfilings } = efilingSlice.actions;

export default efilingSlice.reducer;
