export const getClerkIdFromCookies = () => {
	let clerkId;
	try {
		const userInfo = JSON.parse(localStorage.getItem('userInfo'));
		clerkId = userInfo?.jurisdiction;
	} catch (error) {
		console.error('Unable to fetch current clerk from storage', error);
	}
	return clerkId;
};
