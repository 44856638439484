import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../AlertDialog';
import { createModal } from 'react-modal-promise';
import { useEffect } from 'react';

const ResetModal = ({ isOpen, onResolve, expireDate, mandatory }) => {
	const navigate = useNavigate();
	const onSubmit = () => {
		navigate('/forgot-password');
	};

	useEffect(() => {
		if (mandatory) {
			onSubmit();
		}
	}, []);

	return (
		<AlertDialog
			open={isOpen}
			onClose={onResolve}
			backdrop="static"
			keyboard={false}
			animation={false}
			title="Password Expiration"
			footer={
				<>
					<Button onClick={onSubmit}>Yes</Button>
					<Button variant="secondary" onClick={onResolve}>No</Button>
				</>
			}
		>
			<p>
				Your password expires on {expireDate}
				<br />
				Do you want to change it now?
			</p>
		</AlertDialog>
	);
};
const resetModal = createModal(ResetModal);
export default resetModal;
