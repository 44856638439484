import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { setTitleFromPathname, setTitle } from '../../../utils/documentTitle';

const PageHeading = ({ title, subTitle }) => {
	useEffect(() => {
		if (!isEmpty(title)) {
			setTitle(title);
		} else if (!isEmpty(subTitle)) {
			setTitle(subTitle);
		} else {
			setTitleFromPathname();
		}
	}, [title, subTitle]);

	return (
		<Row className="mb-4">
			<Col>
				{title && <h1 className="text-primary text-center fs-3">{title}</h1>}
				{subTitle && <h2 className="text-center fs-4">{subTitle}</h2>}
			</Col>
		</Row>
	);
};

export default PageHeading;
