import { HttpService } from './HttpService';
import { BASE_URL } from '../utils/apiUtils';

export const TicketService = () => {
	const { get, post, put, deleteFn } = HttpService();

	const getDefendent = (case_id) => {
		return get(`${BASE_URL}/api/party-management/case/${case_id}/party/criminal/defendant`);
	};

	const getTicket = (case_id) => {
		return get(`${BASE_URL}/api/ticket-management/${case_id}`);
	};

	const postTicket = (body) => {
		const url = `${BASE_URL}/api/ticket-management/ticket`;
		return post(url, body, 'postTicket', {});
	};

	const putTicket = (body, id) => {
		const url = `${BASE_URL}/api/ticket-management/ticket/${id}`;
		return put(url, body, 'putTicket', {});
	};

	const deleteTicket = (id) => {
		const url = `${BASE_URL}/api/ticket-management/${id}`;
		return deleteFn(url, 'deleteTicket', {});
	};

	const getBadgeNumber = (case_id) => {
		return get(`${BASE_URL}/api/case/${case_id}/badge-numbers`);
	};

	return {
		getDefendent,
		getBadgeNumber,
		getTicket,
		postTicket,
		putTicket,
		deleteTicket
	};
};
