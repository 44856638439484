import { Button } from 'react-bootstrap';
import styles from '../Alerts/style.module.scss';

const AlertErrorDialog = () => {
    return <></>
};

const Body = ({ content }) => {
    return (
        <div>
            <p className={styles['text-error']}>
                {content}
            </p>
        </div>
    );
};

const Footer = ({ closeModal }) => {
    return (
        <>
            <Button
                onClick={() => closeModal()}
                variant="primary"
                className={styles.button}
            >
                Close
            </Button>
        </>
    );
}

AlertErrorDialog.Body = Body;
AlertErrorDialog.Footer = Footer;

export default AlertErrorDialog;