import { useState, useCallback, useRef, useEffect } from 'react';

/**
 * This hook could be used when we have and asynchronous call and We want to track the execution state. So that wrap the function and return an arry with
 * the wrapped function, loading state, error state and clear function which will be used to clean error and loagind values.
 * @async
 * @param {function} func - the async function that we want to track
 * @returns {Array} [WrappedFunc: {function} the function that we have to use, runningState: boolean equal to true when the function is running,
 * errorState: returns the error when it happens(if no error = null), clearState:  function to execute when we want to clear the runing and error state]
 */
const useAsyncFunc = (func) => {
	const isUnmounted = useRef(false);
	const [functionState, setFunctionState] = useState({ running: false, error: null });
	const handleChangingState = (newState) => {
		if (!isUnmounted.current) {
			setFunctionState(newState);
		}
	};
	const WrappedFunc = useCallback(
		async (...args) => {
			let result;
			try {
				handleChangingState({ ...functionState, running: true });
				result = await func(...args);
				handleChangingState({ ...functionState, running: false, error: null });
			} catch (error) {
				handleChangingState({ ...functionState, error, running: false });
			}
			return result;
		}, // eslint-disable-next-line
		[func, handleChangingState]
	);

	const clearState = () => {
		setFunctionState({ running: false, error: null });
	};

	useEffect(() => {
		return () => {
			isUnmounted.current = true;
		};
	}, []);

	return [WrappedFunc, functionState.running, functionState.error, clearState];
};

export default useAsyncFunc;
