import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import RequiredMark from '../RequiredMark';

/**
 * Description.
 * @param {boolean} allowNegative - Enable or disable negative values
 * @param {string} label - Text showed above input.
 * @param {string} id - Id use in htmlFor property for label and id in input
 * @param {string} name - Name passed to input
 * @param {boolean} required - Flag that renders a red asterisk and set input as required
 * @param {string} value - Value of the input
 * @param {number} editValue - Value that will be setted once and won't affect value later
 * @param {boolean} readOnly - Disable input and set background color gray but keeps input focusabled
 * @param {boolean} disabled - Disable input and set background color gray
 * @param {Function} handlerinput - Handle value change
 * @param {string} ariaLabel - aria-label tag
 * @param {string} decimals - number of decimal places allowed (default 2)
 * @returns {ReactElement} returns JSX with the component NumberInput.
 */

const MoneyInput = (props) => {
	const onValueChange = (e) => {
		if (props.handlerinput) {
			props.handlerinput({
				type: 'change',
				target: { value: e.value, name: props.name }
			});
		}
	};

	const valueIsZero = props.value === '' || props.value === '0';

	const blockInvalidChar = (e) => valueIsZero && e.key === '0' && !props.allowZeroValue && e.preventDefault();

	const Label = () => {
		if (!props.label) {
			return null;
		}

		return (
			<label className="form-label" htmlFor={props.id}>
				{props.label}
				{props.required && <RequiredMark />}
			</label>
		);
	};

	const isNegative = props.value < 0;

	const decimals = isNumber(props.decimals) ? props.decimals : 2;

	const value = props.disabled ? Number(props.value).toFixed(2) : props.value;

	return (
		<>
			<Label />
			<NumberFormat
				allowNegative={props.allowNegative ?? false}
				allowedDecimalSeparators={['.']}
				allowLeadingZeros={false}
				aria-label={props?.ariaLabel ?? ''}
				className={props.className ? `${props.className}` : 'form-control'}
				decimalScale={decimals}
				decimalSeparator={'.'}
				disabled={props.disabled}
				id={props.id}
				data-testid={props.testId}
				isNumericString
				name={props.name}
				onKeyDown={props.allowZero ? () => {} : blockInvalidChar}
				onValueChange={onValueChange}
				placeholder={props.placeholder ?? 'Please enter a number'}
				prefix={'$ '}
				suffix={props.disabled ? isNegative && ')' : undefined}
				readOnly={props.readOnly}
				thousandSeparator={true}
				value={isNegative ? `(${value}` : value}
				inputprops={{
					autoComplete: props.autocomplete
				}}
			/>
		</>
	);
};

MoneyInput.propTypes = {
	allowNegative: PropTypes.bool,
	label: PropTypes.string,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	handlerinput: PropTypes.func,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	ariaLabel: PropTypes.string,
	decimals: PropTypes.number
};

export default MoneyInput;
