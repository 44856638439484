// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_skipLink__qIlir {\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: auto;\n  width: auto;\n  padding: 0.25rem;\n  font-size: 1rem;\n  background-color: #084298;\n  color: #ffffff !important;\n  border: 1px solid #084298;\n  text-decoration: none;\n  text-align: center;\n  z-index: 1050 !important;\n  transform: translateY(-100%);\n  transition: transform 250ms ease;\n}\n.styles_skipLink__qIlir:focus {\n  transform: translateY(0%);\n}", "",{"version":3,"sources":["webpack://./src/components/layout/SkipToContentLink/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;EACA,yBAAA;EACA,yBAAA;EACA,qBAAA;EACA,kBAAA;EACA,wBAAA;EACA,4BAAA;EACA,gCAAA;AACD;AACC;EACC,yBAAA;AACF","sourcesContent":[".skipLink {\r\n\tposition: fixed;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\theight: auto;\r\n\twidth: auto;\r\n\tpadding: 0.25rem;\r\n\tfont-size: 1rem;\r\n\tbackground-color: #084298;\r\n\tcolor: #ffffff !important;\r\n\tborder: 1px solid #084298;\r\n\ttext-decoration: none;\r\n\ttext-align: center;\r\n\tz-index: 1050 !important;\r\n\ttransform: translateY(-100%);\r\n\ttransition: transform 250ms ease;\r\n\r\n\t&:focus {\r\n\t\ttransform: translateY(0%);\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skipLink": "styles_skipLink__qIlir"
};
export default ___CSS_LOADER_EXPORT___;
