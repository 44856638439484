const PDF_FILE = 'application/pdf';
export const DOCX_FILE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const FILES_TYPES_DOCUMENT = [
	'.pdf',
	'.doc',
	'.docx',
	'.xls',
	'.xlsx',
	'.txt',
	'.tif',
	'.tiff'
];
export const FILES_CONTENT_TYPES_DOCUMENT = [
	PDF_FILE,
	'application/msword',
	DOCX_FILE,
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'text/plain'
];

export const FILES_TYPES_IMAGE = ['.png', '.jpg', '.jpeg'];
export const FILES_CONTENT_TYPES_IMAGE = ['image/jpeg', 'image/png'];

export const FILES_TYPES_AUDIO = ['.m4a', '.mp3', '.wav'];
export const FILES_CONTENT_TYPES_AUDIO = ['audio/x-m4a', 'audio/mpeg', 'audio/wav'];

export const FILES_TYPES_VIDEO = ['.mp4', '.avi', '.mov'];
export const FILES_CONTENT_TYPES_VIDEO = ['video/mp4', 'video/x-msvideo', 'video/quicktime'];

export const FILES_TYPES_ZIP = ['.zip'];
export const FILES_CONTENT_TYPE = ['multipart/form-data'];

//MAX SIZE IN BYTES
//500MB
export const MAX_SIZE_GENERAL = 524288000;
//50MB
export const MAX_SIZE_IMAGES = 52428800;
//200MB
export const MAX_SIZE_DOCUMENTS = 209715200;
//5TB
export const MAX_SIZE_VIDEO_AUDIO_FILE = 5497558138880;

export const FILES_TYPES_ACCEPTED = [
	...FILES_TYPES_DOCUMENT,
	...FILES_TYPES_IMAGE,
	...FILES_TYPES_AUDIO,
	...FILES_TYPES_VIDEO,
	...FILES_TYPES_ZIP
];
export const FILES_ACCEPT_IMPORT_CASE = [...FILES_TYPES_ZIP];

export const isMediaFile = (file) => {
	const fileType = `.${file.name.split('.').pop()}`;

	return (
		FILES_TYPES_IMAGE.includes(fileType) ||
		FILES_TYPES_AUDIO.includes(fileType) ||
		FILES_TYPES_VIDEO.includes(fileType)
	);
};

export const isPDFFile = (file) => file.type === PDF_FILE;
