import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { shape, string, element } from 'prop-types';
import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import { personHTTP } from '../../../services/personServices';
import { usePersonViewServices } from '../../../services';

const formatAddress = (address) => {
	let fullAddress = '';
	if (!isEmpty(address.street1)) {
		fullAddress = fullAddress + address.street1 + ', ';
	}
	if (!isEmpty(address.city)) {
		fullAddress = fullAddress + address.city + ', ';
	}
	if (!isEmpty(address.state)) {
		fullAddress = fullAddress + address.state + ', ';
	}
	if (!isEmpty(address.zip)) {
		fullAddress = fullAddress + address.zip;
	}
	return fullAddress;
};

const RightCard = ({
	enableDefaults = false,
	data = null,
	defaultEmail = '',
	defaultPhone = '',
	defaultAddress = '',
	children
}) => {
	const { personId } = useParams();
	const [personData, setPersonData] = useState(data ?? {});
	const [address, setAddress] = useState(defaultEmail);
	const [phone, setPhone] = useState(defaultPhone);
	const [email, setEmail] = useState(defaultAddress);

	const { getPersonPrimaries } = personHTTP();
	const { getPersonData } = usePersonViewServices();

	useEffect(() => {
		if (!defaultEmail && personId) {
			getPersonPrimaries(personId)
				.then((res) => {
					res.data.address && setAddress(formatAddress(res.data.address));
					res.data.phone && setPhone(res.data?.phone);
					res.data.email && setEmail(res.data?.email);
				})
				.catch((error) => console.error(error));
		}

		if (isEmpty(data) && personId) {
			getPersonData(personId)
				.then((res) => {
					setPersonData(res?.personData);
				})
				.catch((error) => console.error(error));
		} else {
			setPersonData(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personId, defaultEmail, data]);

	const buildPersonName = (firstName, middleName, lastName) => {
		let name = '';
		if (lastName) {
			name = name + lastName;
		}
		if (lastName && (firstName || middleName)) {
			name = name + ',';
		}
		if (firstName) {
			name = name + ' ' + firstName;
		}
		if (middleName) {
			name = name + ' ' + middleName;
		}
		return name;
	};

	return (
		<section id={styles.rightCard}>
			<div className="row mb-3">
				<span className="text-center h5">
					{buildPersonName(personData?.firstName, personData?.middleName, personData?.lastName)}
				</span>
			</div>
			{children}
			<div className="row mb-4">
				<strong id={styles.fields}>Email:</strong>
				<span>{enableDefaults ? defaultEmail : email}</span>
			</div>
			<div className="row mb-4">
				<strong id={styles.fields}>Phone:</strong>
				<span>{enableDefaults ? defaultPhone : phone}</span>
			</div>
			<div className="row mb-4">
				<strong id={styles.fields}>Address:</strong>
				<span>{enableDefaults ? defaultAddress : address}</span>
			</div>
		</section>
	);
};

RightCard.propTypes = {
	data: shape({
		firstName: string,
		lastName: string,
		middleName: string
	}),
	defaultEmail: string,
	defaultAddress: string,
	defaultPhone: string,
	children: element
};

export default RightCard;
