import { useReducer, useCallback } from 'react';
import { validate, VALIDATOR_CONFIRM_PASSWORD, VALIDATOR_REQUIRED } from '../utils/formValidator';

const runValidators = (inputs, isFormValid) => {
	const newInputs = { ...inputs };
	for (let clave in inputs) {
		const validator = inputs[clave]?.validators;
		const { isValid, message } = validator
			? validate(inputs[clave].value, validator, { ...inputs })
			: { isValid: true };
		newInputs[clave] = {
			...inputs[clave],
			isValid: isValid,
			textRequired: message
		};
		isFormValid = isFormValid && isValid;
	}
	return { newInputs, isFormValid };
};

const formReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE_INPUT':
			let isFormValid = true;
			const { name, value } = action.input;
			const validators = state.inputs[name].validators;
			const { isValid, message } = validators
				? validate(value, validators, { ...state.inputs })
				: { isValid: true };
			for (let clave in state.inputs) {
				if (!state.inputs[clave]) {
					continue;
				}

				if (name === clave) {
					isFormValid = isFormValid && isValid;
				} else {
					isFormValid = isFormValid && state.inputs[clave].isValid;
				}
			}

			let hasChange = false;
			const inputsClone = { ...state.inputs };
			for (let field in inputsClone) {
				/** search for a change in any of the fields */

				if (hasChange || field === 'reasonModification' || !state.prevData) continue;
				const selectValue = [
					'caseTypeCategory',
					'caseCategory',
					'currencyCode',
					'countryCode',
					'partyGroupCode',
					'caseCategory',
					'dispositionCategoryCode'
				];
				const differentCodes = {
					'Payment Method': 'paymentCode',
					State: 'stateCode',
					Party: 'partyCode',
					Flags: 'flagCode',
					'Case Type Category': 'caseTypeCategoryCode',
					'Disposition Code': 'dispositionCode'
				};

				const currentValue =
					name === field
						? value
						: inputsClone[field].type === 'select' && inputsClone[field].value
						? selectValue.includes(field)
							? inputsClone[field].value.value
							: inputsClone[field].value.label
						: inputsClone[field].value;

				const differentCode = field === 'code' && differentCodes[state.prevData['codeType']];
				const isDate = inputsClone[field].type === 'date';
				field = differentCode ? differentCode : field;

				let prevValue = state.prevData[field];
				/** exception for date type field, because function formatDate apply a - if the date is empty */
				if (isDate && prevValue === '-') {
					prevValue = '';
				}
				hasChange = currentValue !== prevValue;
			}

			/** exception for InactiveDate and ActiveDate */
			if (name === 'inactiveDate') {
				const { isValid: isActiveDateValid, message: activeDateMessage } = validate(
					state.inputs.activeDate.value,
					state.inputs.activeDate.validators,
					{
						...state.inputs,
						inactiveDate: {
							...state.inputs.inactiveDate,
							value
						}
					}
				);
				const { isFormValid: isValidForm } = runValidators(
					{
						...state.inputs,
						inactiveDate: {
							...state.inputs.inactiveDate,
							value
						}
					},
					true
				);
				isFormValid = isValidForm;
				return {
					...state,
					inputs: {
						...state.inputs,
						[name]: {
							...state.inputs[name],
							value,
							isValid: isValid,
							textRequired: message,
							isBlur: true
						},
						activeDate: {
							...state.inputs['activeDate'],
							isValid: true,
							textRequired: activeDateMessage
						}
					},
					isFormValid,
					hasChange
				};
			}

			if (name === 'activeDate') {
				const { isValid: isActiveDateValid, message: activeDateMessage } = validate(
					state.inputs.inactiveDate.value,
					state.inputs.inactiveDate.validators,
					{
						...state.inputs,
						activeDate: {
							...state.inputs.activeDate,
							value
						}
					}
				);
				const { isFormValid: isValidForm } = runValidators(
					{
						...state.inputs,
						activeDate: {
							...state.inputs.activeDate,
							value
						}
					},
					true
				);
				isFormValid = isValidForm;
				return {
					...state,
					inputs: {
						...state.inputs,
						[name]: {
							...state.inputs[name],
							value,
							isValid: isValid,
							textRequired: message,
							isBlur: true
						},
						inactiveDate: {
							...state.inputs['inactiveDate'],
							isValid: isActiveDateValid,
							textRequired: activeDateMessage
						}
					},
					isFormValid,
					hasChange
				};
			}

			/** exception for Password and ConfirmPassword */
			if (name === 'password') {
				const { isValid: isPasswordValid, message: MsgConfirmPassword } = validate(
					state.inputs.confirmPassword.value,
					state.inputs.confirmPassword.validators,
					{
						...state.inputs,
						password: {
							...state.inputs.password,
							value
						}
					}
				);
				const { isFormValid: isValidForm } = runValidators(
					{
						...state.inputs,
						password: {
							...state.inputs.password,
							value
						}
					},
					true
				);
				isFormValid = isValidForm;
				return {
					...state,
					inputs: {
						...state.inputs,
						[name]: {
							...state.inputs[name],
							value,
							isValid: isValid,
							textRequired: message,
							isBlur: true
						},
						confirmPassword: {
							...state.inputs['confirmPassword'],
							isValid: isPasswordValid,
							textRequired: MsgConfirmPassword
						}
					},
					isFormValid,
					hasChange
				};
			}

			return {
				...state,
				inputs: {
					...state.inputs,
					[name]: {
						...state.inputs[name],
						value,
						isValid: isValid,
						textRequired: message,
						isBlur: true
					}
				},
				isFormValid,
				hasChange
			};
		case 'SET_DATA':
			return {
				...state,
				inputs: action.inputs,
				isFormValid: action.isFormValid
			};
		case 'CHANGE_ON_FOCUS':
			return {
				...state,
				inputs: {
					...state.inputs,
					[action.name]: {
						...state.inputs[action.name],
						isBlur: true
					}
				}
			};
		case 'SET_ALL_BLUR':
			const inputsCopy = { ...state.inputs };

			for (let field in inputsCopy) {
				inputsCopy[field].isBlur = true;
			}
			return {
				...state,
				inputs: { ...inputsCopy }
			};

		case 'SET_CODE_BLUR':
			const inputCopy = { ...state.inputs };

			for (let field in inputCopy) {
				if (inputsCopy[field].value === 'code') inputsCopy[field].isBlur = true;
			}

			return {
				...state,
				inputs: { ...inputsCopy }
			};

		case 'CHANGE_INPUT_PREV_DATA':
			return {
				...state,
				prevData: {
					...state.prevData,
					[action.name]: action.value
				}
			};
		case 'SET_PASSWORD_PROTECTED':
			return {
				...state,
				inputs: {
					...state.inputs,
					password: {
						...state.inputs.password,
						value: state.inputs.passwordProtected?.value ? state.prevData.password : '',
						isValid: !state.inputs.passwordProtected?.value,
						required: state.inputs.passwordProtected?.value,
						validators: state.inputs.passwordProtected?.value
							? [VALIDATOR_REQUIRED('Password is required')]
							: [],
						isBlur: false,
						disabled: !state.inputs.passwordProtected?.value,
						textRequired: 'Password is required'
					},
					confirmPassword: {
						...state.inputs.confirmPassword,
						value: '',
						isValid: !state.inputs.passwordProtected?.value,
						required: state.inputs.passwordProtected?.value,
						validators: state.inputs.passwordProtected?.value
							? [
									VALIDATOR_REQUIRED('Confirm Password is required'),
									VALIDATOR_CONFIRM_PASSWORD('Error: passwords do not match', 'password')
							  ]
							: [],
						isBlur: false,
						disabled: !state.inputs.passwordProtected?.value,
						textRequired: 'Confirm Password is required'
					}
				}
			};
		case 'SET_PASSWORD_HAS_CHANGED':
			return {
				...state,
				inputs: {
					...state.inputs,

					confirmPassword: {
						...state.inputs.confirmPassword,
						value: state.inputs.passwordProtected?.value ? state.prevData.password : '',
						isValid: true,
						validators: state.inputs.passwordProtected?.value
							? [
									VALIDATOR_REQUIRED('Confirm Password is required'),
									VALIDATOR_CONFIRM_PASSWORD('Error: passwords do not match', 'password')
							  ]
							: []
					}
				}
			};
		case 'CHANGE_CODE_FOCUS':
			return {
				...state,
				inputs: {
					...state.inputs,
					[action.name]: {
						...state.inputs[action.name],
						isBlur: true
					}
				}
			};

		default:
			return state;
	}
};

const useFormValidator = (initialState) => {
	const [state, dispatch] = useReducer(formReducer, initialState);

	const handlerInput = useCallback((e) => {
		const { name, value } = e.target;
		dispatch({
			type: 'CHANGE_INPUT',
			input: { value, name }
		});
	}, []);

	const setData = useCallback((inputs, isValid) => {
		const { newInputs, isFormValid } = runValidators(inputs, isValid);
		dispatch({ type: 'SET_DATA', inputs: newInputs, isFormValid });
	}, []);

	const onBlurHandle = useCallback((e) => {
		const { name } = e.target.value;
		dispatch({
			type: 'CHANGE_ON_FOCUS',
			name
		});
	}, []);

	const validateAllForm = useCallback(() => {
		dispatch({
			type: 'SET_ALL_BLUR'
		});
	}, []);

	/** This is just for special cases */
	const updatePrevDate = useCallback((input) => {
		const { name, value } = input;
		dispatch({
			type: 'CHANGE_INPUT_PREV_DATA',
			name,
			value
		});
	}, []);

	/** This is only for Finantial Till  */
	const setPasswordProtected = useCallback((isCreating) => {
		dispatch({
			isCreating,
			type: 'SET_PASSWORD_PROTECTED'
		});
	}, []);

	/** This is only when you edit a row in Finantial Till  **/
	const setPasswordHasChanged = useCallback((isCreating) => {
		dispatch({
			isCreating,
			type: 'SET_PASSWORD_HAS_CHANGED'
		});
	}, []);
	const handleCodeBlur = useCallback(() => {
		dispatch({
			type: 'CHANGE_CODE_FOCUS'
		});
	}, []);

	return {
		stateForm: state,
		handlerInput,
		setData,
		onBlurHandle,
		validateAllForm,
		updatePrevDate,
		setPasswordProtected,
		setPasswordHasChanged,
		handleCodeBlur
	};
};

export default useFormValidator;
