import { useState, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import EditHateCrimeModal from './EditHateCrimeModal';
import { Table } from '../../../components/layout/TableWithIntegration';
import { HateCrimesServices } from '../../../services/EventServices/HateCrimesService';
import { useBackendPaginationFetcher } from '../../../hooks';
import { isMobile } from 'react-device-detect';

function HateCrimesModal(props) {
	const { show, onHide, caseId } = props;
	const [openEditModal, setOpenEditModal] = useState(false);
	const [eventSelected, setEventSelected] = useState(null);
	const { GET_HATE_CRIME_ALL } = HateCrimesServices();

	const columns = [
		{ heading: 'Motion Date', value: 'motionDate' },
		{ heading: 'Motion Result', value: 'motionResult' },
		{ heading: 'Affirmation in Judgment', value: 'affirmationInJudgement' },
		{ heading: 'Judgment Date', value: 'judgmentDate' },
		{ heading: 'Updated By', value: 'updatedBy' },
		{ heading: 'Updated At', value: 'updatedAt' }
	];

	const key = 'content';
	const mapper = (content) => ({
		...content,
		id: content.hateCrimeId,
		motionDate: content.affirmDate,
		motionResult: content.affirmResult,
		affirmationInJudgement: content.affirmInJud ? 'Y' : content.affirmInJud === null ? null : 'N',
		judgmentDate: content.judgmentDate,
		updatedBy: content.updatedBy,
		updatedAt: content.updatedAt
	});

	const url = GET_HATE_CRIME_ALL(caseId);

	const { paginationItems, isLoading, data, refreshTable } = useBackendPaginationFetcher({
		url,
		key,
		mapper,
		constantSize: 10
	});

	const clickedRowFunction = async (item) => {
		setEventSelected(item.id);
		setOpenEditModal(true);
	};

	const handleClose = useCallback(() => {
		onHide();
	}, [onHide]);

	return (
		<>
			<Modal
				size="xl"
				aria-labelledby="view-hate-crime-details-title"
				centered
				onHide={handleClose}
				show={show}
				backdrop="static"
			>
				<Modal.Header closeLabel={`View Hate Crime Details Close Dialog`} closeButton>
					<Modal.Title id="view-hate-crime-details-title">View Hate Crime Details</Modal.Title>
				</Modal.Header>
				<>
					<Modal.Body>
						<Table
							tableId={'hateCrimes'}
							entity={'hateCrimes'}
							data={isLoading ? [] : data}
							columns={columns}
							exportCSV={true}
							exportPDF={true}
							showSearch={true}
							customOrder={true}
							limitRows={true}
							enableDelete={true}
							enableSelection={false}
							enableRowFunction
							clickedRowFunction={clickedRowFunction}
							paginationItems={paginationItems}
							isLoading={isLoading}
							showAsIs={true}
							hideTooltip={isMobile}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={handleClose} type="button">
							Close
						</Button>
					</Modal.Footer>
				</>
			</Modal>
			{openEditModal && (
				<EditHateCrimeModal
					show={openEditModal}
					onHide={() => setOpenEditModal(false)}
					hateCrimeId={eventSelected}
					refreshTable={refreshTable}
				/>
			)}
		</>
	);
}

export default HateCrimesModal;
