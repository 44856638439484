import {
	isGreaterThan,
	formatDateWithoutTime,
	isPasswordConfirm,
	isDescriptionConfirmed,
	isLessThan
} from './helperCodeMaintenance';

const VALIDATOR_TYPE_REQUIRED = 'REQUIRED';
const VALIDATOR_TYPE_MINLENGTH = 'MINLENGTH';
const VALIDATOR_TYPE_MAXLENGTH = 'MAXLENGTH';
const VALIDATOR_TYPE_GREATERTHAN = 'GREATERTHAN';
const VALIDATOR_TYPE_EMAIL = 'EMAIL';
const NO_SPECIAL_CHARACTERS = 'NO_SPECIAL_CHARACTERS';
const VALIDATOR_TYPE_MIN_DATE = 'VALIDATOR_TYPE_MIN_DATE';
const VALIDATOR_TYPE_GREATER_THAN_DATE = 'GREATER_THAN_DATE';
const VALIDATOR_TYPE_LESS_THAN_DATE = 'LESS_THAN_DATE';
const VALIDATOR_TYPE_CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
const VALIDATOR_TYPE_ALLOW_ONLY_NUMBERS = 'TYPE_ALLOW_ONLY_NUMBERS';

export const VALIDATOR_REQUIRED = (message = 'No valid field') => ({
	type: VALIDATOR_TYPE_REQUIRED,
	message
});
export const VALIDATOR_MINLENGHT = (val) => ({
	type: VALIDATOR_TYPE_MINLENGTH,
	val
});
export const VALIDATOR_MAXLENGHT = (val) => ({
	type: VALIDATOR_TYPE_MAXLENGTH,
	val
});
export const VALIDATOR_GREATERTHAN = (val) => ({
	type: VALIDATOR_TYPE_GREATERTHAN,
	val
});
export const VALIDATOR_EMAIL = (message = 'Invalid email') => ({
	type: VALIDATOR_TYPE_EMAIL,
	message
});
export const VALIDATOR_NO_SPECIAL_CHARACTERS = (message = 'No valid field') => ({
	type: NO_SPECIAL_CHARACTERS,
	message
});
export const VALIDATOR_VALIDATOR_TYPE_MIN_DATE = (message = 'Invalid field.', date, prevDate) => ({
	type: VALIDATOR_TYPE_MIN_DATE,
	message,
	date,
	prevDate
});
export const VALIDATOR_GREATER_THAN_DATE = (message = 'No valid field', dateField) => ({
	type: VALIDATOR_TYPE_GREATER_THAN_DATE,
	message,
	dateField
});
export const VALIDATOR_LESS_THAN_DATE = (message = 'No valid field', dateField) => ({
	type: VALIDATOR_TYPE_LESS_THAN_DATE,
	message,
	dateField
});
export const VALIDATOR_CONFIRM_PASSWORD = (message = 'No valid field', passwordField) => ({
	type: VALIDATOR_TYPE_CONFIRM_PASSWORD,
	message,
	passwordField
});
export const VALIDATOR_DESCRIPTION = (message = 'No valid field') => ({
	type: VALIDATOR_DESCRIPTION,
	message
});
export const VALIDATOR_ALLOW_ONLY_NUMBERS = (message = 'No valid field') => ({
	type: VALIDATOR_TYPE_ALLOW_ONLY_NUMBERS,
	message
});

export const validate = (val = '', validators = [], inputs) => {
	let isValid = true;
	let message = '';
	let value = ['string', 'boolean'].includes(typeof val) || Array.isArray(val) ? val : val.value;
	if (typeof value === 'number') value = String(value);

	for (let validator of validators) {
		if (validator.type === VALIDATOR_TYPE_REQUIRED) {
			if (typeof val === 'boolean') {
				isValid = true;
			} else {
				isValid = typeof val === 'string' ? value.trim().length > 0 : value.length > 0;
			}
		}
		if (validator.type === VALIDATOR_TYPE_MINLENGTH) {
			isValid = value.trim().length >= validator.val;
		}
		if (validator.type === VALIDATOR_TYPE_MAXLENGTH) {
			isValid = value.trim().length <= validator.val;
		}
		if (validator.type === VALIDATOR_TYPE_GREATERTHAN) {
			isValid = +value > validator.val;
		}
		if (validator.type === VALIDATOR_TYPE_EMAIL) {
			isValid = /^\S+@\S+\.\S+$/.test(value);
		}
		if (validator.type === NO_SPECIAL_CHARACTERS) {
			isValid = !/["!@#$%^&*()+=\-\-[\]\\';,./{}|:<>?~``¡°¿¬¨¨]/g.test(value);
		}
		if (validator.type === VALIDATOR_TYPE_MIN_DATE) {
			if (!validator.prevDate) {
				isValid = isGreaterThan(value, formatDateWithoutTime(validator.date));
			}
		}
		if (validator.type === VALIDATOR_TYPE_GREATER_THAN_DATE) {
			// const date = inputs[validator.dateField].value;
			// isValid = !isGreaterThan(value, date);
			const date = inputs?.activeDate?.value ? inputs?.inactiveDate?.value : null;
			if (inputs?.activeDate?.value) {
				isValid = isLessThan(value?.split('T').shift(), date?.split('T').shift());
			}
		}

		if (validator.type === VALIDATOR_TYPE_LESS_THAN_DATE) {
			const date = inputs?.activeDate?.value ? inputs?.activeDate?.value : null;
			if (value && inputs?.activeDate?.value) {
				isValid = isGreaterThan(value.split('T').shift(), date.split('T').shift());
			}
		}
		if (validator.type === VALIDATOR_TYPE_CONFIRM_PASSWORD) {
			const confirmPassword = inputs[validator.passwordField].value;
			isValid = isPasswordConfirm(value, confirmPassword);
		}
		if (validator.type === VALIDATOR_DESCRIPTION) {
			const confirmDescription = inputs?.description?.value;
			isValid = isDescriptionConfirmed(confirmDescription);
		}
		if (validator.type === VALIDATOR_TYPE_ALLOW_ONLY_NUMBERS) {
			const regex = RegExp(/[0-9]+/g);
			const test_result = regex.test(value);
			isValid = test_result;
		}

		message = validator.message;
		if (!isValid) break;
	}
	return { isValid, message };
};
