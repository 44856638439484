import { useEffect, useState } from 'react';
import { useScanDocument } from '../../../../DocumentView/Helpers';
import { v4 as uuid } from 'uuid';
import { TOAST_TYPES } from '../../../../../components/ToastAlert';
import { Button } from 'react-bootstrap';
import ScanButtonServices from './ScanButtonServices';

const PDF_CONFIG = {
	extension: 'pdf',
	l: process.env.REACT_APP_PDFTRON_FE
};

const ScanButton = ({
	instanceDocumentViewer,
	setIsLoadingDocument,
	setToast,
	token,
	webViewer
}) => {
	const [uuidToken, setUUIDToken] = useState();
	const [urlScanDocument, setUrlScanDocument] = useState();
	const { data, error } = useScanDocument(urlScanDocument, uuidToken);
	const [scanFiles, setScanFiles] = useState([]);
	const [firstPageLoaded, setFirstPageLoaded] = useState(false);
	const { selectFile } = ScanButtonServices();

	useEffect(() => {
		if (!urlScanDocument) return;

		if (data && data?.scanningCompleted) {
			setUrlScanDocument(null);
			setUUIDToken();
			setScanFiles(data.files);
			return;
		}

		if (error) {
			setToast({
				show: true,
				text: 'An error has been encountered on retrieving data from the scanner',
				type: TOAST_TYPES.DANGER
			});
			setUrlScanDocument(null);
			setUUIDToken();
			setIsLoadingDocument(false);
			return;
		}
	}, [urlScanDocument, data, error]);

	useEffect(() => {
		if (scanFiles.length <= 0) return;

		createDocumentFromScan();
	}, [scanFiles]);

	useEffect(() => {
		if (!instanceDocumentViewer) return;
		if (firstPageLoaded) {
			if (scanFiles.length > 1) {
				loadPagesOnExistingDocument();
			} else {
				setIsLoadingDocument(false);
				setScanFiles([]);
				setFirstPageLoaded(false);
			}
		}
	}, [firstPageLoaded, instanceDocumentViewer]);

	const createDocumentFromScan = () => {
		if (instanceDocumentViewer) {
			loadFirstPageOnExistingDocument();
		} else {
			loadFirstPageOnEmptyDocument();
		}
	};

	/**
	 * loadPagesOnExistingDocument
	 */

	const loadPagesOnExistingDocument = async () => {
		try {
			const { Core } = webViewer;
			const currentDocument = instanceDocumentViewer.getDocument();

			/**
			 * Avoid loading first page if it was already uploaded on loadFirstPageOnEmptyDocument
			 */
			const pagesToUpload = [...scanFiles.slice(1)];

			/**
			 * Creates scanDocument, this one will be inserted into the current visible document
			 */
			const { url } = pagesToUpload[0];
			const file = await selectFile(url, webViewer);
			let scanDocument = await Core.createDocument(file, PDF_CONFIG);

			/**
			 * Creates a temporal document for every page in order to insert on scanDocument
			 */
			const pages = pagesToUpload.slice(1);
			for (const item of pages) {
				const { url } = item;
				const fileTemp = await selectFile(url, webViewer);
				const pagesToInsert = [1];
				const pageIndexToInsert = scanDocument.getPageCount() + 1;
				const docToInsert = await Core.createDocument(fileTemp, PDF_CONFIG);

				await scanDocument.insertPages(docToInsert, pagesToInsert, pageIndexToInsert);
			}

			/**
			 * get an array of pages to insert into the curent visible document (all pages)
			 */
			let pagesToInsert = [];
			for (let i = 1; i <= scanDocument.getPageCount(); i++) {
				pagesToInsert.push(i);
			}
			const pageIndexToInsert = currentDocument.getPageCount() + 1;

			/**
			 * insert scanDocument into de current visible document
			 */
			await currentDocument.insertPages(scanDocument, pagesToInsert, pageIndexToInsert);
		} catch (error) {
			console.error(error);
			setToast({
				show: true,
				text: 'An error has been encountered while loading the scanned pages',
				type: TOAST_TYPES.DANGER
			});
		} finally {
			setIsLoadingDocument(false);
			setScanFiles([]);
			setFirstPageLoaded(false);
		}
	};

	/** loadFirstPageOnExistingDocument
	 * needed to load the first page on an existing instanceDocumentViewer
	 */
	const loadFirstPageOnExistingDocument = async () => {
		try {
			const { Core } = webViewer;
			const currentDocument = instanceDocumentViewer.getDocument();

			/**
			 * Creates scanDocument, this one will be inserted into the current visible document
			 */
			const { url } = scanFiles[0];
			const file = await selectFile(url, webViewer);
			let scanDocument = await Core.createDocument(file, PDF_CONFIG);

			/**
			 * get an array of pages to insert into the curent visible document (all pages)
			 */
			let pagesToInsert = [1];
			const pageIndexToInsert = currentDocument.getPageCount() + 1;

			/**
			 * insert scanDocument into de current visible document
			 */
			await currentDocument.insertPages(scanDocument, pagesToInsert, pageIndexToInsert);
		} catch (error) {
			console.error(error);
			setToast({
				show: true,
				text: 'An error has been encountered while loading the first page',
				type: TOAST_TYPES.DANGER
			});
		} finally {
			setIsLoadingDocument(false);
			setFirstPageLoaded(true);
		}
	};

	/**
	 * loadFirstPageOnEmptyDocument
	 * - Will load the first page on an empty instance of webViewer
	 */
	const loadFirstPageOnEmptyDocument = async () => {
		try {
			const initDocument = scanFiles[0];
			const { url } = initDocument;
			const { UI } = webViewer;
			const file = await selectFile(url, webViewer);

			await UI.loadDocument(file, {
				extension: 'pdf',
				l: process.env.REACT_APP_PDFTRON_FE
			});
		} catch (error) {
			console.error(error);
			setToast({
				show: true,
				text: 'An error has been encountered while loading the first page',
				type: TOAST_TYPES.DANGER
			});
			setScanFiles([]);
		} finally {
			setIsLoadingDocument(false);
			setFirstPageLoaded(true);
		}
	};

	const calliDockScanApp = () => {
		try {
			const url = new URL(`${process.env.REACT_APP_API_URL}/api/scanner`);

			let port = '80';

			if (url.protocol === 'https:') {
				port = '443';
			} else if (url.port) {
				port = url.port;
			}

			const unique_id = uuid();
			const link = `ucmsscan: page=1; scan=multiple;uuid=${unique_id};user=dummy;token=${token};host=${url.host};path=${url.pathname};port=${port};`;

			window.location.assign(link);
			setUUIDToken(unique_id);
			setUrlScanDocument('/scanner/scanner-document-filings');
		} catch (error) {
			console.error(error);
			setToast({
				show: true,
				text: 'An error has been encountered on calling the scanner handler',
				type: TOAST_TYPES.DANGER
			});
			setUrlScanDocument(null);
		}
	};

	return (
		<Button id="btn-scan-doc-id" onClick={calliDockScanApp} disabled={!webViewer}>
			Scan Document
		</Button>
	);
};

export default ScanButton;
