// constantes
const initialState = {
	urlParams: {}
};

// types
const GET_URL_PARAMS = 'GET_URL_PARAMS';

// reducer
export default function urlParamReducer(state = initialState, action) {
	switch (action.type) {
		case GET_URL_PARAMS:
			return { ...state, urlParams: action.payload };
		default:
			return state;
	}
}

// actions
export const getUrlParams = (urlParams) => async (dispatch) =>
	dispatch({
		type: GET_URL_PARAMS,
		payload: urlParams
	});
