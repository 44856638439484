export const ConfirmationDialogUtils = () => {
	const buildTitle = ({ entity, customTitle }) => {
		if (customTitle) {
			return customTitle;
		}
		if (entity) {
			return `Warning: ${entity} will be deleted`;
		}
		return 'Confirmation Required';
	};

	const getModalBody = ({ customDelMessage = null, entity = '' }) =>
		customDelMessage ?? `Are you sure you want to delete ${entity}?`;

	return {
		buildTitle,
		getModalBody
	};
};
