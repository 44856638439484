import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectInputList } from '../redux/isReportAffected/isReportAffectedSelector';

function useReportAffected(pageName) {
	const inputList = useSelector(selectInputList);
	const affectsOcaInputs = useMemo(() => inputList[pageName] ?? [], [inputList, pageName]);

	const isReportAffected = useCallback(
		(inputName) => affectsOcaInputs?.includes(inputName),
		[affectsOcaInputs]
	);

	return isReportAffected;
}

export default useReportAffected;
