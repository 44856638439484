export const cookieFinder = (cookieName) => {
	const cookies = document.cookie.split(';');
	const value = cookies.find((cookie) => cookie.includes(cookieName));
	return value?.substring(value.indexOf('=') + 1, value.length);
};

export function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

export function setCookie(cname, cvalue, exdays) {
	if (exdays) {
		const d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		let expires = 'expires=' + d.toUTCString();
		document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
	} else {
		document.cookie = cname + '=' + cvalue + ';path=/';
	}
}

export function removeCookie(cname) {
	document.cookie = cname + '=0;max-age=0;path=/';
}
