import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const RefundService = () => {
	const { get, post, put, deleteFn } = HttpService();

	const getAllDisbursements = (caseId) => {
		return get(`${BASE_URL}/api/refund/${caseId}`, 'getAllDisbursements', {});
	};

	const postRefund = (body) => {
		const url = `${BASE_URL}/api/refund`;
		return post(url, body, 'postRefund', {});
	};

	const getRefundDetails = (refundId) => {
		return get(`${BASE_URL}/api/refund/requested/${refundId}`, 'getRefundDetails', {});
	};

	const getFeesFine = (body) => {
		const url = `${BASE_URL}/api/refund/multiple-payment?page=0&size=1000`;
		return post(url, body, 'getFeesFine', {});
	};

	const getHistory = (caseId) => {
		const url = `${BASE_URL}/api/payments/case/${caseId}?page=0&size=1000`;
		return get(url, 'getHistory', {});
	};

	const getDisbursementDistribution = (body) => {
		const url = `${BASE_URL}/api/refund/final-payment?page=0&size=1000`;
		return post(url, body, 'getDisbursementDistribution', {});
	};

	const getRefundStatus = (paymentId) => {
		const url = `${BASE_URL}/api/refund/${paymentId}/status`;
		return get(url, 'getRefundStatus', {});
	};

	const getRefundReceiptPos = (refundId) => {
		const url = `${BASE_URL}/api/refund/refund-receipt/${refundId}`;
		return get(url, 'getRefundReceiptPos', {});
	};

	return {
		getAllDisbursements,
		postRefund,
		getRefundDetails,
		getFeesFine,
		getHistory,
		getDisbursementDistribution,
		getRefundStatus,
		getRefundReceiptPos
	};
};
