import * as Yup from 'yup';

export const RECEIPT_FORM_SCHEMA = () => {
	const emailRegex = /^[^.][\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
	const validationObject = {
		option: Yup.string(),

		receiptSize: Yup.object().when('option', {
			is: (value) => value === 'print',
			then: Yup.object().required('Receipt size is a required field').nullable()
		}),

		emailAdress: Yup.string().when('option', {
			is: (value) => value === 'email',
			then: Yup.string()
				.required('An Email Address is required')
				.matches(emailRegex, ' Invalid email address')
		}),

		phoneNumber: Yup.string().when('option', {
			is: (value) => value === 'text',
			then: Yup.string()
				.matches(
					/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
					'Phone number field is missing characters.'
				)
				.max(15, 'Must be 15 characters or less')
				.required('A Phone Number is required')
		})
	};
	return Yup.object(validationObject);
};
