/**
 * scrollToTop
 * @param {string} domElement - html name or regex to be applied by querySelector. Default centerPanel
 **/

export const scrollToTop = (domElement = '[id^=styles_centerPanel__]') => {
	const element = document.querySelector(domElement);
	element.scrollTo({
		top: 0,
		behavior: 'smooth'
	});
	element.scrollIntoView({
		top: 0,
		behavior: 'smooth'
	});
};
