import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const conflictOfInterestServices = () => {
	const { get, post, put } = HttpService();

	const getConflictsOfInterest = async () => {
		const response = await get(`${BASE_URL}/api/conflictInterestPerson/conflictOfInterestCombo`);
		return response.data;
	};

	const getListOfParticipants = async (caseId, setData) => {
		const response = await get(`${BASE_URL}/api/party-management/party/cases/${caseId}`);
		setData(response.data);
	};

	const getCaseParticipants = async (caseId) => {
		await get(`${BASE_URL}/api/party-management/cases/${caseId}`).then((data) => {});
	};

	const getPartyDismissalReasons = async (setData) => {
		const response = await get(
			`${BASE_URL}/api/code-table-management/code-tables/categories/party_dismissal_reason`
		);
		return response.data;
	};
	const postNewConflicts = (body, caseId) => {
		const url = `${BASE_URL}/api/conflictInterestParty/cases/${caseId}`;
		return post(url, body, 'newConflict', {});
	};

	const getCodeTables = async (codeType, setState) => {
		const codeTableObj = await get(`${BASE_URL}/api/code-table-management/code-types`).then(
			function (res) {
				var result = res.data.filter((obj) => {
					return obj.codeType === codeType;
				});
				return result;
			}
		);

		const options = await get(
			`${BASE_URL}/api/code-table-management/codes?codeCategoryId=${codeTableObj[0].codeCategoryId}&codeTypeId=${codeTableObj[0].codeTypeId}`
		).then(function (res) {
			return res.data.fields;
		});
		setState(options);
	};

	const putCaseConflict = async (body) => {
		const result = await put(
			`${BASE_URL}/api/conflictInterestParty/viewConflicts/update`,
			body,
			'Updating'
		);
		return result;
	};

	const putPersonConflict = async (body) => {
		const result = await put(
			`${BASE_URL}/api/conflictInterestParty/viewConflicts/conflictOfInterest/update`,
			body,
			'Updating'
		);
		return result;
	};

	const postNewPersonConflicts = (body, personId) => {
		const url = `${BASE_URL}/api/conflictInterestPerson/person/${personId}`;
		return post(url, body, 'NewPersonConflicts', {});
	};

	const editPersonConflicts = (body) => {
		const url = `${BASE_URL}/api/conflictInterestPerson/update`;
		return put(url, body, 'EditPersonConflicts');
	};

	const getPersonConflictsByConlfictId = (conflictOfInterestId) => {
		const url = `${BASE_URL}/api/conflictInterestPerson/conflictOfInterestId/${conflictOfInterestId}`;
		return get(url);
	};

	const checkCaseAccessStatus = (caseId, userId) => {
		const url = `${BASE_URL}/api/conflictInterestPerson/checkDenyCaseAccessStatus/${userId}/${caseId}`;
		return get(url);
	};

	const checkCaseParticipationStatus = (caseId, personId) => {
		const url = `${BASE_URL}/api/conflictInterestPerson/checkDenyCaseParticipationStatus/${personId}/${caseId}`;
		return get(url);
	};

	const getCaseConflictsByConlfictId = (caseConflictOfInterestId) => {
		const url = `${BASE_URL}/api/conflictInterestParty/caseConflict/${caseConflictOfInterestId}`;
		return get(url);
	};

	const checkAttorneyCaseParticipation = (caseId, attorneyId) => {
		const url = `${BASE_URL}/api/conflictInterestPerson/checkDenyCaseParticipationStatus/attorney/${attorneyId}/${caseId}`;
		return get(url);
	};

	return {
		getConflictsOfInterest,
		getCaseParticipants,
		postNewConflicts,
		getCodeTables,
		getPartyDismissalReasons,
		putCaseConflict,
		putPersonConflict,
		postNewPersonConflicts,
		editPersonConflicts,
		getPersonConflictsByConlfictId,
		getListOfParticipants,
		checkCaseAccessStatus,
		checkCaseParticipationStatus,
		getCaseConflictsByConlfictId,
		checkAttorneyCaseParticipation
	};
};
