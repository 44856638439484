import React from 'react';

import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import { FormDatePicker } from './';

export const FieldFormDatePicker = ({
	id,
	label = '',
	required,
	maxDate,
	minDate,
	onChange,
	disabled,
	onKeyDown
}) => (
	<>
		<Field name={id}>
			{({ field, form, meta }) => (
				<FormDatePicker
					id={field.name}
					label={label}
					value={field.value}
					onChange={(value) => {
						form.setFieldValue(field.name, value);
						onChange?.(value);
					}}
					required={required}
					maxDate={maxDate}
					minDate={minDate}
					disabled={disabled}
					onKeyDown={onKeyDown}
					isInvalid={meta.touched && meta.error}
				/>
			)}
		</Field>
		<ErrorMessage
			component="div"
			name={id}
			className={'position-absolute small text-danger'}
			role="alert"
		/>
	</>
);

FieldFormDatePicker.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	required: PropTypes.bool
};
