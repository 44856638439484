import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { HomeAdminService } from '../../../services/HomeAdminService';
import { Dialog } from '../../../components/common';
import MainSectionDialog from '../../../pages/HomePage/MainSectionDialog';
import { formPathsFromUrl, formPathsFromMultipleUrls } from '../../../utils/urlUtils';
import { useToast, useModalDialog } from '../../../hooks';
import { ResponseAlert, TOAST_TYPES } from '../../ResponseAlert/ResponseAlert';
import useUserStore from '../../../redux/utils/storeUtils';

/**
 * Description.
 * @param {boolean} accessibleFromMultiplePages - boolean value to determine if the page, where breadcrumbnav is beign used from, is accessible from multiple places.
 * @param {array} paths - array of objects with name (link description) and url (route) props for links. Last item will be render as the current page.
 */

const BreadcrumbNav = ({ paths: customPaths, accessibleFromMultiplePages, denyBookmark }) => {
	const { selectedClerkId } = useUserStore();
	const { getBookmark } = HomeAdminService();
	const [alreadyBookmark, setAlreadyBookmark] = useState(false);
	const [isOpenCancelDialog, openCancelDialog, closeCancelDialog] = useModalDialog();
	const [dialogData, setDialogData] = useState({});
	const [dialogSubmit, setDialogSubmit] = useState(false);
	const userId = useSelector((state) => state.user.userInfo?.id);
	const username = useSelector((state) => state.user.userInfo?.username);
	const [modalTitle, setModalTitle] = useState('Save Bookmark');
	const [toast, openToast, closeToast] = useToast();

	const location = useLocation();
	const pathsHistory = useSelector((state) => state.pathsHistory.pathsHistory);

	let cleanLink = new URL(window.location.href);
	let cleanUrl = process.env.REACT_APP_BASE_URL + cleanLink.pathname + cleanLink.search;

	const buildBreadcrumbNav = () => {
		const paths =
			accessibleFromMultiplePages && pathsHistory.length > 1
				? formPathsFromMultipleUrls(pathsHistory)
				: customPaths || formPathsFromUrl(location.pathname);

		const length = paths.length;

		const formatPathName = (pathName) => {
			let path = pathName;
			if (path) {
				path = path?.replaceAll('Oca ', 'OCA ');
				path = path?.replaceAll('Gasb ', 'GASB ');
			}
			return path;
		};

		return paths.map((path, index) => {
			if (index < length - 1) {
				return (
					<BreadcrumbItem key={index} linkAs={Link} linkProps={{ to: path.url }}>
						{formatPathName(path.name)}
					</BreadcrumbItem>
				);
			} else {
				return (
					<BreadcrumbItem active key={index}>
						{formatPathName(path.name)}
					</BreadcrumbItem>
				);
			}
		});
	};

	const checkBookmark = (status) => {
		getBookmark(username).then((res) => {
			res?.data?.bookmarkDTOS?.forEach((item) => {
				if (item.url === cleanUrl) {
					setAlreadyBookmark(true);
					setModalTitle('Edit Bookmark');
					setDialogData({
						id: item.id,
						name: item.name,
						description: item.description,
						categoryId: 0,
						url: cleanUrl,
						userId: {
							id: userId,
							username: username
						}
					});
				} else {
				}
			});
		});

		if (status === 'open') {
			openCancelDialog();
		}
	};

	const saveClose = () => {
		setDialogSubmit(false);
		closeCancelDialog();
		openToast(
			alreadyBookmark ? 'Bookmark updated successfully.' : 'Bookmark created successfully.',
			TOAST_TYPES.SUCCESS
		);
	};

	useEffect(() => {
		checkBookmark('check');
		setAlreadyBookmark(false);
		setModalTitle('Save Bookmark');
		setDialogData({
			id: 0,
			name: 'New bookmark name',
			description: 'New bookmark description',
			categoryId: 0,
			url: cleanUrl,
			userId: {
				id: userId,
				username: username
			}
		});
	}, [selectedClerkId]);

	return (
		<Row>
			<Col xs="9" md="11">
				<Breadcrumb>{buildBreadcrumbNav()}</Breadcrumb>
			</Col>
			<Col xs="3" md="1" className="d-flex justify-content-end align-items-start">
				<Dialog
					isShow={isOpenCancelDialog}
					title={modalTitle}
					onClose={() => {
						closeCancelDialog();
						setDialogSubmit(false);
					}}
					onCancelButton={() => {
						closeCancelDialog();
						setDialogSubmit(false);
					}}
					isAForm={true}
					enableSubmit={true}
					onConfirm={() => {
						setDialogSubmit(!dialogSubmit);
					}}
					size="md"
				>
					<MainSectionDialog
						alreadyBookmark={alreadyBookmark}
						dialogData={dialogData}
						setSubmition={dialogSubmit}
						checkBookmark={checkBookmark}
						saveClose={saveClose}
					/>
				</Dialog>
				{!denyBookmark && (
					<div
						className={alreadyBookmark ? 'text-darkblue' : 'text-secondary'}
						onClick={() => {
							checkBookmark('open');
						}}
						role="button"
						aria-label="Bookmark Page"
						tabIndex={0}
					>
						<i className="fas fa-star"></i>
					</div>
				)}
			</Col>
			<Col>
				<ResponseAlert show={toast.show} text={toast.text} type={toast.type} close={closeToast} />
			</Col>
		</Row>
	);
};

BreadcrumbNav.propTypes = {
	accessibleFromMultiplePages: PropTypes.bool,
	paths: PropTypes.array
};

export default BreadcrumbNav;
