import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { setUserSelectedClerkId } from '../../../redux/userDucks';
import useUserStore from '../../../redux/utils/storeUtils';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

const JurisdictionSelect = () => {
	const { userClerks, selectedClerkId, selectedClerk } = useUserStore();
	const dispatch = useDispatch();
	const jurisdictionTopMenuRef = useRef(null);
	const location = useLocation();
	const locationState = location.state;

	const handleJurisdictionSelect = (clerkId) => {
		dispatch(setUserSelectedClerkId(clerkId));
		if (locationState?.reloadPageUrlOnJurisdictionChange) {
			const a = document.createElement('a');
			a.href = locationState?.reloadPageUrlOnJurisdictionChange;
			document.body.appendChild(a);
			a.click();
			return;
		}
		window.location.reload();
	};

	useEffect(() => {
		function focusJurisdictionTopMenu(event) {
			if (event.keyCode === 74 && event.altKey) {
				jurisdictionTopMenuRef.current.focus();
			}
		}

		document.addEventListener('keydown', focusJurisdictionTopMenu);

		return () => {
			document.removeEventListener('keydown', focusJurisdictionTopMenu);
		};
	});

	return (
		<>
			<div role="navigation" aria-label="Jurisdiction Menu">
				<ul className={`${styles.jurisdictionTopMenu} navbar-nav`}>
					<li className="visually-hidden">Select Jurisdiction</li>
					<li className="nav-item dropdown">
						<a
							aria-expanded="false"
							className="nav-link"
							data-bs-toggle="dropdown"
							href="/#"
							id="JurisdictionDropdown"
							role="button"
							ref={jurisdictionTopMenuRef}
						>
							{!isEmpty(userClerks) ? (
								<>
									<div>
										{!isEmpty(selectedClerk)
											? selectedClerk.countyCodeDescription
											: !isEmpty(userClerks)
											? userClerks[0].countyCodeDescription
											: ''}
									</div>
									<div>
										{!isEmpty(selectedClerk)
											? selectedClerk.clerkTypeDescription
											: !isEmpty(userClerks)
											? userClerks[0].clerkTypeDescription
											: ''}
									</div>
								</>
							) : (
								<>
									<div>Jurisdiction</div>
									<div>N/A</div>
								</>
							)}
							<i className="fas fa-caret-down"></i>
						</a>
						<ul aria-labelledby="JurisdictionDropdown" className="dropdown-menu">
							<li>
								<span>Jurisdiction</span>
							</li>
							{!isEmpty(userClerks) ? (
								userClerks.map((item, index) => (
									<li key={index}>
										<button onClick={() => handleJurisdictionSelect(item.clerkId)}>
											{item.jurisdiction}
										</button>
										{selectedClerkId && selectedClerkId === item.clerkId ? (
											<i className="fas fa-circle"></i>
										) : (
											<i></i>
										)}
									</li>
								))
							) : (
								<>
									<li>
										<button className="text-center">No Jurisdiction available</button>
									</li>
								</>
							)}
						</ul>
					</li>
				</ul>
			</div>
		</>
	);
};

export default JurisdictionSelect;
