import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const QuickSearchService = () => {
	const { get } = HttpService();

	const searchByFilter = (search,page = 0,size = 10) => {
		const url = `${BASE_URL}/api/cases/quick-search?search=${search}&page=${page}&size=${size}`;
		return get(url, 'searchByQuickSearchFilters', {});
	};

	return {
		searchByFilter
	};
};
