export const NOOP = () => {};

export const ALIAS_TYPE_CODE_ID = 4;
export const ID_TYPE_CODE_ID = 76;

export const EMPTY_TOAST_ALERT = {
	type: '',
	text: '',
	show: false
};

export const DEBOUNCE_TEXT_INPUT_TIME = 500;
export const YYYYMMDD = 'YYYY-MM-DD';
export const UNEXPECTED_ERROR = 'An unexpected error occurred contact your administrator';

export const UNITED_STATES_OF_AMERICA_ACRONYM = 'USA';

export const DEFAULT_ITEMS_CALCULATED_IN_FE = {
	items: [],
	itemsInPages: [],
	totalPages: 0,
	currentPage: 0,
	totalElements: 0,
	size: null
};
