import { useCallback } from 'react';

import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const useCodesServices = () => {
	const { get } = HttpService();

	const getCategories = useCallback(
		(codeCategory) =>
			get(`${BASE_URL}/api/code-table-management/code-tables/categories/${codeCategory}`),
		[get]
	);

	return {
		getCategories
	};
};
