import { isValid } from 'date-fns';
import _, { isNaN, toLower } from 'lodash';
import { dashes2slashes, dateToUSDate, handleCleanAmount } from '../utils';
import { isMobile } from 'react-device-detect';
import ReactDOMServer from 'react-dom/server';
import { PdfReportHeader } from './PdfReportHeader';
import {
	SKIP_SELECT_CHECKBOX_CSV_PDF,
	TABLE_KEY_EVENT_VIEW_ALL
} from '../constants/tableWithIntegration';

// CREATE CSV OF TABLE
export function exportTableToCSV(filename, separator, tableRows, tableId) {
	try {
		const csv = [];
		const rows = tableRows.current.querySelectorAll('tr');

		// Add TotalRow
		const totalRow = document.getElementById(`totalRow-${tableId}`);
		if (totalRow) {
			rows?.push(totalRow);
		}

		for (let i = 0; i < rows.length; i++) {
			let row = [];
			const cols = rows[i].querySelectorAll('td, th');

			let filteredCols = [];
			cols.forEach((e) => {
				e?.innerText !== 'Accesibility Column Fix' &&
					e?.innerText !== 'Actions' &&
					filteredCols.push(e);
			});
			for (let j = 0; j < filteredCols.length; j++)
				if (filteredCols[j]?.firstChild?.nodeName === 'INPUT') {
					filteredCols[j].firstChild?.checked ? row.push(`selected`) : row.push(`" "`);
				} else {
					// Add double quote to make the value stay in the same column if is separated by comma
					row.push(`"${filteredCols[j].innerText.trim()}"`);
				}

			csv.push(row.join(separator));
		}

		// Download CSV file
		downloadCSV(csv.join('\n'), filename);
	} catch {
		console.error('exportTableToCSV func is not working propertly');
	}
}

function replaceCommasWithSpaces(arr) {
	const result = arr.map((obj) => {
		const newObj = {};
		Object.keys(obj).forEach((key) => {
			const value = obj[key];
			if (typeof value === 'string') {
				newObj[key] = value.replace(/,/g, ' ');
			} else {
				newObj[key] = value;
			}
		});
		return newObj;
	});
	return result;
}

function enableRadioCheckboxes(columns, rows, printOptions) {
	columns = [
		{ heading: printOptions?.radioColumnTitle || 'Selected', value: 'isChecked' },
		...columns
	];
	if (printOptions?.enableRadio) {
		rows = rows?.map((e) => {
			const isSelected = _.isEqual(e, printOptions?.radioSelectedRow);
			return { ...e, isChecked: isSelected };
		});
	} else {
		rows = rows?.map((e) => {
			const isSelected = printOptions?.selectedRows.find((x) => e.id === x.id) !== undefined;
			return { ...e, isChecked: isSelected };
		});
	}

	return {
		newColumns: columns,
		newRows: rows
	};
}

// ALL DATA TO CSV
export function allDataToCSV(columns, rows, printOptions) {
	if (printOptions?.enableSelection || printOptions?.enableRadio) {
		const { newColumns, newRows } = enableRadioCheckboxes(columns, rows, printOptions);

		columns = newColumns;
		rows = newRows;
	}

	if (SKIP_SELECT_CHECKBOX_CSV_PDF.includes(printOptions.tableId)) {
		columns = columns.filter((col) => col.heading !== 'Selected');
	}

	// Add TotalRow
	const totalRow = document.getElementById(`totalRow-${printOptions.tableId}`);
	if (totalRow) {
		const cells = totalRow.querySelectorAll('td');
		rows.push({
			fees: cells[0].textContent.trim(),
			amount: cells[1].textContent.trim(),
			paymentAmount: cells[2].textContent.trim(),
			creditAmount: cells[3].textContent.trim(),
			currentBalance: cells[4].textContent.trim()
		});
	}

	try {
		var csv = '';
		// Add the column headers to the CSV string
		csv +=
			columns
				.map(function (column) {
					return column.heading;
				})
				.join(',') + '\n';

		const formattedRows = replaceCommasWithSpaces(rows);

		// Find the maximum length of any value in each column
		var columnWidths = {};
		columns.forEach(function (column) {
			var max = column.heading.length;
			rows.forEach(function (row) {
				var value = String(row[column.value]);
				if (value.length > max) {
					max = value.length;
				}
			});
			columnWidths[column.value] = max + 2; // Add some padding for the column heading
		});

		// Loop through each row and add it to the CSV string
		formattedRows.forEach(function (row) {
			csv +=
				columns
					.map(function (column) {
						const isRadio =
							row[column.value]?.props?.hasOwnProperty('isChecked') ||
							typeof row[column.value] === 'boolean';
						if (printOptions.tableId === 'case_search' && column.value === 'warrant') {
							return row[column.value] ? 'Yes' : 'No';
						} else if (
							printOptions.tableId === TABLE_KEY_EVENT_VIEW_ALL &&
							column.value === 'documentIcon'
						) {
							return row.totalAddedDocument;
						} else if (isRadio) {
							const isChecked = row[column.value]?.props?.isChecked || row[column.value];
							if (isChecked === true) {
								return 'selected';
							} else {
								return '-';
							}
						} else if (column.value === 'representedBy') {
							let newString = '';
							row.representedByList.forEach((representing) => {
								newString += `${representing.fullName}${representing.isLead ? ' * ' : ' '}`;
							});
							return newString;
						} else if (printOptions.tableId === 'case_search' && column.value === 'parties') {
							return row.partiesString;
						} else if (
							row[column.value] &&
							isValid(new Date(row[column.value])) &&
							column.type === 'date'
						) {
							return dateToUSDate(row[column.value]);
						} else {
							const cellValue = String(row[column.value]);
							csv += cellValue.padEnd(columnWidths[column.value], ' ') + ',';
							return row[column.value];
						}
					})
					.join(',') + '\n';
		});

		downloadCSV(csv, 'filename.csv');
	} catch (error) {
		console.log(error);
	}
}

// DOWNLOAD CREATED CSV
export function downloadCSV(csv, filename) {
	try {
		let csvFile;
		let downloadLink;

		// csv file
		csvFile = new Blob([csv], { type: 'text/csv' });

		// Download link
		downloadLink = document.createElement('a');

		// File name
		downloadLink.download = filename;

		// Create a link to the file
		downloadLink.href = URL.createObjectURL(csvFile);

		// Hide download link
		downloadLink.style.display = 'none';

		// Add the link to DOM
		document.body.appendChild(downloadLink);

		// Click download link
		downloadLink.click();
	} catch {
		console.error('downloadCSV is not working properly');
	}
}

// EXPORT ALL DATA TO PDF
export function allDataToPDF(columns, rows, printOptions, headerProps, addHeader = false) {
	if (printOptions?.enableSelection || printOptions?.enableRadio) {
		const { newColumns, newRows } = enableRadioCheckboxes(columns, rows, printOptions);
		columns = newColumns;
		rows = newRows;
	}

	if (SKIP_SELECT_CHECKBOX_CSV_PDF.includes(printOptions.tableId)) {
		columns = columns.filter((col) => col.heading !== 'Selected');
	}

	// Create a new HTML table element
	const table = document.createElement('table');

	// Add a class to the table
	const tableClass = 'printableTable';
	table.setAttribute('class', tableClass);

	// Create a table header row
	const headerRow = table.insertRow();
	columns.forEach((col) => {
		const headerCell = document.createElement('th');
		headerCell.textContent = col.heading;
		headerRow.appendChild(headerCell);
	});

	// Add TotalRow
	const totalRow = document.getElementById(`totalRow-${printOptions.tableId}`);
	if (totalRow) {
		const cells = totalRow.querySelectorAll('td');
		rows.push({
			fees: cells[0].textContent.trim(),
			amount: cells[1].textContent.trim(),
			paymentAmount: cells[2].textContent.trim(),
			creditAmount: cells[3].textContent.trim(),
			currentBalance: cells[4].textContent.trim()
		});
	}
	if (addHeader) {
		const tableRow = table.insertRow();
		tableRow.appendChild(PdfReportHeader(headerProps));
	}

	// Add the rows to the table
	rows.forEach((row) => {
		const tableRow = table.insertRow();

		columns.forEach((col) => {
			const tableCell = tableRow.insertCell();
			try {
				const isRadio =
					row[col.value]?.props?.hasOwnProperty('isChecked') || typeof row[col.value] === 'boolean';

				if (printOptions.tableId === 'case_search' && col.value === 'warrant') {
					tableCell.textContent = row[col.value] ? 'Yes' : 'No';
				} else if (printOptions.tableId === 'case_search' && col.value === 'parties') {
					tableCell.innerHTML = ReactDOMServer.renderToString(row[col.value]);
				} else if (
					printOptions.tableId === TABLE_KEY_EVENT_VIEW_ALL &&
					col.value === 'documentIcon'
				) {
					tableCell.innerHTML = row.totalAddedDocument;
				} else if (isRadio) {
					const isChecked = row[col.value]?.props?.isChecked || row[col.value];
					const radio = document.createElement('input');
					radio.type = 'radio';
					radio.disabled = true;

					if (isChecked === true) {
						radio.setAttribute('class', 'selectedRadio');
						radio.checked = true;
					}
					tableCell.appendChild(radio);
				} else if (col.value === 'representedBy') {
					row.representedByList.forEach((representing) => {
						const record = document.createElement('p');
						record.innerHTML = `${representing.fullName}${representing.isLead ? ' *' : ''}`;
						tableCell.appendChild(record);
					});
				} else if (isValid(new Date(row[col.value])) && col.type === 'date') {
					tableCell.textContent = dateToUSDate(row[col.value]);
				} else {
					tableCell.textContent = row[col.value];
				}
			} catch (error) {
				tableCell.textContent = row[col.value];
			}
		});
	});

	// Open a new window and add the table to it
	const printWindow = window.open('', '', 'height=600,width=800');
	const htmlContent = `
	<html>
	  <head>
		<title>Table PDF</title>
		<style>
		  .${tableClass} {
			border-collapse: collapse;
			font-family: Arial, sans-serif;
			font-size: 12px;
			width: 100%;
		  }
		  .${tableClass} th, .${tableClass} td {
			border: 1px solid #000;
			padding: 8px;
			text-align: left;
		  }
		  .${tableClass} th {
			background-color: #ccc;
			font-weight: bold;
			text-transform: uppercase;
		  }

		  .${tableClass} .selectedRadio::after {
			  content: " "; 
			  display: flex; 
			  border-radius: 360px; 
			  justify-content: center; 
			  align-items: center; 
			  width: 13px; 
			  height: 13px; 
			  background-color: blue;}
		</style>
	  </head>
	  <body>
		${table.outerHTML}
	  </body>
	</html>
  `;

	if (isMobile) {
		printWindow.document.open();
		printWindow.document.write(htmlContent);
		printWindow.document.close();
		printWindow.print();
	} else {
		printWindow.document.write(htmlContent);
		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
	}
}

// EXPORT TO PDF with Vanilla JS
export function createPDF(outerTable, windowSize) {
	try {
		// Clone Table
		let sTable = outerTable.current.cloneNode(true);

		let filtersRow = sTable.getElementsByTagName('tr')[1];
		let rows = sTable.getElementsByTagName('tr');
		let cells = sTable.getElementsByTagName('td');

		let isSearchRow = filtersRow?.classList?.contains('search-row');

		// Remove Filters Row
		if (isSearchRow) {
			filtersRow.style.display = 'none';
		}

		// Remove Actions Column
		for (let i = 0; i < cells.length; i++) {
			if (cells[i]?.classList?.contains('actions-cell')) {
				for (let j = 0; j < rows.length; j++) {
					rows[j].deleteCell(-1);
				}
			}
		}

		let NoDataElement = document.getElementById('TableEmptyMessage');
		NoDataElement = NoDataElement?.innerHTML || '';
		// Updated Table
		let pdfTable = sTable.innerHTML;

		let style = '<style>';

		style = style + 'table {width: 100%;font: 17px Calibri;}';
		style = style + 'table, th, td {border: solid 1px #DDD; border-collapse: collapse;';
		style = style + 'padding: 2px 3px;text-align: center;}';
		style =
			style +
			'.radioSelected::after {content: " "; display: flex; border-radius: 360px; justify-content: center; align-items: center; width: 13px; height: 13px; background-color: blue;}';
		style = style + '#TableEmptyMessage {color: red!important;}';
		style = style + '</style>';

		if (document.getElementsByName('frame1')[0]) {
			document.getElementsByName('frame1')[0].remove();
		}

		let frame1 = document.createElement('iframe');
		frame1.name = 'frame1';
		frame1.style.position = 'absolute';
		frame1.style.top = '-1000000px';
		document.body.appendChild(frame1);
		let frameDoc = frame1.contentWindow
			? frame1.contentWindow
			: frame1.contentDocument.document
			? frame1.contentDocument.document
			: frame1.contentDocument;
		frameDoc.document.open();
		frameDoc.document.write('<html><head><title>Data</title>');
		frameDoc.document.write(
			"<link href='https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css' rel='stylesheet' integrity='sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC' crossorigin='anonymous'>"
		);
		frameDoc.document.write(style);
		frameDoc.document.write(
			'</head><body>' +
				pdfTable +
				'<div style="display: flex; justify-content: center;">' +
				NoDataElement +
				'</div>' +
				'</body></html>'
		);
		frameDoc.document.close();

		window.frames['frame1'].focus();
		if (windowSize > 780) {
			window.frames['frame1'].print();
			const pdfButton = document.getElementsByClassName('pdf-table-button')?.[0];
			pdfButton && pdfButton.focus();
		} else {
			frameDoc.print();
		}

		return false;
	} catch (error) {
		console.error('CreatePDF func is not working properly');
	}
}

// Helper Functions

export const sortData = (colDirection, setColDirection, dataCollection, item) => {
	try {
		const direction = colDirection === 'desc' ? 'asc' : 'desc';

		setColDirection(direction);

		const key = item?.value;

		const isAmount = (data) =>
			String(data?.[key]).includes('$') && !isNaN(String(data?.[key])?.replaceAll('$', ''));

		const isDate = (data) => isNaN(Number(data?.[key])) && isValid(new Date(data?.[key]));

		const isNumericValue = (data) => !isNaN(Number(data?.[key]));

		if (key?.toLowerCase().includes('date') && dataCollection?.some(isDate)) {
			return dataCollection.sort(sortDates(key, direction));
		}
		if (dataCollection.some(isAmount)) {
			return dataCollection.sort(sortAmounts(key, direction));
		}
		if (dataCollection.some(isNumericValue)) {
			return dataCollection.sort(sortAmounts(key, direction));
		}

		return dataCollection.sort(sortStrings(key, direction));
	} catch (error) {
		console.error('sortData func is not working properly');
	}
};

export function sortStrings(property, order) {
	const sort_order = order === 'desc' ? -1 : 1;
	return function (a, b) {
		const aValue = toLower(a[property]);
		const bValue = toLower(b[property]);
		if (aValue < bValue) {
			return -1 * sort_order;
		} else if (aValue > bValue) {
			return 1 * sort_order;
		} else {
			return 0 * sort_order;
		}
	};
}

export function sortAmounts(property, order) {
	const sort_order = order === 'desc' ? -1 : 1;
	return function (a, b) {
		const cleanedA = handleCleanAmount(a[property]);
		const cleanedB = handleCleanAmount(b[property]);
		if (cleanedA < cleanedB) {
			return -1 * sort_order;
		} else if (cleanedA > cleanedB) {
			return 1 * sort_order;
		} else {
			return 0 * sort_order;
		}
	};
}

export function sortDates(property, order) {
	const sort_order = order === 'desc' ? -1 : 1;
	return function (a, b) {
		if (a[property] === '-') return -1 * sort_order;
		if (b[property] === '-') return 1 * sort_order;
		const dateA = new Date(a[property])?.getTime();
		const dateB = new Date(b[property])?.getTime();
		if (dateA < dateB) {
			return -1 * sort_order;
		} else if (dateA > dateB) {
			return 1 * sort_order;
		} else {
			return 0 * sort_order;
		}
	};
}

//Paginate Table Data
export function paginateTable(data, n) {
	try {
		let paginatedData = [];
		let tempArr = [];
		const dataLength = data && data.length ? data.length : 0;

		for (let i = 0; i < dataLength; i++) {
			tempArr.push(data[i]);

			if ((i + 1) % n === 0 || i + 1 === data.length) {
				paginatedData.push(tempArr);
				tempArr = [];
			}
		}

		return paginatedData;
	} catch {
		console.error('paginateTable func is not working properly');
	}
}

export const search = (table, input, inputReference) => {
	try {
		// Declare variables
		let filter, tr, td, i, txtValue;
		input = document.getElementById(inputReference);
		filter = input.value.toUpperCase();

		tr = table.current.children[1].children;

		for (i = 0; i < tr.length; i++) {
			let rowData = tr[i].children;

			let includesInput = false;
			for (let j = 0; j < rowData.length; j++) {
				td = rowData[j];
				if (td) {
					txtValue = td.textContent || td.innerText;
					if (txtValue.toUpperCase().indexOf(filter) > -1) {
						includesInput = true;
						break;
					}
				}
			}

			if (includesInput === true) {
				tr[i].style.display = '';
			} else {
				tr[i].style.display = 'none';
			}
		}
	} catch {
		console.error('Search func is not working properly');
	}
};

export const searchSpecificColumn = (
	inputReference,
	allData,
	objValue,
	searchInputValues,
	setSearchInputValues
) => {
	try {
		const input = document.getElementById(inputReference);
		const filter = String(input?.value).toUpperCase() || '';

		const newFilterData =
			filter === ''
				? allData
				: allData.filter((data) => {
						const val = data[objValue];
						if (val === null || val === undefined) {
							return false;
						}

						if (val?.[0] === '$' && String(handleCleanAmount(val)).indexOf(filter) > -1) {
							return true;
						} else if (String(val).toUpperCase().indexOf(filter) > -1) {
							return true;
						} else if (checkIfDate(val)) {
							return compareDates(filter, val);
						}
						return false;
				  });
		if (Object.keys(searchInputValues).length > 1) {
			eraseSearchFields(inputReference, searchInputValues, setSearchInputValues);
		}
		return newFilterData;
	} catch {
		console.error('searchSpecificColumn func is not working properly');
	}
};

const checkIfDate = (value) => {
	const parsed = Date.parse(value);
	if (isNaN(parsed)) return false;
	else if (parsed < 0) return false;
	else return true;
};

const compareDates = (filter, value) => {
	const parsedFilter = Date.parse(filter.toString());
	const parsedValue = Date.parse(dashes2slashes(value).toString());

	if (!isNaN(parsedFilter) && !isNaN(parsedValue)) return parsedValue === parsedFilter;
	else return false;
};

export const eraseSearchFields = (inputReference, searchInputValues, setSearchInputValues) => {
	try {
		if (inputReference && typeof setSearchInputValues === 'function') {
			setSearchInputValues({
				[inputReference]: searchInputValues?.[inputReference]
			});
		}
	} catch (e) {
		console.error(e);
		console.error('eraseSearchFields func is not working properly');
	}
};

// Delete Specific Row
export function deleteRow(data, rowID, callback, toastDelete) {
	const name = data?.filter((item) => item.id === rowID);
	let answer = window.confirm('Are you sure you want to delete : ' + name[0].id);
	if (answer === true) {
		data = data.filter((item) => item.id !== rowID);

		if (callback) {
			callback(rowID);
			if (toastDelete) toastDelete();
		}
	}
	return data;
}

export function deleteRowBE(rowID, callback) {
	let answer = window.confirm('Are you sure you want to delete this row');
	if (answer === true && callback) {
		setTimeout(() => callback(rowID), 100);
	}
}

export const onRowSelected = (e, item, selected, setSelected) => {
	if (!e.target.checked) {
		const filteredIds = selected.filter((x) => x !== item.id);
		setSelected(filteredIds);
	} else {
		setSelected([...selected, item.id]);
	}
};

export const onSelectAll = (e, data, setSelected) => {
	if (!e.target.checked) {
		setSelected([]);
	} else {
		const allIds = data.map((x) => x.id);
		setSelected([...allIds]);
	}
};

export const onRowSelectedLodash = (
	e,
	item,
	selected,
	setSelected,
	setNewSelected,
	setUnSelected
) => {
	if (!e.target.checked) {
		const filteredIds = selected.filter((x) => x.id !== item.id);
		setUnSelected(item);
		setSelected(filteredIds);
	} else {
		setSelected([...selected, item]);
		setNewSelected(item);
	}
};

export const onSelectAllLodash = (
	e,
	data,
	selectedRows,
	setSelected,
	setNewSelected,
	setUnSelected,
	disabledRows
) => {
	if (!e.target.checked) {
		setSelected(
			selectedRows
				.filter((selectedRows) => !data.some((data) => selectedRows.id === data.id))
				.concat(disabledRows)
		);

		setUnSelected(data);
	} else {
		setSelected(
			_.unionWith(
				data.filter((item) => !item?.selected?.isDisabled),
				selectedRows,
				_.isEqual
			)
		);
		setNewSelected(data);
	}
};

export function seeDocument(file) {
	file ? window.open(file) : alert("File doesn't exist ");
}

export function saveData(dataCollection) {}

export function goToLink(location, hiddenLink) {
	hiddenLink.current.click();
}

export const onRadioSelectedLodash = (e, item, setRow) => {
	if (e.target.checked) {
		setRow(item);
	}
};

export const generateItemsSplitInPages = (data = [], size) => {
	try {
		const itemsInPages = [];

		for (let i = 0; i < data.length; i += size) {
			itemsInPages.push(data.slice(i, i + size));
		}

		return itemsInPages;
	} catch {
		console.error('generateItemsSplitInPages func is not working properly');
	}
};
