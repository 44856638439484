import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { PLACEHOLDER_TEXT } from '../../../constants/placeholders';
import { InputFormCustom } from '../../../components/InputForm';

const SchemaValidation = Yup.object({
	description: Yup.string().max(255, '')
});

const ModalDocumentDescription = ({ setDocumentDescription, hidden }) => {
	const [show, setShow] = useState(false);

	const hideModal = () => {
		setShow(false);
	};

	const onSubmit = (values) => {
		setDocumentDescription(values.description);
		hideModal();
	};

	const ModalComponent = () => {
		return (
			<Modal
				onHide={hideModal}
				show={show}
				backdrop="static"
				size="lg"
				centered
				aria-labelledby="document-description-modal-title"
			>
				<Formik
					enableReinitialize
					initialValues={{
						description: ''
					}}
					validationSchema={SchemaValidation}
					onSubmit={onSubmit}
				>
					{(props) => (
						<>
							<Modal.Header closeLabel={`Change Document Description Close Dialog`} closeButton>
								<Modal.Title id="document-description-modal-title">
									Change Document Description
								</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<Row>
									<Col md={12} sm={12} xs={12}>
										<InputFormCustom
											label="Document Description"
											id="description"
											name="description"
											type="text"
											limitCharacters={255}
											placeholder={`${PLACEHOLDER_TEXT} description`}
										/>
									</Col>
								</Row>
							</Modal.Body>

							<Modal.Footer>
								<Button onClick={props.handleSubmit}>Submit</Button>
								<Button type="button" variant="secondary" onClick={hideModal}>
									Cancel
								</Button>
							</Modal.Footer>
						</>
					)}
				</Formik>
			</Modal>
		);
	};

	return (
		<>
			<Button onClick={() => setShow(true)} hidden={hidden}>
				Change Document Description
			</Button>
			{ModalComponent()}
		</>
	);
};

export default ModalDocumentDescription;
