import { useEffect, useState } from 'react';

import { HttpService } from '../services/HttpService';
import { BASE_URL } from '../utils';

export const useGetCodeTablesByCategories = (...categories) => {
	const { get } = HttpService();
	const [results, setResults] = useState([]);
	useEffect(() => {
		const getCategories = async () => {
			const results = [];
			for (const category of categories) {
				try {
					const result = await get(
						`${BASE_URL}/api/code-table-management/code-tables/categories/${category}`
					);
					results.push(...(result?.data || []));
				} catch (error) {
					console.warn(error);
				}
			}
			setResults(results);
		};
		getCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); /* categories, get */

	return [results];
};
