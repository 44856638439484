import { createSlice } from '@reduxjs/toolkit';

const EMTPY_EVENT = {
	values: {
		caseNumber: '',
		defendant: '',
		documentDescription: '',
		effectiveDate: '',
		enteredDate: '',
		eventType: '',
		file: null,
		nonPublic: '',
		path: '',
		sealed: '',
		totalPages: '',
		volumePage: '',
		submitted: false,
		errorOnSubmit: false
	},
	dirty: false,
	repeatedCaseNumber: false
};

const initialState = {
	batch: [{ id: Math.floor(Math.random() * 100000), ...EMTPY_EVENT }],
	invalidEventBatch: true
};

export const caseBatchUpdateSlice = createSlice({
	name: 'caseBatchUpdate',
	initialState: initialState,
	reducers: {
		addCard: (state) => {
			let currentBatch = state.batch;
			currentBatch.push({ id: Math.floor(Math.random() * 100000), ...EMTPY_EVENT });
			state.batch = currentBatch;
			state.invalidEventBatch = true;
		},
		clearBatch: (state) => {
			state.batch = initialState.batch;
			state.invalidEventBatch = true;
		},
		removeEventCard: (state, action) => {
			let currentBatch = state.batch.filter((item) => item.id !== action.payload);
			state.batch = currentBatch;
			state.invalidEventBatch = false;
		},
		updateCard: (state, action) => {
			let currentBatch = state.batch.map((item) => {
				if (item.id === action.payload.id) {
					return {
						...item,
						dirty: action.payload.dirty,
						repeatedCaseNumber: action.payload.repeatedCaseNumber,
						values: {
							...item.values,
							[`${action.payload.field}`]: action.payload.value
						}
					};
				} else {
					return item;
				}
			});
			state.batch = currentBatch;
			state.invalidEventBatch =
				action.payload.invalidEventBatch || action.payload.repeatedCaseNumber;
		},
		addFile: (state, action) => {
			let currentBatch = state.batch.map((item) => {
				if (item.id === action.payload.id) {
					const { dirty, documentDescription, file, name, totalPages } = action.payload;
					return {
						...item,
						dirty,
						values: {
							...item.values,
							documentDescription,
							file,
							name,
							totalPages
						}
					};
				} else {
					return item;
				}
			});
			state.batch = currentBatch;
		},
		updateSubmitStatus: (state, action) => {
			let currentBatch = state.batch
				.map((item) => ({
					...item,
					submitted: true,
					errorOnSubmit: action.payload?.includes(item.values.caseNumber?.value)
				}))
				.filter((item) => item.submitted && item.errorOnSubmit);
			state.batch = currentBatch;
		}
	}
});

export const { addCard, addFile, clearBatch, updateCard, removeEventCard, updateSubmitStatus } =
	caseBatchUpdateSlice.actions;

export default caseBatchUpdateSlice;
