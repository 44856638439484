import { isEmpty, startCase } from 'lodash';

export const formatTitle = (title) => {
	return `UCMS | ${startCase(title.replace(/ucms/gi, ''))}`;
};

export const getTitle = () => {
	return document.title;
};

export const setTitleFromPathname = () => {
	const routePathname = window.location.pathname.split('/').filter((path) => !isEmpty(path));

	if (!isEmpty(routePathname)) {
		if (routePathname.length > 1) {
			setTitle(routePathname[1]);
		} else {
			setTitle(routePathname[0]);
		}
	}
};

export const setTitle = (title) => {
	if (!isEmpty(title)) {
		document.title = formatTitle(title);
	} else {
		document.title = 'UCMS';
	}
};
