import Form from 'react-bootstrap/Form';
import RequiredMark from '../RequiredMark';

const PhoneInput = ({
	id = 'phone-input',
	label = 'Phone Number',
	className = '',
	value = '',
	onChange = () => {},
	required = false
}) => {
	return (
		<Form.Group className={`phone-input-container mb-3 ${className}`}>
			<Form.Label htmlFor={id}>
				{label}
				{required && <RequiredMark />}
			</Form.Label>
			<Form.Control id={id} type="tel" value={value} onChange={onChange} />
		</Form.Group>
	);
};
export default PhoneInput;
