import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	requirements: [],
	newRequirements: [],
	deletedRequirements: []
};

const requirementsSlice = createSlice({
	initialState,
	name: 'requirementsSlice',
	reducers: {
		clearAll: (state) => {
			state.requirements = [];
			state.newRequirements = [];
			state.deletedRequirements = [];
		},
		clearNewRequirements: (state) => {
			state.newRequirements = [];
		},
		clearDeletedRequirements: (state) => {
			state.deletedRequirements = [];
		},
		fillRequirements: (state, action) => {
			state.requirements.push(...action.payload);
		},
		addRequirement: (state, action) => {
			state.newRequirements.push(action.payload);
		},
		removeRequirement: (state, action) => {
			const obj = action.payload;
			const { communityServiceRequirementCode } = obj;

			if (obj.status !== 'insert') {
				obj.status = 'delete';
				state.deletedRequirements.push(obj);
			} else {
				state.newRequirements = state.newRequirements.filter(
					(item) => item.communityServiceRequirementCode !== communityServiceRequirementCode
				);
			}
		}
	}
});

export const {
	fillRequirements,
	addRequirement,
	removeRequirement,
	clearAll,
	clearNewRequirements,
	clearDeletedRequirements
} = requirementsSlice.actions;

export default requirementsSlice.reducer;
