import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Card, OverlayTrigger, Tooltip, Collapse } from 'react-bootstrap';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { CaseService } from './../../services/CaseService';
import CaseSealedLabel from './CaseSealedLabel';
import { dateToUSDate } from '../../utils';

const flagList = [
	{ value: 'D', type: 'dangerous', label: 'Dangerous Party' },
	{ value: 'W', type: 'warrant', label: 'Warrant' },
	{ value: 'R', type: 'repeatOffender', label: 'Repeat Offender' },
	{ value: 'C', type: 'conflictOfInterest', label: 'Conflict of Interest' },
	{ value: 'I', type: 'interpreterNeeded', label: 'Interpreter Needed' },
	{ value: 'F', type: 'delinquentFees', label: 'Delinquent Fees' },
	{ value: 'O', type: 'overdueItems', label: 'Overdue Items' }
];

function CaseBlock({ caseId, reloadData, setReloadData }) {
	const [open, setIsOpen] = React.useState(false);
	const { getCaseCardData } = CaseService();
	const [collapse, setCollapse] = useState(true);

	const handleCaseInfo = async () => {
		try {
			const caseInfo = await getCaseCardData(caseId);
			return caseInfo.data;
		} catch (error) {
			console.error('Unable to get case block information error: ', error);
		}
	};

	const { data: caseCardData, refetch } = useQuery('caseBlockInfo', handleCaseInfo);

	useEffect(() => {
		if (reloadData && caseId) {
			refetch();
			if (setReloadData) {
				setReloadData(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadData, caseId]);

	return (
		<Card className="shadow-sm text-center position-relative">
			<Card.Body>
				<div className={'btn-collapse-wrapper'}>
					<button
						className={'btn-collapse'}
						onClick={() => {
							setIsOpen(!open);
							setCollapse(!collapse);
						}}
						type="button"
						title={collapse ? 'Collapse Case Information' : 'Expand Case Information'}
						aria-label={collapse ? 'Collapse Case Information' : 'Expand Case Information'}
					>
						{!open ? (
							<MdArrowDropUp size={20} role="img" aria-label="Collapse Case Information" />
						) : (
							<MdArrowDropDown size={20} role="img" aria-label="Expand Case Information" />
						)}
					</button>
				</div>
				<Card.Subtitle className="mb-2 text-muted">Case Number</Card.Subtitle>
				<Card.Title className="fw-bold">{caseCardData?.caseNo}</Card.Title>
				<CaseSealedLabel sealed={caseCardData?.sealed} />
				{collapse && (
					<>
						{caseCardData?.filedDate ? (
							<Card.Subtitle className="mt-2 mb-2 text-muted">
								Filed: {dateToUSDate(caseCardData?.filedDate.split('T')[0])}
							</Card.Subtitle>
						) : null}
						{caseCardData?.style ? <Card.Text>{caseCardData?.style}</Card.Text> : null}

						{caseCardData?.countyName && caseCardData?.courtName ? (
							<Card.Text>
								County:
								<br />
								{caseCardData?.countyName} {caseCardData?.courtName}
							</Card.Text>
						) : null}
						{caseCardData?.statusCase ? (
							<Card.Text className="text-primary text-decoration-underline">
								Status: {caseCardData?.statusCase}
							</Card.Text>
						) : null}
						{caseCardData?.comment ? (
							<Collapse in={open}>
								<Card.Text className="mt-2">{caseCardData?.comment}</Card.Text>
							</Collapse>
						) : null}
						<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
							{caseCardData?.flags?.map((flag, index) => (
								<div className="btn-group m-2" key={index}>
									<OverlayTrigger
										key="bottom"
										placement="bottom"
										overlay={<Tooltip>{flag?.description}</Tooltip>}
									>
										<div
											data-testid={`flag-${flag?.color}`}
											className={['btn-flag', `btn-flag-${flag?.color?.toLowerCase()}`].join(' ')}
										>
											{flag?.flagImgIcon}
										</div>
									</OverlayTrigger>
								</div>
							))}
						</div>
					</>
				)}
			</Card.Body>
		</Card>
	);
}

CaseBlock.flags = flagList;

export default React.memo(CaseBlock);
