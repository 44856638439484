import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const partyHTTP = () => {
	const { get, post, put, deleteFn } = HttpService();

	const getPartyViewInfo = (partyId) => {
		const response = get(`${BASE_URL}/api/party-management/party/${partyId}/party-view`);
		return response;
	};

	//ORDER MEDICAL EVALUATIONS
	const getTypeSelectOptions = async (setData) => {
		await get(
			`${BASE_URL}/api/code-table-management/code-tables/categories/party_medical_eval_type`
		).then(function (res) {
			setData(res.data.map((option) => ({ value: option.codeTableId, label: option.description })));
		});
	};
	const getResultOptions = async (setData) => {
		await get(
			`${BASE_URL}/api/code-table-management/code-tables/categories/party_medical_eval_results`
		).then(function (res) {
			setData(res.data);
		});
	};
	const getMedicalProfessionalsSelectOptions = async (setData) => {
		await get(
			`${BASE_URL}/api/professional-resource-management/professional-resource/categories/MED`
		).then(function (res) {
			setData(
				res.data.map((opt) => ({
					value: opt.prId,
					label: opt.fullName
				}))
			);
		});
	};

	const postOrderMedicalEval = (body) => {
		const url = `${BASE_URL}/api/party-management/party-medical-evaluation`;
		return post(url, body, 'New Order for medical evaluation', {}).then((res) => {
			return res.status;
		});
	};

	const putRemoveParty = async (partyId, body) => {
		const url = `${BASE_URL}/api/party-management/party/${partyId}/dismissal`;
		const res = await put(url, body, 'Dismiss Party from Case', {});
		return res.status;
	};

	const postNewAddress = async (partyId, body) => {
		const url = `${BASE_URL}/api/user/profile/address`;
		const res = await put(url, body, 'Create person address', {});
		return res.status;
	};
	const putOrderMedicalEval = (id, body) => {
		const url = `${BASE_URL}/api/party-management/party-medical-evaluation/${id}`;
		return put(url, body, 'Update party medical eval', {});
	};

	const deleteMedicalEval = (id) => {
		const url = `${BASE_URL}/api/party-management/party-medical-evaluation/${id}`;
		return deleteFn(url, 'deleteMedicalEvaluation', {});
	};

	const deleteParty = (partyId) => {
		const url = `${BASE_URL}/api/party-management/${partyId}`;
		return deleteFn(url, 'deleteParty', {});
	};
	const deleteProfessionalResource = (personId, proResourceId) => {
		const url = `${BASE_URL}/api/person/${personId}/personofficialid/${proResourceId}`;
		return deleteFn(url, 'deleteProfessionalResource', {});
	};

	const getPartiesByCaseId = async (caseId) => {
		const url = `${BASE_URL}/api/party-management/party/cases/${caseId}`;
		return get(url, 'Get Parties by Case Id', {});
	};

	const getPartiesOnlyActivesByCaseId = async (caseId) => {
		const url = `${BASE_URL}/api/party-management/party/cases/${caseId}?active=true`;
		return get(url, 'Get Parties by Case Id', {});
	};

	const updateParty = (partyId, body) => {
		const url = `${BASE_URL}/api/party-management/party/${partyId}`;
		return put(url, body, 'updateParty', {});
	};

	const getPartiesByCauseNumber = (caseNumber) => {
		const url = `${BASE_URL}/api/party-management/cause-number/option-case/${caseNumber}?page=0&size=100000`;
		return get(url, 'Get Parties by Cause Number', {});
	};

	const getPartiesByCode = (caseId, codes) => {
		const url = `${BASE_URL}/api/party-management/case/${caseId}/warrant-search?page=0&size=100000&party-codes=${codes}`;
		return get(url, 'Get Parties by Codes', {});
	};
	const getAttorneyTypes = () => {
		const url = `${BASE_URL}/api/party-code-management/party-code/party-group-code/ATT`;
		return get(url, getAttorneyTypes);
	};

	const getIsCOIExist = (caseId, personId) => {
		const url = `${BASE_URL}/api/attorney/checkConflictOfInterest/case/${caseId}/person/${personId}`;
		return get(url, getIsCOIExist);
	};

	const getPersonAddress = (personId) => {
		const url = `${BASE_URL}/api/person-management/${personId}/addresses?page=0&size=3`;
		return get(url, getPersonAddress);
	};
	const getPersonPhoneNumber = (personId) => {
		const url = `${BASE_URL}/api/person-management/${personId}/phone?page=0&size=3`;
		return get(url, getPersonPhoneNumber);
	};
	const getPersonIdentification = (personId) => {
		const url = `${BASE_URL}/api/person/${personId}/personofficialid?page=0&size=20`;
		return get(url, getPersonIdentification);
	};

	return {
		getPartyViewInfo,
		getTypeSelectOptions,
		getMedicalProfessionalsSelectOptions,
		postOrderMedicalEval,
		putRemoveParty,
		putOrderMedicalEval,
		getResultOptions,
		deleteMedicalEval,
		getPartiesByCaseId,
		deleteProfessionalResource,
		postNewAddress,
		updateParty,
		getPartiesOnlyActivesByCaseId,
		getPartiesByCauseNumber,
		getPartiesByCode,
		getAttorneyTypes,
		getIsCOIExist,
		getPersonAddress,
		deleteParty,
		getPersonPhoneNumber,
		getPersonIdentification
	};
};
