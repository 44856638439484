import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { RequiredMark } from '../common';

export const FormTextareaInput = ({
	id,
	label = '',
	value,
	onChange,
	required,
	errorMessage,
	placeholder,
	readOnly = false,
	disabled = false
}) => {
	return (
		<Form.Group controlId={id}>
			<Form.Label>
				{label}
				{required && <RequiredMark />}
			</Form.Label>
			<Form.Control
				type="text"
				required={required}
				as="textarea"
				value={value}
				onChange={({ target }) => onChange(target.value)}
				placeholder={placeholder}
				readOnly={readOnly}
				disabled={disabled}
			/>
			{errorMessage && <div className="small text-danger">{errorMessage}</div>}
		</Form.Group>
	);
};

FormTextareaInput.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	errorMessage: PropTypes.string
};
