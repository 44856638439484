import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';
import _ from 'lodash';

import styles from './styles.module.scss';

/**
 * TablePaginator.
 * @param {number} currentPage Current page index
 * @param {Object[]} data Array of data showed in the table
 * @param {number} rowsDisplayed Number of rows to display
 * @param {Function} setCurrentPage Handle current page change
 * @param {number} totalElements Total of item accesible from the database
 * @param {number} totalPages Total of pages used in pagination
 * @param {boolean} showTotalEntriesOnly Show only total number of entries and not the page numbers
 * @returns {ReactElement} returns JSX with the component TablePaginator.
 */

const TablePaginator = ({
	hidePagination,
	currentPage,
	data,
	rowsDisplayed,
	setCurrentPage,
	totalElements,
	totalPages,
	formTitle,
	showTotalEntriesOnly
}) => {
	const paginationClickHandler = (page) => setCurrentPage(page);

	const EntriesCounter = () => (
		<div
			data-testid={`PaginationEntries-Text`}
			className="d-flex flex-row justify-content-lg-start justify-content-center align-items-center"
		>
			{showTotalEntriesOnly ? (
				<span>
					{' '}
					Total {totalElements ? totalElements : 0} {totalElements === 1 ? 'entry' : 'entries'}{' '}
				</span>
			) : (
				<span>
					Showing {currentPage ? currentPage * rowsDisplayed + 1 : _.isEmpty(data) ? 0 : 1} to{' '}
					{data && !_.isNaN(currentPage) ? currentPage * rowsDisplayed + data.length : 0} of{' '}
					{totalElements ? totalElements : 0} {totalElements === 1 ? 'entry' : 'entries'}
				</span>
			)}
		</div>
	);

	const Previous = () => (
		<Pagination.Prev
			aria-label={`${formTitle}-Previous page`}
			data-testid={`Pagination-Prev`}
			disabled={currentPage > 0 ? false : true}
			onClick={() => {
				paginationClickHandler(currentPage - 1);
			}}
			onMouseDown={() => {
				paginationClickHandler(currentPage - 1);
			}}
		>
			Previous
		</Pagination.Prev>
	);

	const FirstPage = () => (
		<Pagination.Item
			aria-label={`${formTitle}-Page 1`}
			data-testid={`PaginationItem`}
			active={
				currentPage + 1 === 1 && (currentPage + 1 !== totalPages || totalPages === 1) ? true : false
			}
			onClick={() => {
				paginationClickHandler(0);
			}}
			onMouseDown={() => {
				paginationClickHandler(0);
			}}
			style={totalPages < 1 || !totalPages ? { display: 'none' } : { display: 'initial' }}
		>
			{1}
		</Pagination.Item>
	);

	const SecondPage = () => (
		<Pagination.Item
			aria-label={`${formTitle}-Page ${
				currentPage >= totalPages - 4 && totalPages >= 10
					? totalPages - 3
					: currentPage >= 5 && totalPages >= 6 && totalPages < 10
					? totalPages - 3
					: currentPage >= 5
					? currentPage
					: 2
			}`}
			data-testid={`PaginationItem`}
			active={
				(currentPage + 1 === 2 && (currentPage + 1 !== totalPages || totalPages === 2)) ||
				(currentPage === totalPages - 4 && totalPages > 8)
					? true
					: false
			}
			onClick={() => {
				let goTo =
					currentPage >= totalPages - 4 && totalPages >= 10
						? totalPages - 4
						: currentPage >= 5 && totalPages >= 6 && totalPages < 10
						? totalPages - 4
						: currentPage >= 5
						? currentPage - 1
						: 1;
				paginationClickHandler(goTo);
			}}
			onMouseDown={() => {
				let goTo =
					currentPage >= totalPages - 4 && totalPages >= 10
						? totalPages - 4
						: currentPage >= 5 && totalPages >= 6 && totalPages < 10
						? totalPages - 4
						: currentPage >= 5
						? currentPage - 1
						: 1;
				paginationClickHandler(goTo);
			}}
			style={totalPages < 2 || !totalPages ? { display: 'none' } : { display: 'initial' }}
		>
			{currentPage >= totalPages - 4 && totalPages >= 10
				? totalPages - 3
				: currentPage >= 5 && totalPages >= 6 && totalPages < 10
				? totalPages - 3
				: currentPage >= 5
				? currentPage
				: 2}
		</Pagination.Item>
	);

	const ThirdPage = () => (
		<Pagination.Item
			aria-label={`${formTitle}-Page ${
				currentPage >= totalPages - 4 && totalPages >= 10
					? totalPages - 2
					: currentPage >= 5 && totalPages >= 6 && totalPages < 10
					? totalPages - 2
					: currentPage >= 5
					? currentPage + 1
					: 3
			}`}
			data-testid={`PaginationItem`}
			active={
				(currentPage + 1 === 3 && (currentPage + 1 !== totalPages || totalPages === 3)) ||
				(currentPage >= 5 && currentPage <= totalPages - 2 && currentPage < totalPages - 4) ||
				(currentPage === totalPages - 3 && totalPages >= 8)
					? true
					: false
			}
			onClick={() => {
				let goTo =
					currentPage >= totalPages - 4 && totalPages >= 10
						? totalPages - 3
						: currentPage >= 5 && totalPages >= 6 && totalPages < 10
						? totalPages - 3
						: currentPage >= 5
						? currentPage + 1
						: 2;
				paginationClickHandler(goTo);
			}}
			onMouseDown={() => {
				let goTo =
					currentPage >= totalPages - 4 && totalPages >= 10
						? totalPages - 3
						: currentPage >= 5 && totalPages >= 6 && totalPages < 10
						? totalPages - 3
						: currentPage >= 5
						? currentPage + 1
						: 2;
				paginationClickHandler(goTo);
			}}
			style={totalPages <= 3 || !totalPages ? { display: 'none' } : { display: 'initial' }}
		>
			{currentPage >= totalPages - 4 && totalPages >= 10
				? totalPages - 2
				: currentPage >= 5 && totalPages >= 6 && totalPages < 10
				? totalPages - 2
				: currentPage >= 5
				? currentPage + 1
				: 3}
		</Pagination.Item>
	);

	const FourthPage = () => (
		<Pagination.Item
			aria-label={`${formTitle}-Page ${
				currentPage >= totalPages - 4 && totalPages >= 10
					? totalPages - 1
					: currentPage >= 5 && totalPages >= 6 && totalPages < 10
					? totalPages - 1
					: currentPage >= 5
					? currentPage + 2
					: 4
			}`}
			data-testid={`PaginationItem`}
			active={
				(currentPage + 1 === 4 && currentPage + 1 !== totalPages) ||
				(currentPage >= 5 && currentPage === totalPages - 2) ||
				(currentPage === totalPages - 2 && totalPages >= 10)
					? true
					: false
			}
			onClick={() => {
				let goTo =
					currentPage >= totalPages - 4 && totalPages >= 10
						? totalPages - 2
						: currentPage >= 5 && totalPages >= 6 && totalPages < 10
						? totalPages - 2
						: currentPage >= 5
						? currentPage + 1
						: 3;
				paginationClickHandler(goTo);
			}}
			onMouseDown={() => {
				let goTo =
					currentPage >= totalPages - 4 && totalPages >= 10
						? totalPages - 2
						: currentPage >= 5 && totalPages >= 6 && totalPages < 10
						? totalPages - 2
						: currentPage >= 5
						? currentPage + 1
						: 3;
				paginationClickHandler(goTo);
			}}
			style={totalPages <= 4 || !totalPages ? { display: 'none' } : { display: 'initial' }}
		>
			{currentPage >= totalPages - 4 && totalPages >= 10
				? totalPages - 1
				: currentPage >= 5 && totalPages >= 6 && totalPages < 10
				? totalPages - 1
				: currentPage >= 5
				? currentPage + 2
				: 4}
		</Pagination.Item>
	);

	const StaticPage = (pageNumber) => (
		<Pagination.Item
			aria-label={`${formTitle}-Page ${pageNumber}`}
			data-testid={`PaginationItem`}
			active={currentPage + 1 === pageNumber && currentPage + 1 !== totalPages ? true : false}
			onClick={() => {
				paginationClickHandler(pageNumber - 1);
			}}
			onMouseDown={() => {
				paginationClickHandler(pageNumber - 1);
			}}
			style={
				totalPages <= pageNumber || !totalPages || currentPage >= 5
					? { display: 'none' }
					: { display: 'initial' }
			}
		>
			{pageNumber}
		</Pagination.Item>
	);

	const LastPage = () => (
		<Pagination.Item
			aria-label={`${formTitle}-Page ${totalPages ?? 1}`}
			data-testid={`PaginationLastPage`}
			onClick={() => paginationClickHandler(totalPages - 1)}
			onMouseDown={() => paginationClickHandler(totalPages - 1)}
			active={currentPage + 1 === totalPages}
			style={totalPages && totalPages >= 3 ? { display: 'initial' } : { display: 'none' }}
		>
			{totalPages ?? 1}
		</Pagination.Item>
	);

	const Next = () => (
		<Pagination.Next
			aria-label={`${formTitle}-Next page`}
			data-testid={`Pagination-Next`}
			disabled={currentPage + 1 === totalPages || totalPages < 2 || !totalPages}
			onClick={() => paginationClickHandler(currentPage + 1)}
			onMouseDown={() => paginationClickHandler(currentPage + 1)}
		>
			Next
		</Pagination.Next>
	);

	return (
		<div
			data-testid="TablePaginator"
			className={`${
				hidePagination ? 'd-none' : 'd-flex'
			} flex-lg-row flex-column gap-2 justify-content-lg-between justify-content-center my-2`}
		>
			<div role="status" aria-live="polite">
				<EntriesCounter />
			</div>

			<div className="d-flex justify-content-lg-end justify-content-center align-items-center ">
				<div className={`d-flex flex-row align-items-center ${styles.paginationContainer}`}>
					<Pagination>
						<Previous />

						<FirstPage />
						<Pagination.Ellipsis
							disabled
							style={{
								display:
									(currentPage < 5 &&
										totalPages <= 6 &&
										(totalPages <= 4 || (currentPage < 5 && totalPages <= 6) || !totalPages)) ||
									currentPage < 5
										? 'none'
										: 'block'
							}}
						/>

						<SecondPage />
						<ThirdPage />
						<FourthPage />
						{StaticPage(5)}
						{StaticPage(6)}
						<Pagination.Ellipsis
							data-testid={`PaginationEllipsis`}
							disabled={true}
							style={{
								display:
									(currentPage < 7 &&
										totalPages <= 7 &&
										(totalPages <= 7 || !totalPages || currentPage >= totalPages - 4)) ||
									currentPage >= totalPages - 2
										? 'none'
										: 'block'
							}}
						/>

						<LastPage />

						<Next />
					</Pagination>
				</div>
			</div>
		</div>
	);
};

TablePaginator.propTypes = {
	currentPage: PropTypes.number.isRequired,
	data: PropTypes.arrayOf(PropTypes.object),
	rowsDisplayed: PropTypes.number.isRequired,
	setCurrentPage: PropTypes.func.isRequired,
	totalElements: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired
};

export default TablePaginator;
