import { createSelector } from '@reduxjs/toolkit';

const selectIsReportAffected = (state) => state.isReportAffected;

export const selectInputsArray = createSelector(
	selectIsReportAffected,
	(isReportAffected) => isReportAffected.inputFields
);

export const selectInputList = createSelector(selectInputsArray, (inputFields) => {
	return inputFields.reduce((acc, inputField) => {
		const { page, dbColumn } = inputField;
		if (!Array.isArray(acc[page])) acc[page] = [];
		acc[page].push(dbColumn);
		return acc;
	}, []);
});
