export const capitalizeEachWord = (string) => {
	let stringToPascal = string.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
		return g1.toUpperCase() + g2.toLowerCase();
	});

	if (stringToPascal.includes('-')) {
		return stringToPascal.split('-').join(' ');
	}

	return stringToPascal;
};

export const wordInString = (s, word) => new RegExp('\\b' + word + '\\b', 'i').test(s);

export const formatMiddleName = (middleName) => (middleName ? `${middleName.charAt(0)}.` : '');

export const formatLastFirstMiddleName = (lastName, firstName, middleName) => {
	let fullName = '';

	if (lastName && firstName) {
		fullName = `${lastName}, ${firstName}`;
	} else if (firstName) {
		fullName = `${firstName}`;
	}

	if (middleName) {
		fullName += ` ${middleName}`;
	}

	return fullName;
};

export const removeBrackets = (text) => text.replace(/\[|\]/g, '');

export const safelyParseJSON = (json) => {
	let parsed;
	try {
		parsed = JSON.parse(json);
	} catch (e) {
		console.error('error executing JSON.parse');
	}

	return parsed;
};
