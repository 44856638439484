import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export const useAuthContext = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('Auth Context not exists. Use hook for Auth provider childrens.');
	}
	return context;
};
