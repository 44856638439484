import { useCallback, useEffect, useState } from 'react';

import { HttpService } from '../services/HttpService';
import { BASE_URL, getAliasByType } from '../utils';

export const useGetAlias = ({ personId, codeCategoryId }, hadnleClickPrimary) => {
	const { get } = HttpService();
	const [aliasData, setAliasData] = useState([]);
	const [aliasTypeCodes, setAliasTypeCodes] = useState({});
	const [idTypeCodes, setIdTypeCodes] = useState({});
	const [alias, setAlias] = useState([]);
	const [stamp, setStamp] = useState(0);
	const reload = useCallback(() => setStamp(Date.now()), []);

	const getAliasTypeCodes = async () => {
		const result = await get(
			`${BASE_URL}/api/code-table-management/codes?codeCategoryId=${codeCategoryId}&codeTypeId=4`
		);
		const aliasTypeCodes = {};
		(result?.data?.fields || []).forEach(
			(option) => (aliasTypeCodes[option.code] = option.description)
		);
		setAliasTypeCodes(aliasTypeCodes);
	};
	const getIdTypeCodes = async () => {
		const result = await get(
			`${BASE_URL}/api/code-table-management/codes?codeCategoryId=${codeCategoryId}&codeTypeId=76`
		);
		const aliasTypeCodes = {};
		(result?.data?.fields || []).forEach(
			(option) => (aliasTypeCodes[option.code] = option.description)
		);
		setIdTypeCodes(aliasTypeCodes);
	};
	useEffect(() => {
		getAliasTypeCodes();
		getIdTypeCodes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAliasById = useCallback(
		async (aliasId) => {
			const result = await get(`${BASE_URL}/api/person-management/${personId}/alias/${aliasId}`);
			return result.data;
		},
		[personId, get]
	);

	const onClickSetPrimary = useCallback(
		async (e, aliasId) => {
			e.stopPropagation();
			const aliasInfo = await getAliasById(aliasId);
			hadnleClickPrimary({ ...aliasInfo, primaryAlias: 1 }, aliasId);
			reload();
		},
		[getAliasById, hadnleClickPrimary, reload]
	);

	useEffect(() => {
		const getAlias = async () => {
			let result = await get(`${BASE_URL}/api/person-management/${personId}/alias`);
			setAliasData(result.data || []);
		};
		getAlias();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stamp, personId]);

	useEffect(() => {
		setAlias(
			aliasData.map((alias) => {
				const aliasDescription = (getAliasByType[alias.aliasCode] || getAliasByType.A)(alias);
				return {
					...alias,
					aliasType: aliasTypeCodes[alias.aliasCode],
					idType: idTypeCodes[alias.idCode],
					aliasDescription,
					isPrimary: alias.primaryAlias,
					isPrimaryCheckbox: (
						<input
							readOnly
							type="radio"
							checked={alias.primaryAlias === 1}
							id={`alias-checkbox-${alias.aliasId}`}
							onClick={(e) => {
								onClickSetPrimary(e, alias.aliasId);
							}}
						/>
					),
					aliasEntity: aliasDescription || getAliasByType.A(alias)
				};
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aliasTypeCodes, aliasData, idTypeCodes]); /* onClickSetPrimary */

	return [alias, reload];
};
