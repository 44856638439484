import React from 'react';
import Router from './router';
import { Provider } from 'react-redux';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import './scss/main.scss';
import { AuthProvider } from './context/AuthContext';
import store from './redux/store';
import ConfigsContextProvider from './context/ConfigsContext';
import { CookiesProvider, useCookies } from 'react-cookie';
import './hooks/controls/useIsRequired';
import PageLoading from './components/PageLoading';

const App = () => {
	const [cookies] = useCookies(['session_token']);
	const token = cookies.session_token;

	return (
		<CookiesProvider>
			<Provider store={store}>
				<AuthProvider initialIdToken={token}>
					<ConfigsContextProvider>
						<React.Suspense fallback={<PageLoading />}>
							<Router />
						</React.Suspense>
					</ConfigsContextProvider>
				</AuthProvider>
			</Provider>
		</CookiesProvider>
	);
};

export default App;
