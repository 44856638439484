import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { maxDate, minDate } from '../../utils/casedateUtils';
import Select from 'react-select';
import DateInput from '../DateInput';
import { parse } from 'date-fns';
import AffectsReportAlertTooltip from '../AffectsReportAlertTooltip/AffectsReportAlertTooltip';
import { RequiredMark } from '../common';
import { fixFilterOptions } from '../FormikFactory/utils/Select';
import ErrorMessage from '../../components/FormikFactory/utils/ErrorMessage';

/**
 * FormInput.
 * @param {String} id Id use in htmlFor property for label and id in input.
 * @param {String} value Value for input field.
 * @param {String} name Name for input field.
 * @param {Function} onChange Function to handle onChange.
 * @param {String} pattern Pattern for input field.
 * @param {String} type Input type text | select | date.
 * @param {String} label Text will be shown as label.
 * @param {Object[]} selectOptions Array of data showed in the select options.
 * @param {Boolean} required Mark to identify that field is required.
 * @param {String} keyOptionName Name will be set as "key" in select options.
 * @param {String} valueOptionName Name will be set as "Value"  in select options.
 * @param {Boolean} disabled Value to enable or disable field.
 * @returns {ReactElement} returns JSX with the component FormInput.
 */

const FormInput = (props) => {
	const {
		checked,
		className = {},
		clearInput,
		dateFormat,
		disabled = false,
		id,
		incomingFormat,
		isBlockInvalidChar = false,
		isCheckboxOnlyRow,
		isInvalid,
		isValid,
		keyOptionName = 'id',
		label = '',
		margin,
		min,
		name,
		onBlur,
		onChange,
		onKeyPress,
		onKeyDown = () => {},
		onKeyUp = () => {},
		pattern,
		placeholder,
		required = false,
		returnFormat,
		selectOptions,
		setClearInput,
		testId,
		type = 'text',
		value = '',
		valueOptionName = 'value',
		onPaste,
		isReportAffected,
		inputRef,
		showErrorMessage = true,
		isAutoDate,
		maxFormDate,
		minFormDate,
		maxLength,
		style,
		...rest
	} = props;

	const [selectedValue, setSelectedValue] = useState(value);

	useEffect(() => {
		if (type.toLowerCase() === 'select' || type === 'reactSelect') {
			setSelectedValue(value);
		}
		// eslint-disable-next-line
	}, [value]);

	// eslint-disable-next-line
	useEffect(() => {
		if (clearInput) {
			setClearInput(false);
			clearSelectedInput();
		}
		// eslint-disable-next-line
	}, [clearInput]);

	const onSelectValue = (selectValue) => {
		onChange(selectValue);
		setSelectedValue(selectValue);
	};

	const onChangeValue = (e) => {
		onChange(e);
	};

	const clearSelectedInput = () => {
		onSelectValue('');
	};

	const blockInvalidChar = (e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
	const inputType = (type) => {
		switch (type.toLowerCase()) {
			/* Deprecated - use reactSelect instead */
			case 'select':
				return (
					<Form.Select
						id={id}
						required={required}
						disabled={disabled}
						onChange={(e) => {
							onSelectValue(e.target.value);
						}}
						value={selectedValue}
						isValid={isValid}
						isInvalid={isInvalid}
						data-testid={testId}
						onBlur={onBlur}
						name={name}
						className={disabled ? 'notAllowed' : null}
						ref={inputRef}
					>
						<option value="">- Select -</option>
						{selectOptions &&
							selectOptions?.map((item, i) => (
								<option key={`${item[keyOptionName]}-${i}`} value={item[keyOptionName]}>
									{item[valueOptionName]}
								</option>
							))}
					</Form.Select>
				);
			case 'react-select':
				return (
					<Select
						isDisabled={disabled}
						id={id}
						name={name}
						placeHolderText="- Select -"
						onChange={(value) => onSelectValue(value)}
						onBlur={
							onBlur &&
							function () {
								setTimeout(() => {
									onBlur();
								}, 50);
							}
						}
						options={selectOptions}
						testId={testId}
						value={value}
						className={disabled ? 'notAllowed' : null}
						ref={inputRef}
						filterOption={fixFilterOptions()}
						aria-label={isReportAffected ? `${label}, ${isReportAffected?.tooltip}.` : ''}
					/>
				);
			case 'date':
				// DEPRECATED - please use datepicker instead
				return (
					<Form.Control
						id={id}
						value={value || ''}
						checked={checked}
						name={name}
						pattern={pattern}
						onChange={onChange}
						type={type}
						disabled={disabled}
						className={`${className} ${disabled ? 'notAllowed' : null}`}
						required={required}
						max={maxFormDate ?? new Date(maxDate)}
						min={minFormDate ?? new Date(minDate)}
						isValid={isValid}
						isInvalid={isInvalid}
						onBlur={onBlur}
						data-testid={testId}
					/>
				);
			case 'datepicker':
				return (
					<DateInput
						dateFormat={dateFormat}
						disabled={disabled}
						id={id}
						incomingFormat={incomingFormat}
						isInvalid={isInvalid}
						maxDate={maxFormDate ?? parse(maxDate, 'yyyy-MM-dd', new Date())}
						minDate={minFormDate ?? parse(minDate, 'yyyy-MM-dd', new Date())}
						name={name}
						placeholder={placeholder}
						onChange={onChange}
						returnFormat={returnFormat}
						value={value}
						required={required}
						className={disabled ? 'notAllowed' : null}
						ref={inputRef}
						aria-label={isReportAffected ? `${label}, ${isReportAffected?.tooltip}.` : ''}
						isAutoDate={isAutoDate}
					/>
				);
			case 'checkbox':
				return (
					<Form.Check
						id={id}
						name={name}
						type={type}
						onChange={onChangeValue}
						disabled={disabled}
						className={`${className} ${disabled ? 'notAllowed' : null}`}
						required={required}
						isValid={isValid}
						isInvalid={isInvalid}
						checked={value || false}
						data-testid={testId}
						ref={inputRef}
						aria-label={isReportAffected ? `${label}, ${isReportAffected?.tooltip}.` : ''}
					/>
				);

			case 'textarea':
				return (
					<Form.Control
						id={id}
						value={value || ''}
						name={name}
						onChange={onChange}
						onBlur={onBlur}
						as="textarea"
						rows={2}
						disabled={disabled}
						className={`${className} ${disabled ? 'notAllowed' : null}`}
						isValid={isValid}
						isInvalid={isInvalid}
						style={{ resize: 'none' }}
						data-testid={testId}
						placeholder={placeholder}
						required={required}
						ref={inputRef}
						aria-label={isReportAffected ? `${label}, ${isReportAffected?.tooltip}.` : ''}
						maxLength={maxLength}
					/>
				);
			case 'percentage':
				return (
					<NumberFormat
						value={value}
						id={id}
						name={name}
						isNumericString
						className={'form-control'}
						suffix={' %'}
						ref={inputRef}
						aria-label={isReportAffected ? `${label}, ${isReportAffected?.tooltip}.` : ''}
					/>
				);

			case 'radio':
				return (
					<Form.Check
						id={id}
						name={name}
						type={type}
						onChange={onChange}
						disabled={disabled}
						className={`${className} ${disabled ? 'notAllowed' : null}`}
						required={required}
						isValid={isValid}
						isInvalid={isInvalid}
						checked={value || false}
						data-testid={testId}
						ref={inputRef}
						aria-label={isReportAffected ? `${label}, ${isReportAffected?.tooltip}.` : ''}
					/>
				);

			default:
				return (
					<>
						<Form.Control
							{...rest}
							id={id}
							value={value || ''}
							name={name}
							min={min}
							pattern={pattern}
							onChange={onChange}
							onPaste={onPaste}
							onKeyPress={onKeyPress}
							onBlur={onBlur}
							type={type}
							disabled={disabled}
							className={`${className} ${disabled ? 'notAllowed' : null}`}
							required={required}
							isValid={isValid}
							isInvalid={isInvalid}
							data-testid={testId}
							onKeyDown={isBlockInvalidChar ? blockInvalidChar : onKeyDown}
							onKeyUp={onKeyUp}
							ref={inputRef}
							placeholder={placeholder}
							aria-label={isReportAffected ? `${label}, ${isReportAffected?.tooltip}.` : ''}
							maxLength={maxLength}
							style={style}
						/>
						{showErrorMessage ? <ErrorMessage error={isInvalid} /> : <></>}
					</>
				);
		}
	};

	return (
		<>
			{/* TODO: controllable with props icons. */}
			{/* style={isCheckboxOnlyRow ? checkboxOnlyMargins : notCheckboxOnlyMargins} */}
			{type.toLowerCase() === 'checkbox' || type.toLowerCase() === 'radio' ? (
				<Form.Group
					className={`d-flex gap-3 align-items-center ${isCheckboxOnlyRow ? 'mt-2 mb-4' : 'mt-4'}`}
				>
					{inputType(type)}
					<AffectsReportAlertTooltip isReportAffected={isReportAffected}>
						<Form.Label htmlFor={id} onChange={onChange} className="m-0">
							{label} {required && <RequiredMark />}
						</Form.Label>
					</AffectsReportAlertTooltip>
				</Form.Group>
			) : (
				<Form.Group className={margin ? `mb-${margin}` : 'mb-3'}>
					{label ? (
						<AffectsReportAlertTooltip isReportAffected={isReportAffected}>
							<Form.Label htmlFor={id} onChange={onChange}>
								{label} {required && <RequiredMark />}
							</Form.Label>
						</AffectsReportAlertTooltip>
					) : null}
					{inputType(type)}
				</Form.Group>
			)}
		</>
	);
};
export default FormInput;
