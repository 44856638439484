// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_saveDocument__pkObF {\n  height: 100vh;\n  background-color: #f1f3f5;\n  border-radius: 10px;\n}\n\n.styles_hidden__0li33 {\n  display: none;\n}\n\n.styles_documentContainer__-2\\+Kf {\n  display: flex;\n  justify-content: center;\n}\n\n.styles_image__0QzSO {\n  width: 100%;\n  object-fit: contain;\n}\n\n@keyframes styles_loading-animation__RjPjQ {\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n.styles_loadingAnimation__Um23- {\n  animation: styles_loading-animation__RjPjQ 3s infinite;\n}", "",{"version":3,"sources":["webpack://./src/components/Loader/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,yBAAA;EACA,mBAAA;AACD;;AACA;EACC,aAAA;AAED;;AAAA;EACC,aAAA;EACA,uBAAA;AAGD;;AADA;EACC,WAAA;EACA,mBAAA;AAID;;AADA;EACC;IACC,UAAA;EAIA;EADD;IACC,UAAA;EAGA;EAAD;IACC,UAAA;EAEA;AACF;AA2CA;EAIC,sDAAA;AARD","sourcesContent":[".saveDocument {\n\theight: 100vh;\n\tbackground-color: #f1f3f5;\n\tborder-radius: 10px;\n}\n.hidden {\n\tdisplay: none;\n}\n.documentContainer {\n\tdisplay: flex;\n\tjustify-content: center;\n}\n.image {\n\twidth: 100%;\n\tobject-fit: contain;\n}\n\n@keyframes loading-animation {\n\t0% {\n\t\topacity: 1;\n\t}\n\n\t50% {\n\t\topacity: 0;\n\t}\n\n\t100% {\n\t\topacity: 1;\n\t}\n}\n\n@-o-keyframes loading-animation {\n\t0% {\n\t\topacity: 1;\n\t}\n\n\t50% {\n\t\topacity: 0;\n\t}\n\n\t100% {\n\t\topacity: 1;\n\t}\n}\n\n@-moz-keyframes loading-animation {\n\t0% {\n\t\topacity: 1;\n\t}\n\n\t50% {\n\t\topacity: 0;\n\t}\n\n\t100% {\n\t\topacity: 1;\n\t}\n}\n\n@-webkit-keyframes loading-animation {\n\t0% {\n\t\topacity: 1;\n\t}\n\n\t50% {\n\t\topacity: 0;\n\t}\n\n\t100% {\n\t\topacity: 1;\n\t}\n}\n\n.loadingAnimation {\n\t-webkit-animation: loading-animation 3s infinite;\n\t-moz-animation: loading-animation 3s infinite;\n\t-o-animation: loading-animation 3s infinite;\n\tanimation: loading-animation 3s infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveDocument": "styles_saveDocument__pkObF",
	"hidden": "styles_hidden__0li33",
	"documentContainer": "styles_documentContainer__-2+Kf",
	"image": "styles_image__0QzSO",
	"loadingAnimation": "styles_loadingAnimation__Um23-",
	"loading-animation": "styles_loading-animation__RjPjQ"
};
export default ___CSS_LOADER_EXPORT___;
