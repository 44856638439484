/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { HateCrimesServices } from '../../../../services/EventServices/HateCrimesService';
import { FormatDatePicker } from '../../EventDatesFormat';
import ResponseAlert, { TOAST_TYPES } from '../../../../components/ResponseAlert';
import { SelectFormSearchable } from '../../../../components/SelectFormSearchable';
import { isEmpty } from 'lodash';

export default function EditHateCrimeModal({ show, onHide, hateCrimeId, refreshTable }) {
	const { getHateCrimeById, putHateCrimeById, getCodeTable } = HateCrimesServices();
	const [hateCrimeData, setHateCrimeData] = useState();

	const [initialValues, setInitialValues] = useState({
		affirmDate: '',
		affirmResult: '',
		affirmInJud: '',
		judgmentDate: ''
	});

	const hateCrimeTemplate = useRef(null);
	const [codeTable, setCodeTable] = useState([
		{
			label: 'Waiting...',
			value: null
		}
	]);
	const findJudgement = [
		{ label: 'Yes', value: 1 },
		{ label: 'No', value: 0 }
	];
	const [toast, setToast] = useState({
		type: '',
		text: '',
		show: false
	});

	const fetchCodeTable = async () => {
		try {
			const response = await getCodeTable();
			if (response.status === 200) {
				const { data } = response;
				const result = data.map((element) => ({
					label: element.description,
					value: element.codeTableId
				}));

				setCodeTable(result);
			}
		} catch (error) {
			console.log(error);
			setCodeTable([]);
		}
	};

	const getHateCrime = async () => {
		try {
			const response = await getHateCrimeById(hateCrimeId);
			if (response.status === 200) {
				const { data } = response;
				setHateCrimeData({
					affirmDate: data.affirmDate,
					affirmResult: data.affirmResult,
					affirmInJud: data.affirmInJud,
					judgmentDate: data.judgmentDate
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (!show) return;
		getHateCrime();
		fetchCodeTable();
	}, []);

	useEffect(() => {
		if (!isEmpty(hateCrimeData) && codeTable[0].value) {
			const valueAffirmResult = codeTable.find((item) => item.label === hateCrimeData.affirmResult);

			setInitialValues((prev) => ({
				...prev,
				affirmDate: hateCrimeData.affirmDate,
				affirmResult: valueAffirmResult,
				affirmInJud: hateCrimeData.affirmInJud
					? findJudgement[0]
					: hateCrimeData.affirmInJud === false && findJudgement[1],
				judgmentDate: hateCrimeData.judgmentDate
			}));
		}
	}, [hateCrimeData, codeTable]);

	const handleClose = useCallback(() => {
		onHide();
	}, [onHide]);

	const onSubmit = async (values) => {
		let body = {
			hateCrimeId: hateCrimeId,
			affirmDate: values.affirmDate,
			affirmResult: values.affirmResult.label,
			affirmInJud: values.affirmInJud.value,
			judgmentDate: values.judgmentDate
		};
		try {
			const res = await putHateCrimeById(hateCrimeId, body);
			if (res.status === 201 || res.status === 200) {
				setToast({
					toast,
					type: TOAST_TYPES.SUCCESS,
					text: 'Hate Crime Detail has been updated.',
					show: true
				});
				setTimeout(() => {
					onHide();
				}, 1500);
				refreshTable();
			} else {
				setToast({
					toast,
					type: TOAST_TYPES.DANGER,
					text: 'Hate Crime Detail can not be updated.',
					show: true
				});
			}
		} catch (err) {
			setToast({
				toast,
				type: TOAST_TYPES.DANGER,
				text: 'Hate Crime Detail can not be updated.',
				show: true
			});
		}
	};

	return (
		<Modal
			size="xl"
			aria-labelledby="edit-hate-crime-title"
			centered
			onHide={handleClose}
			show={show}
			backdrop="static"
		>
			<Modal.Header closeLabel={`Edit Hate Crime Close Dialog`} closeButton>
				<Modal.Title id="edit-hate-crime-title">Edit Hate Crime</Modal.Title>
			</Modal.Header>
			<ResponseAlert
				show={toast.show}
				text={toast.text}
				type={toast.type}
				delay={3000}
				autoClose={true}
				close={() => {
					setToast({
						...toast,
						show: false
					});
				}}
			/>
			<>
				<Modal.Body>
					<Formik
						innerRef={hateCrimeTemplate}
						enableReinitialize
						initialValues={initialValues}
						onSubmit={onSubmit}
					>
						{(props) => {
							return (
								<Form onSubmit={props.handleSubmit}>
									<Row>
										<Col className="mb-4" xs={12} lg={5}>
											<FormatDatePicker
												label="Motion Affirmation Date"
												name="affirmDate"
												id="affirmDate"
												type="date"
												functionDate={(date) => props.setFieldValue('affirmDate', date)}
												formik={props}
												maxDate={new Date()}
											/>
										</Col>
										<Col xs={12} lg={5} className="mb-4">
											<SelectFormSearchable
												label="Motion on Affirmative Finding Result"
												name="affirmResult"
												id="affirmResult"
												options={codeTable}
												isSearchable={true}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mb-4" xs={12} lg={5}>
											<SelectFormSearchable
												label="Motion on Affirmative Finding Judgement"
												name="affirmInJud"
												id="affirmInJud"
												options={findJudgement}
												isSearchable={true}
											/>
										</Col>
										<Col xs={12} lg={5} className="mb-4">
											<FormatDatePicker
												label="Date of Judgment"
												name="judgmentDate"
												id="judgmentDate"
												type="date"
												functionDate={(date) => props.setFieldValue('judgmentDate', date)}
												formik={props}
											/>
										</Col>
									</Row>
								</Form>
							);
						}}
					</Formik>
				</Modal.Body>
				<Modal.Footer>
					<Button type="button" onClick={hateCrimeTemplate.current?.handleSubmit}>
						Submit
					</Button>
					<button onClick={handleClose} type="button" className="btn btn-secondary">
						Cancel
					</button>
				</Modal.Footer>
			</>
		</Modal>
	);
}
