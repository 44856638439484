// PLEASE note this is a mock for now, the url will change in the future

import axios from 'axios';
import useSWR from 'swr';
import { BASE_URL } from '../../utils';
import { cookieFinder } from '../../utils/cookieFinder';
import { mapToControls } from './utils';

const url = `${BASE_URL}/api/page-control-management`;

const fetcher = (url) => {
	const token = cookieFinder('session_token');

	return axios
		.get(url, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
		.then((res) => {
			const newData = mapToControls(
				res.data.pageControls.map((control) => ({
					...control,
					pageControlConfigurable: Boolean(Number(control.pageControlConfigurable)),
					pageControlRequired: Boolean(Number(control.pageControlRequired))
				}))
			);
			return newData;
		})
		.catch((err) => {
			return err.message;
		});
};

const useControls = (pageName) => {
	const { data: controls } = useSWR(`${url}/${pageName}`, fetcher);

	return { controls: !controls ? {} : controls };
};

export default useControls;
