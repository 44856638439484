import React from 'react';

import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import { FormTextareaInput } from './FormTextarea';

export const FieldFormTextareaInput = ({ id, label = '', required }) => (
	<Field name={id}>
		{({ field, form }) => (
			<>
				<FormTextareaInput
					id={field.name}
					label={label}
					value={field.value || ''}
					onChange={(value) => form.setFieldValue(field.name, value)}
					required={required}
				/>
				<ErrorMessage
					component="div"
					name={field.name}
					className="small text-danger"
					role="alert"
				/>
			</>
		)}
	</Field>
);

FieldFormTextareaInput.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	required: PropTypes.bool
};
