import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';

import {
	CenterPanel,
	LeftPanel,
	RightPanel,
	PageHeading,
	BreadcrumbNav
} from '../../components/layout';
import { useBackendPaginationFetcher } from '../../hooks/useBackendPaginationFetcher';
import { Table } from '../../components/layout/TableWithIntegration';
import { columns, mapper, statusOptions } from '../../constants/certifiedPayments';
import NewDatePicker from '../../components/NewDatePicker/index';
import { InputLabel } from '../../components/forms';
import SelectInput from '../../components/common/SelectInput';
import MaintenanceLeftSideNavigation from '../../components/Maintenance/MaintenanceLeftSideNavigation';
import CertifiedPaymentModal from './CertifiedPaymentModal';
import { PaymentService } from '../../services/PaymentService';
import { dateFormatYYMMDD } from '../../utils/dateUtils';

function CertifiedPayments() {
	const { requestCertifiedPayments } = PaymentService();
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [certifiedPaymentsUrl, setCertifiedPaymentsUrl] = useState(false);
	const [selectedRowId, setSelectedRowId] = useState('');
	const [searchValues, setSearchValues] = useState({
		startDate: '',
		endDate: '',
		status: {
			label: 'Pending',
			value: false
		}
	});
	const { paginationItems, data, refreshTable, isLoading } = useBackendPaginationFetcher({
		url: certifiedPaymentsUrl,
		mapper: mapper,
		key: 'certifiedPayments',
		constantSize: 10
	});
	const { navigate } = useNavigate();

	const SidePanel = () => {
		return (
			<Button
				variant="primary"
				onClick={() => {
					const body = {
						startDate: '',

						endDate: '',

						pageIndex: '0',

						pageSize: '100'
					};
					requestCertifiedPayments(body);
				}}
			>
				Request Payments
			</Button>
		);
	};

	useEffect(() => {
		if (!searchValues.startDate || !searchValues.endDate) {
			return;
		}
		if (searchValues?.startDate > searchValues?.endDate) {
			return;
		}
		setCertifiedPaymentsUrl(
			`/api/certified-payment/certified-payments/${searchValues.status.value}?startDate=${searchValues.startDate}&endDate=${searchValues.endDate}`
		);
	}, [searchValues]);

	return (
		<>
			<LeftPanel>
				<MaintenanceLeftSideNavigation />
			</LeftPanel>

			<CenterPanel>
				<BreadcrumbNav />
				<PageHeading title="Certified Payments" subTitle="View All" />

				<Row>
					<Col xs={12} md={3} className="mb-3">
						<InputLabel name={'From'} htmlFor={'startDate'} />
						<NewDatePicker
							id="startDate"
							name="startDate"
							onChange={(e) => {
								setSearchValues({
									...searchValues,
									startDate: `${dateFormatYYMMDD(e.target.value)}T00:00:00`
								});
							}}
							maxDate={new Date()}
							placeholderText={'mm/dd/yyyy'}
							isAutoDate
						/>
					</Col>
					<Col xs={12} md={3} className="mb-3">
						<InputLabel name={'To'} htmlFor={'endDate'} />
						<NewDatePicker
							id="endDate"
							name="endDate"
							onChange={(e) => {
								setSearchValues({
									...searchValues,
									endDate: `${dateFormatYYMMDD(e.target.value)}T23:59:59`
								});
							}}
							placeholderText={'mm/dd/yyyy'}
							minDate={searchValues?.startDate && new Date(searchValues?.startDate)}
							isAutoDate
						/>
						{searchValues?.endDate && searchValues?.startDate > searchValues?.endDate && (
							<div className="text-danger">End date cannot be before the start date.</div>
						)}
					</Col>
					<Col xs={12} md={3} className="mb-3">
						<SelectInput
							id={'status'}
							label={'Status'}
							defaultValue={'pending'}
							onChange={(e) => {
								setSearchValues({
									...searchValues,
									status: e
								});
							}}
							options={statusOptions}
							value={searchValues.status}
						></SelectInput>
					</Col>
				</Row>

				<Table
					columns={columns}
					data={data}
					exportCSV={true}
					exportPDF={true}
					limitRows={true}
					showSearch={true}
					paginationItems={paginationItems}
					isLoading={isLoading}
					enableRowFunction
					tableId={'certified-payments-table'}
					hoverMessage={'Select to process'}
					clickedRowFunction={(e) => {
						if (!searchValues.status.value) {
							setSelectedRowId(e.certifiedPaymentId);
							setShowPaymentModal(true);
						}
					}}
					accesibilityLabels={{ title: 'Process Certified Payment', alt: 'Processes the payment' }}
					entity={'Certified Payment'}
				/>
				{showPaymentModal && (
					<CertifiedPaymentModal
						show={showPaymentModal}
						setShow={setShowPaymentModal}
						paymentId={selectedRowId}
						status={searchValues?.status?.value}
					/>
				)}
			</CenterPanel>

			<RightPanel>
				<h3>Actions</h3>
				<SidePanel />
			</RightPanel>
		</>
	);
}

export default CertifiedPayments;
