import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect, { components, createFilter } from 'react-select';
import _ from 'lodash';
import FormLabel from '../../FormLabel';
import ErrorMessage from './ErrorMessage';
import { RequiredMark } from '../../common';

// Prevents searchable select to match with the value instead of the label
// First check is to prevent UT failing
export const fixFilterOptions = () =>
	createFilter instanceof Function
		? createFilter({
				matchFrom: 'any',
				stringify: (option) => `${option.label}`
		  })
		: null;

const Select = ({
	label,
	required,
	isInvalid,
	isDisabled,
	options,
	name,
	id,
	isSearchable,
	onChange,
	isMulti,
	onBlur,
	error,
	defaultValue = null,
	conditionallyRequired,
	flagsStyles,
	styles,
	noMargin,
	isClereable = false,
	...props
}) => {
	const [selectValue, setSelectValue] = useState();

	const toOption = (value) => {
		if (_.isArray(value) && options.length) {
			return value.map((v) => {
				const option = options.find((o) => o.value === v);
				return option ? option : null;
			});
		}

		return options.find((option) => option.value === value);
	};

	const Control = (props) => (
		<components.Control
			{...props}
			className={
				(props.className || '') +
				(Boolean(error) ? ' is-invalid' : '') +
				(isDisabled ? ' is-disabled' : '')
			}
		/>
	);

	useEffect(() => {
		setSelectValue(defaultValue ? toOption(defaultValue) : null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue, options]);

	return (
		<Form.Group className={noMargin ? '' : 'my-1'}>
			{!conditionallyRequired ? (
				<Form.Label htmlFor={id}>
					{label}
					{required && <RequiredMark />}
				</Form.Label>
			) : (
				<FormLabel {...conditionallyRequired} />
			)}

			<ReactSelect
				components={{ Control }}
				isDisabled={isDisabled}
				options={options}
				value={selectValue}
				name={name}
				aria-label={label ? label : `Select option for ${id}`}
				isSearchable={isSearchable}
				filterOption={fixFilterOptions()}
				onChange={(e) => {
					onChange(e);
					setSelectValue(e);
				}}
				placeholder={'-- Select --'}
				isMulti={isMulti}
				menuPlacement="bottom"
				onBlur={
					onBlur &&
					function (e) {
						setTimeout(() => {
							onBlur(e);
						}, 50);
					}
				}
				styles={styles}
				isClearable={isClereable}
				{...props}
			/>
			<ErrorMessage error={error} />
		</Form.Group>
	);
};

export default Select;
