import { useFormik } from 'formik';
import { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { SelectInput } from '../../../../components/common';
import FormikModal from '../../../../components/FormikModal';
import FormInput from '../../../../components/item/FormInput';
import { initialValues, priorityOptions, typeOptions, validationSchema } from './utils';

const ReportModal = ({ isOpen, closeModal }) => {
	const userInfo = useSelector((state) => state?.user?.userInfo);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedPriority, setSelectedPriority] = useState(null);

	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		errors,
		touched,
		resetForm,
		setFieldValue,
		setFieldTouched
	} = useFormik({
		initialValues: { ...initialValues, requester: `${userInfo?.firstName} ${userInfo?.lastName}` },
		validationSchema,
		onSubmit: () => {
			onClose();
		}
	});

	let onClose = () => {
		resetForm();
		closeModal();
		setSelectedType(null);
		setSelectedPriority(null);
	};

	return (
		<FormikModal
			title="Report a Problem"
			isOpen={isOpen}
			closeable
			onClose={onClose}
			customFooter={
				<>
					<Button variant="primary" onClick={handleSubmit}>
						Submit
					</Button>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
				</>
			}
		>
			<Col md={6}>
				<FormInput
					label="Requester"
					name="requester"
					required
					value={values.requester}
					disabled
					id="requester"
				/>
			</Col>

			<Col md={6} className="mt-3">
				<SelectInput
					label="Type of Problem"
					required
					name="type"
					value={selectedType}
					onChange={(e) => {
						setFieldValue('type', e.value);
						setSelectedType(e);
					}}
					onBlur={() => setFieldTouched('type', true)}
					options={typeOptions}
					id="type"
				/>
				{touched.type && errors.type && <p className="text-danger small mt-1">{errors.type}</p>}
			</Col>
			<Col md={6} className="mt-3">
				<SelectInput
					label="Priority"
					required
					name="priority"
					value={selectedPriority}
					onChange={(e) => {
						setFieldValue('priority', e.value);
						setSelectedPriority(e);
					}}
					onBlur={() => setFieldTouched('priority', true)}
					options={priorityOptions}
					id="priority"
				/>
				{touched.priority && errors.priority && (
					<p className="text-danger small mt-1">{errors.priority}</p>
				)}
			</Col>
			<Col md={6} className="mt-3">
				<FormInput
					label="Subject"
					name="subject"
					required
					value={values.subject}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.subject && errors.subject}
					id="subject"
				/>
				{touched.subject && errors.subject && (
					<p className="text-danger small mt-1">{errors.subject}</p>
				)}
			</Col>
			<Col className="mt-3">
				<FormInput
					label="Description"
					name="description"
					required
					value={values.description}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.description && errors.description}
					type="textarea"
					id="description"
				/>
				{touched.description && errors.description && (
					<p className="text-danger small mt-1">{errors.description}</p>
				)}
			</Col>
		</FormikModal>
	);
};

export default ReportModal;
