import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { fetchUnReviewedEfilings } from '../../../redux/reducers/efiling.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { EfileService } from '../../../services/EfileService';

const EfilingNavButton = () => {
	const { totalUnReviewedEfilings = 0 } = useSelector((state) => state.efiling);
	const { getUnRevievedEfile } = EfileService();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchUnReviewedEfilings(getUnRevievedEfile));
	}, []);

	return (
		<div className="position-relative">
			<Link className="nav-link" to="/efile">
				<i className="far fa-envelope"></i> E-Filing
			</Link>
			<span className={styles.alertCounter}>{totalUnReviewedEfilings}</span>
		</div>
	);
};

export default EfilingNavButton;
