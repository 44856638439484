// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_responseAlertText__l79B0 {\n  margin-left: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/ResponseAlert/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD","sourcesContent":[".responseAlertText {\n\tmargin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responseAlertText": "styles_responseAlertText__l79B0"
};
export default ___CSS_LOADER_EXPORT___;
