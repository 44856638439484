import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './AffectsReportAlertTooltip.scss';

const AffectsReportAlertTooltip = ({ isReportAffected = {}, children }) => {
	const { tooltip } = isReportAffected;
	const hasCustomTooltip = Boolean(tooltip);
	const showTooltip = typeof isReportAffected === 'boolean' || isReportAffected.isAffected;
	// Default tooltip text in case no value is given
	const tooltipText = hasCustomTooltip ? tooltip : 'This field feeds one or more state reports.';

	return (
		<div className="affects-report-alert-container">
			{children}
			{showTooltip && (
				<OverlayTrigger
					placement="top"
					overlay={
						<Tooltip role="alert" aria-live="polite">
							{tooltipText}
						</Tooltip>
					}
				>
					<i className="fas fa-info-circle fa-lg" />
				</OverlayTrigger>
			)}
		</div>
	);
};

export default AffectsReportAlertTooltip;
