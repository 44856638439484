import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';
import { SUCCESFUL_REQUEST_CODE } from '../utils';

export const newPartyHTTP = () => {
	const { get, post } = HttpService();

	const getPartyDismissalReasons = async (setData) => {
		const response = await get(
			`${BASE_URL}/api/code-table-management/code-tables/categories/party_dismissal_reason`
		);
		return response.data;
	};
	const getAttornies = async (endpoint, setData) => {
		const response = await get(`${BASE_URL}${endpoint}`);
		setData(response.data);
	};
	const getPartyTypes = async (endpoint, setData) => {
		const response = await get(`${BASE_URL}${endpoint}`);
		SUCCESFUL_REQUEST_CODE.includes(response.status) && setData(response.data);
	};
	const getClassification = async (endpoint, setData) => {
		const response = await get(`${BASE_URL}${endpoint}`);
		setData(response.data);
	};
	const getSex = async (setData) => {
		const response = await get(
			`${BASE_URL}/api/code-table-management/code-tables/categories/sex_code`
		);
		setData(response.data);
	};

	const getFlags = async () => {
		const maxNumber = 500;
		const response = await get(
			`${BASE_URL}/api/code-flag-management/flag-codes?page=0&size=${maxNumber}`
		);
		return response;
	};

	const getFlagsPartyView = (isGlobalAdmin) => {
		return get(
			`${BASE_URL}/api/code-flag-management/flag-codes-by-clerk-id?page=0&size=1000`,
			'getFlagsPartyView',
			undefined,
			isGlobalAdmin,
			undefined
		);
	};

	const getAttorneyTypes = async (endpoint, setData) => {
		const response = await get(`${BASE_URL}${endpoint}`);
		SUCCESFUL_REQUEST_CODE.includes(response.status) && setData(response.data);
	};
	const postNewParty = (body) => {
		const url = `${BASE_URL}/api/party-management/party`;
		return post(url, body, 'newParty', {});
	};

	const getCodeTables = async (codeType, setState) => {
		const codeTableObj = await get(`${BASE_URL}/api/code-table-management/code-types`).then(
			function (res) {
				var result = res.data.filter((obj) => {
					return obj.codeType === codeType;
				});
				return result;
			}
		);

		const options = await get(
			`${BASE_URL}/api/code-table-management/codes?codeCategoryId=${codeTableObj[0].codeCategoryId}&codeTypeId=${codeTableObj[0].codeTypeId}`
		).then(function (res) {
			return res.data.fields;
		});
		setState(options);
	};

	return {
		postNewParty,
		getAttornies,
		getPartyTypes,
		getClassification,
		getSex,
		getAttorneyTypes,
		getCodeTables,
		getPartyDismissalReasons,
		getFlags,
		getFlagsPartyView
	};
};
