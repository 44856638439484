import { useEffect, useRef, useState } from 'react';
import {
	BreadcrumbNav,
	CenterPanel,
	LeftPanel,
	PageHeading,
	RightPanel
} from '../../components/layout';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import CaseLeftSideNavigation from '../../components/Case/CaseLeftSideNavigation';
import CaseNumber from '../../components/CaseNumber';
import SidePanelButton from '../../components/SidePanelButton/SidePanelButton';
import { Formik } from 'formik';
import { PLACEHOLDER_TEXT } from '../../constants/placeholders';
import { MoneyInput, RequiredMark, SelectInput } from '../../components/common';
import NewDatePicker from '../../components/NewDatePicker';

import TableWithIntegration from '../../components/layout/TableWithIntegration/TableWithIntegration';
import { usePaymentMethodData } from '../../hooks/usePaymentMethodData';
import {
	payOutSchema,
	payoutPaidFeesColumn,
	payoutInitialValues,
	disbursementDistributionColumn
} from './MakePayoutData';
import ResponseAlert, { TOAST_TYPES } from '../../components/ResponseAlert';
import { RefundService } from '../../services/RefundService';
import { PaymentService } from '../../services/PaymentService';
import { FieldFormTextInput } from '../../components/forms';
import {
	SUCCESFUL_REQUEST_CODE,
	dashes2slashes,
	dateFormatYYYYMMDDslashed,
	errorClassSelector,
	formatDate
} from '../../utils';
import ReceiptFormModal from '../PaymentCompleted/ReceiptFormModal';
import FullReceiptModal from '../PaymentCompleted/FullReceiptModal';
import PosReceiptModal from '../PaymentCompleted/PosReceiptModal';
import { PAGINATION_SIZE_ARRAY } from '../../constants/tableWithIntegration';

export const MakePayOut = () => {
	const { paymentId, caseId, refundId } = useParams();
	const { methodsOption } = usePaymentMethodData('false');
	const { methodsOption: methodsOptionData } = usePaymentMethodData('true');
	const [refundAmountApplied, setRefundAmountApplied] = useState();
	const navigate = useNavigate();
	const formRef = useRef();
	const [errorMessage, setErrorMessage] = useState(null);
	const [refundValues, setRefundValues] = useState();
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState([]);
	const { getPayment } = PaymentService();
	const [paymentRefundId, setPaymentRefundId] = useState(refundId);
	const [feesData, setFeesData] = useState([]);
	const [disbursementDistribution, setDisbursementDistribution] = useState([]);
	const [loadingTableData, setLoadingTableData] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [showReceiptForm, setShowReceiptForm] = useState(false);
	const [buttonText, setButtonText] = useState('');
	const [recipientData, setRecipientData] = useState('');
	const [showFullReceiptModal, setShowFullReceiptModal] = useState('');
	const [showPosReceiptModal, setShowPosReceiptModal] = useState();
	const [refundMethodOptions, setRefundMethodOptions] = useState([]);

	const { getRefundDetails, postRefund, getHistory, getFeesFine, getDisbursementDistribution } =
		RefundService();

	const [toast, setToast] = useState({
		type: '',
		text: '',
		show: false
	});

	useEffect(() => {
		const getSelectedPayment = async () => {
			const res = await getPayment(paymentId);
			setCurrentPaymentMethod({
				label: res?.data?.methods[0].description,
				value: res?.data?.methods[0].paymentCode
			});
		};

		const fetchHistory = async () => {
			if (paymentRefundId) {
				fetchDistributions();
			} else {
				setLoadingTableData(true);
				const historyRes = await getHistory(caseId);
				const historyIds = historyRes?.data?.content?.map((element) => element.paymentId);
				const feeRes = await getFeesFine({
					caseId: caseId,
					refundNo: paymentRefundId,
					paymentIds: historyIds
				});
				if (SUCCESFUL_REQUEST_CODE.includes(feeRes.status)) {
					setFeesData(
						feeRes?.data
							?.filter((x) => x.totalAmount !== 0)
							?.map((x) => ({
								...x,
								totalAmount: `\$${x.totalAmount.toFixed(2)}`,
								createdDate: dateFormatYYYYMMDDslashed(x?.createdDate),
								refundAmountApplied: ''
							}))
					);
				} else {
					setFeesData([]);
				}
				setLoadingTableData(false);
			}
		};

		getSelectedPayment();

		fetchHistory();
	}, []);

	const fetchDistributions = async () => {
		setLoadingTableData(true);
		const historyRes = await getHistory(caseId);
		const historyIds = historyRes?.data?.content?.map((element) => element.paymentId);
		const distRes = await getDisbursementDistribution({
			caseId: caseId,
			refundNo: paymentRefundId,
			paymentIds: historyIds
		});

		if (SUCCESFUL_REQUEST_CODE.includes(distRes.status)) {
			setDisbursementDistribution(
				distRes?.data
					?.filter((x) => x?.totalAmount !== 0)
					?.map((x) => ({
						...x,
						totalAmount: `\$${x.totalAmount.toFixed(2)}`,
						createdDate: formatDate(x.createdDate)
					}))
			);
		} else {
			setDisbursementDistribution([]);
		}
		setLoadingTableData(false);
	};

	useEffect(() => {
		if (paymentRefundId) {
			fetchRefundDetails();
			fetchDistributions();
		}
	}, [paymentRefundId, feesData]);

	useEffect(() => {
		if (methodsOptionData.length > 0 && methodsOption.length > 0) {
			setRefundMethodOptions([...methodsOptionData, ...methodsOption]);
		}
	}, [methodsOptionData, methodsOption]);

	const fetchRefundDetails = async () => {
		const res = await getRefundDetails(paymentRefundId);
		if (SUCCESFUL_REQUEST_CODE.includes(res.status)) {
			setRefundValues({
				...res?.data,
				refundDate: res?.data?.refundDate
					? new Date(dashes2slashes(res?.data.refundDate))
					: new Date(),
				checkDate: res?.data?.checkDate ? new Date(dashes2slashes(res?.data.checkDate)) : null
			});
		} else {
			setToast((prev) => ({
				...prev,
				show: true,
				text: 'Failed to load refund.',
				type: TOAST_TYPES.DANGER
			}));
		}
	};

	useEffect(() => {}, [errorMessage]);

	const handleFeeRowAmountChange = (row) => {
		setFeesData(
			feesData.map((item, index) =>
				index === row.rowIndex ? { ...item, refundAmountApplied: row.textContent } : item
			)
		);
		let tempList = refundAmountApplied ?? {};
		tempList[row.item.id] = parseFloat(row.textContent);
		if (parseFloat(row.textContent) !== 0) setRefundAmountApplied(tempList);
	};

	const compareAmount = (refund, total) => {
		const amount = refund - total.toFixed(2);
		if (amount < 0) {
			setErrorMessage(
				`The amount distributed exceeds the total of the payment by \$${Math.abs(
					amount.toFixed(2)
				)}`
			);
			return false;
		} else if (amount > 0) {
			setErrorMessage(`Amount left to distribute : \$${amount.toFixed(2)}`);
			return false;
		} else {
			setToast((prev) => ({
				...prev,
				show: false
			}));
			setErrorMessage(null);
			return true;
		}
	};

	const removeSymbol = (e) => parseFloat(e.replace('$', ''));
	return (
		<>
			<LeftPanel>
				<CaseLeftSideNavigation caseId={caseId} />
			</LeftPanel>
			<CenterPanel>
				<BreadcrumbNav />

				<PageHeading
					title="Payments"
					subTitle={paymentRefundId ? 'Disbursement Information' : 'Apply Refund'}
				/>

				<ResponseAlert
					show={toast.show}
					text={toast.text}
					type={toast.type}
					delay={3000}
					close={() => {
						setToast({
							...toast,
							show: false
						});
					}}
				/>

				<Formik
					initialValues={payoutInitialValues(refundValues)}
					validationSchema={payOutSchema}
					onSubmit={async (fieldValues) => {
						let total = 0;
						if (refundAmountApplied) {
							total = Object.values(refundAmountApplied).reduce(
								(current, value) => current + value
							);
						}

						if (compareAmount(parseFloat(fieldValues.refundAmount), total)) {
							const ids = feesData.map((x) => {
								if (refundAmountApplied[x.id]) {
									return {
										id: x.id,
										amount: removeSymbol(x.totalAmount) - refundAmountApplied[x.id],
										refundAmount: refundAmountApplied[x.id]
									};
								}
							});

							const newList = ids
								.map((e) => {
									var item = feesData.find((x) => x?.id == e?.id);
									if (item && e) {
										e = {
											feeId: parseFloat(e.id),
											amount: e.amount,
											refundAmount: e.refundAmount,
											description: item.description,
											date: new Date(),
											caseId: parseFloat(caseId),
											paymentId: parseFloat(paymentId)
										};
									}

									return e;
								})
								.filter((item) => item !== undefined);

							const incorrectValue = newList.find((x) => x?.amount && x?.amount < 0);

							if (incorrectValue) {
								setErrorMessage(
									`Amount for Fee/Fine type ${
										incorrectValue.description
									} is exceeding by ${Math.abs(incorrectValue.amount.toFixed(2))}`
								);
							} else {
								setErrorMessage(null);
								const payload = {
									...fieldValues,
									caseId: parseFloat(caseId),
									paymentId: parseFloat(paymentId),
									refundPaybreakDTO: newList.map((element) => ({
										...element,
										amount: element.refundAmount
									})),
									refundMethod: currentPaymentMethod?.value
								};
								setIsSubmitting(true);
								const res = await postRefund(payload);
								if (SUCCESFUL_REQUEST_CODE.includes(res.status)) {
									setToast((prev) => ({
										...prev,
										show: true,
										text: 'Refund completed successfully.',
										type: TOAST_TYPES.SUCCESS
									}));
									setPaymentRefundId(res?.data?.refundNo);
									setIsSubmitting(false);
								} else {
									setToast((prev) => ({
										...prev,
										show: true,
										text: 'Failed to save refund.',
										type: TOAST_TYPES.DANGER
									}));
									setIsSubmitting(false);
								}
							}
						}
					}}
					enableReinitialize={paymentRefundId}
					innerRef={formRef}
				>
					{(props) => (
						<Form id="applyRefund" aria-label="Apply Refund" onSubmit={props.handleSubmit}>
							<div className="container">
								<Row className="mb-4">
									<Col xs={12} md={4}>
										<Form.Label>
											Refund To <RequiredMark />
										</Form.Label>
										<Form.Control
											className={props.errors.refundTo && props.touched.refundTo && 'is-invalid'}
											id="refundTo"
											aria-label="refundTo"
											name="refundTo"
											onChange={props.handleChange}
											value={props?.values?.refundTo}
											disabled={paymentRefundId}
											placeholder={`${PLACEHOLDER_TEXT} refund to`}
										></Form.Control>
										{props.errors.refundTo && props.touched.refundTo ? (
											<div className="text-danger small">{props.errors.refundTo}</div>
										) : null}
									</Col>

									<Col xs={12} md={4}>
										<Form.Label>
											Refund Amount <RequiredMark />
										</Form.Label>
										<MoneyInput
											id="refundAmount"
											name="refundAmount"
											label={''}
											value={paymentRefundId ? refundValues?.refundAmount : null}
											handlerinput={props.handleChange}
											placeholder={`${PLACEHOLDER_TEXT} refund amount`}
											className={errorClassSelector(props.errors, props.touched, 'refundAmount')}
											disabled={paymentRefundId}
										/>
										{props.errors.refundAmount && props.touched.refundAmount ? (
											<div className="text-danger small">{props.errors.refundAmount}</div>
										) : null}
									</Col>

									<Col xs={12} md={4}>
										<Form.Label htmlFor="refundDate">Refund Date</Form.Label>
										<NewDatePicker
											id="refundDate"
											name="refundDate"
											onChange={props.handleChange}
											disabled={paymentRefundId}
											type="date"
											className={
												props.errors.refundDate &&
												props.touched.refundDate && ['is-invalid form-control']
											}
											initialValue={
												paymentRefundId && refundValues ? refundValues.refundDate : new Date()
											}
											isInvalid={props.errors.refundDate && props.touched.refundDate}
											isAutoDate
										/>
										{props.errors.refundDate && props.touched.refundDate ? (
											<div className="text-danger small">{props.errors.refundDate}</div>
										) : null}
									</Col>
								</Row>

								<Row className="mb-4 mt-4">
									<Col xs={12} md={4}>
										<Form.Label>
											Refund By
											<RequiredMark />
										</Form.Label>
										<Form.Control
											className={props.errors.refundBy && props.touched.refundBy && 'is-invalid'}
											id="refundBy"
											aria-label="refundBy"
											name="refundBy"
											disabled={paymentRefundId}
											onChange={props.handleChange}
											value={props?.values?.refundBy}
											placeholder={`${PLACEHOLDER_TEXT} refund by`}
										></Form.Control>
										{props.errors.refundBy && props.touched.refundBy ? (
											<div className="text-danger small">{props.errors.refundTo}</div>
										) : null}
									</Col>
									{!refundValues?.fromDisbursement && (
										<>
											<Col xs={12} md={4}>
												<SelectInput
													id="refundMethod"
													label="Refund Method"
													name="refundMethod"
													options={refundMethodOptions}
													isSearchable
													isDisabled
													value={refundMethodOptions.find(
														(element) => element.label === currentPaymentMethod?.label
													)}
													placeholderText="- Select -"
													onChange={props.handleChange}
													isInvalid={props.touched.refundMethod && props.errors.refundMethod}
												/>
											</Col>

											<Col xs={12} md={4}>
												<FieldFormTextInput
													id="checkNumber"
													label={'Refund Check Number'}
													disabled={paymentRefundId}
													placeholder={`${PLACEHOLDER_TEXT} refund check number`}
													onChange={(e) => {
														props.setFieldValue('checkNumber', e.target.value);
													}}
												/>
											</Col>
										</>
									)}
								</Row>
								{!refundValues?.fromDisbursement && (
									<Row>
										<Col xs={12} md={3}>
											<Form.Label htmlFor="checkDate">
												Check Date
												<span className="text-danger fw-bold mx-1" aria-hidden="true">
													*
												</span>
											</Form.Label>
											<NewDatePicker
												id="checkDate"
												name="checkDate"
												disabled={paymentRefundId}
												onChange={props.handleChange}
												isInvalid={props.errors.checkDate && props.touched.checkDate}
												initialValue={
													paymentRefundId && refundValues ? refundValues.checkDate : new Date()
												}
												isAutoDate
											/>
											{props.errors.checkDate && props.touched.checkDate ? (
												<div className="text-danger small">{props.errors.checkDate}</div>
											) : null}
										</Col>
									</Row>
								)}
								{!refundValues?.fromDisbursement && (
									<>
										<Row className="mb-3 gap-3 gap-md-0 mt-4">
											<Col>
												<FieldFormTextInput
													id="refundAddress"
													type="textarea"
													rows={3}
													label={'Refund Address'}
													disabled={paymentRefundId}
													placeholder={`${PLACEHOLDER_TEXT} refund address`}
													onChange={(e) => {
														props.setFieldValue('refundAddress', e.target.value);
													}}
												/>
											</Col>
										</Row>

										<Row className="mb-3 gap-3 gap-md-0 mt-4">
											<Col>
												<FieldFormTextInput
													id="description"
													type="textarea"
													rows={3}
													disabled={paymentRefundId}
													label={'Description'}
													placeholder={`${PLACEHOLDER_TEXT} description`}
													onChange={(e) => {
														props.setFieldValue('description', e.target.value);
													}}
												/>
											</Col>
										</Row>
									</>
								)}
								{!refundValues?.fromDisbursement && (
									<Row className="mb-3 gap-3 gap-md-0 mt-4">
										<TableWithIntegration
											tableId={
												paymentRefundId ? 'disbursement-distribution-table' : 'fee-group-table'
											}
											columns={
												paymentRefundId ? disbursementDistributionColumn : payoutPaidFeesColumn
											}
											data={paymentRefundId ? disbursementDistribution : feesData}
											limitRows={paymentRefundId}
											exportCSV={paymentRefundId}
											exportPDF={paymentRefundId}
											showSearch={paymentRefundId}
											paginationItems={{
												parentPage: 0,
												setParentPage: () => {},
												setSize: () => {},
												totalElements: paymentRefundId
													? disbursementDistribution?.length
													: feesData?.length,
												totalPages: 1,
												allData: paymentRefundId ? disbursementDistribution : feesData,
												showAll: false,

												size: paymentRefundId
													? disbursementDistribution?.length ?? 0
													: feesData?.length ?? 0,
												paginationSizeArray: PAGINATION_SIZE_ARRAY
											}}
											enableServerSort={false}
											accesibilityLabels={{
												title: 'Fees',
												alt: 'Fees.'
											}}
											onRowEdited={(e) => {
												handleFeeRowAmountChange({
													...e,
													textContent: e.textContent.trim() !== '' ? e.textContent : 0
												});
											}}
											formTitle={paymentRefundId ? 'Disbursement Distribution' : 'Paid Fees/Fine'}
											isLoading={loadingTableData}
										/>

										{errorMessage && !paymentRefundId && (
											<h5 className="text-center mb-3 ml-1 fw-bold text-danger">{errorMessage}</h5>
										)}
									</Row>
								)}
							</div>
						</Form>
					)}
				</Formik>
				{showReceiptForm && (
					<ReceiptFormModal
						show={showReceiptForm}
						setShow={setShowReceiptForm}
						setShowPDF={setShowFullReceiptModal}
						setButtonText={setButtonText}
						setShowPos={setShowPosReceiptModal}
						setData={setRecipientData}
					/>
				)}

				{showFullReceiptModal && (
					<FullReceiptModal
						show={showFullReceiptModal}
						setShow={setShowFullReceiptModal}
						buttonText={buttonText}
						recipientData={recipientData}
						paymentId={paymentId}
						caseId={caseId}
						setMainToast={setToast}
						refundId={paymentRefundId}
					/>
				)}
				{showPosReceiptModal && (
					<PosReceiptModal
						paymentId={paymentId}
						show={showPosReceiptModal}
						setShow={setShowPosReceiptModal}
						refundId={paymentRefundId}
					/>
				)}
			</CenterPanel>

			<RightPanel>
				<CaseNumber />
				{!refundValues?.fromDisbursement && <h3>Actions</h3>}

				{!paymentRefundId ? (
					<SidePanelButton
						id="savePayOut"
						text="Save"
						onClick={formRef?.current?.handleSubmit}
						disabled={isSubmitting}
					/>
				) : (
					!refundValues?.fromDisbursement && (
						<SidePanelButton
							id="print"
							text="Print/Send Receipt"
							onClick={() => {
								setShowReceiptForm(true);
							}}
						/>
					)
				)}

				{!paymentRefundId && (
					<Button
						variant="secondary"
						onClick={() => {
							navigate(-1);
						}}
					>
						Cancel
					</Button>
				)}
			</RightPanel>
		</>
	);
};
