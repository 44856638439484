import { BASE_URL } from '../utils/apiUtils';
import { HttpService } from './HttpService';

export const JudgeService = () => {
	const { get, post } = HttpService();

	const getJudges = (size = 10, clerkId = null) => {
		const url = `${BASE_URL}/api/judge/judge/view?size=${size}&page=0`;
		return get(url, 'GetJudges', [], null, clerkId);
	};
	const getActiveJudges = async (size = 10) => {
		const url = `${BASE_URL}/api/judge/active?size=${size}&page=0`;
		return get(url, 'GetJudges', []);
	};
	const getJudge = (judgeId) => {
		const url = `${BASE_URL}/api/judge/judge/${judgeId}`;
		return get(url, 'GetJudge', []);
	};

	const getCourtOptions = () => {
		const url = `${BASE_URL}/api/courts?page=0&size=1000`;
		return get(url, 'GetCourts', []);
	};

	const getJudgesByCourt = (court_id) => {
		const url = `${BASE_URL}/api/judge/court/${court_id}`;
		return get(url, 'GetJudgesByCourt', []);
	};

	const createJudge = async (data) => {
		const url = `${BASE_URL}/api/judge/judge`;
		return await post(url, data, 'newJudge', {});
	};

	return {
		getJudges,
		getJudge,
		getActiveJudges,
		getCourtOptions,
		getJudgesByCourt,
		createJudge
	};
};
