import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

/**
 * ToastAlert.
 * @param {string} body - Text in the body of the Alert
 * @param {string} containerClass - Optional style for the container
 * @param {number} delay - Number of miliseconds until the toast hides automatically, 6000 by default
 * @param {string} marginTop - Percentage value for top margin
 * @param {Function} onClose - Functiion trigerred when delay finish or close button is clicked
 * @param {boolean} show - Flag to show alert.
 * @param {string} title - Text on the top of the alert
 * @param {string} type - Defines colors of the alert, use TOAST_TYPES for better practice.
 * @returns {ReactElement} returns JSX with the component ToastAlert.
 */

export const TOAST_TYPES = {
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  WARNING: "WARNING",
};

export const ToastAlert = ({
  body,
  containerClass,
  delay,
  marginTop,
  onClose,
  show,
  title,
  type,
}) => {
  const _containerClass = containerClass ? containerClass : "";
  const _delay = delay ? delay : 6000;
  const margin = marginTop ? marginTop : "0%";
  const style = { marginTop: margin };
  const BODY_WHITE_CLASS = "bg-white";

  const BODY_COLOR = {
    [TOAST_TYPES.SUCCESS]: BODY_WHITE_CLASS,
    [TOAST_TYPES.WARNING]: BODY_WHITE_CLASS,
    [TOAST_TYPES.DANGER]: BODY_WHITE_CLASS,
  };

  const isMobile = window.innerWidth <= 800 && window.innerHeight <= 800;

  return (
    <ToastContainer
      className={`${styles.toastContainer} ${_containerClass}`}
      position={isMobile ? "bottom-center" : "top-center"}
      style={style}
    >
      <Toast
        autohide
        bg={type.toLowerCase()}
        className={styles.toast}
        delay={_delay}
        onClose={onClose}
        show={show}
      >
        <Toast.Header>
          <strong className="me-auto text-dark">{title}</strong>
        </Toast.Header>

        <Toast.Body className={BODY_COLOR[type]}>{body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

ToastAlert.propTypes = {
  body: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  delay: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  marginTop: PropTypes.string,
};

export default ToastAlert;
