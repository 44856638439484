import { useContext, useEffect, useCallback, useState } from 'react';
import {
	UNSAFE_NavigationContext as NavigationContext,
	useLocation,
	useNavigate
} from 'react-router-dom';
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 * @param blocker - A function that takes a transaction object and returns a function that unblocks the
 * transaction.
 * @param when - A boolean that determines whether the blocker should be active or not.
 */
const useBlocker = (blocker, when) => {
	const { navigator } = useContext(NavigationContext);

	useEffect(() => {
		if (!when) return;

		const unblock = navigator.block((tx) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				}
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
};
/**
 * Prompts the user with an Alert before they leave the current screen.
 * It will prompt the user before navigating away from the current page if the user has made changes to
 * the page
 * @param when - boolean
 * @param showAlert - This is a function that sets the state of the modal to show or hide.
 * @param  alertChildren - This is a function that will be called when the user tries to navigate away from
 * the page.
 */
export const usePromptBlocker = (when, showAlert, alertChildren) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [lastLocation, setLastLocation] = useState(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback(() => {
		showAlert(false);
	}, [showAlert]);

	const blocker = useCallback(
		(nextLocation) => {
			if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
				setLastLocation(nextLocation);
				showAlert(true);
				alertChildren();
				return false;
			}
			return true;
		},
		[confirmedNavigation, location, alertChildren, showAlert]
	);

	const confirmNavigation = useCallback(() => {
		showAlert(false);
		setConfirmedNavigation(true);
	}, [showAlert]);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			const { pathname, state } = lastLocation.location;
			navigate(pathname, { state });
		}
	}, [confirmedNavigation, lastLocation, navigate]);

	useBlocker(blocker, when);

	return [confirmNavigation, cancelNavigation];
};
